import React, { useEffect, useState, Fragment, useRef } from 'react'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import ArrowDown from '@material-ui/icons/ArrowDownwardSharp'
import AddSharp from '@material-ui/icons/AddSharp'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Typography,
  TextField,
  Fab
} from '@material-ui/core/'
import Autocomplete from '@material-ui/lab/Autocomplete'
import EditIcon from '@material-ui/icons/Edit'
import Add from '@material-ui/icons/Add'
import { Link, Route } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import { withStyles } from '@material-ui/core/styles'
import ClassTypesEditor from './ClassTypesEditor'
import { exportList , runQuery } from '../api'
import { handle } from '../utilities'
import { LoginRedirector } from './Auth'
import { Body, Content } from './Shell'
import FabFabulous from './FabFabulous'
import { GET_LOCATIONS } from './Locations'
import { GET_ROLES } from './Roles'


const ClassesStyles = theme => ({
  fab: {
    position: 'fixed',
    bottom: 30,
    right: 30,
    color: '#fff'
  }
})

export const GET_CLASS_TYPES = gql`
query gClassType {
  class_types(order: "name") {
    id
    class_type_id
    name
    schema_type
    active
  }
}
`

export function ClassType(props) {
  const { class_type_id, name, schema_type, active } = props
  const inActive = '#AAA'

  // const [updateTimePunch, { data: { out: update_out } = {} }] = useMutation(UPDATE_TIME_PUNCH_LEAD)

  return (
    <TableRow>
      <TableCell style={{ color: active ? '#000' : inActive }} key={0}>
        <Link to={`/class-type/${class_type_id}`}>
          <IconButton>
            <EditIcon />
          </IconButton>
        </Link>
      </TableCell>
      <TableCell style={{ color: active ? '#000' : inActive }} key={1}>
        {name}
      </TableCell>
    </TableRow>
  )
}

function ClassTypesTable(props) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Action</TableCell>
          <TableCell>Name</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{props.children}</TableBody>
    </Table>
  )
}

export function ClassTypes(props) {
  const { data: { class_types = [] } = {} } = useQuery(GET_CLASS_TYPES)

  const closeEditor = () => {
    props.history.push('/class-type')
  }

  const exportClassTypes = () => {
    exportList({
      name: 'Class_types',
      data: class_types,
      fields: [
        'class_type_id',
        'string_id',
        'schema_type',
        'name',
        'template',
        'sub',
        'active'
      ]
    })
  }

  const updateCache = (cache, newClass_type) => {
    let data
    const test = cache.readQuery({ query: GET_CLASS_TYPES })
    const { class_types } = test
    console.log({ class_types })
    data = {
      class_types: class_types
        ? [newClass_type, ...class_types]
        : [newClass_type]
    }
    cache.writeQuery({
      query: GET_CLASS_TYPES,
      data
    })
  }

  // file upload handling
  const inputFile = useRef(null)
  const openUpload = () => {
    // `current` points to the mounted file input element
    inputFile.current.click()
  }
  // const { data: { class_types = [] } = {} } = useQuery(GET_CLASS_TYPES);
  return (
    <Body>
      <LoginRedirector />
      <Content>
        <ClassTypesTable>
          {class_types.map(u => (
            <ClassType key={u.id} {...u} />
          ))}
        </ClassTypesTable>
        <Route
          path={`${props.match.url  }/:class_type_id`}
          component={prps => (
            <ClassTypesEditor
              {...prps}
              open={typeof prps.match.params.class_type_id === 'string'}
              updateCache={updateCache}
              onClose={closeEditor}
            />
          )}
        />
        <FabFabulous
          actions={actions}
          exportClassTypes={e => exportClassTypes(class_types)}
          addClassType={e => {
            props.history.push('/class-type/new')
          }}
        />
      </Content>
    </Body>
  )
}

export default withStyles(ClassesStyles)(ClassTypes)

export function ClassTypePicker(props) {
  const [val, setVal] = useState(-1)
  const { onChange, value = '', inputId: key, color, disabled } = props
  const { data: { class_types = [] } = {} } = useQuery(GET_CLASS_TYPES)

  useEffect(() => {
    class_types.find((c, i) => {
      const found = c.string_id === value
      if (found) {
        setVal(i)
      }
      return found
    })
  }, [value, class_types])

  return (
    <Autocomplete
      id={key}
      options={class_types}
      getOptionLabel={class_type => class_type.name || ''}
      style={{ width: 150, color }}
      value={val === -1 ? val : class_types[val]}
      onChange={onChange}
      autoHighlight
      disabled={disabled}
      renderInput={params => (
        <TextField {...params} style={{ color }} fullWidth />
      )}
    />
  )
}

const actions = [
  { icon: <ArrowDown />, name: 'Export', onClick: 'exportClassTypes' },
  { icon: <AddSharp />, name: 'Create', onClick: 'addClassType' }
]
