import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from 'firebase'
import { Divider } from '@material-ui/core'
import { Body } from './Shell'

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/timecard',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      buttonColor: "#8bc34a",
      autoUpgradeAnonymousUsers: false,
    },
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    // firebase.auth.EmailAuthProvider.PROVIDER_ID
  ]
}

export function Login(props)
{
  const {
    loggedIn,
    classes: { wrapper, body, title, input, buttonWrapper, error },
    create
  } = props
  return (
    <Body className={body}>
      <Paper className={wrapper}>
        <h2>Lumia</h2>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      </Paper>
    </Body>
  )
}


const AuthStyles = theme => ({
  body: {
    justifyContent: 'center'
  },
  wrapper: {
    padding: 30,
    width: '100%',
    maxWidth: 300
  },
  title: {
    marginBottom: 10
  },
  input: {
    marginBottom: 10,
    width: '100%'
  },
  buttonWrapper: {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  error: {
    color: 'rgb(227, 19, 19)',
    fontSize: 12,
    fontStyle: 'italic',
    width: '100%',
    display: 'block',
    marginBottom: 4
  }
})

Login = connect(
  state => ({
    loggedIn: state.auth.loggedIn
  }),
  {  }
)(Login)
Login = withStyles(AuthStyles)(Login)

export let LoginRedirector = ({ loggedIn, checkingAuthState }) =>
  !loggedIn && !checkingAuthState && <Redirect to="/login" />
LoginRedirector = connect(state => ({
  loggedIn: state.auth.loggedIn,
  checkingAuthState: state.auth.checkingAuthState
}))(LoginRedirector)

export function Auth(props)
{
  return <props.children {...props} />
}

export function NoBelong(props)
{
  return (
    <Body>
      <Paper style={{padding: 30}}>
        <h1>OOPS!</h1>
        <p>
          Looks like you logged in and can't do anything. If you think you
          reached this page in error, contact your supervisor. Also check the
          email that you are logged in as by clicking on the icon of the person
          above, your supervisor will need it in order to get you logged in.
        </p>
      </Paper>
    </Body>
  )
}