import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'

const SearchStyles = theme => ({
  marginBottom: '10px',
  search:
    {
      marginBottom: '10px'
    }
})

class Search extends Component{
  constructor(){
    super()
    this.state = {
    }
  }

  set = (key, e) => {
    const val = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    // this.setState({
    //   [key]: val
    // })
    this.props.onChange(val)
  }

  render(){
    const { label, search } = this.props
    return(
      <Grid container spacing={10} style={{justifyContent: "center", marginBottom: "10px"}}>
        <Grid style={{ alignItems: 'center', display: "flex" }} item sm={8} xs={12}>
          <SearchIcon style={{color: "#8bc34a", marginTop: 5}} />
          <TextField value={search} style={{width: "calc(100% - 34px)", marginLeft: "5px"}} onChange={e=>{this.set('search',e)}} autoFocus label={label} />
        </Grid>
      </Grid>
    )
  }
}
export default withStyles(SearchStyles)(connect(
)(Search))


