import React, { Component, useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Redirect } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import SaveAlt from '@material-ui/icons/ArrowDownward'
import { connect } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import gql from 'graphql-tag'
import {
  resetSelectedRecipes,
  filterDisplayableRecipes
} from '../actions'
import { exportList, RECIPE_GET_ALL_GQL } from '../api'
import { Body } from './Shell'
import { LoginRedirector } from './Auth'
import RecipeEditor from './RecipeEditor'
import Search from './Search'
import RecipeCard from './RecipeCard'

const RecipesStyles = theme => ({
  fab: {
    position: 'fixed',
    bottom: 30,
    right: 30,
    color: '#fff'
  },
  recipeCards: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap'
  }
})

class Recipes extends Component {
  constructor() {
    super()
    this.state = {
      editorOpen: false,
      recipeIdBeingEdited: null,
      search: ''
    }
  }

  toggleEditorOpen = () => {
    this.setState(state => ({
      editorOpen: !state.editorOpen,
      recipeIdBeingEdited: null
    }))
  };

  startRecipeEditor = recipeId => {
    // console.log(recipeId)
    this.toggleEditorOpen()
    this.setState({
      recipeIdBeingEdited: recipeId
    })
  };

  exportRecipes = () => {
    exportList({
      name: 'Recipes',
      data: this.props.recipes,
      fields: [
        'id',
        'name',
        'image',
        'desc',
        'cookInst',
        'servings',
        'serveSuggestions',
        'heart',
        'calConsc',
        'diabetes',
        'calories',
        'tfat',
        'carbs',
        'fiber',
        'protein',
        'sugar',
        'satFat',
        'sodium',
        'dietNotes',
        'pressCookInst',
        'grocList',
        'addiSeas',
        'category',
        'cook_time_sl_sm',
        'cook_time_sl_rg',
        'cook_time_pr_sm',
        'cook_time_pr_rg',
        'cook_inst_sl_sm',
        'cook_inst_sl_rg',
        'cook_inst_pr_sm',
        'cook_inst_pr_rg',
        'grocery1',
        'grocery2',
        'grocery3',
        'grocery4',
        'grocery5',
        'grocery6',
        'serve_amount_sm',
        'serve_amount_rg',
        'serve_size',
        'ingredients',
        'db_id',
        'nutritionNotes'
      ]
    })
  };

  filterRecipes = val => {
    this.props.filterDisplayableRecipes(val)
  };

  render() {
    const {
      recipes = [],
      filter,
      classes: { fab }
    } = this.props
    // console.log("recipes: ", recipes);
    const incomingRecipe = Array.isArray(recipes)
      ? recipes.find(r => r && r.id === this.state.recipeIdBeingEdited)
      : undefined
    return (
      <Body>
        <LoginRedirector />
        {!this.props.isAdmin && <Redirect to="/classes" />}
        {/* <Content>
          <Typography type='title'>
            Recipes
          </Typography>
        </Content> */}
        <Search
          label="Search Recipes"
          onChange={this.filterRecipes}
          search={filter}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ position: 'absolute', top: 90, right: 20 }}
          onClick={this.exportRecipes}
        >
          <SaveAlt /> EXPORT
        </Button>
        <RecipeEditor
          open={this.state.editorOpen}
          title={
            incomingRecipe
              ? `Editing ${incomingRecipe.name}`
              : 'Create a new recipe'
          }
          incomingRecipe={incomingRecipe}
          onClose={this.toggleEditorOpen}
        />
        <RecipeCards onEditRequested={this.startRecipeEditor} search={filter} />
        <Button
          variant="fab"
          color="primary"
          aria-label="Add"
          className={fab}
          onClick={this.toggleEditorOpen}
        >
          <AddIcon />
        </Button>
      </Body>
    )
  }
}
export default withStyles(RecipesStyles)(
  connect(
    state => ({
      recipes: state.recipes.recipes,
      filter: state.recipes.displayableFilter
    }),
    {
      filterDisplayableRecipes
    }
  )(Recipes)
)

class RecipeCards extends Component {
  componentDidMount() {
    // this.props.resetSelectedRecipes();
    // this.props.resetSelectedClasses();
  }

  render() {
    const {
      displayableRecipes,
      onEditRequested,
      selectedRecipes,
      classes: { recipeCards }
    } = this.props
    return (
      <div className={recipeCards}>
        {displayableRecipes.map(recipe => (
          <RecipeCard
            {...recipe}
            onEditRequested={onEditRequested}
            key={recipe.id}
            selected={!!selectedRecipes[recipe.id]}
          />
        ))}
      </div>
    )
  }
}
RecipeCards = withStyles(RecipesStyles)(
  connect(
    state => ({
      selectedRecipes: state.recipes.selectedRecipes,
      displayableRecipes: state.recipes.displayableRecipes
    }),
    {
      resetSelectedRecipes
    }
  )(RecipeCards)
)


export const RECIPE_GET_ACTIVE = gql`
  {
    recipes(
       where: {
         or: [
           { value: { record_status: null } }
           { value: { record_status: "active" } }
         ]
       },
       order: "value.name"
    ) {
      id
      value
      original_id
      recipe_id
    }
  }
`

export function RecipesPicker(props)
{
  const [val, setVal] = useState(-1)
  const { onChange, value = "", inputId: key, color, disabled, input, label="Recipe" } = props
  const { data: { recipes = [] } = {} } = useQuery(RECIPE_GET_ACTIVE)

  useEffect(() =>
  {
    // console.log({value})
    recipes.find((r, i) =>
    {
      const found = r && r.value && r.value.id === value
      if (found) {
        setVal(i)
      }
      return found
    })
  }, [value, recipes])

  return (
    <Autocomplete
      id={key}
      options={recipes}
      getOptionLabel={recipe => (recipe && recipe.value && recipe.value.name ? recipe.value.name : "")}
      style={{ width: "100%", color }}
      value={val === -1 ? val : recipes[val]}
      onChange={onChange}
      autoHighlight
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          className={input}
          label={label}
          style={{ color }}
          fullWidth />
      )}
    />
  )
}