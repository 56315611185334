import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import { CardActions, CardContent, CardMedia } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import {setSelectedRecipes} from '../actions'

const RecipeCardStyles = theme => ({
  image: {
    height: 120
  },
  cardWrapper: {
    padding: 10,
    flex: '0 1 25%',
    width: 'calc(25% - 5px)'
  },
  [`@media (max-width: ${theme.breakpoints.values.xl}px)`]: {
    cardWrapper: {
      flex: '0 1 25%',
      width: 'calc(25% - 5px)'
    }
  },
  [`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
    cardWrapper: {
      flex: '0 1 33.333%'
    }
  },
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    cardWrapper: {
      flex: '0 1 50%'
    }
  },
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    cardWrapper: {
      flex: '0 1 100%'
    }
  },
  [`@media (max-width: ${theme.breakpoints.values.xs}px)`]: {
    cardWrapper: {
      flex: '0 1 100%'
    }
  }
})

class RecipeCard extends Component{
  editRecipe = (e) => {
    e.stopPropagation()
    this.props.onEditRequested(this.props.id)
  }

  toggleRecipe = (id) => {
    const {setSelectedRecipes, selectedRecipes, selected} = this.props
    console.log(selectedRecipes)
    setSelectedRecipes({...selectedRecipes, [id]: !selected})
  }

  render(){
    const { name, desc, image: imageSrc, selected, id, classes: { image, cardWrapper } } = this.props
    const style = {
      ...(false ? {borderTop:  'solid 2px #c1dca6', marginTop: '-2px'} : {}),
      ...(selected ? {background:  '#d8e7f5'} : {})
    }
    return(
      <div className={cardWrapper}>
        <Card style={style} onClick={() => {this.toggleRecipe(id)}}>
          <CardMedia
            title={name}
            image={imageSrc !== '' && imageSrc ? `https://firebasestorage.googleapis.com/v0/b/citrus-pear-11452.appspot.com/o/recipeThumbs%2F${id}.JPG?alt=media` : 'https://source.unsplash.com/collection/251966/400x300'}
            className={image}
          />
          <CardContent>
            <Typography type="headline" component="h2">
              {name}
            </Typography>
            <Typography component="p">
              {desc}
            </Typography>
          </CardContent>
          <CardActions>
            <Button color="primary" onClick={this.editRecipe}>
              Edit Recipe
            </Button>
          </CardActions>
        </Card>
      </div>
    )
  }
}
export default withStyles(RecipeCardStyles)(connect(
  state => ({
    selectedRecipes: state.recipes.selectedRecipes
  }),
  { setSelectedRecipes }
)(RecipeCard))
