import React, { Component } from 'react'
import { Router as ReactRouter, Route, Redirect } from 'react-router-dom'
import { Login, NoBelong } from './components/Auth'
import Recipes from './components/Recipes'
import Classes from './components/Classes'
import Users from './components/Users'
import Roles from './components/Roles'
import Ingredients from './components/Ingredients'
import Settings from './components/Settings'
import TimeTracker from './components/TimeTracker'
import { ClassTypes } from './components/ClassTypes'

const {createBrowserHistory} = require("history")

export const history = createBrowserHistory()

export default class Router extends Component {
  render() {
    const { children } = this.props
    return <ReactRouter history={history}>{children}</ReactRouter>
  }
}

export const Routes = props => (
  <div>
    <Route exact path="/" component={prps => <Redirect to="/classes" />} />
    <Route exact path="/login" component={prps => <Login {...prps} />} />
    <Route exact path="/youdontbelonghere" component={prps => <NoBelong />} />
    <Route
      exact
      path="/recipes"
      component={prps => <Recipes {...prps} isAdmin={props.isAdmin} />}
    />
    <Route exact path="/classes" component={prps => <Classes {...prps} />} />
    <Route
      path="/ingredients"
      component={prps => <Ingredients {...prps} isAdmin={props.isAdmin} />}
    />
    <Route
      path="/settings"
      component={prps => <Settings {...prps} isAdmin={props.isAdmin} />}
    />
    <Route
      path="/timecard"
      component={prps => <TimeTracker {...prps} />}
    />
    <Route path="/users" component={prps => <Users {...prps} />} />
    <Route path="/roles" component={prps => <Roles {...prps} />} />
    <Route path="/class-type" component={prps => <ClassTypes {...prps} />} />
  </div>
)
