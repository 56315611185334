import React from 'react'
import { Redirect } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { Body } from './Shell'
import Locations from './Locations'
import UsersAdmin from './UsersAdmin'
import RecipeOutline from './RecipeOutline'
import { LoginRedirector } from './Auth'

export const Settings = props => {
  return (
    <Body>
      <LoginRedirector />
      {!props.isAdmin && <Redirect to="/classes" />}
      <Typography style={{alignSelf: 'flex-start'}} variant="h4" gutterBottom>
        Locations
      </Typography>
      <p>Locations have been moved to the <a href='https://admin.citruspear.com/locations' target='_blank'>admin</a>.</p>
      {/*<Locations />*/}
      <Typography style={{alignSelf: 'flex-start', margin: '30px 0px 10px 0px'}}  variant="h4" guttertop={16}>
        Admin Users
      </Typography>
      <UsersAdmin />
      <Typography style={{alignSelf: 'flex-start', margin: '30px 0px 10px 0px'}}  variant="h4" guttertop={16}>
        Recipe Card Header
      </Typography>
      <RecipeOutline />
    </Body>
  )
}
export default Settings
  