import React, { useEffect, useState } from 'react'
import { gql } from "apollo-boost"
import { useQuery, useMutation } from '@apollo/react-hooks'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton
} from '@material-ui/core/'
import AddIcon from '@material-ui/icons/Add'
import Delete from '@material-ui/icons/Delete'
import { RolePicker } from './Roles'
import { UserPicker } from './Users'
import TimeRange from './TimeRange'




const frmt = numb =>
{
  // return num;
  if (numb < 0) return "00"
  const num = numb
  return num > 9 ? `${  String(num)}` : `0${  String(num)}`
}
const getTime = seconds =>
{
  const rounded = Math.round(seconds)
  const mints = Math.floor(rounded / 60)
  const hrs = Math.floor(rounded / (60 * 60))
  const t = `${frmt(hrs)}:${frmt(mints % 60)}:${frmt(rounded % 60)}`
  return t
}
const GET_LOCATION_TIME_PUNCHES = gql`
query gPunchLocations($location_id: String!){
  time_punchs(
    where: {
      location_id: $location_id
      }
  ) {
    time_punch_id
    class_event_id
    approved
    role
    user_email
    id
    time_punch_id
    in_time
    location_id
    scheduled_date
    out
  }
}
`

const GET_CLASS_TIME_PUNCHES = gql`
query gPunchClasses($class_event_id: Int!){
  time_punchs(
    where: {
      class_event_id: $class_event_id
    },
    order: "reverse:time_punch_id"      
  ) {
    time_punch_id
    approved
    role
    user_email
    id
    in_time
    location_id
    scheduled_date
    out
  }
}
`

const UPDATE_TIME_PUNCH_LEAD_ROLE = gql`
mutation uTimePunch(
  $time_punch_id: String!,
  $role: String!,
  $scheduled_date: Date!
) {
    updateTime_punch(
      time_punch_id: $time_punch_id,
      approved: true,
      approved_by: "-1",
      scheduled_date: $scheduled_date,
      role: $role
    ) {
      time_punch_id
      approved
      role
      user_email
      id
      in_time
      location_id
      scheduled_date
      out
    }
  }
`

const UPDATE_TIME_PUNCH_LEAD_EMAIL = gql`
mutation uTimePunchEmail(
  $time_punch_id: String!,
  $email: String!,
  $scheduled_date: Date!
) {
    updateTime_punch(
      time_punch_id: $time_punch_id,
      approved: true,
      approved_by: "-1",
      user_email: $email,
      scheduled_date: $scheduled_date
    ) {
      time_punch_id
      approved
      role
      user_email
      id
      in_time
      location_id
      scheduled_date
      out
    }
  }
`

const UPDATE_TIME_PUNCH_TIME = gql`
  mutation uTimePunch(
    $time_punch_id: String!, 
    $in_time: Date,
    $out: Date) 
  {
    updateTime_punch(time_punch_id: $time_punch_id, out: $out, in_time: $in_time) {
      time_punch_id
      approved
      role
      user_email
      id
      in_time
      location_id
      scheduled_date
      out
    }
  }
`

const CREATE_TIME_PUNCH_LEAD = gql`
  mutation cTimePunchLead($location_id: String!, $class_event_id: String!, $scheduled_date: Date!) {
    createTime_punch(
      location_id: $location_id,
      scheduled_date: $scheduled_date,
      class_event_id: $class_event_id
    ) {
      time_punch_id
      class_event_id
      approved
      role
      user_email
      id
      in_time
      location_id
      scheduled_date
      out
    }
  }
`

const DELETE_TIME_PUNCH = gql`
mutation dTimePunch(
  $time_punch_id: String!
) {
  deleteTime_punch(
      where: {time_punch_id: $time_punch_id}
    ) {
      id
    }
  }
`

export default function TimePunch(props)
{ 
  const {
    time_punch_id,
    user_email = "",
    date,
    class_event_id,
    id,
    scheduled_date,
    in_time: inTime,
    out: outTime,
    approved,
    role = "-1",
  } = props
  // console.log(approved);
  const inEpoch = inTime === null ? null : (new Date(inTime)).getTime()
  const outEpoch = outTime === null ? null : (new Date(outTime)).getTime()

  const roles = [{ role_id: 1, name: "Assistant" }]
    
  const notApproved = "#AAA"

  const [updateTimePunchRole, { data: { out: update_out } = {} }] = useMutation(UPDATE_TIME_PUNCH_LEAD_ROLE)
  const [updateTimePunchEmail] = useMutation(UPDATE_TIME_PUNCH_LEAD_EMAIL)
  const [updateTimePunchTime] = useMutation(UPDATE_TIME_PUNCH_TIME)
  
  const [deleteTimePunch] = useMutation(DELETE_TIME_PUNCH, {
    update(cache, mutationResult)
    {
      console.log({mutationResult})
      const { data } = mutationResult
      const { deleteTime_punch } = data
      console.log( deleteTime_punch )
      const test = cache.readQuery({ query: GET_CLASS_TIME_PUNCHES, variables: { class_event_id } })
      const { time_punchs } = test
      console.log({ test })
      cache.writeQuery({
        query: GET_CLASS_TIME_PUNCHES, variables: { class_event_id },
        data: { time_punchs: time_punchs.filter(t => t.time_punch_id !== time_punch_id) },
      })
    }
  })
  
  return (
    <TableRow>
      <TableCell style={{ color: approved ? '#000' : notApproved }} key={0}>
        <RolePicker
          onChange={e =>
            updateTimePunchRole({
              variables: {
                time_punch_id,
                scheduled_date: date,
                role: String(e.target.value)
              }
            })
          }
          value={role}
        />
      </TableCell>
      <TableCell style={{ color: approved ? '#000' : notApproved }} key={1}>
        <UserPicker
          value={user_email}
          inputId={id}
          color={approved ? '#000' : notApproved}
          disabled={!approved || !!outEpoch}
          onChange={(e, selectedUser = {}) =>
            updateTimePunchEmail({
              variables: {
                time_punch_id,
                scheduled_date: date,
                email:
                    selectedUser === null ? "" : selectedUser.email
              }
            })
          }
        />
      </TableCell>
      <TableCell style={{ color: approved ? '#000' : notApproved }} key={2}>
        {getTime((outEpoch - inEpoch) / 1000)}
      </TableCell>
      <TableCell style={{ color: approved ? '#000' : notApproved }} key={3}>
        <IconButton
          variant="contained"
          color="secondary"
          onClick={e => window.confirm("are you sure you want to delete this punch?") && 
            deleteTimePunch({
              variables: {
                time_punch_id
              }
            })
          }
        >
          <Delete />
        </IconButton>
        <TimeRange
          start={inEpoch}
          end={outEpoch}
          onChange={([in_time, out]) =>
          {
            updateTimePunchTime({
              variables: {
                time_punch_id,
                in_time,
                out,
              }
            })
          }}
        />
      </TableCell>
    </TableRow>
  )
}

export function TimePunchesByLocation(props)
{ 
  const { data: { time_punchs = {} } = {} } = useQuery(GET_LOCATION_TIME_PUNCHES)
  return time_punchs.map(tp => <TimePunch {...tp} />)
}

function TimePunchesTable(props)
{
  return (
    <Table style={{minWidth: 480, minHeight: 216}}>
      <TableHead>
        <TableRow>
          <TableCell>Role</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Time</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {props.children}
      </TableBody>
    </Table>
  )
}

export function TimePunchesByClass(props)
{
  const { class_event_id, location_id, date } = props
  console.log("Before createTimePunch creation:")
  console.log({ class_event_id, location_id, date })

  const [createTimePunch] = useMutation(CREATE_TIME_PUNCH_LEAD, {
    update(cache, { data })
    {
      const { createTime_punch } = data
      console.log({ createTime_punch })
      const test = cache.readQuery({ query: GET_CLASS_TIME_PUNCHES, variables: { class_event_id } })
      const { time_punchs } = test
      console.log({ test })
      cache.writeQuery({
        query: GET_CLASS_TIME_PUNCHES, variables: { class_event_id },
        data: { time_punchs: time_punchs ? [{ ...time_punchs[0], ...createTime_punch }, ...time_punchs] : [createTime_punch] },
      })
    }
  })
  const rtn = useQuery(GET_CLASS_TIME_PUNCHES, {
    skip: !class_event_id,
    variables: { class_event_id },
  })
  console.log(rtn)
  const { data: { time_punchs = [] } = {} } = rtn
  console.log(time_punchs)
  return (
    <TimePunchesTable>
      <TableRow>
        <TableCell key={0}>
          <IconButton onClick={
            e =>
            {
              createTimePunch({
                variables: {
                  location_id,
                  class_event_id: class_event_id.toString(),
                  scheduled_date: date
                }
              })
            }
          }>
            <AddIcon />
          </IconButton>
        </TableCell>
        <TableCell colSpan={3} key={1}>
            Schedule an Employee
        </TableCell>
      </TableRow>
      {time_punchs.map(tp => <TimePunch key={tp.id} {...tp} date={date} class_event_id={class_event_id} />)}
    </TimePunchesTable>
  )
}
    
export function TimePunchesByUser(props)
{ 
  const { data: { time_punchs = {} } = {} } = useQuery(GET_CLASS_TIME_PUNCHES)
  return time_punchs.map(tp => <TimePunch {...tp} date={props.date}/>)
}
