const initialState = {
  recipes: [],
  displayableRecipes: [],
  displayableFilter: 
    localStorage.getItem("recipeDisplayableFilter") ? 
      JSON.parse(localStorage.getItem("recipeDisplayableFilter") )
      :
      "",
  selectedRecipes: {}
}

const RecipesReducer = (state=initialState, action) => {
  switch (action.type) {
  case 'SET_RECIPES':
    return {...state, recipes: action.recipes}
  case 'SET_SELECTED_RECIPES':
    return {...state, selectedRecipes: action.selectedRecipes}
  case 'SET_RECIPE_FILTER':
    return {...state, displayableFilter: action.displayableFilter}
  case 'SET_RECIPES_DISPLAYABLE':
    return {...state, displayableRecipes: action.displayableRecipes}
  default:
    return state
  }
}
export default RecipesReducer
