const initialState = {
  loggedIn: false,
  user: null,
  checkingAuthState: true,
  permisions: {
    auth_level: 100,
    isAdmin: null,
    location: []
  }
}

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
  case 'SET_LOGGED_IN_USER':
    return {
      ...state,
      loggedIn: true,
      user: action.user,
      checkingAuthState: false
    }
  case 'LOGOUT_USER':
    return { ...state, loggedIn: false, user: null }
  case 'SET_CHECKING_AUTH_STATE':
    return { ...state, checkingAuthState: action.checkingAuthState }
  case 'SET_USER_PERMISIONS':
    return { ...state, permisions: {...state.permisions, ...action.payload } }
  default:
    return state
  }
}

export default AuthReducer
