import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CheckCircle from '@material-ui/icons/CheckCircle'
import { CardContent } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const RecipeCardStyles = theme => ({
  image: {
    height: 120
  },
  rel: {
    position: 'relative'
  },
  smallButton: {
    minWidth: '55px',
    position: 'absolute',
    top: 10,
    right: 10
  },
  cardWrapper: {
    padding: 10,
    flex: '0 1 25%',
    width: 'calc(25% - 5px)',
    cursor: 'pointer'
  },
  [`@media (max-width: ${theme.breakpoints.values.xl}px)`]: {
    cardWrapper: {
      flex: '0 1 25%',
      width: 'calc(25% - 5px)'
    }
  },
  [`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
    cardWrapper: {
      flex: '0 1 33.333%'
    }
  },
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    cardWrapper: {
      flex: '0 1 50%'
    }
  },
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    cardWrapper: {
      flex: '0 1 100%'
    }
  },
  [`@media (max-width: ${theme.breakpoints.values.xs}px)`]: {
    cardWrapper: {
      flex: '0 1 100%'
    }
  }
})

const ClassCard = (props) =>
{
  const editClass = (event) =>
  {
    event.stopPropagation()

    // console.log(props.value, event, props)
    props.onEditRequested(props.value)
  }

  const toggleClass = (event, id) =>
  {
    const shift = event.shiftKey
    if (props.isAdmin) {
      // setSelected(!selected)
      props.toggleClass({ id, selected, shift })
    }
  }

  const {
    id,
    classes: { cardWrapper, smallButton, rel },
    sku,
    template,
    selected,
    completed,
    small,
    regular,
    participants,
    formatted_date,
    classSize,
    active,
    private_menu,
    classEvent,
  } = props

  // console.log(props, classEvent)

  const style = {
    ...(false
      ? {
        borderTop: 'solid 2px #c1dca6',
        marginTop: '-2px'
      }
      : {}),
    ...(selected ? { background: '#d8e7f5' } : {}),
    ...(!active ? { opacity: .5 } : {}),
    position: 'relative',
    overflow: 'visible'
  }
  return (
    <div className={cardWrapper}>
      <Card
        style={style}
        onClick={(e) => {
          toggleClass(e, id)
        }}
      >
        {participants > 0 && (
          <div
            style={{
              backgroundColor: participants > 9 ? '#8bc34a' : '#ec7921',
              top: '0px',
              left: '0px',
              height: '3px',
              position: 'relative',
              width: `${
                (100 * (participants > classSize ? classSize : participants)) / classSize
              }%`,
            }}
          >
            <div
              style={{
                backgroundColor: participants > 9 ? '#8bc34a' : '#ec7921',
                position: 'absolute',
                top: '-11px',
                right: '0px',
                height: '24px',
                width: '24px',
                borderRadius: '13px 13px 13px 0',
                transform: 'rotate(45deg)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                "&:hover": {
                  height: '48px',
                  width: '48px',
                },
              }}
            ></div>
            <div
              style={{
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                top: '-11px',
                right: '0px',
                background: 'transparent',
                height: '24px',
                width: '24px',
                color: '#fff',
                fontWeight: 700,
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              }}
            >
              {participants}
            </div>
          </div>
        )}
        <CardContent className={rel}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typography
              type="headline"
              component="h2"
              style={{
                fontSize: '1.5rem',
                textTransform: 'uppercase',
                fontWeight: '100',
                display: 'inline-flex',
                width: 'unset',
              }}
            >
              {sku}
            </Typography>
            {private_menu && (
              <div
                style={{
                  color: '#fff',
                  backgroundColor: '#CECECE',
                  borderRadius: '10px',
                  display: 'inline-flex',
                  textAlign: 'center',
                  width: '20px',
                  padding: '3px 6px',
                  fontSize: '12px',
                  lineHeight: '12px',
                  margin: '0 0 2px 6px',
                }}
              >
                P
              </div>
            )}
          </div>
          <Typography component="p" style={{ color: selected ? '#000' : '#bbb' }}>
            {formatted_date}
          </Typography>
          <Button color="primary" className={smallButton} onClick={editClass}>
            Edit
          </Button>
          {completed ? (
            <CheckCircle
              style={{
                color: '#8bc34a',
                position: 'absolute',
                bottom: '15px',
                right: '15px',
              }}
            />
          ) : null}
          <Typography
            component="p"
            style={{
              color: participants > 9 ? '#8bc34a' : '#ec7921',
              position: 'absolute',
              bottom: '15px',
              right: '45px',
            }}
          >
            {(small || 0) + (regular || 0)}
          </Typography>
        </CardContent>
      </Card>
    </div>
  )
}

export default withStyles(RecipeCardStyles)(
  (React.memo(ClassCard))
)
