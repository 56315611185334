import React, { useEffect, useState, Fragment } from 'react'
import { gql } from "apollo-boost"
import shortid from 'shortid'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { withStyles } from '@material-ui/core/styles'

import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Done'
import AddIcon from '@material-ui/icons/Add'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Switch,
  FormControlLabel,
  Select
} from '@material-ui/core/'
import { RolePicker, RoleIdToName } from './Roles'
import {LocationPickerMulti } from './Locations'

const GET_USER_BY_ID = gql`
query gUser($user_id: Int!){
  user(user_id: $user_id) {
    user_id
    name
    phone
    address
    city
    state
    zip
    firebase_user_id
    email
    id
    active
    birthday
    hire_date
    notes
    food_handler_date
    paperwork_complete
    auth_level
    locations{
      id
      user_x_location_id
      user_id
      location_id
    }
  	roles{
      id
      user_x_role_id
      user_id
      role_id
      rate
      base
      min
      rate2
    }
  }
}
`

const UPDATE_USER = gql`
mutation uUser(
  $user_id: String!,
  $name: String!,
  $phone: String!,
  $address: String!,
  $city: String!,
  $state: String!,
  $zip: String!,
  $email: String!,
  $active: Boolean!,
  $birthday: Date!,
  $hire_date: Date!,
  $auth_level: Int!,
  $firebase_user_id: String!,
  $notes: String!,
  $food_handler_date: Date!,
  $paperwork_complete: Boolean!,
) {
    updateUser(
      user_id: $user_id,
      name: $name,
      phone: $phone,
      address: $address,
      city: $city,
      state: $state,
      zip: $zip,
      email: $email,
      active: $active,
      birthday: $birthday,
      hire_date: $hire_date,
      notes: $notes,
      auth_level: $auth_level,
      food_handler_date: $food_handler_date,
      firebase_user_id: $firebase_user_id,
      paperwork_complete: $paperwork_complete
    ) {
      user_id
      name
      phone
      address
      city
      state
      zip
      firebase_user_id
      email
      id
      active
      birthday
      hire_date
      notes
      food_handler_date
      auth_level
      paperwork_complete
      locations{
        id
        user_x_location_id
        user_id
        location_id
      }
      roles{
        id
        user_x_role_id
        user_id
        role_id
        rate
        base
        min
        rate2
      }
    }
  }
`

const CREATE_USER = gql`
  mutation cUser(
    $name: String!
    $phone: String!
    $address: String!
    $city: String!
    $state: String!
    $zip: String!
    $email: String!
    $active: Boolean!
    $birthday: Date!
    $hire_date: Date!
    $notes: String!
    $auth_level: Int!,
    $food_handler_date: Date!,
    $paperwork_complete: Boolean!
  ) {
    createUser(
      name: $name
      phone: $phone
      address: $address
      city: $city
      state: $state
      zip: $zip
      email: $email
      active: $active
      birthday: $birthday
      hire_date: $hire_date
      auth_level: $auth_level
      notes: $notes
      food_handler_date: $food_handler_date,
      paperwork_complete: $paperwork_complete
    ) {
      user_id
      name
      phone
      address
      city
      state
      zip
      firebase_user_id
      auth_level
      email
      id
      active
      birthday
      hire_date
      notes
      food_handler_date
      paperwork_complete
      locations {
        id
        user_x_location_id
        user_id
        location_id
      }
      roles {
        id
        user_x_role_id
        user_id
        role_id
        rate
        base
        min
        rate2
      }
    }
  }
`

const UPDATE_USER_X_ROLE = gql`
mutation uUserRole(
  $user_x_role_id: String!,
  $role_id: String!,
  $user_id: String!,
  $rate: String!,
  $base: String!,
  $min: String!,
  $rate2: String!
) {
    updateUser_x_role(
      user_x_role_id: $user_x_role_id,
      role_id: $role_id,
      user_id: $user_id,
      rate: $rate,
      base: $base,
      min: $min,
      rate2: $rate2
    ) {
      id
      user_x_role_id
      user_id
      role_id
      rate
      base
      min
      rate2
    }
  }
`

const CREATE_USER_X_ROLE = gql`
mutation cUserRole(
  $role_id: String!,
  $user_id: String!,
  $rate: String!,
  $base: String!,
  $min: String!,
  $rate2: String!
) {
    createUser_x_role(
      role_id: $role_id,
      user_id: $user_id,
      rate: $rate,
      base: $base,
      min: $min,
      rate2: $rate2
    ) {
      id
      user_x_role_id
      user_id
      role_id
      rate
      base
      min
      rate2
    }
  }
`

const CREATE_USER_X_LOCATION = gql`
mutation cUserLocation(
  $location_id: String!,
  $user_id: String!
) {
    createUser_x_location(
      location_id: $location_id,
      user_id: $user_id
    ) {
      id
      location_id
      user_id
    }
  }
`

const UPDATE_USER_X_LOCATION = gql`
mutation uUserLocation(
  $location_id: String!,
  $user_x_location_id: String!,
  $user_id: String!
) {
    updateUser_x_location(
      user_x_location_id: $user_x_location_id,
      location_id: $location_id,
      user_id: $user_id
    ) {
      id
      user_x_location_id
      location_id
      user_id
    }
  }
`

const DELETE_USER_X_LOCATION = gql`
mutation dUserLocation(
  $user_x_location_id: String!
) {
  deleteUser_x_location(
    where: 
    {user_x_location_id: $user_x_location_id}
  ) {
      id
    }
  }
`

const getDefaults = e =>
{
  return {
    name: "",
    phone: "",
    auth_level: 80,
    address: "",
    city: "",
    state: "",
    zip: "",
    firebase_user_id: "",
    email: "",
    active: true,
    birthday: (new Date()),
    hire_date: (new Date()),
    notes: "",
    food_handler_date: (new Date()),
    roles: [],
    locations: [],
    paperwork_complete: false
  }
}

const EditorStylesheet = theme => ({
  input: {
    width: '100%'
  },
  rowMargin: {
    marginBottom: 5
  },
  smallButton: {
    minWidth: '55px',
    padding: '8px 10px'
  },
})

function UsersEditor(props)
{
  const [originalUser, setOriginalUser] = useState(getDefaults())
  const [userCache, setUserCache] = useState(getDefaults())
  
  let { user_id } = props.match.params
  const {
    open,
    onClose,
    updateCache,
    classes: { input, rowMargin, midTitle, smallButton }
  } = props
  const isNew = (user_id === "new")
  // user_id = isNew ? -1 : user_id
  const { data: { user = {} } = {} } = useQuery(GET_USER_BY_ID, {
    variables: {
      user_id: typeof user_id === 'string' && isFinite(user_id) ? parseInt(user_id) : user_id
    },
    onCompleted: (data) =>
    {
      // console.log(data)
      if (!data) return
      
      const { user = {} } = data
      setOriginalUser(user)
      setUserCache(user)
    }
  })

  const {
    name,
    phone,
    address,
    city,
    state,
    zip,
    firebase_user_id,
    email,
    auth_level,
    id,
    active,
    birthday,
    hire_date,
    notes,
    paperwork_complete,
    food_handler_date,
    locations,
    roles
  } = userCache

  const [updateRole, { data: updateRoleRtn = {} }] = useMutation(UPDATE_USER_X_ROLE)
  const [createRole, { data: createRoleRtn = {} }] = useMutation(CREATE_USER_X_ROLE)
  
  const [deleteLocation, { data: deleteLocationRtn = {} }] = useMutation(DELETE_USER_X_LOCATION, {ignoreResults: true})
  const [createLocation, { data: createLocationRtn = {} }] = useMutation(CREATE_USER_X_LOCATION)

  const updateRolesLocations = (cache, user) => {

    const { locations: user_locations_temp = [], roles } = userCache
    const user_locations = user_locations_temp.length && typeof user_locations_temp[0] === "object" ? user_locations_temp.map(u => u.location_id) : user_locations_temp
    // save all roles
    for (const role of roles) {
      const isRoleNew = role.id.includes("new_")
      if (isRoleNew) {
        const variables = {
          ...role,
          ...(isNew ? { user_id: user.user_id } : { user_id })
        }
        console.log(variables, user_id, user)
        createRole({
          variables
        })
      } else {
        updateRole({ variables: role })
      }
    }


    // save all locations
    // deleteLocation({ variables: { user_id } });
    console.log(user_locations, user_id)

    // delete all locations that need to first
    const locationsAlreadyCreated =[]
    for (const loc of originalUser.locations) {
      if (user_locations.includes(loc.location_id)) { 
        locationsAlreadyCreated.push(loc.location_id)
      }
      else {
        console.log(loc, originalUser)
        // cache.evict(loc.user_x_location_id)
        deleteLocation({ variables: { user_x_location_id: loc.user_x_location_id } })
      }
    }

    // create all that aren't already there
    for (const loc of user_locations) {
      if (!locationsAlreadyCreated.includes(loc)) { 
        createLocation({ variables: { location_id: loc, 
          ...(isNew ? { user_id: user.user_id } : { user_id }) } })
      }
    }
  }

  
  const [updateUser, { data: updateUserRtn = {} }] = useMutation(UPDATE_USER)
  const [createUser, { data: createUserRtn = {} }] = useMutation(CREATE_USER, {
    update: (cache, { data }) =>
    {
      const { createUser } = data
      let updates = updateRolesLocations(cache, createUser)

      // update the cache
      console.log({ createUser, updates })
      updateCache(cache, createUser)
    }
  }
  )

  const saveUser = e =>
  {
    // save user
    const saveable = {
      ...userCache,
      hire_date: new Date(hire_date),
      birthday: new Date(birthday),
      paperwork_complete: !!userCache.paperwork_complete,
      active: !!userCache.active,
      auth_level: parseInt(userCache.auth_level ? userCache.auth_level : "100", 10),
      firebase_user_id: userCache.firebase_user_id === null ? '' : userCache.firebase_user_id,
      food_handler_date: new Date(food_handler_date),
      ...(isNew ? { user_id: -1 } : {})
    }
    if (isNew) {
      delete saveable.user_id
      createUser({ variables: saveable })
    } else {
      updateRolesLocations(null, saveable)

      // const { locations, roles, ...theRest } = saveable;
      updateUser({ variables: saveable })
    }
    console.log("save")

    props.history.push("/users")
    console.log("props.history.push(\"/users\")")
  }
  const set = (prop, e) =>
  {
    setUserCache({
      ...userCache,
      [prop]: e.target.value
    })
  }
  
  return (
    <Dialog
      open={open}
      style={{ visibility: open ? 'display' : 'hidden' }}
      maxWidth="md"
      fullWidth
    >
      <>
        <DialogTitle>Editing User: {name}</DialogTitle>
        <hr style={{ width: 'calc(100% - 45px)' }} />
        <DialogContent>
          <Grid container className={rowMargin} spacing={3}>
            <Grid item sm={6} xs={12}>
              <TextField
                type="text"
                value={name}
                className={input}
                label="Name"
                onChange={e => {
                  set('name', e)
                }}
              />
            </Grid>

            <Grid item sm={3} xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!active}
                    onChange={e =>
                      set('active', {
                        target: {
                          value: !active,
                          checked: !active
                        }
                      })
                    }
                    color="primary"
                  />
                }
                label="Active"
              />
            </Grid>
            <Grid item sm={3} xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!paperwork_complete}
                    onChange={e =>
                      set('paperwork_complete', {
                        target: {
                          value: !paperwork_complete,
                          checked: !paperwork_complete
                        }
                      })
                    }
                    color="primary"
                  />
                }
                label="Paperwork Complete"
              />
            </Grid>
            <Grid item sm={6} xs={12} key="location">
              <LocationPickerMulti
                showAll={false}
                value={
                  Array.isArray(locations)
                    ? locations.map(l =>
                      typeof l === 'object' ? l.location_id : l
                    )
                    : []
                }
                onChange={e => {
                  console.log(e)
                  set('locations', e)
                  // setLocations(e.target.value)
                }}
                className={input}
              />
            </Grid>
            <Grid item sm={6} xs={12} key="food-handler-dete">
              <TextField
                InputProps={{
                  style: {
                    height: 45
                  }
                }}
                id="datetime-food-handlers"
                label="Food Handler's Exp. Date"
                type="date"
                onChange={e => {
                  console.log(
                    e.target.valueAsDate,
                    new Date(food_handler_date).toLocaleDateString()
                  )
                  set('food_handler_date', e)
                }}
                value={new Date(food_handler_date).toISOString().split('T')[0]}
                className={input}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                id="datetime-hire-date"
                label="Hire Date"
                type="date"
                onChange={e => {
                  console.log(
                    e.target.valueAsDate,
                    new Date(hire_date).toLocaleDateString()
                  )
                  set('hire_date', e)
                }}
                value={new Date(hire_date).toISOString().split('T')[0]}
                className={input}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                id="birthday"
                label="Birthday"
                type="date"
                onChange={e => {
                  set('birthday', e)
                }}
                value={new Date(birthday).toISOString().split('T')[0]}
                className={input}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                type="text"
                value={phone}
                className={input}
                label="Phone"
                onChange={e => {
                  set('phone', e)
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                type="text"
                value={email}
                className={input}
                label="Email"
                onChange={e => {
                  set('email', e)
                }}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField
                type="text"
                multiline
                value={notes}
                className={input}
                label="Notes"
                onChange={e => {
                  set('notes', e)
                }}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField
                type="text"
                value={address}
                className={input}
                label="Address"
                onChange={e => {
                  set('address', e)
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="text"
                value={city}
                className={input}
                label="City"
                onChange={e => {
                  set('city', e)
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="text"
                value={state}
                className={input}
                label="State"
                onChange={e => {
                  set('state', e)
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="text"
                value={zip}
                className={input}
                label="Postal"
                onChange={e => {
                  set('zip', e)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                native
                value={auth_level}
                onChange={e => set('auth_level', e)}
              >
                <option value="" />
                <option value={1}>Super Admin</option>
                <option value={20}>Admin</option>
                <option value={40}>Lead</option>
                <option value={60}>Instructor</option>
                <option value={80}>Assistant</option>
                <option value={100}>Anonymous</option>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <UserRoles roles={roles} onChange={e => set('roles', e)} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button color="primary" onClick={saveUser}>
            Save User
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

UsersEditor = withStyles(EditorStylesheet)(UsersEditor)
export default UsersEditor

function UserRoles(props)
{
  const [selectedRole, setSelectedRole] = useState("")
  const { roles = [], onChange } = props
  function cvrt(str)
  {
    const num = (parseFloat(str) * 100).toFixed(0)
    return String(num)
  }
  function save(updatedRole)
  {
    const {
      id,
      user_x_role_id,
      base,
      min,
      rate,
      rate2
    } = updatedRole
    const value = roles.map(r => r.id === id ? {
      ...updatedRole,
      base: cvrt(base),
      min: cvrt(min),
      rate: cvrt(rate),
      rate2: cvrt(rate2)
    } : r)
    onChange({ target: { value } })
    setSelectedRole("")
    return updatedRole
  }
  function create()
  {
    const id = `new_${shortid.generate()}`
    const newUserRole = { id, base: "0", min: "0", rate: "0", rate2: "0" }
    const value = roles ? [newUserRole, ...roles] : [newUserRole]
    setSelectedRole(id)
    onChange({ target: { value } })
  }
  // console.log({roles})
  return (
    <UserRolesTable>
      <TableRow>
        <TableCell key={0}>
          <IconButton
            onClick={create}>
            <AddIcon />
          </IconButton>
        </TableCell>
        <TableCell key={1} colSpan={5}>
          Create New Role
        </TableCell>
      </TableRow>
      {
        roles.map(r =>
          (
            selectedRole !== r.id ?
              <UserRole key={r.id} {...r } setSelected={setSelectedRole} /> 
              :
              <UserRoleEditor key={r.id} {...r} save={save} />
          ))
      }
    </UserRolesTable>
    
  )
}


function UserRolesTable(props)
{
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Action</TableCell>
          <TableCell>Role</TableCell>
          <TableCell>Base</TableCell>
          <TableCell>Minimum</TableCell>
          <TableCell>Rate</TableCell>
          <TableCell>Rate2</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.children}
      </TableBody>
    </Table>
  )
}


export function UserRole(props)
{ 
  const {
    id,
    role_id,
    rate,
    base,
    min,
    rate2,
    setSelected
  } = props
  const frmt = num => `$${(num / 100).toFixed(2)}`
  return (
    <TableRow>
      <TableCell key={0}>
        <IconButton
          onClick={
            e => setSelected(id)
          }>
          <EditIcon />
        </IconButton>
      </TableCell>
      <TableCell key={1}>
        <RoleIdToName>
          {role_id}
        </RoleIdToName>
      </TableCell>
      <TableCell key={2}>
        {frmt(base)}
      </TableCell>
      <TableCell key={3}>
        {frmt(min)}
      </TableCell>
      <TableCell key={4}>
        {frmt(rate)}
      </TableCell>
      <TableCell key={5}>
        {frmt(rate2)}
      </TableCell>
    </TableRow>
  )
}


export function UserRoleEditor(props)
{ 
  const [roleCache, setRoleCache] = useState({})
  
  useEffect(() =>
  {

    const frmt = num => `${(num / 100).toFixed(2)}`
    const {
      id,
      role_id = null,
      user_id,
      user_x_role_id,
      rate,
      base,
      min,
      rate2,
    } = props
    setRoleCache({
      id,
      role_id: String(role_id),
      user_x_role_id,
      user_id,
      rate: frmt(rate),
      base: frmt(base),
      min: frmt(min),
      rate2: frmt(rate2)
    })
  }, [
    props.id    
  ])

  const {
    id,
    role_id,
    user_x_role_id,
    user_id,
    rate,
    base,
    min,
    rate2
  } = roleCache
  const {
    classes: { input } = {},
    save
  } = props
  const set = (prop, e) =>
  {
    setRoleCache({
      ...roleCache,
      [prop]: e.target.value
    })
  }

  return (
    <TableRow>
      <TableCell key={0}>
        <IconButton onClick={
          e => save(roleCache)
        }>
          <CloseIcon />
        </IconButton>
      </TableCell>
      <TableCell key={1}>
        <RolePicker
          value={ role_id }
          onChange={e =>
          {
            console.log(e)
            set('role_id', e)
            // setLocations(e.target.value)
          }}
          className={input}
        />
      </TableCell>
      <TableCell key={2}>
        <TextField
          type="text"
          value={base}
          className={input}
          onChange={e => {
            set('base', e)
          }}
        />
      </TableCell>
      <TableCell key={3}>
        <TextField
          type="text"
          value={min}
          className={input}
          onChange={e => {
            set('min', e)
          }}
        />
      </TableCell>
      <TableCell key={4}>
        <TextField
          type="text"
          value={rate}
          className={input}
          onChange={e => {
            set('rate', e)
          }}
        />
      </TableCell>
      <TableCell key={5}>
        <TextField
          type="text"
          value={rate2}
          className={input}
          onChange={e => {
            set('rate2', e)
          }}
        />
      </TableCell>
    </TableRow>
  )
}
