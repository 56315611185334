
export function format(inDate, formatString)
{
  // Written by Will Morrison 2018-04-05
    
  function isValidDate(input)
  {
    return typeof input.getMonth === 'function'
  }
  
  function joinObj(obj, seperator) {
    const out = [] 
    for (const k in obj) {  
      out.push(k) 
    } 
    return out.join(seperator)
  }
  
  // Validate that we're working with a date
  if(!isValidDate(inDate))
  {
    inDate = new Date(inDate)
  }
    
  const dateObject = {
    M: inDate.getMonth() + 1,
    d: inDate.getDate(),
    D: inDate.getDate(),
    h: inDate.getHours(),
    m: inDate.getMinutes(),
    s: inDate.getSeconds(),
    y: inDate.getFullYear(),
    Y: inDate.getFullYear()
  }
  // Build Regex Dynamically based on the list above.
  // Should end up with something like this "/([Yy]+|M+|[Dd]+|h+|m+|s+)/g"
  const dateMatchRegex = `${joinObj(dateObject, "+|")  }+`
  const regEx = new RegExp(dateMatchRegex,"g")
  formatString = formatString.replace(regEx, function(formatToken) {
    const datePartValue = dateObject[formatToken.slice(-1)]
    var tokenLength = formatToken.length
        
    // A conflict exists between specifying 'd' for no zero pad -> expand to '10' and specifying yy for just two year digits '01' instead of '2001'.  One expands, the other contracts.
    // so Constrict Years but Expand All Else
    if(formatToken.indexOf('y') < 0 && formatToken.indexOf('Y') < 0)
    {
      // Expand single digit format token 'd' to multi digit value '10' when needed
      var tokenLength = Math.max(formatToken.length, datePartValue.toString().length)
    }
    const zeroPad = (datePartValue.toString().length < formatToken.length ? "0".repeat(tokenLength) : "")
    return (zeroPad + datePartValue).slice(-tokenLength)
  })
    
  return formatString
}
    
export function changeTimezone(date, ianatz) {

  // suppose the date is 12:00 UTC
  const invdate = new Date(date.toLocaleString('en-US', {
    timeZone: ianatz
  }))

  // then invdate will be 07:00 in Toronto
  // and the diff is 5 hours
  const diff = date.getTime() - invdate.getTime()


  const rtn = new Date(date.getTime() - diff)
  console.log({date, diff, invdate, ianatz, rtn})

  // so 12:00 in Toronto is 17:00 UTC
  return rtn

}

export function getTimezoneOffsetMs(date, ianatz) {

  // suppose the date is 12:00 UTC
  const invdate = new Date(date.toLocaleString('en-US', {
    timeZone: ianatz
  }))

  // then invdate will be 07:00 in Toronto
  // and the diff is 5 hours
  const diff = date.getTime() - invdate.getTime()
  return diff
}