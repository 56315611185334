import React, { useEffect, useState } from 'react'

import AccessTime from '@material-ui/icons/AccessTime'
import { IconButton, Popover } from '@material-ui/core'

import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns'

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from '@material-ui/pickers'

const TimeRange = props => {
  const { start, end, onChange } = props
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <IconButton
        variant="contained"
        aria-describedby={id}
        color="secondary"
        aria-label="timespan"
        onClick={e => setAnchorEl(e.currentTarget)}
      >
        <AccessTime />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={e => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        style={{}}
      >
        <Grid container justify="space-around" style={{ margin: 15 }}>
          <TimePicker
            label="start"
            date={start}
            setDate={d =>
            { 
              onChange([d === null ? null : new Date(d).toUTCString(), end === null ? null : new Date(end).toUTCString()])
            }}
          />
          <TimePicker
            label="end"
            date={end}
            setDate={d =>
            { 
              console.log(d)
              onChange([start === null ? null : new Date(start).toUTCString(), d === null ? null : new Date(d).toUTCString()])
            }}
          />
        </Grid>
      </Popover>
    </>
  )
}

const TimePicker = props => {
  const { date, setDate, placeholder = "06:00 PM", label = "time" } = props
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        label={label}
        placeholder={placeholder}
        mask="__:__ _M"
        value={date}
        onChange={d => setDate(d)}
      />
    </MuiPickersUtilsProvider>
  )
}
export default TimeRange
