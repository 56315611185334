const initialState = {
}

const UsersReducer = (state=initialState, action) => {
  switch (action.type) {
  case 'POPULATE_USERS':
    return {...state, ...action.users}
  default:
    return state
  }
}

export default UsersReducer
