import firebase from 'firebase'
import gql from 'graphql-tag'
import { GraphQLClient } from 'graphql-request'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import moment from 'moment'
import { changeTimezone } from './helpers/date'
import { handle } from './utilities'
import axiosWithAuth from './api/axiosWithAuth'

import {
  wooClassTemplate,
  classTypes
} from './data'

let isDev = process.env.NODE_ENV === 'development'
let isStaging = window.location.href.includes('deploy-preview')
// export const ecommerce_url = isStaging ? 'https://lumia-staging.pearcitr.us' : process.env.REACT_APP_ECOMMERCE_URL || 'https://api.new.citruspeardinners.com'

let ecommerceUrl = process.env.REACT_APP_ECOMMERCE_URL

if (isDev) {
  ecommerceUrl = process.env.REACT_APP_ECOMMERCE_URL
} else if (isStaging) {
  ecommerceUrl = 'https://lumia-staging.pearcitr.us'
} else {
  ecommerceUrl = 'https://api.new.citruspeardinners.com'
}

export const ecommerce_url = ecommerceUrl

export const baseNodeUrl = ecommerceUrl
export const graphQlUrl = `${ecommerceUrl}/graphql`
export const default_class_sku = 'TEMPLATE'

export const apolloClient = new ApolloClient({
  // By default, this client will send queries to the
  //  `/graphql` endpoint on the same host
  // Pass the configuration option { uri: YOUR_GRAPHQL_API_URL } to the `HttpLink` to connect
  // to a different host
  link: new HttpLink({ uri: graphQlUrl }),
  cache: new InMemoryCache()
})

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
}

firebase.initializeApp(config)

export default firebase

export const getFetchPromise = (
  url,
  verb,
  body,
  recurseLevel = 0,
  idToken = localStorage.getItem('firebase.auth.idToken')
) => {
  return new Promise((resolve, reject) => {
    // return resolve([]);
    fetch(url, {
      mode: 'cors',
      method: verb,
      headers: {
        'Content-type': 'application/json',
        idToken
      },
      body
    })
      .then(response => {
        // console.log('before refresh:', url, recurseLevel + 1, response);
        const { status } = response || {}
        if (status === 401 && recurseLevel < 3) {
          firebase
            .auth()
            .currentUser.getIdToken(true)
            .then(token => {
              localStorage.setItem('firebase.auth.idToken', token)
              console.log('successful refresh:', url, recurseLevel + 1)
              resolve(
                getFetchPromise(url, verb, body, recurseLevel + 1, token)
              )
            })
        } else {
          // don't return if we got here because of being unauthenticated
          if (recurseLevel >= 3) return reject({ message: 'failed to login' })

          response.text().then(data => {
            if (
              data &&
              data.includes('Authentication Failed') &&
              recurseLevel < 3
            ) {
              firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(token => {
                  localStorage.setItem('firebase.auth.idToken', token)
                  console.log('successful refresh:', url, recurseLevel + 1)
                  resolve(
                    getFetchPromise(url, verb, body, recurseLevel + 1, token)
                  )
                })
            } else {
              resolve(
                data ? JSON.parse(data) : { message: 'No data was passed back' }
              )
            }
          })
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const Get = url => {
  return getFetchPromise(url, 'get', undefined)
}
export const Put = (url, body) => {
  // console.log(JSON.stringify(body, null, '\t'));
  return getFetchPromise(url, 'put', JSON.stringify(body))
}
export const Post = (url, body) => {
  return getFetchPromise(url, 'post', JSON.stringify(body))
}
export const Delete = url => {
  return getFetchPromise(url, 'delete', undefined)
}

// Yuzu
export const getForecastClasses = (params) => {
  return axiosWithAuth.get(`/reports/forecast`, { params })
}

export const getClasses = (params = {}) => {
  return axiosWithAuth.get(`/yuzu/classes`, { params })
}

export const getClass = (id, params = {}) => {
  return axiosWithAuth.get(`/yuzu/classes/${id}`, { params })
}

export const saveClass = (data) => {
  return axiosWithAuth.post(`/yuzu/classes`, data)
}

export const updateClass = (id, data) => {
  return axiosWithAuth.put(`/yuzu/classes/${id}`, data)
}

export const completeClass = (id, data) => {
  return axiosWithAuth.put(`/yuzu/classes/${id}/complete`, data)
}

export const deleteClass = (id) => {
  return axiosWithAuth.delete(`/yuzu/classes/${id}`)
}

export const cancelClass = (id) => {
  return axiosWithAuth.put(`/yuzu/classes/${id}/cancel`)
}

export const archiveClass = (id) => {
  return axiosWithAuth.put(`/yuzu/classes/${id}/archive`)
}

export const getEventUsers = (id) => {
  return axiosWithAuth.get(`/yuzu/classes/${id}/users`)
}

export const getTemplates = (params = {}) => {
  return axiosWithAuth.get(`/yuzu/templates`, { params })
}

export const getTemplate = (id, params = {}) => {
  return axiosWithAuth.get(`/yuzu/templates/${id}`, { params })
}

export const getLocations = (params = {}) => {
  return axiosWithAuth.get(`/yuzu/locations`, { params })
}

export const getLocation = (id, params = {}) => {
  return axiosWithAuth.get(`/yuzu/locations/${id}`, { params })
}

export const getRecipes = (params = {}) => {
  return axiosWithAuth.get(`/yuzu/recipes`, { params })
}

export const getRecipe = (id, params = {}) => {
  return axiosWithAuth.get(`/yuzu/recipes/${id}`, { params })
}

export const getClassPdfs = (sku) => {
  return axiosWithAuth.get(`/api/pdf/${sku}`)
}

export const getClassesReport = (params) => {
  return axiosWithAuth.get(`/reports/classes`, { params })
}

const getClassOrders = (id) => {
  return axiosWithAuth.get(`/reports/classes/${id}/orders`)
}

/** *******************************************
 *  WooCommerce !
 ******************************************** */

const getRequestWooClass = sku => {
  const temp = Get(`${baseNodeUrl}/ecommerce/woo/product/${sku}`)
  return temp
}

export const getEcommerceClassPromise = Classe => {
  return new Promise((res, rej) => {
    getEcommerceClass(
      Classe,
      undefined,
      rtnClass => {
        res(rtnClass)
      },
      err => {
        rej(err)
      }
    )
  })
}

// export const getEcommerceClass = (Classe, location, cb, errCb) =>
const getEcommerceClass_DECOY = (Classe, location, cb, errCb) =>
{
  console.log("Class MOCK save to WOO COMMERCE: ", Classe)
  // const sku = Classe.sku ? Classe.sku : default_class_sku;
  cb(classTypes[0])
}

const getEcommerceClass_REAL = (Classe, location, cb, errCb) =>
// export const getEcommerceClass = (Classe, location, cb, errCb) =>
{
  console.log("Class beginning to save: ", Classe)
  const sku = Classe.sku ? Classe.sku : default_class_sku

  // don't do a look up if it is the template
  if (!Classe.db_id) return cb(wooClassTemplate)

  getRequestWooClass(sku)
    .then(woo_class => {
      // check to see if there was an error getting the class
      if (woo_class.hasOwnProperty('message')) {
        // send default if there was an error
        // cb(wooClassTemplate);
        errCb(new Error(woo_class.message))
      }
      getEcommerceParticipants(
        Classe,
        ordersRaw =>
        {
          
          console.log("getRequestWooClass: start.")
          const orders = ordersRaw.filter(
            o => o && (['processing', 'completed', 'on-hold'].includes(o.status))
          )

          // get the count of all spots ordered in the class
          const participantCount = orders.reduce((pQty, curr, arr) => {
            const curQty =
              typeof curr.qty === 'string' && curr.qty
                ? parseInt(curr.qty)
                : curr.qty
            return pQty + curQty
          }, 0)

          const small = orders
            .filter(
              o =>
                o.note.toString().includes('20 Meals Small Family') ||
                o.note.toString().includes('40 Meals Small Family')
            )
            .reduce((pQty, curr, arr) => {
              // console.log("curr.note.toString()", curr.note.toString())
              const qtyPurchased =
                typeof curr.qty === 'string' && curr.qty
                  ? parseInt(curr.qty)
                  : curr.qty
              console.log('small qty: ', curr.qty, qtyPurchased)
              return (
                pQty +
                (qtyPurchased *
                  (curr.note.toString().length
                    ? parseInt(curr.note.toString().substr(0, 2), 10)
                    : 0)) /
                  10
              )
            }, 0)
          const regular = orders
            .filter(
              o =>
                (o.note.toString().includes('20 Meals Small Family') ||
                  o.note.toString().includes('40 Meals Small Family')) === false
            )
            .reduce((pQty, curr, arr) => {
              // console.log("curr", curr)
              // console.log("curr.note.toString()", curr.note.toString())
              const qtyPurchased =
                typeof curr.qty === 'string' && curr.qty
                  ? parseInt(curr.qty)
                  : curr.qty
              console.log('regular qty: ', curr.qty, qtyPurchased)
              return (
                pQty +
                (qtyPurchased *
                  (curr.note.toString()
                    ? parseInt(curr.note.toString().substr(0, 2), 10)
                    : 0)) /
                  10
              )
            }, 0)
          console.log(small, regular)

          cb({
            ...woo_class[0],
            small,
            regular,
            participants: participantCount
          })
        },
        errCb
      )
    })
    .catch(errCb)
}

export const deleteWooClass = (Classe, cb, errCb) => {
  const product_id = Classe.product_id ? Classe.product_id : -1

  // don't do a look up if it is the template
  if (product_id === -1)
    return errCb({ msg: 'No valid Product ID was passed to delete.' })

  Delete(`${baseNodeUrl}/ecommerce/woo/product/${product_id}`)
    .then(woo_class => {
      // check to see if there was an error getting the class
      if (woo_class.hasOwnProperty('message')) errCb(woo_class)

      cb(woo_class)
    })
    .catch(errCb)
}

export const deleteWooClassPromise = Classe => {
  return new Promise((res, rej) => {
    deleteWooClass(Classe, res, rej)
  })
}

export const deleteWooClassWithJustUpdate = (Classe, cb, errCb) => {
  const product_id = Classe.product_id ? Classe.product_id : -1

  // don't do a look up if it is the template
  if (product_id === -1)
    return errCb({ msg: 'No valid Product ID was passed to delete.' })

  Put(`${baseNodeUrl}/ecommerce/woo/product/${product_id}`, {
    id: product_id,
    status: 'trash'
  })
    .then(woo_class => {
      // check to see if there was an error getting the class
      if (woo_class.hasOwnProperty('message')) errCb(woo_class)

      cb(woo_class)
    })
    .catch(errCb)
}

const getWooOrdersPromise = Classe => {
  return Get(`${baseNodeUrl}/ecommerce/woo/participants/${Classe.product_id}`)
}

const getWooOrders = (Classe, cb, errCb) => {
  getWooOrdersPromise(Classe)
    .then((data, err) => {
      cb(data)
    })
    .catch(err => {
      errCb(err)
    })
}

export const completeWooOrders = (orders, cb, errCb) => {
  const orderUpdates = orders.map(o => ({
    id: o.order_increment_id,
    status: o && o.status === 'processing' ? 'completed' : o.status
  }))
  Post(`${baseNodeUrl}/ecommerce/woo/orders/batch/`, { update: orderUpdates })
    .then((data, err) => {
      if (err) errCb(err)
      cb(data)
    })
    .catch(err => {
      errCb(err)
    })
}

export const setOnholdWooOrders = (orders, cb, errCb) => {
  const orderUpdates = orders.map(o => ({
    id: o.order_increment_id,
    status: o && o.status !== 'refunded' ? 'on-hold' : o.status
  }))
  Post(`${baseNodeUrl}/ecommerce/woo/orders/batch/`, { update: orderUpdates })
    .then((data, err) => {
      if (err) errCb(err)
      cb(data)
    })
    .catch(err => {
      errCb(err)
    })
}

export const getClassesParticipants = (classEventIds) => {

  let classes = classEventIds.join('|')

  return new Promise((resolve, reject) => {
    axiosWithAuth.get(`/api/classes/orders`, { params: {classes: classes}})
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        console.error(error)

        reject(error)
      })
  })
}

export const getParticipantsAsync = classe =>
{
  console.log({'getParticipantsAsync': classe})

  return new Promise((resolve, reject) => {
    axiosWithAuth.get(`/api/classes/${classe.id}/orders`)
      .then((response) => {
        console.log(response.data, classe)

        resolve(response.data)
      })
      .catch((error) => {
        console.error(error)

        reject(error)
      })
  })

  // return new Promise((res, rej) => getEcommerceParticipants(classe, res, rej))
}

export const getEcommerceParticipants = (Classe, cb, errCb) => {
  // console.log("is class ok? ", Classe)
  if ((Classe && Classe.product_id) === false) return cb([])
  // console.log("class was ok")
  getWooOrdersPromise(Classe)
    .then(participants => {
      if (participants.hasOwnProperty('trace')) {
        errCb(participants)
        console.log('error:', participants)
        // cb([])
      }
      // ['Ticket', 'Customer', 'Email', 'Quantity', 'Type', 'Date'], name: classLocation })

      if (participants && participants.length > 0)
        cb(
          participants.reduce((prev, p, i) =>
          {
            const {
              number,
              status,
              date_created,
              billing: { first_name, last_name, email, phone } = {},
              coupon_lines: [{ code: coupon, discount: discount_amt } = {}] = [{}],
              refunds: [{ total: order_refund_amt } = {}] = [{}],
              payment_method,
              date_modified
            } = p
            const phoneObj = p.meta_data.find(m => m.key === 'billing_phone')
            const Phone = phoneObj ? phoneObj.value : p.billing.phone
            const lineItems = p.line_items.filter(
              l => l.product_id === Classe.product_id
            )
            return [
              ...prev,
              ...lineItems.map(lineItem => {
                const mealSize = lineItem.meta_data.find(
                  m => m.key === 'meal-size'
                )
                const mealQty = lineItem.meta_data.find(
                  m => m.key === 'number-of-meals'
                )
                const allergy = p.meta_data.find(
                  m => m.key === 'additional_field_2'
                )
                const refferal = p.meta_data.find(
                  m => m.key === 'additional_field_726'
                )
                const stockQty = lineItem.meta_data.find(
                  m => m.key === "_reduced_stock"
                )
                const qty = calculateQty(lineItem, p.refunds, stockQty ? parseInt(stockQty.value) : 0, p.total ? parseFloat(p.total) : 0)
                if (!mealQty) {  return console.log(`bad meal qty: ${p.id}`)  }
                return {
                  ...lineItem,
                  date_created,
                  coupon,
                  discount_amt,
                  payment_method,
                  order_refund_amt,
                  date_modified,
                  order_increment_id: p.number,
                  customer_name: `${p.billing.first_name} ${p.billing.last_name}`,
                  customer_email: p.billing.email,
                  // qty: calculateQty(lineItem, p.refunds),
                  qty,
                  note: mealQty ? `${mealQty.value} ${mealSize.value}`: '',
                  created_at: p.date_created,
                  Phone,
                  status: qty !== 0 && p.status === "refunded" ? "completed" : p.status,
                  PortionSize: mealSize ? mealSize.value : '',
                  PortionQty: mealQty ? mealQty.value : '',
                  Allergy: allergy ? allergy.value : '',
                  Refferal: refferal ? refferal.value : '',
                  Notes: p.customer_note ? p.customer_note : '-'
                }
              })
            ]
          }, [])
        )
      else cb([])
    })
    .catch(errCb)
}

const calculateQty = (lineItem, refunds, reduced_stock, order_total) =>
{
  // console.log(lineItem, refunds)
  let refundSubtract = 0
  let refundTotal = 0
  if (lineItem) {
    if (refunds && refunds.length > 0) {
      refunds.forEach(r => {
        const lAmount = lineItem.total ? parseFloat(lineItem.total) : null
        const rAmount = r.total ? parseFloat(r.total) : 0
        refundTotal += rAmount
        if (rAmount !== 0 && lAmount !== null) {
          const remainder = lAmount % rAmount
          // check to see if they are exactly divisible
          if (remainder === 0) {
            refundSubtract++
          }
        }
      })
    }
  }


  let rtn = (
    (lineItem && lineItem.quantity !== 0 ? lineItem.quantity : 0) -
    refundSubtract
  )

  // short circuit calculation if its a 1 item refund and they cancel out
  rtn = (refundTotal !== 0 && order_total !== 0 && refundTotal + order_total === 0) ? 0 : rtn
  rtn = rtn <= reduced_stock ? rtn : reduced_stock

  let perm = localStorage.getItem("admin_permissions")
  perm = perm ? JSON.parse(perm) : {}
  if (perm.auth_level === 1) {
    // console.log({ rtn, refundSubtract, reduced_stock, qty: lineItem.quantity, refundTotal, order_total, refunds, lineItem })
  }
  return rtn
}

export const getSku = (
  date,
  Code,
  sku_format = 'YY-MMDD-LC',
  defaultSku = 'Error'
) => {
  return date && Code
    ? sku_format
      .replace(
        /YY-MMDD/,
        date.format ? date.format('YY-MMDD') : moment(date).format('YY-MMDD')
      )
      .replace(/LC/, Code)
    : defaultSku
}

export const prepareWooClassData = (woo_class_passed, passedClasse, location) =>
{
  if (!passedClasse) throw new Error(`passedClasse was not defined for prepareWooClassData, please contact the developer to fix this.`)
  if (!location) throw new Error(`location was not defined for prepareWooClassData, please contact the developer to fix this.`)
  if (!location.timezone) {console.log(location); throw new Error(`timeZone must be selected for location: ${location.name}`)}

  console.log("what should be UTC time:", passedClasse.date)
  const Classe = { ...passedClasse, date: changeTimezone(new Date(passedClasse.date), location.timezone) }

  const { featured_image_urls, author_info, comments_num, ...woo_class } = woo_class_passed
  console.log('All of the data', woo_class, Classe, location)

  // // get the classtype
  // const classType = classTypes.find(c => c.id === Classe.classType) || {}
  // const variationsTemplate = classType.variations

  // get the class type
  // get class type variations

  console.log('variationsTemplate', variationsTemplate)

  console.log(Classe)
  if (!variationsTemplate) throw new Error(`Variations not defined on template, for class_type: ${Classe.classType} ${Classe.class_type}`)

  const updatedTemplate = {
    ...wooClassTemplate,
    ...classType.template
  }

  // console.log(
  //   'Important Things: ',
  //   stock_qty,
  //   updatedTemplate,
  //   classType.stock_quantity,
  //   location.Code
  // );

  // construct the sku, or send the default one
  const sku_format = classType.sku_format || 'YY-MMDD-LC'
  const new_sku = getSku(
    Classe.date,
    location.Code,
    sku_format,
    default_class_sku
  )

  // build the description
  const tempDesc = Classe.newRecipes
    ? `${Classe.newRecipes.reduce((prev, curr) => {
      if (!curr) return prev
      const name = curr && curr.name ? curr.name : 'No Name Provided'
      // console.log(curr.name);
      prev =
          `${prev !== '' ? `${prev}` : `` 
          }<span style="color: #ff9900;">${
            name.replace(/\*/g, "")
          }</span><br/>`
      return prev
    }, '<h3>Menu:<br/>')  }</h3>`
    : 'None Temp'

  console.log(Classe)

  const extraCharge = (Classe.newRecipes
    ? Classe.newRecipes
    : Classe.recipes
  ).filter(
    r => r && (r.name.includes('*'))
  )

  const is_first_save =
    woo_class.sku === 'TEMPLATE' ||
    !Classe.sku ||
    new_sku !== woo_class.sku ||
    new_sku === default_class_sku

  delete woo_class.id

  const default_stock_qty = classType.qty_key
    ? location[classType.qty_key]
    : location.Default_Class_Size

  // default the quantity to the classType class size on first save (above), or if not available location default size,
  // If it is a save AFTER the first save, location
  let stock_qty =
    (default_stock_qty || 0) -
    (Classe.participants ? Classe.participants : 0)

  // If the calculation above bombed, set to current stock quantity
  stock_qty =
    stock_qty || stock_qty === 0 ? stock_qty : woo_class.stock_quantity

  const classPlusPlus = {
    ...Classe,
    stock_qty,
    is_first_save,
    tempDesc,
    sku: new_sku,
    hasCarneAsada: extraCharge.length > 0,
    location: { ...location }
  }

  // calculate variations
  const S_extraCharge = 2
  const D_extraCharge = 4
  const extraChargeSingle = extraCharge.filter(c => !c.name.includes("**"))
  const extraChargeDouble = extraCharge.filter(c => c.name.includes("**"))
  const carneCastigo = (D_extraCharge * extraChargeDouble.length) + (S_extraCharge * extraChargeSingle.length)

  const calcPrice = (origPrice, carneCastigoMultiplier = 1, cCastigo = 0) =>
  {
    const { price_offset: location_offset = 0, price_multiplier: location_multiplier = 1 } = location
    let regular_price = classType.pricing_offset
      ?
      parseFloat(origPrice) +
        classType.pricing_offset +
        (cCastigo * carneCastigoMultiplier)
      : parseFloat(origPrice) + (cCastigo * carneCastigoMultiplier)
    regular_price = Math.ceil((regular_price * location_multiplier) + location_offset)
    regular_price = regular_price.toString()
    return regular_price
  }

  const howManySets = (variation = {attributes: []}) =>
  {
    const attributes = variation.attributes.map(({ option }) => option)
    const regularDouble = attributes.includes("Regular Family Size (6-8 Adults)") && attributes.includes("20 Meals")
    const smallDouble = attributes.includes("Small Family Size (3-4 Adults)") && attributes.includes("40 Meals")
    return regularDouble || smallDouble ? 2 : 1
  }

  const variations = variationsTemplate.map((v, i) =>
  {
    // get the id so that an update will work
    const { variations: vary = [undefined, undefined, undefined, undefined] } = woo_class
    const id = is_first_save ? undefined : vary[i]

    // calculate price each
    const regular_price = classType.pricing_override ? classType.pricing_override : calcPrice(v.price, howManySets(v), Classe.privateMenu ? carneCastigo : 0)

    return {
      ...v,
      ...(id ? { id }: {}),
      regular_price,
      price: regular_price,
      permalink: v.permalink
        ? v.permalink
          .replace('{TEMPLATE}', is_first_save ? new_sku : woo_class.sku)
          .replace('{ECOMMERCE_URL}', ecommerce_url)
        : undefined,
      image: {
        ...(updatedTemplate && updatedTemplate.images
          ? (updatedTemplate.images.length > 1 ? updatedTemplate.images[1] : updatedTemplate.images[0])
          :
          {
            id: 517726,
            src:
              'https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/01/05205624/CP-Product-Image-20-MAIN.png',
            name: 'CP Pricing Base Price',
            alt: '',
            position: 0
          }
        )
      }
    }
  })

  console.log('variations: ', variations)
  // update the class
  const curClass = {
    ...woo_class,
    // ...(is_first_save ? { ...updatedTemplate } : {}),
    ...updatedTemplate,
    name: templateReplace(updatedTemplate.name, classPlusPlus),
    variations,
    ...(!woo_class.hasOwnProperty('message') && Classe.hasOwnProperty('sku')
      ? { sku_url: Classe.sku }
      : {}),
    ...(woo_class.hasOwnProperty('message')
      ? {}
      : { id: Classe.product_id ? Classe.product_id : undefined }),
    default_attributes:
      updatedTemplate.default_attributes &&
      updatedTemplate.default_attributes.length
        ? woo_class.default_attributes
        : updatedTemplate.default_attributes,
    sku: is_first_save ? new_sku : woo_class.sku,
    slug: is_first_save ? new_sku : woo_class.sku,
    status: Classe.enabled ? 'publish' : 'draft',
    stock_quantity: !['complete', 'cancelled'].includes(Classe.status) ? stock_qty : 0,
    class_size: !['complete', 'cancelled'].includes(Classe.status) ? stock_qty : 0,
    in_stock: !['complete', 'cancelled'].includes(Classe.status),
    catalog_visibility: Classe.classIsPublic ? 'visible' : 'hidden',
    description: templateReplace(updatedTemplate.description, classPlusPlus),
    short_description: templateReplace(
      updatedTemplate.short_description,
      classPlusPlus
    ),
    purchase_note: templateReplace(
      updatedTemplate.purchase_note,
      classPlusPlus
    ),
    categories: [
      {
        id: location.Category_ID
      },
      // if classtype is monthly/private but also marked as public, show only location, otherwise add the category type
      ...(Classe.classIsPublic &&
      (Classe.classType === 'ciuw7893hdej' ||
        Classe.classType === '982jheb8f' ||
        Classe.classType === 'q28rul50fe' ||
        Classe.classType === 'js73ytsnx9')
        ? []
        : updatedTemplate.categories),
      // if completed is set, we add completed category
      ...(Classe.completed
        ? [
          {
            id: 148
          }
        ]
        : [])
    ],
    tags: [
      ...updatedTemplate.tags,
      ...(Classe.privateMenu ? [{
        id: 117,
        name: 'Private Menu',
        slug: 'private-menu'
      }] : [{
        id: 116,
        name: 'Monthly Menu',
        slug: 'monthly-menu'
      }]),
      ...(Classe.status === 'cancelled'
        ? [
          {
            id: 180,
            name: 'Cancelled Class',
            slug: 'cancelled'
          }
        ]
        : [])
    ],
    ...(updatedTemplate && updatedTemplate.images ? { images: updatedTemplate.images } : {})
      
  }

  // console.log({curClass});
  return { curClass, is_first_save }
}

export const templateReplace = (text, Classe) => {
  return text
    .replace(/{location_name}/, Classe.location.Location)
    .replace(/{recipes}/, Classe.tempDesc)
    .replace(
      /{date_M_D}/,
      Classe.date.format
        ? Classe.date.format('MMMM Do')
        : moment(Classe.date).format('MMMM Do')
    )
    .replace(
      /{date}/,
      `${
        Classe.date.format
          ? Classe.date.format('dddd, MMMM Do')
          : moment(Classe.date).format('dddd, MMMM Do')
      }`
    )
    .replace(
      /{time}/,
      `${
        Classe.date.format
          ? Classe.date.format('h:mm A')
          : moment(Classe.date).format('h:mm A')
      }`
    )
    .replace(
      /{time_before}/,
      `(arrive by ${
        Classe.date.format
          ? Classe.date
            .subtract(moment.duration({ minutes: 15 }))
            .format('h:mm A')
          : moment(Classe.date)
            .subtract(moment.duration({ minutes: 15 }))
            .format('h:mm A')
      })`
    )
    .replace(/{store_city}/, `${Classe.location.Location}`)
    .replace(/{store_address}/, `${Classe.location.Address}`)
    .replace(/{store_name}/, `${Classe.location.Store_Name}`)
    .replace(
      /{carne_asada_handling}/,
      Classe.hasCarneAsada
        ? '<p style="text-align: center;">*The price of meat for this meal adds an additional fee to the class*</p>'
        : ''
    )
    .replace(/{sku}/, Classe.sku)
    .replace(
      /{refund_date}/,
      `${
        Classe.date.format
          ? Classe.date
            .subtract(moment.duration({ days: 8 }))
            .format('dddd, MMMM Do')
          : moment(Classe.date)
            .subtract(moment.duration({ days: 8 }))
            .format('dddd, MMMM Do')
      } 11:59 PM`
    )
}


export const batchPromises = async (list, function_to_call, cb) =>
{

  // get all of the promises
  const rtn = []
  const data = []
  const batchSize = 5.0

  let percentComplete = 0.0; let num = 0.0; let den = 1.0

  const count = list.length ? list.length : 1
  console.log("promise qty:", count)
  
  for (let i = 0; i < count; i += batchSize){
    const array_of_arrays = await Promise.all(
      list
        .filter((e, j) => (j >= i && j < i + batchSize))
        .map(c => handle(function_to_call(c))))
    array_of_arrays
      .forEach(
        ([a, err]) =>
          a && Array.isArray(a) ?
            a.forEach(a2 => rtn.push(a2))
            :
            console.error(err || a)
      )
    num = (i + batchSize)
    den = count
    percentComplete = (num / den) * 100
    // console.log(`${num}/${den} = ${percentComplete}`)
    if (cb) cb(percentComplete)
  }

  console.log("order qty returned:", rtn.length, rtn)
  return rtn
    
}

export const exportParticipants = async (classes, cb) =>
{
  const classEventIds = classes.map((classEvent) => { return classEvent.id })

  let rtn = await getClassesParticipants(classEventIds)

  // const product_id_list = classes.map(citrus_class => ({ id: citrus_class.id}))

  // get all of the orders
  // const rtn = []
  // const data = []
  // const batchSize = 5.0
  //
  // let percentComplete = 0.0; let num = 0.0; let den = 1.0
  //
  // const classCount = product_id_list.length ? product_id_list.length : 1
  // console.log("class qty:", classCount)
  //
  // for (let i = 0; i < classCount; i += batchSize){
  //   const array_of_arrays = await Promise.all(
  //     product_id_list
  //       .filter((e, j) => (j >= i && j < i + batchSize))
  //       .map(c => handle(getParticipantsAsync(c)))
  //   )
  //
  //   array_of_arrays.forEach(
  //     ([a, err]) => {
  //
  //       console.log({ 'array_of_arrays': array_of_arrays, 'a': a })
  //
  //       a && Array.isArray(a) ?
  //         a.forEach(a2 => rtn.push(a2))
  //         :
  //         console.error(err || a)
  //     })
  //
  //   num = (i + batchSize)
  //   den = classCount
  //   percentComplete = (num / den) * 100
  //   // console.log(`${num}/${den} = ${percentComplete}`)
  //   if (cb) cb(percentComplete)
  // }
  //
  // console.log("order qty returned:", rtn.length, rtn)
    
  // loop through the orders, structure the data
  // rtn.forEach(p =>
  // {
  //   const {
  //     number,
  //     status,
  //     date_created,
  //     billing: { first_name, last_name, email, phone } = {},
  //     line_items,
  //     coupon_lines: [{ code: coupon, discount: discount_amt } = {}] = [{}],
  //     refunds: [{ total: order_refund_amt } = {}] = [{}],
  //     payment_method,
  //     date_modified
  //   } = p;
  //   line_items.forEach(l =>
  //   {
  //     const {
  //       sku,
  //       name: item_name,
  //       variation_id,
  //       quantity,
  //       subtotal: order_line_subtotal
  //     } = l;

  //     const part = {
  //       number,
  //       status,
  //       order_date: date_created,
  //       first_name,
  //       last_name,
  //       email,
  //       phone,
  //       sku,
  //       item_name,
  //       variation_id,
  //       quantity,
  //       order_line_subtotal,
  //       coupon,
  //       discount_amt,
  //       payment_method,
  //       order_refund_amt,
  //       date_modified
  //     }
  //     data.push(part);
  //   })
  // })

  return rtn.map(l =>
  {
    console.log({'rtn': rtn, 'l': l})

    const {
      sku,
      name: item_name,
      variation_id,
      quantity,
      order_line_subtotal,
      order_line_taxes,
      coupon,
      discount_amt,
      payment_method,
      order_refund_amt,
      date_modified,
      first_name,
      last_name,
      date_created,
      PortionSize,
      PortionQty,
    } = l

    return {
      number: l.order_increment_id,
      status: l.status,
      order_date: l.created_at,
      date_created,
      first_name,
      last_name,
      email: l.customer_email,
      phone: l.Phone,
      sku,
      item_name,
      variation_id,
      quantity,
      order_line_subtotal,
      order_line_taxes,
      coupon,
      discount_amt,
      payment_method,
      order_refund_amt,
      date_modified,
      PortionSize,
      PortionQty,
    }
  })
}


const saveEcommerceClass_DECOY = (woo_class, Classe, location, cb, errCb) => {
  // export const saveEcommerceClass = (woo_class, Classe, location, cb, errCb) => {
  const { curClass } = prepareWooClassData(
    woo_class,
    Classe,
    location
  )
  cb({ id: "THIS_MAKES_IT_SAVE", curClass })
  console.log(
    'saveEcommerceClass is disabled, but this is what it should have saved: ',
    curClass
  )
}

const saveEcommerceClass_REAL = (woo_class, Classe, location, cb, errCb) => {
// export const saveEcommerceClass = (woo_class, Classe, location, cb, errCb) => {
  console.log('saveEcommerceClass start: Classe.db_id:', Classe.db_id)
  let { curClass, is_first_save } = prepareWooClassData(
    woo_class,
    Classe,
    location
  )
  const wooClassTemp = curClass

  let temp; let rtnWooClass
  let { variations, sku_url, ...wooClass } = wooClassTemp

  console.log(
    'after prepareWooClassData data, variations: ',
    wooClass,
    variations,
    sku_url
  )

  if (!is_first_save) {
    temp = Put(`${baseNodeUrl}/ecommerce/woo/product/${wooClass.id}`, {
      ...wooClass
    })
  } else {
    console.log('post')
    if (wooClass.id) delete wooClass.id
    temp = Post(`${baseNodeUrl}/ecommerce/woo/product`, wooClass)
  }
  let updatingSku = false
  temp
    .then(wClass => {
      if (wClass && wClass.message === 'Invalid or duplicated SKU.') {
        console.log('Invalid or duplicated SKU, getting class', wClass)
        updatingSku = true
        is_first_save = false
        return getEcommerceClassPromise({ ...Classe, sku: wooClass.sku })
      } return makePromise({ ...wClass })
    })
    .then(rClass => {
      if (rClass && updatingSku) {
        const { variations: rVariations, ...veryTempClass } = rClass
        variations = { ...rVariations }
        rtnWooClass = { ...wooClass, id: rClass.id }
        console.log(
          'We got the class, now updating... wooClass, rtnWooClass, variations',
          wooClass,
          rtnWooClass,
          variations,
          veryTempClass
        )
        return Put(`${baseNodeUrl}/ecommerce/woo/product/${rtnWooClass.id}`, {
          ...rtnWooClass
        })
      } return makePromise({ ...rClass })
    })
    .then(wClass => {
      rtnWooClass = wClass
      console.log('returned product:', wClass)
      return is_first_save
        ? Post(`${baseNodeUrl}/ecommerce/woo/variation/${wClass.id}`, {
          create: variations
        })
        : Post(`${baseNodeUrl}/ecommerce/woo/variation/${wClass.id}`, {
          update: variations
        })
    })
    .then(wVariations => {
      console.log('calling callback on saveEcommerceClass', wVariations)
      cb({ ...rtnWooClass, variations: wVariations })
    })
    .catch(errCb)
}

export const saveEcommerceNew = (woo_class, Classe) => {
  // export const saveEcommerceNew_REAL = (woo_class, Classe, location, cb, errCb) => {
  return new Promise((res, rej) =>
  {
    const { variations, ...wooClass } = woo_class
    let is_first_save = !wooClass.id; let rtnWooClass = {}; let temp

    if (!is_first_save) {
      temp = Put(`${baseNodeUrl}/ecommerce/woo/product/${wooClass.id}`, {
        ...wooClass
      })
    } else {
      console.log('post')
      if (wooClass.id) delete wooClass.id
      temp = Post(`${baseNodeUrl}/ecommerce/woo/product`, wooClass)
    }
    // resolve and check if we have a duplicate sku, then delete, and resubmit
    let updatingSku = false
    temp
      .then(wClass => {
        if (wClass && wClass.message === 'Invalid or duplicated SKU.') {
          console.log('Invalid or duplicated SKU, getting class', wClass)
          updatingSku = true
          is_first_save = false
          return getEcommerceClassPromise({ ...Classe, sku: wooClass.sku })
        } return makePromise({ ...wClass })
      })
      .then(rClass => {
        if (rClass && updatingSku) {
          const { variations: rVariations, ...veryTempClass } = rClass
          variations = { ...rVariations }
          rtnWooClass = { ...wooClass, id: rClass.id }
          console.log(
            'We got the class, now updating... wooClass, rtnWooClass, variations',
            wooClass,
            rtnWooClass,
            variations,
            veryTempClass
          )
          return Put(`${baseNodeUrl}/ecommerce/woo/product/${rtnWooClass.id}`, {
            ...rtnWooClass
          })
        } return makePromise({ ...rClass })
      })
      .then(wClass => {
        rtnWooClass = wClass
        console.log('returned product:', wClass)
        return is_first_save
          ? Post(`${baseNodeUrl}/ecommerce/woo/variation/${wClass.id}`, {
            create: variations
          })
          : Post(`${baseNodeUrl}/ecommerce/woo/variation/${wClass.id}`, {
            update: variations
          })
      })
      .then(wVariations => {
        console.log('resolving', wVariations)
        res({ ...rtnWooClass, variations: wVariations })
      })
      .catch(rej)
  })
   
}

export const getPdfJson = async sku => {
  return await Get(`${baseNodeUrl}/api/pdf/${sku}`)
}

export const makePromise = obj => {
  return Promise.resolve(obj)
}

export const exportList = ({ name, data, fields }) => {
  if (data.length === 0) return
  fetch(`${baseNodeUrl}/api/convertJSONtoCSV`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      idToken: localStorage.getItem('firebase.auth.idToken')
    },
    body: JSON.stringify({
      data,
      fields: fields || Object.keys(data[0]),
      name
    })
  }).then(res => {
    res.text().then(csv => {
      const blobUrl = window.URL.createObjectURL(
        new Blob([csv], { type: 'text/csv' })
      )
      const a = document.createElement('a')
      a.download = `Export ${name} - ${moment().format('MMMM Do, Y')}.csv`
      a.href = blobUrl
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    })
  })
}

export const INGREDIENT_GET_ALL_GQL = gql`
  {
    ingredients {
      id
      value
      original_id
      ingredient_id
    }
  }
`

export const INGREDIENT_CREATE_GQL = gql`
  mutation createIngredient($id: String!, $value: SequelizeJSON!) {
    createIngredient(original_id: $id, value: $value) {
      id
      value
      original_id
      ingredient_id
    }
  }
`

export const INGREDIENT_UPDATE_GQL = gql`
  mutation updateIngredient($id: String!, $value: SequelizeJSON!) {
    updateIngredient(ingredient_id: $id, value: $value) {
      id
      value
      original_id
      ingredient_id
    }
  }
`

export const INGREDIENT_DELETE_GQL = gql`
  mutation deleteIngredient($id: String!) {
    deleteIngredient(where: { ingredient_id: $id }) {
      ingredient_id
    }
  }
`

export const RECIPE_GET_ALL_GQL = gql`
  {
    recipes {
      id
      value
      original_id
      recipe_id
    }
  }
`

export const RECIPE_CREATE_GQL = gql`
  mutation createRecipe($id: String!, $value: SequelizeJSON!) {
    createRecipe(original_id: $id, value: $value) {
      id
      value
      original_id
      recipe_id
    }
  }
`

export const RECIPE_UPDATE_GQL = gql`
  mutation updateRecipe($id: String!, $value: SequelizeJSON!) {
    updateRecipe(recipe_id: $id, value: $value) {
      id
      value
      original_id
      recipe_id
    }
  }
`

export const RECIPE_DELETE_GQL = gql`
  mutation deleteRecipe($id: String!) {
    deleteRecipe(where: { recipe_id: $id }) {
      recipe_id
    }
  }
`

// export const GET_CLASS_ALL = gql`
//   {
//     class_events(
//       where: {
//         or: [
//           { value: { record_status: null } }
//           { value: { record_status: "active" } }
//         ]
//       }
//     ) {
//       id
//       value
//       original_id
//       class_event_id
//     }
//   }
// `;

export const CLASS_CREATE_GQL = gql`
  mutation createClass(
    $id: String!, 
    $value: SequelizeJSON!,
    $date: Date!,
    $sku: String!,
    $class_type: String!,
    $location: String!,
    $participants: String!,
    $small: Int!,
    $regular: Int!  
    $recipes: SequelizeJSON!  
    ) {
    createClass_event(
      original_id: $id, 
      value: $value,
      date: $date,
      sku: $sku,
      class_type: class_type,
      location: $location,
      participants: $participants,
      small: $small,
      regular: $regular,
      recipes: $recipes
      ) {
      id
      class_event_id
      original_id
      value
      date
      sku
      class_type
      location
      participants
      small
      regular
      recipes
    }
  }
`

export const CLASS_UPDATE_GQL = gql`
  mutation updateClass(
    $id: String!,
    $value: SequelizeJSON!,
    $date: Date!,
    $sku: String!,
    $class_type: String!,
    $location: String!,
    $participants: String!,
    $small: Int!,
    $regular: Int! 
    $recipes: SequelizeJSON! 
    ) {
    updateClass_event(
      class_event_id: $id,
      value: $value,
      date: $date,
      sku: $sku,
      class_type: $class_type,
      location: $location,
      participants: $participants,
      small: $small,
      regular: $regular,
      recipes: $recipes
      ) {
        id
        class_event_id
        original_id
        value
        date
        sku
        class_type
        location
        participants
        small
        regular
        recipes
    }
  }
`

export const CLASS_DELETE_GQL = gql`
  mutation deleteClass($id: String!) {
    deleteClass_event(where: { class_event_id: $id }) {
      id
    }
  }
`

export const SETTING_GET_ALL_GQL = gql`
  {
    settings {
      id
      setting_id
      type
      sub_type
      sub_sub_type
      data
    }
  }
`

export const SETTING_CREATE_GQL = gql`
  mutation createSetting($id: String!, $value: SequelizeJSON!) {
    createSetting_event(original_id: $id, value: $value) {
      id
      value
      original_id
      setting_id
    }
  }
`

export const SETTING_UPDATE_GQL = gql`
  mutation updateSetting($id: String!, $value: SequelizeJSON!) {
    updateSetting_event(setting_id: $id, value: $value) {
      id
      value
      original_id
      setting_id
    }
  }
`

export const SETTING_DELETE_GQL = gql`
  mutation deleteSetting($id: String!) {
    deleteSetting_event(where: { setting_id: $id }) {
      id
    }
  }
`

export const LOCATIONS_FOR_USER_GQL = gql`
  query getLocationsForUser($uid: String!){
  users(where: { firebase_user_id: $uid }) {
    locations {
      location_id
    }
  }
}
`

export const GET_LOCATIONS = gql`
  {
    locations(order: "name"){
      id,
      location_id
      active
      name
      data
    }
  }`

export const runQuery = async (
  QUERY,
  params,
  recursionLevel = 0,
  idToken = localStorage.getItem('firebase.auth.idToken')
) => {
  try {
    const headers = {
      headers: {
        idToken
      }
    }
    const query = QUERY.loc.source.body
    // console.log("QUERY", QUERY.loc.source.body)
    // console.log("headers")
    const client = new GraphQLClient(graphQlUrl, headers)
    // console.log("client")
    const data = await client.request(query, params)
    if (!data) throw new Error('No data was returned:', data)
    return data
    // console.log("data", data)
    // console.log(JSON.stringify(data, undefined, 2))
  } catch (error) {
    // console.log(1);
    // console.log(
    //   'refresh test: ',
    //   recursionLevel,
    //   typeof error,
    //   Object.keys(error),
    //   JSON.parse(error.response.error)
    // );
    let err
    try {
      console.log({error})
      err = JSON.parse(error.response.error)
    } catch (e) {
      console.log('parse failed: ', err)
    }

    if (
      recursionLevel < 3 &&
      err &&
      err.message.includes('Authentication Failed')
    ) {
      const token = await firebase.auth().currentUser.getIdToken(true)
      localStorage.setItem('firebase.auth.idToken', token)

      // console.log(
      //   'retry query, after login: ',
      //   QUERY.loc.source.body,
      //   params,
      //   recursionLevel + 1
      // );

      return await runQuery(QUERY, params, recursionLevel + 1, token)
    }
    console.log('error', error)
    // console.error(JSON.stringify(error, undefined, 2))
  }
}


// export const getEcommerceClass = isDev ? getEcommerceClass_DECOY : getEcommerceClass_REAL
// export const saveEcommerceClass = isDev ? saveEcommerceClass_DECOY : saveEcommerceClass_REAL
export const getEcommerceClass = isDev ? getEcommerceClass_REAL : getEcommerceClass_REAL
export const saveEcommerceClass = isDev ? saveEcommerceClass_REAL : saveEcommerceClass_REAL

// export const axiosWithAuth = axios.create({
//   baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
//   headers: {
//     accept: 'application/json',
//     idToken: localStorage.getItem('firebase.auth.idToken'),
//   }
// })
