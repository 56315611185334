import React, { Component, useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import ArrowDown from '@material-ui/icons/ArrowDownwardSharp'
import AddSharp from '@material-ui/icons/AddSharp'
import useDebouncedCallback from "use-debounce/lib/useDebouncedCallback"
import { useQuery } from '@apollo/react-hooks'
import { connect } from 'react-redux'
import { Fab } from '@material-ui/core'
import FabFabulous from './FabFabulous'
import { GET_CLASS_ALL, client } from '../graphql'
import {
  exportList,
  exportParticipants,
  getForecastClasses,
  getClasses,
  updateClass,
  getClassesReport, archiveClass,
} from '../api'
import { ArchiveHeader } from "./Header"
import { GET_LOCATIONS } from './Locations'
import Filters from './Filters'
import { Body } from './Shell'
import {
  archiveClasses,
  LoadingFactory,
  addLoading,
  setLoadingArray,
  setErrors,
  addError,
  importJanuaryClasses,
  multiClassSave
} from '../actions'
import { LoginRedirector } from './Auth'
import ClassEditor from './ClassEditor'
import ClassCard from './ClassCard'
import Search from './Search'
import Error from './Error'
import Loading from './Loading'
import payroll, { getPayrollData } from '../helpers/exportPayroll'
import exportFoodForecast, { getFoodForecastData } from '../helpers/exportFoodForecast'

const ClassesStyles = theme => ({
  fab: {
    position: 'fixed',
    bottom: 30,
    right: 30,
    color: '#fff',
    zIndex: 1
  },
  recipeCards: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap'
  }
})

class Classes extends Component {
  constructor() {
    super()
    this.state = {
      editorOpen: false,
      classBeingEdited: {},
      search: '',
      selectedClasses: {},
      showArchiveHeader: 0,
      classVariables: { where: { class_event_id: -1 } },
      classList: [],
      userLocations: [],
    }
  }

  componentDidMount = async (prevProps, prevState, prevContext) => {
    await this.updateClassList()
  }

  componentWillUnmount() {

    // fixes the unmounted state update warning error
    this.setState = () => { return }
  }

  updateClassList = async () => {
    let date = moment()

    let params = {
      'after_date': date.subtract(6, 'months').toISOString(),
      'defaults': 'none',
    }

    if (!this.props.isAdmin) {
      params.location_ids = localStorage.getItem('user_locations').replaceAll(',', '|') ?? 0
    }

    getClasses(params)
      .then((response) => {
        this.setState({classList: response.data})
      })
      .catch((error) => {
        console.log(error)
      })
  }

  resetSelectedClasses = e => {
    console.log('reset')
    this.setState({ selectedClasses: {}, showArchiveHeader: 0 })
  };

  archive = async () => {
    const { selectedClasses } = this.state

    // archive class
    let archiveIds = []
    Object.keys(selectedClasses).forEach((key) => {
      if (selectedClasses[key]) {
        archiveIds.push(key)
      }
    })

    Promise.all(archiveIds.map((key) => {archiveClass(key)}))
      .then((result) => {
        this.updateClassList()
        this.setState({
          selectedClasses: {},
          // classList: this.state.classList.filter((citrusClass) => { return !archiveIds.includes(citrusClass.id.toString()) }),
          showArchiveHeader: 0
        })
      })
      .catch((error) => {
        console.log(error)
      })
  };

  multiClassSave = async ({ where }) => {
    const { selectedClasses } = this.state
    const data = client.readQuery({
      query: GET_CLASS_ALL,
      variables: where
    })

    const temp = await this.props.multiClassSave({
      selectedClasses,
      citrusClasses: data.class_events
        ? data.class_events.map(c =>
        {
          const { value, ...theRest } = c
          return { ...value, ...theRest, db_id: c.class_event_id, classType: c.class_type }
        })
        : [],
      onFinish: e => ""
    })
    await temp
  };

  amountSelected = selected => {
    return Object.keys(selected).filter(k => selected[k] === true).length
  };

  toggleClass = ({ id, selected, shift, classes }) => {
    // console.log({ id, selected, shift, classes })
    const key = id
    const { selectedClasses } = this.state
    let newSelectedObj = {}
    if (shift) {
      const firstSelected = Object.keys(selectedClasses).find(k => selectedClasses[k])
      console.log({firstSelected})
      let shouldISelect = false
      if (firstSelected) {
        const startIndex = classes.map(c => c.class_event_id).indexOf(firstSelected)
        const endIndex = classes.map(c => c.class_event_id).indexOf(id)
        newSelectedObj = classes.reduce((p, c, i) =>
        { 
          if (i === (startIndex < endIndex ? startIndex : endIndex)) {
            shouldISelect = true
          }
          if (i === (startIndex >= endIndex ? startIndex : endIndex)) {
            shouldISelect = false
            return { ...p, [c.class_event_id]: true }
          }
          return shouldISelect ? { ...p, [c.class_event_id]: true } : p
        }, { ...selectedClasses })
        
      }
      else {
        newSelectedObj = { [key]: !selected }
      }
    }
    else {
      newSelectedObj = { [key]: !selected }
    }
    
    this.setState({
      selectedClasses: {...selectedClasses, ...newSelectedObj},
      showArchiveHeader: this.amountSelected({...selectedClasses, ...newSelectedObj})
    })
  };

  toggleEditorOpen = () => {
    this.setState(state => ({
      editorOpen: !state.editorOpen,
      classBeingEdited: {}
    }))
  };

  exportClasses = classes => {

    let ids = classes.map((classEvent) => { return classEvent.id })
    getClassesReport({ classes: ids })
      .then((result) => {
        const data = result.data.map((classEvent) => {
          return {
            assembled: '',
            canComplete: classEvent.can_complete,
            changeAmounts: classEvent.change_amounts,
            classIsPublic: classEvent.class_is_public,
            classType: classEvent.template_id,
            completed: classEvent.completed,
            date: classEvent.date,
            db_id: classEvent.class_event_id,
            desc: classEvent.description ?? '',
            enabled: classEvent.enabled,
            id: classEvent.class_event_id,
            image: '',
            location: classEvent.location_id,
            locked: classEvent.locked,
            name: classEvent.name,
            notes: classEvent.notes ?? '',
            participants: '',
            product_id: '',
            recipes: classEvent.classRecipes.map((recipe) => {
              return {name: recipe.value.name, id: recipe.recipe_id}
            }),
            record_status: '',
            regular: '',
            sku: classEvent.sku,
            small: '',
            spreadsheetId: classEvent.spreadsheetId,
            stock: ''
          }
        })
        exportList({
          name: 'Classes',
          data,
          fields: [
            'assembled',
            'canComplete',
            'changeAmounts',
            'classIsPublic',
            'classType',
            'completed',
            'date',
            'db_id',
            'desc',
            'enabled',
            'id',
            'image',
            'location',
            'locked',
            'name,',
            'notes',
            'participants',
            'product_id',
            'recipes',
            'record_status',
            'regular',
            'sku',
            'small',
            'spreadsheetId',
            'stock'
          ]
        })
      })
      .catch((error) => {
        console.log(error)
      })
  };

  exportFoodForecast = async (class_events) => {
    // console.log(class_events)

    const exportLoading = LoadingFactory(
      `Exporting Food Forecast`
    )
    this.props.addLoading(exportLoading, this.props.loadingArray)
    
    const payload = getFoodForecastData()
    const forecastClasses = await getForecastClasses({'classes': class_events.map((class_event) => { return class_event.id }).join('|')})

    // console.log({'forecast payload': payload, 'forecastClasses': forecastClasses.data})

    // get the data
    const data = await exportFoodForecast({ ...payload, class_events: forecastClasses.data })

    this.props.setLoadingArray(
      this.props.loadingArray.filter(l => l.id !== exportLoading.id)
    )

    if (data.spreadsheetId) {
      const win = window.open(
        `https://docs.google.com/spreadsheets/d/${data.spreadsheetId}/`,
        '_blank'
      )
      win.focus()
    }
  }

  exportPayroll = async (class_events) => {
    console.log(class_events)

    if (!this.props.recipes || !this.props.recipes.length) throw new Error("Recipes is not defined")
    const percentComplete = 0
    let exportLoading = LoadingFactory(
      `Exporting Kiyomi payroll: ${percentComplete}% complete`
    )
    this.props.addLoading(exportLoading, this.props.loadingArray)

    // get the data
    const data = await getPayrollData(class_events, (percentComplete) =>
    {
      exportLoading = LoadingFactory(
        `Exporting Kiyomi payroll: ${percentComplete.toFixed(1)}% complete`
      )
      this.props.addLoading(exportLoading, [])
    })

    this.props.setLoadingArray(
      this.props.loadingArray.filter(l => l.id !== exportLoading.id)
    )

    const rtn = await payroll({ ...data, recipes: this.props.recipes})

    // console.log(rtn)

    if (rtn.spreadsheetId) {

      const win = window.open(
        `https://docs.google.com/spreadsheets/d/${rtn.spreadsheetId}/`,
        '_blank'
      )
      win.focus()
    }
  }

  exportParticipants = async classes => {
    let percentComplete
    let exportLoading = LoadingFactory(
      `Downloading order info: ${percentComplete}% complete`
    )
    this.props.addLoading(exportLoading, this.props.loadingArray)

    // get the data
    const data = await exportParticipants(classes, (percentComplete) =>
    {
      exportLoading = LoadingFactory(
        `Downloading order info: ${percentComplete.toFixed(1)}% complete`
      )
      this.props.addLoading(exportLoading, [])
    })

    this.props.setLoadingArray(
      this.props.loadingArray.filter(l => l.id !== exportLoading.id)
    )
    exportList({
      name: 'Orders',
      data,
      fields: [
        'number',
        'status',
        'date_created',
        'first_name',
        'last_name',
        'email',
        'phone',
        'sku',
        'item_name',
        'variation_id',
        'quantity',
        'order_line_subtotal',
        'coupon',
        'discount_amt',
        'payment_method',
        'order_refund_amt',
        'date_modified'
      ]
    })
  }

  startClassEditor = passedClass => {
    const {
      value,
      ...theRest
    } = passedClass
    const selectedClass = {
      ...value,
      classType: theRest.class_type,
      ...theRest
    }
    this.setState({
      classBeingEdited: selectedClass,
      editorOpen: true,
    })
    
    console.log('selectedClass: ', selectedClass)
  }

  render() {
    const {
      classes: { fab },
      locationFilter,
      isAdmin
    } = this.props
    const {
      search,
      classBeingEdited: incomingClass,
      selectedClasses,
      showArchiveHeader
    } = this.state
    const where = isAdmin
      ? { where: { 'value.record_status': 'active' } }
      : locationFilter.length > 0
        ? {
          where: {
            'location': { $in: locationFilter },
            'value.record_status': 'active'
          }
        }
        : { where: { class_event_id: -1 } }
    return (
      <Body>
        <LoginRedirector />
        <Loading />
        <Error />
        {showArchiveHeader > 0 && (
          <ArchiveHeader
            archive={e => this.archive()}
            multiClassSave={e => this.multiClassSave({ where })}
            clear={this.resetSelectedClasses}
            style={{ position: 'absolute', top: 0, width: '100%' }}
          />
        )}
        { this.state.editorOpen ?
          <ClassEditor
            open={this.state.editorOpen}
            title={
              incomingClass.name
                ? `Editing ${incomingClass.name}`
                : 'Create a new class'
            }
            locationFilter={locationFilter}
            isAdmin={isAdmin}
            incomingClass={incomingClass}
            onClose={this.toggleEditorOpen}
            fab={fab}
            where={where}
            updateClassList={this.updateClassList}
          /> : null }
        <ClassCards
          onEditRequested={this.startClassEditor}
          fab={fab}
          search={search}
          isAdmin={isAdmin}
          selectedClasses={selectedClasses}
          toggleClass={this.toggleClass}
          exportClasses={this.exportClasses}
          toggleEditorOpen={this.toggleEditorOpen}
          exportParticipants={this.exportParticipants}
          exportFoodForecast={this.exportFoodForecast}
          exportPayroll={this.exportPayroll}
          where={where}
          classList={this.state.classList}
        />
      </Body>
    )
  }
}

const actions = [
  { icon: <AddSharp />, name: 'Create', onClick: "addClass", auth: 40 },
  { icon: <ArrowDown />, name: 'Forecast', onClick: "exportFoodForecast", auth: 20 },
  { icon: <ArrowDown />, name: 'Payroll', onClick: "exportPayroll", auth: 20 },
  { icon: <ArrowDown />, name: 'Classes', onClick: "exportClasses", auth: 20 },
  { icon: <ArrowDown />, name: 'Orders', onClick: "exportOrders", auth: 20 },
]
export default withStyles(ClassesStyles)(
  connect(
    state => ({
      ingredients: state.ingredients,
      recipes: state.recipes.recipes,
      filter: state.classes.citrusDisplayableFilter,
      errors: state.app.errors,
      loadingArray: state.app.loadingArray,
      locationFilter: state.auth.permisions.location.map(l => l.Category_ID),
      isAdmin: state.auth.permisions.isAdmin
    }),
    {
      setErrors,
      addError,
      LoadingFactory,
      addLoading,
      setLoadingArray,
      importJanuaryClasses,
      archiveClasses,
      multiClassSave
    }
  )(Classes)
)

let ClassCards = props =>
{
  const {
    onEditRequested,
    toggleClass,
    selectedClasses,
    isAdmin,  
    where: passedWhere,
    fab,
    classes: { recipeCards },
    toggleEditorOpen,
    exportClasses,
    exportPayroll,
    exportFoodForecast,
    exportParticipants,
    classList,
  } = props

  const admin_permissions = localStorage.getItem('admin_permissions')
  const [permisions, setPermisions] = useState(
    admin_permissions
      ? JSON.parse(admin_permissions)
      : { isAdmin: false, auth_level: 100 }
  )
  const [calculated_classes, setCalculated] = useState([])
  const [finalFilter, setFinalFilter] = useState([])
  const [filteredClasses, setFiltered] = useState([])
  const [where, setWhere] = useState(passedWhere)
  const [called, setCalled] = useState((new Date()).getTime())
  const [search, setSearch] = useState(JSON.parse(localStorage.getItem('citrusDisplayableFilter') )|| "")
  const [filters, setFilters] = useState([])
  const { data: { locations = [] } = {} } = useQuery(GET_LOCATIONS, { skip: isAdmin === null })

  useEffect(() =>
  {
    // console.log("filters changed effect")

    // console.log({'filteredClasses': filteredClasses, 'filters': filters})

    const wh = passedWhere || where
    const newWhere = filters.reduce((p, c) => ({ ...p, ...c.where }), {})
    const mergedWhere = { where: {...wh.where, ...newWhere } }
    setWhere(mergedWhere)
  }, [passedWhere, filters])

  useEffect(() => {
    // console.log({filters: filters})

    let filtersMap = new Map()
    filters.forEach((filter) => { filtersMap.set(filter.id, filter)})

    let fClasses = filteredClasses

    // sku
    if(filtersMap.get('cjw94357tha')) {
      let filter = filtersMap.get('cjw94357tha')
      fClasses = fClasses.filter((citrusClass) => {
        return citrusClass.sku.toLowerCase().includes(filter.name.toLowerCase())
      })
    }

    // location
    if(filtersMap.get('39fjnz0ej')) {
      let filter = filtersMap.get('39fjnz0ej')
      fClasses = fClasses.filter((citrusClass) => {
        return citrusClass.location_id === parseInt(filter.where.location)
      })
    }

    // menu
    if(filtersMap.get('dew3ryi8jbn')) {
      let filter = filtersMap.get('dew3ryi8jbn')
      fClasses = fClasses.filter((citrusClass) => {
        return citrusClass.private_menu === filter.where.value.privateMenu
      })
    }

    // public
    if(filtersMap.get('gx63tr473jdcnfg57')) {
      let filter = filtersMap.get('gx63tr473jdcnfg57')
      fClasses = fClasses.filter((citrusClass) => {
        return citrusClass.class_is_public === filter.where.value.classIsPublic
      })
    }

    // filter active
    if(!filtersMap.get('scsh372g478fhs')) {
      fClasses = fClasses.filter((citrusClass) => {
        return citrusClass.active === true
      })
    }

    // class size
    if(filtersMap.get('hgswrte54ghfu')) {
      let filter = filtersMap.get('hgswrte54ghfu')
      fClasses = fClasses.filter((citrusClass) => {
        return citrusClass.registrations_count <= filter.where.participants.lte
      })
    }

    // date range
    if(filtersMap.get('s83u2hd92')) {
      let filter = filtersMap.get('s83u2hd92')
      fClasses = fClasses.filter((citrusClass) => {
        let citrusDate = moment(citrusClass.date).local().valueOf()
        return citrusDate >= filter.where.date.gte && citrusDate <= filter.where.date.lte
      })
    }

    // template
    if(filtersMap.get('templateFilter')) {
      let filter = filtersMap.get('templateFilter')

      if (filter.where.template === 27) {
        fClasses = fClasses.filter((citrusClass) => {
          return citrusClass.template_id === 27 || citrusClass.template_id === 28
        })
      }
      else if (filter.where.template === 25) {
        fClasses = fClasses.filter((citrusClass) => {
          return citrusClass.template_id === 25 || citrusClass.template_id === 26
        })
      }
      else {
        fClasses = fClasses.filter((citrusClass) => {
          return citrusClass.template_id === filter.where.template
        })
      }
    }

    setFinalFilter(fClasses)

  }, [filteredClasses, filters])

  useEffect(() =>
  {
    setCalculated(calculateTheClasses(classList))
  }, [classList])

  useEffect(() =>
  {
    filterClasses(search, calculated_classes)
  }, [calculated_classes, search])

  useEffect(() =>
  {
    const passThisFilter = JSON.parse(localStorage.getItem('citrusDisplayableFilter')) || ""
    if(passThisFilter !== search) setSearch(passThisFilter)
  }, [])

  const calculateTheClasses = (classes) =>
  {
    const c_classes = classes.map(c =>
    {
      const location = locations.find(
        loc => String(loc.location_id) === c.location_id
      )
      
      const formatted_date = moment(c.date).format('MMMM Do, Y')
      const Default_Class_Size =
        location && location.Default_Class_Size
          ? location.Default_Class_Size
          : 15
      return {...c, classSize: Default_Class_Size, formatted_date}
    })
    return c_classes
  }

  const [filterClasses] = useDebouncedCallback((filter, classes) =>
  {
    
    const newcalled = (new Date()).getTime()
    console.log({"filterClasses": filter})
    setCalled(newcalled)
    const filterWasPassedIntoFunction =
      filter !== undefined && typeof filter === 'string'

    if (filterWasPassedIntoFunction) {
      localStorage.setItem('citrusDisplayableFilter', JSON.stringify(filter))
    }

    const filterUpper = (filter === null ?  "" : filter).toUpperCase()

    console.log('setFiltered classes', classes)
    console.log('setFiltered filter', filter)
    setFiltered(
      classes
        .map(c => ({ ...c, db_id: c.id }))
        .filter(c => {
          const sku = c.sku ? c.sku.toUpperCase() : ''
          const t = sku.includes(filterUpper)
          return t
        })
    )
  }, 50, false)

  const filteredActions = actions.filter(a => permisions.auth_level <= a.auth)
  
  return (
    <div className={recipeCards}>
      {isAdmin && <Filters filters={filters} setFilters={setFilters} search={search} onChange={setSearch} />}
      <Search label="Search Classes" onChange={setSearch} search={search} />
      {filteredActions.length > 1 ? (
        <FabFabulous
          actions={filteredActions}
          exportPayroll={e => exportPayroll(finalFilter)}
          exportFoodForecast={e => exportFoodForecast(finalFilter)}
          addClass={e => toggleEditorOpen()}
          exportClasses={e => exportClasses(finalFilter)}
          exportOrders={e => exportParticipants(finalFilter)}
        />
      ) : (
        <Fab
          variant="round"
          color="primary"
          aria-label="Add"
          className={fab}
          onClick={toggleEditorOpen}
        >
          <AddSharp />
        </Fab>
      )}

      {finalFilter.length > 0 && finalFilter.map(citrusClass => {
        const {
          locked,
          completed,
          private_menu,
          active,
          template_id,
          location,
          sku,
          date,
          id,
          registrations_count,
          registration_breakdown,
          capacity,
          formatted_date
        } = citrusClass
        const key = citrusClass.id
        let small = registration_breakdown ? registration_breakdown.small_single + (registration_breakdown.small_double * 2) : 0
        let regular = registration_breakdown ? registration_breakdown.regular_single + (registration_breakdown.regular_double * 2) : 0
        return (
          <ClassCard
            classEvent={citrusClass}
            id={citrusClass.id}
            private_menu={private_menu}
            date={date}
            sku={sku}
            isAdmin={isAdmin}
            template={template_id}
            locked={locked}
            completed={completed}
            small={small}
            regular={regular}
            selected={selectedClasses[citrusClass.id]}
            participants={registrations_count}
            class_event_id={id}
            location={location}
            classSize={capacity}
            formatted_date={formatted_date}
            toggleClass={e => toggleClass({...e, classes: finalFilter})}
            active={active}
            onEditRequested={classId => {
              onEditRequested(citrusClass)
            }}
            key={key}
            // subscribeToStationUpdates={() =>
            //   // something is broken that prevents a where clause to work correctly if
            //   // a paramaterized query is sent.  hence the replace functions below:
            //   subscribeToMore({
            //     document: GET_CLASS_ALL,
            //     variables: {},
            //     updateQuery: (prev, { subscriptionData }) => {
            //       if (!subscriptionData.data) return prev;
            //       return class_update_list(
            //         prev,
            //         [subscriptionData.data.class_event_changed],
            //         where
            //       );
            //     }
            //   })
            // }
          />
        )
      })}
    </div>
  )
}
ClassCards = withStyles(ClassesStyles)(ClassCards)

