import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Card,
  Paper,
  Backdrop,
  FormGroup,
  Popper,
  Box
} from '@material-ui/core'
import RecipeSelect from './classEditor/RecipeSelect'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Delete from '@material-ui/icons/Delete'
import Add from '@material-ui/icons/Add'
import orderBy from 'lodash/orderBy'
import {
  getSku, ecommerce_url, getClass, getLocations, getTemplates,
  saveClass, updateClass, getRecipes, deleteClass, cancelClass,
  getEventUsers, completeClass, getClassPdfs,
} from '../api'
import {
  classSave,
  setDataForClasses,
  setPreviousClass,
  setPrevSku,
  LoadingFactory,
  addLoading,
  setLoadingArray,
  ErrorFactory,
  addError,
  setErrors,
  markClassComplete,
  markClassCancelled,
  startEtrog,
} from '../actions'

import { TimePunchesByClass } from './TimePunch'
import { changeTimezone, getTimezoneOffsetMs } from '../helpers/date'

import {
  groceryExport,
  startParticipantOutput,
  meatExport, getEtrog
} from '../classOutput'
import { Autocomplete } from '@material-ui/lab'
import ClearIcon from '@material-ui/icons/Clear'

const EditorStylesheet = theme => ({
  input: {
    width: '100%'
  },
  rowMargin: {
    marginBottom: 5
  },
  smallButton: {
    minWidth: '55px',
    padding: '8px 10px'
  },
  hiddenUpload: {
    width: 1,
    height: 1,
    opacity: '.01',
    position: 'fixed',
    left: -5,
    top: -5
  },
  upload: {
    width: '100%',
    minHeight: 70,
    border: `2px dashed ${theme.palette.primary[400]}`,
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary[600],
    transition: 'background 200ms',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    padding: 10,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      background: theme.palette.primary[100]
    }
  },
  midTitle: {
    marginTop: 45,
    fontSize: 18
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  filter: {
    width: '100%',
    height: 50,
    padding: 13,
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'italic',
    '&:focus': {
      outline: 'none',
      background: theme.palette.secondary[100],
      border: 'none'
    }
  },
  recipeRow: {
    paddingLeft: '30px !important',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    '&:hover': {
      '& $deleteButton': {
        opacity: 1
      }
    }
  },
  deleteButton: {
    position: 'absolute',
    left: 0,
    top: 35,
    width: 30,
    height: 30,
    opacity: 0,
    transition: 'opacity 300ms',
    '&:hover': {
      color: theme.palette.grey[700]
    },
    '&:focus': {
      opacity: 1
    }
  },
  recipeImage: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    height: 70,
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover $imageDeleteButton': {
      opacity: 1
    }
  },
  imageDeleteButton: {
    width: 40,
    height: 40,
    borderRadius: '100%',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: theme.shadows[3],
    opacity: 0,
    transition: 'opacity 300ms'
  },
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    imageDeleteButton: {
      opacity: 1
    },
    deleteButton: {
      opacity: 1
    }
  }
})

const getRecipeRow = () => ({id: null, name: null})

const getNewClassObject = () => ({
  id: null,
  name: '',
  image: null,
  desc: '',
  location_id: null,
  template_id: null,
  date: '',
  private_menu: false,
  host_name: '',
  host_email: '',
  class_is_public: false,
  enabled: false,
  assembled: false,
  change_amounts: false,
  small: 0,
  regular: 0,
  participants: 0,
  stock: '',
  notes: '',
  can_complete: -9,
  completed: false,
  active: true,
  recipes: [],
  capacity: null,
  spreadsheet_id: null,
  can_select_meals: false,
  variations: [],
  pdf_json: null,
})

class ClassEditor extends Component   {
  constructor() {
    super()

    this.state = {
      loading: true,
      loadedClass: {},
      classObj: { classType: '', ...getNewClassObject() },
      locationFilter: '',
      classTypeFilter: '',
      saving: false,
      timePunchOpen: false,
      anchorEl: undefined,
      isAdmin: false,
      auth_level: 100,
      templates: [],
      locations: [],
      recipes: [],
      classRecipes: [],
      selected: {
        template: undefined,
        location: undefined,
        date: null,
      },
      host: {
        name: null,
        email: null,
      },
      pdf_json: [],
    }
  }

  componentDidMount = async (prevProps, prevState, prevContext) => {
    this.setState({ loading: true })

    const admin_permissions = localStorage.getItem('admin_permissions')
      ? JSON.parse(localStorage.getItem('admin_permissions'))
      : { isAdmin: false, auth_level: 100 }

    this.setState(admin_permissions)

    console.log(this.props, this.props.isAdmin)
    let locationParams = {'active': 1}
    if (!this.props.isAdmin) {
      locationParams.location_ids = localStorage.getItem('user_locations').replaceAll(',', '|')
    }

    Promise.all([getTemplates({'active': true, 'relations': 'portionVariations'}), getLocations(locationParams), getRecipes({'active': true})])
      .then(async (values) => {

        let templates = values[0].data
        let locations = values[1].data
        let recipes = values[2].data

        if (this.props.incomingClass.id) {
          let params = {
            'relations': 'host|classLocation|classRecipes|diet|fulfillment|reservationSize|registrations|registrations.portionSize|registrations.portionQuantity|registrations.selections|registrations.status|portionVariations',
            'classEvent': this.props.incomingClass.id,
          }

          let small = 0
          let regular = 0
          let recipeCounts = null

          getClass(this.props.incomingClass.id, params)
            .then(async (result) => {
              console.log(result.data)
              let class_event = result.data

              if (class_event.registration_breakdown && class_event.registration_breakdown.total > 0) {

                small = class_event.registration_breakdown.small_single + class_event.registration_breakdown.small_double + class_event.registration_breakdown.small_half
                regular = class_event.registration_breakdown.regular_single + class_event.registration_breakdown.regular_double

                console.log({'recipes': recipes, 'small': small, 'regular': regular, 'selections_count': class_event.selections_count})

                if (class_event?.selections_count.length > 0) {
                  console.log('selections', class_event.selections_count)

                  recipeCounts = class_event.selections_count.map((selection) => {
                    return {
                      id: selection.recipe_id,
                      amountDbl: selection.regular_single + selection.regular_double - regular,
                      subUnit: '',
                      amount: selection.small_half + selection.small_single + selection.small_double - small,
                    }
                  })
                } else {
                  console.log('recipes', class_event.recipes)

                  recipeCounts = class_event.recipes.map((recipe) => {
                    return {
                      id: recipe.id,
                      amount: regular,
                      subUnit: '',
                      amountDbl: small,
                    }
                  })
                }

                await this.setState({...this.state, classObj: {...this.state.classObj, small: small, regular: regular, recipe_counts: recipeCounts}})
              }

              let citrusClass = {
                ...this.state.classObj,
                id: class_event.id,
                base_price: class_event.base_price,
                class_event_id: class_event.class_event_id,
                sku: class_event.sku,
                name: class_event.name,
                image: class_event.image,
                description: class_event.description,
                location: class_event.location,
                location_id: class_event.location_id,
                class_type: class_event.class_type,
                date: class_event.date,
                private_menu: class_event.private_menu,
                host_name: class_event.host ? class_event.host.name : null,
                host_email: class_event.host ? class_event.host.email : null,
                class_is_public: class_event.class_is_public || false,
                enabled: class_event.enabled || false,
                assembled: class_event.assembled,
                change_amounts: class_event.change_amounts,
                small: small,
                regular: regular,
                recipes: class_event.recipes,
                participants: class_event.registrations_count,
                registration_breakdown: class_event.registration_breakdown,
                selections_count: class_event.selections_count,
                notes: class_event.notes,
                completed: class_event.completed,
                active: class_event.active,
                capacity: class_event.capacity,
                can_complete: this.getCanComplete(class_event),
                template_id: class_event.template_id,
                recipe_counts: recipeCounts,
                can_select_meals: class_event.can_select_meals,
                pdf_json: [],
                fulfillment: class_event.fulfillment,
              }

              this.setState({
                templates: templates,
                locations: locations,
                recipes: recipes,
                classRecipes: class_event.recipes,
                selected: {
                  template: templates.filter((template) => template.id === class_event.template_id)[0],
                  location: locations.filter((location) => location.id === class_event.location_id)[0],
                  capacity: citrusClass.capacity,
                },
                host: {
                  name: class_event.host ? class_event.host.name : null,
                  email: class_event.host ? class_event.host.email : null,
                },
                loadedClass: class_event,
                classObj: citrusClass,
                saving: false,
                loading: false
              })
            })
            .catch((error) => {
              console.error(error)
            })
        }
        else {
          this.setState({
            templates: templates,
            locations: locations,
            recipes: recipes,
            loading: false,
          })
        }
      })
      .catch((error) => {
        this.props.addError(ErrorFactory(`Error: ${error}`), this.props.errors)
        console.error(error)

        this.setState({ loading: false })
        this.closeThis()
      })
  };

  getCanComplete = citClass => {
    const canComplete =
      citClass && citClass.date
        ? moment().diff(
          moment(citClass.date)
            .startOf('day')
            .add(moment.duration({ hours: 28 })),
          'days'
        )
        : -9
    // console.log("canComplete: ",  canComplete)
    return canComplete
  };

  setDate = (key, e, tz) => {
    let newDate

    console.log(key, e.target.value, tz)

    try {
      const interim_date = new Date(Date.parse(e.target.value))
      const offset = getTimezoneOffsetMs(interim_date, tz)
      newDate = new Date(interim_date.getTime() + offset).toISOString()
    } catch (err) {
      console.log(String(err))
      newDate = e.target.value
    }
    console.log(newDate)

    this.setState({
      classObj: {
        ...this.state.classObj,
        [key]: newDate
      }
    })
  };

  setLocation() {
    // location = this.props.locations.find(
    //   l => String(l.Category_ID) === classObj.location
    // );
  }

  isSelectionDouble = (variation) => {
    return (variation.portion_size_id === 1 && variation.portion_quantity_id === 3) || (variation.portion_size_id === 2 && variation.portion_quantity_id === 2)
  }

  set = (key, e, tz) => {
    // console.log(key, e.target.type);
    const getVal = {
      'datetime-local': e => {
        // console.log(e.target.value)
        let newDate
        try {
          const interim_date = new Date(Date.parse(e.target.value))
          // console.log(interim_date.toISOString())
          const offset = getTimezoneOffsetMs(interim_date, tz)

          // console.log({offset})
          newDate = new Date(interim_date.getTime() + offset).toISOString()
          return newDate
        } catch (err) {
          console.log(String(err))
          newDate = e.target.value
        }
        return newDate
      },
      checkbox: e => e.target.checked
    }

    const value = getVal[e.target.type]
      ? getVal[e.target.type](e)
      : e.target.value
    this.setState({
      classObj: {
        ...this.state.classObj,
        [key]: value
      }
    })
  };

  convertToDateLocal = (date, tz) => {

    // console.log(date, tz)

    if ((!date && date !== 0) || !tz) return ''

    // const interim_date = Date.parse(date);
    // const newDate = new Date(interim_date)
    // const tempDate = changeTimezone(newDate, tz);
    // return tempDate
    //   .toISOString()
    //   .substring(0, 16);
    // console.log({ date })
    try {
      const interim_date = new Date(Date.parse(date))
      const offset = interim_date.getTimezoneOffset() * 1000 * 60
      // console.log({ offset, interim_date, tz})
      const differentTimeZoneOffset = getTimezoneOffsetMs(interim_date, tz)
      // console.log({differentTimeZoneOffset, offset, interim_date})
      return new Date(interim_date - offset - differentTimeZoneOffset)
        .toISOString()
        .substring(0, 16)
    } catch (e) {
      console.log('Error parsing date: ', String(e))
      return date
    }
  };

  setRoot = (key, e) => {
    // console.log(key, e)
    this.setState({
      ...this.state,
      [key]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
    })
  };

  copyFromPrevious = () => {
    // this.dateTimePicker.current.focus();
    const { defaultLocations } = this.props
    const {
      sku,
      product_id,
      small,
      regular,
      locked,
      enabled,
      completed,
      db_id,
      pdfJson,
      id,
      participants,
      spreadsheetId,
      notes,
      image,
      status,
      date,
      location_id,
      template_id,
      recipes,
      ...preClass
    } = this.props.prevClass

    this.setState({
      ...this.state,
      classObj: {
        ...this.state.classObj,
        ...preClass,
        recipes: recipes,
      },
      selected: {
        template: this.state.templates.filter((template) => template.id === template_id)[0],
        location: this.state.locations.filter((location) => location.id === location_id)[0],
      },
      classRecipes: recipes,
    })
  };

  addRecipeRow = () => {
    this.setState(state => ({
      classObj: {
        ...this.state.classObj,
        recipes: [...state.classObj.recipes, getRecipeRow()]
      }
    }))
  };

  deleteRecipeRow = index => {
    this.setState(state => ({
      classObj: {
        ...this.state.classObj,
        recipes: [
          ...this.state.classObj.recipes.slice(0, index),
          ...this.state.classObj.recipes.slice(index + 1)
        ]
      }
    }))
  };

  deleteAClass = id => {
    if (window.confirm('Are you sure you want to delete this class?')) {
      const classDeleting = LoadingFactory(
        `Deleting Class ${
          this.state.classObj.sku
            ? this.state.classObj.sku
            : this.state.classObj.date
        }`
      )
      this.props.addLoading(classDeleting, this.props.loadingArray)

      deleteClass(id, {active: false})
        .then((result) => {
          this.props.setLoadingArray([])
          this.closeThis()
          console.log(result)
        })
        .catch((error) => {
          this.props.setLoadingArray([])
          this.props.addError(ErrorFactory(`Error: ${error}`), this.props.errors)
          console.log(error)
        })
    }
  };

  markClassComplete = async classe => {
    console.log(classe)

    if(classe.active && window.confirm('Are you sure you want to mark this class as complete?')) {

      this.props.addLoading(LoadingFactory(`Marking class completed: ${classe.sku}`), this.props.loadingArray)

      completeClass(classe.id, {pdf_json: this.state.classObj.pdf_json})
        .then((result) => {
          this.props.setLoadingArray([])
          this.closeThis()
        })
        .catch((error) => {
          console.log(error)
          this.props.addError(ErrorFactory(`Error: ${error}`), this.props.errors)
        })
    }
  };

  markClassCancelled = (id) => {
    if (window.confirm('Are you sure you want to cancel this class?')) {
      cancelClass(id)
        .then((result) => {
          console.log(result)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  };

  handleDateTimeChange = dateTime => {
    // console.log({'dateTimeChange': dateTime})
    const date = moment.isMoment(dateTime)
      ? dateTime.format()
      : dateTime.toString()
    this.setState(state => ({
      classObj: {
        ...this.state.classObj,
        date
      }
    }))
  };

  setRecipes = value => {
    this.setState(state => ({
      classObj: {
        ...this.state.classObj,
        recipes: value.length < 10 ? [...value, getRecipeRow()] : value
      }
    }))
  };

  getCapacity = (template, location) => {
    console.log(template, location)

    let size
    if (template.fulfillment_id === 3 || template.fulfillment_id === 2) {
      size = location.pickup_class_size
    }
    else if (template.reservation_size_id === 2) {
      size = location.couple_class_size
    }
    else {
      size = location.default_class_size
    }

    return size ? size : 15
  };

  setHost = (key, value) => {
    if (key === 'name') {
      this.setState({...this.state, host: {...this.state.host, name: value}})
    }
    else if (key === 'email') {
      this.setState({...this.state, host: {...this.state.host, email: value}})
    }
  }

  saveClass = async () => {
    console.log('save')
    console.log(this.state)

    const { classObj, host } = this.state
    const template = this.state.selected.template
    const location = this.state.selected.location

    if(classObj.recipes.length < 10 || classObj.recipes.length > 12) {
      this.props.addError(ErrorFactory(`Error: Classes should have at least 10 and no greater than 12 meals.`))
      return
    }

    try {
      let sku = await getSku(classObj.date, location.code, template.sku_format)

      let date = moment(classObj.date)
      if(!date.isValid()) throw new Error('Invalid DateTime')
      let name = `${location.name} ${moment(classObj.date).format('MMMM Do')} ${template.name}`

      let classData = {
        sku: sku,
        date: classObj.date,
        base_price: classObj.base_price ? classObj.base_price : template.base_price,
        template_id: template.id,
        location_id: location.id,
        capacity: this.getCapacity(template, location),
        can_select_meals: classObj.can_select_meals,
        private_menu: classObj.private_menu,
        class_is_public: classObj.class_is_public,
        enabled: classObj.enabled,
        active: true,
        completed: classObj.completed,
        change_amounts: false,
        notes: classObj.notes,
        name: name,
        description: null,
        image: null,
        can_complete: classObj.can_complete,
        diet_id: template.diet_id,
        fulfillment_id: template.fulfillment_id,
        reservation_size_id: template.reservation_size_id,
        recipes: classObj.recipes.map((recipe) => {return recipe.id}),
        host_name: host.name ?? null,
        host_email: host.email ?? null,
        spreadsheet_id: null,
        pdf_json: classObj.pdf_json ?? null,
      }

      let recipeOffsetTotal = 0
      if(classObj.private_menu) {
        classObj.recipes.forEach((recipe) => { recipeOffsetTotal += parseFloat(recipe.price_offset) })
      }

      // TODO: class draft status, if not in draft status price changes not allowed
      if (classData.template_id !== classObj.template_id) {
        classData.variations = template.portion_variations.map((variation) => {
          let offset = recipeOffsetTotal
          if(variation.portion_quantity_id === 1 && variation.portion_size_id === 1) offset = offset / 2

          return {
            portion_quantity_id: variation.portion_quantity_id,
            portion_size_id: variation.portion_size_id,
            price: this.isSelectionDouble(variation) ? parseFloat(variation.price) + (offset * 2) : parseFloat(variation.price) + offset
          }
        })
      }

      this.props.addLoading(LoadingFactory(`Saving class: ${sku}`), this.props.loadingArray)

      if (!!classObj.id) { // class exists, update existing record
        const capacity = this.state.selected.capacity
        classData.id = classObj.id
        classData.capacity = capacity

        console.log('update', classData, classObj, template)
        updateClass(classData.id, classData)
          .then((result) => {
            // console.log(result)
            this.closeThis()
          })
          .catch((error) => {
            // console.log({message: error?.response?.data?.message, error: error})
            if (error?.response?.data?.message) {
              this.props.addError(ErrorFactory(`${error?.response?.data?.message}`), this.props.errors)
            }
            else {
              this.props.addError(ErrorFactory(`${error}`), this.props.errors)
            }
          })
          .finally(() => {
            this.props.setLoadingArray([])
          })
      }
      else { // class is new, create a new class
        console.log('save', classData)
        classData.can_select_meals = true

        saveClass(classData)
          .then((result) => {
            this.closeThis()
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              this.props.addError(ErrorFactory(`${error?.response?.data?.message}`), this.props.errors)
            }
            else if (error?.response?.data?.errors?.sku.length > 0) {
              error.response.data.errors.sku.forEach((sku) => {
                this.props.addError(ErrorFactory(`Error: ${sku}`), this.props.errors)
              })
            }
            else {
              this.props.addError(ErrorFactory(`${error}`), this.props.errors)
            }
            // console.log({response: error?.response, error: error})
          })
          .finally(() => {
            this.props.setLoadingArray([])
          })
      }
    }
    catch (error) {
      this.props.addError(ErrorFactory(`Error: ${error}`), this.props.errors)
      console.error(error)
    }
  };

  getClassObj = ({ classObj }, { incomingClass }) => {
    return {
      ...incomingClass,
      ...classObj
    }
  };

  startEtrog = async () =>  {
    this.props.addLoading(LoadingFactory(`Building Etrog. Please wait.`), this.props.loadingArray)

    try {
      let citrusClass = this.getClassObj(this.state, this.props)
      let participants = await this.getParticipants(this.state.classObj.id)

      // console.log(citrusClass, this.state.classObj)

      getEtrog(citrusClass, participants)
        .then(async (classEvent) => {
          if (classEvent.spreadsheet_id) {
            let url = `https://docs.google.com/spreadsheets/d/${classEvent.spreadsheet_id}/`

            window.open(url, '_blank').focus()
          }

          await updateClass(classEvent.id, {spreadsheet_id: classEvent.spreadsheet_id})
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.props.setLoadingArray([])
        })
    }
    catch (error) {
      this.props.addError(ErrorFactory(`Error: ${error}`), this.props.errors)
      console.error(error)
    }
  };

  startParticipantOutput = () => {
    let citrusClass = this.getClassObj(this.state, this.props)

    this.props.addLoading(LoadingFactory(`Building participants list. Please wait.`), this.props.loadingArray)

    this.getParticipants(this.state.classObj.id)
      .then((response) => {
        console.log(response, this.state.classObj.id, citrusClass)

        startParticipantOutput(citrusClass, response)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        this.props.setLoadingArray([])
      })
  };

  getParticipants = async (id) => {
    return getEventUsers(id)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  }

  getParticipantCounts = async (sku) => {
    return axios.get(`https://www.citruspeardinners.local/api/classes/${sku}/counts`)
      .then((response) => {
        console.log(response.data)
        return response.data
      })
      .catch((error) => {
        throw error
      })
  }

  getRecipeCounts = async (sku) => {
    return axios.get(`https://www.citruspeardinners.local/api/classes/${sku}/recipes`)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  }

  closeThis = () => {
    this.props.updateClassList()
    this.props.setPreviousClass(this.state.classObj)
    if(this.state?.loadedClass?.sku) this.props.setPrevSku(this.state.loadedClass.sku)
    this.props.onClose()
  };

  toggleTimePunchOpen = e => {
    const newState = {
      timePunchOpen: !this.state.timePunchOpen,
      anchorEl: !this.state.timePunchOpen ? e.currentTarget : undefined
    }
    console.log({ newState })
    this.setState({ ...this.state, ...newState })
  };

  handleRecipeSelect = (recipes) => {
    this.setState({ ...this.state, classObj: { ...this.state.classObj, recipes: recipes } })
  }

  canComplete = () => {
    let classEvent = this.state.classObj

    if(classEvent.id) {
      console.log(classEvent?.fulfillment?.key)

      if(classEvent?.fulfillment?.key === 'delivery' && classEvent.can_complete >= -10) return true
      else if(classEvent.can_complete >= -7) return true
      else return false
    }
    else return false

  }

  render() {
    const {
      isAdmin,
      open,
      title,
      loadingArray,
      classes: { input, rowMargin, midTitle, smallButton },
      defaultLocations: tempLocations = [],
      prevSku,
    } = this.props
    const {
      date,
      id,
      recipes,
      class_is_public,
      private_menu,
      enabled,
      change_amounts,
      can_complete,
      completed,
      locked: locky,
      sku,
      notes,
      participants,
      status,
      can_select_meals,
    } = this.state.classObj || {}

    const {
      auth_level = 100
    } = this.state

    const { anchorEl, toggleTimePunchOpen, currClassTypes } = this.state
    const classObj = this.getClassObj(this.state, this.props)
    const { small, regular } = classObj
    const locked = locky || completed
    const classHasSaved = !!sku
    const disableButtons = loadingArray.length > 0

    // console.log('class state', this.state.classObj)
    // console.log('class has saved', classHasSaved, sku)

    const lockRecipeEditing =
      small + regular ? (!isAdmin) : false

    // const defaultLocations = tempLocations.map(l => {
    //   const { Location: name, id, location_id, active, ...data } = l
    //   return { id, location_id, name, active, ...data }
    // })
    // const locationObj = this.props.locations.find(
    //   l => String(l.location_id) === String(classObj.location_id)
    // )

    const locationObj = this.state.selected.location

    // console.log({locationObj, 'prop locations': this.props.locations, tempLocations, 'class location': classObj.location_id, classObj, 'state': this.state})

    // TODO: implement the loading dialog
    // TODO: work out the initial state for class_types and locations
    // TODO: add material UI tooltips for disabled button states etc

    if (!this.state.loading) {
      return (
        <Dialog
          open={open}
          style={{ visibility: open ? 'display' : 'hidden' }}
          maxWidth="md"
          fullWidth
        >
          <Backdrop
            style={{
              zIndex: 1399
            }}
            open={this.state.timePunchOpen}
            onClick={this.toggleTimePunchOpen}
          />
          <Popper
            style={{
              zIndex: 1400,
              maxHeight: '60vh',
              overflow: 'auto',
              boxShadow:
                'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px'
            }}
            placement="bottom-end"
            disablePortal
            anchorEl={anchorEl}
            open={this.state.timePunchOpen}
            modifiers={{
              flip: {
                enabled: true
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'scrollParent'
              },
              arrow: {
                enabled: false,
                element: anchorEl
              }
            }}
          >
            <Box zIndex="tooltip" boxShadow={3} style={{}}>
              <Card>
                <TimePunchesByClass
                  class_event_id={id}
                  date={date}
                  location_id={locationObj && `${locationObj.id}`}
                />
              </Card>
            </Box>
          </Popper>
          <DialogTitle>{title}</DialogTitle>
          {classHasSaved && (
            <Typography
              type="headline"
              component="p"
              style={{
                textTransform: 'uppercase',
                fontWeight: '100',
                color: '#bbb',
                padding: '0 0 0 20px'
              }}
            >
              {sku}
            </Typography>
          )}
          <DialogContent>
            <FormGroup>
              <Grid container className={rowMargin} spacing={3}>

                <Grid item xs={12}>
                  <hr />
                  <Button
                    disabled={disableButtons || (!locked && !isAdmin)}
                    color="primary"
                    className={smallButton}
                    onClick={this.startEtrog}
                  >
                    Etrog
                  </Button>
                  <Button
                    disabled={disableButtons}
                    color="primary"
                    className={smallButton}
                    onClick={this.startParticipantOutput}
                  >
                    Attendees ({participants})
                  </Button>
                  { participants > 0 ? (
                    <Button
                      disabled={true}
                      color="primary"
                      className={smallButton}
                      onClick={() => this.markClassCancelled(classObj.id)}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      disabled={disableButtons}
                      color="primary"
                      className={smallButton}
                      onClick={() => {this.deleteAClass(classObj.id)}}
                    >
                      Delete
                    </Button>
                  )}
                  {this.canComplete() && (
                    <Button
                      disabled={disableButtons || locked}
                      color="primary"
                      className={smallButton}
                      onClick={() => this.markClassComplete(classObj)}
                    >
                      Complete
                    </Button>
                  )}
                  {classHasSaved && (
                    <Button
                      color="primary"
                      className={smallButton}
                      target="_blank"
                      href={` https://www.citruspear.com/classes/${sku}`}
                    >
                      Preview
                    </Button>
                  )}
                  {classHasSaved && (
                    <Button
                      color="primary"
                      className={smallButton}
                      target="_blank"
                      href={`https://pearcitr.us/menu/${sku}`}
                    >
                      Cards
                    </Button>
                  )}

                  {classHasSaved && (
                    <Button
                      color="primary"
                      className={smallButton}
                      onClick={this.toggleTimePunchOpen}
                    >
                      Schedule
                    </Button>
                  )}

                  <hr />
                </Grid>

                <Grid item sm={4} xs={12} key="templates">
                  <Autocomplete
                    id="templates"
                    disabled={!!this.props.incomingClass.id}
                    value={this.state.selected.template ? this.state.selected.template : {name: ''}}
                    inputValue={this.state.selected.template ? this.state.selected.template.name : undefined}
                    options={this.state.templates}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      event.preventDefault()
                      console.log(event.target.value, value, this.state.selected)
                      this.setState({
                        ...this.state,
                        selected: { ...this.state.selected, template: value }
                      })
                    }}
                    renderInput={(params) => <TextField {...params} label="Class Type" variant="standard" fullWidth={true} />}
                  />
                </Grid>

                <Grid item sm={3} xs={12} key="location">
                  <Autocomplete
                    id="class-location"
                    disabled={!!this.props.incomingClass.id}
                    value={this.state.selected.location ? this.state.selected.location : {name: ''}}
                    inputValue={this.state.selected.location ? this.state.selected.location.name : undefined}
                    options={this.state.locations}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      event.preventDefault()
                      console.log(event.target.value, value, this.state.selected)
                      this.setState({
                        ...this.state,
                        selected: { ...this.state.selected, location: value }
                      })
                    }}
                    renderInput={(params) => <TextField {...params} label="Location" variant="standard" fullWidth={true} />}
                  />
                </Grid>

                <Grid item sm={4} xs={12} key="date">
                  <TextField
                    // disabled={true}
                    disabled={ typeof locationObj === 'undefined' || ((disableButtons || locked || classHasSaved ) && (auth_level > 1 )) }
                    id="datetime-local"
                    // ref={this.dateTimePicker}
                    label="Date & Time"
                    type="datetime-local"
                    onChange={e => {
                      // console.log(e, typeof locationObj === 'undefined', locationObj)

                      this.set('date', e, locationObj && locationObj.timezone)
                    }}
                    onBlur={e => {
                      // console.log(e, typeof locationObj === 'undefined', locationObj)

                      this.setDate('date', e, locationObj && locationObj.timezone)
                    }}
                    value={this.convertToDateLocal(
                      date,
                      locationObj && locationObj.timezone
                    )}
                    className={input}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                {this.props.incomingClass.id &&
                  <Grid item sm={1} xs={12} key="capacity">
                    <TextField
                      label="Capacity"
                      disabled={!isAdmin || !this.props.incomingClass.id}
                      defaultValue={this.state.classObj.capacity}
                      className={input}
                      onChange={(event) => {
                        event.preventDefault()
                        console.log(event.target.value, this.state.selected)
                        this.setState({
                          ...this.state,
                          selected: { ...this.state.selected, capacity: event.target.value }
                        })
                      }}
                    />
                  </Grid>
                }

                <Grid item sm={4} xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={(locked && !isAdmin) || disableButtons}
                        checked={!!class_is_public}
                        onChange={e =>
                          this.set('class_is_public', {
                            target: {
                              value: !class_is_public,
                              checked: !class_is_public
                            }
                          })
                        }
                        color="primary"
                      />
                    }
                    label="Make Class Public"
                  />
                </Grid>

                <Grid item sm={4} xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={(locked && !isAdmin) || disableButtons}
                        checked={!!enabled}
                        onChange={e =>
                          this.set('enabled', {
                            target: { value: !enabled, checked: !enabled }
                          })
                        }
                        color="primary"
                      />
                    }
                    label="Published"
                  />
                </Grid>

                <Grid item sm={4} xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={!!this.props.incomingClass.id}
                        checked={!!private_menu}
                        onChange={e =>
                          this.set('private_menu', {
                            target: { value: !private_menu, checked: !private_menu }
                          })
                        }
                        color="primary"
                      />
                    }
                    label="Private Menu"
                  />
                </Grid>
                {private_menu && <>
                  <Grid item sm={6} xs={12}><TextField
                    type="text"
                    value={this.state.host.name}
                    className={input}
                    onChange={event => this.setHost('name', event.target.value)}
                    disabled={(locked && !isAdmin) || disableButtons}
                    label="Hostess Name"
                  />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      type="text"
                      value={this.state.host.email}
                      className={input}
                      onChange={event => this.setHost('email', event.target.value)}
                      disabled={(locked && !isAdmin) || disableButtons}
                      label="Hostess Email"
                    />
                  </Grid>
                </>}

                <Grid item xs={12}>
                  <TextField
                    type="text"
                    defaultValue={notes}
                    className={input}
                    multiline
                    rowsMax="10"
                    onChange={e => this.set('notes', e)}
                    disabled={false}
                    label="Notes"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography type="title" className={midTitle}>
                    Recipes
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <RecipeSelect classRecipes={this.state.classRecipes} recipes={this.state.recipes} onRecipeSelect={this.handleRecipeSelect}/>
                </Grid>

              </Grid>
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button disabled={locked || !!id} onClick={this.copyFromPrevious}>
              Copy from Previous{!!prevSku && `: ${prevSku}`}
            </Button>
            <Button onClick={this.closeThis}>Cancel</Button>
            <Button
              disabled={disableButtons}
              color="primary"
              onClick={!this.state.saving ? this.saveClass : undefined}
            >
              Save Class
            </Button>
          </DialogActions>
        </Dialog>
      )
    }
    else {
      return (
        <Dialog
          open={open}
          style={{ visibility: open ? 'display' : 'hidden', padding: '2rem'}}
          maxWidth="md"
          fullWidth
          loading
        >
          <div style={{
            height: '60vh',
            textAlign: 'center',
          }}>
            Loading ...

            <Button onClick={() => {this.closeThis()}}>
              Cancel
            </Button>
          </div>
        </Dialog>
      )
    }
  }
}
export default withStyles(EditorStylesheet)(
  connect(
    state => ({
      recipes: state.recipes.recipes.filter(
        r => r && r.record_status === undefined
      ),
      locations: state.app.locations,
      prevClass: state.classes.prevClass,
      prevSku: state.classes.prevSku,
      loadingArray: state.app.loadingArray,
      errors: state.app.errors,
      isAdmin: state.auth.permisions.isAdmin,
      defaultLocations: state.auth.permisions.location
    }),
    {
      saveClass,
      classSave,
      startEtrog,
      setDataForClasses,
      setPreviousClass,
      setPrevSku,
      LoadingFactory,
      addLoading,
      setLoadingArray,
      addError,
      setErrors,
      markClassComplete,
      markClassCancelled,
      getLocations
    }
  )(ClassEditor)
)

class RecipeRowEditor extends Component {
  bubbleRecipe = (value, index) => {
    this.props.onChange([
      ...this.props.value.slice(0, index),
      value,
      ...this.props.value.slice(index + 1)
    ])
  };

  componentDidMount = () => {
    // console.log(this.props.classObj, this.props.value, this.props)

    // getClass({}, {}, data => {
    //   // console.log(data)
    // })
  };

  render() {
    const {
      value = [],
      onButtonClick,
      onDelete,
      lockRecipeEditing,
      classes: { buttonRow },
      classObj
    } = this.props
    return [
      ...value.map((row, i) => (
        <RecipeRow
          lockRecipeEditing={lockRecipeEditing}
          row={row}
          classObj={classObj}
          onChange={this.bubbleRecipe}
          onDelete={onDelete}
          index={i}
          key={i}
        />
      )),
      <Grid item xs={12} className={buttonRow} key="button">
        <Button
          disabled={classObj.locked || classObj.small + classObj.regular > 0}
          color="primary"
          onClick={onButtonClick}
        >
          <Add />
          Add Recipe
        </Button>
      </Grid>
    ]
  }
}
RecipeRowEditor = withStyles(EditorStylesheet)(RecipeRowEditor)

class RecipeRow extends Component {
  constructor() {
    super()
    this.state = {
      filter: ''
    }
  }

  componentDidMount = () => {
    console.log(this.props)
  }

  setQtys = (name, value, numeric) => {
    let newValue = value
    const { regular = 0, small = 0 } = this.props.classObj
    if (name === 'amount')
      newValue = value > 0 ? (value || 0) - small : 0 - small

    if (name === 'amountDbl')
      newValue = value > 0 ? (value || 0) - regular : 0 - regular

    console.log(`newValue: ${newValue}, value: ${value}`)
    this.set(name, newValue, numeric)
  };

  set = (name, value, numeric) => {
    numeric = numeric === 'numeric'
    // if (numeric) {
    //   value = value.replace(/[^0-9.]/g, '');
    //   if (value.split('.').length > 2) {
    //     value = this.props.row[name];
    //   }
    // }
    // blank out value if the same as parent

    let newValue = { ...this.props.row, [name]: value }
    // console.log("newValue:", newValue)
    if (name === 'name') {
      newValue = { ...newValue, subUnit: '' }
    }
    this.props.onChange(newValue, this.props.index)
    if (name === 'name' || name === 'subUnit') {
      this.setState({
        filter: ''
      })
    }
  };

  handleInputClick = e => {
    console.log('handleInputClick')
    e.stopPropagation()
  };

  setFilter = e => {
    const val = e.target.value
    this.setState({
      filter: val
    })
  };

  deleteRow = () => {
    this.props.onDelete(this.props.index)
  };

  render() {
    const {
      recipes,
      row: { name, amount, amountDbl },
      classes: { input, filter, recipeRow, deleteButton },
      classObj
    } = this.props
    // const subUnitsOptions = name !== '' ? subUnits[ingredientsLookup[name].units] : []

    const { small, regular, change_amounts } = classObj
    // const lockit = locked || lockRecipeEditing;
    console.log({recipes, filter: this.state.filter})
    // console.log("classObj: ", classObj)
    // console.log("amount, amountDbl, small, regular: ", amount, amountDbl, small, regular)
    return (
      <Grid item container xs={12} className={recipeRow}>
        <IconButton
          disabled={false}
          className={deleteButton}
          onClick={this.deleteRow}
        >
          <Delete />
        </IconButton>
        {change_amounts && (
          <Grid item sm={2} xs={6} key={2}>
            <TextField
              type="number"
              defaultValue={
                (amountDbl ? parseInt(amountDbl, 10) : 0) +
                (small ? parseInt(small, 10) : 0)
              }
              onChange={e => {
                this.setQtys('amount', e.target.value, 'numeric')
              }}
              className={input}
              label="Small"
            />
          </Grid>
        )}
        {change_amounts && (
          <Grid item sm={2} xs={6} key={1}>
            <TextField
              type="number"
              defaultValue={
                (amount ? parseInt(amount, 10) : 0) +
                (regular ? parseInt(regular, 10) : 0)
              }
              onChange={e => {
                this.setQtys('amountDbl', e.target.value, 'numeric')
              }}
              className={input}
              label="Regular"
            />
          </Grid>
        )}

        <Grid item sm={12} xs={12} key={394}>

        </Grid>

        <Grid item sm={change_amounts ? 8 : 12} xs={12} key={0}>
          <TextField
            disabled={false}
            select
            value={name}
            onChange={e => {
              this.set('name', e.target.value)
            }}
            className={input}
            label="Name"
          >
            <FilterInput
              className={filter}
              type="text"
              value={this.state.filter}
              onClick={this.handleInputClick}
              onChange={this.setFilter}
            />
            {recipes?.count && recipes
              .filter(rcpe =>
                rcpe.name.toLowerCase().includes(this.state.filter.toLowerCase())
              )
              .map(rpce => (
                <MenuItem value={rpce.id} key={`recipe_editor_${rpce.id}`}>
                  {rpce.name}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      </Grid>
    )
  }
}
RecipeRow = withStyles(EditorStylesheet)(
  connect(state => ({
    recipesLookup: state.recipes.recipes,
    recipes: orderBy(state.recipes.recipes, ['name'])
  }))(RecipeRow)
)

class FilterInput extends Component {
  componentDidMount = () => {
    this.input.focus()

    console.log(this.props)
  };

  render() {
    const { className, value, onClick, onChange } = this.props
    return (
      <input
        ref={input => {
          this.input = input
        }}
        className={className}
        type="text"
        value={value}
        onClick={onClick}
        onMouseDown={onClick}
        onMouseUp={onClick}
        onChange={onChange}
      />
    )
  }
}
