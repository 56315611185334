import { runQuery, baseNodeUrl, exportParticipants } from '../api'
import { classTypes } from '../data'
import {
  getIngredientAmountsFromClass,
  formatIngredientsForCSV,
  getLocations,
  getMeatAmountsFromClass,
  getIngredients,
  getRecipes,
  reduceIngredients
} from '../classOutput'

export const getEtrog = (classEvent, location) =>
{
  return new Promise(async (res, rej) =>
  {
    // change amounts to be a full class if it hasn't been sold out
    // const Class = {
    //   ...c,
    //   small: c.emailSent && c.small ? c.small : location.Default_Class_Size * 2,
    //   regular: c.emailSent && c.regular ? c.regular : 2,
    // }
    // const totalSets =
    //     (Class && Class.small ? parseInt(Class.small, 10) : 0) / 2 +
    //     (Class && Class.regular ? parseInt(Class.regular, 10) : 0)
      
    console.log({ classEvent })
    const groceries = getIngredientAmountsFromClass(classEvent, true)
    res(groceries)
  })
}


export const getFoodForecastData = e =>
{
  const locations = getLocations()
  const ingredients = getIngredients()
  const recipes = getRecipes()
  return {
    locations,
    ingredients,
    recipes,
    classTypes
  }
}

export const foodForecast = ({ class_events, recipes, locations, ingredients, email }) =>
{
  console.log({'food forecast': class_events})

  return new Promise(async (res, rej) =>
  {
    const class_types = classTypes.reduce((p, c) => ({...p, [c.id]: c}), {})

    // const recipes = [];
    const first = class_events[0]
    const last = class_events[class_events.length - 1]
    const daterange = `${(new Date(first.date)).toLocaleDateString()} -> ${(new Date(last.date)).toLocaleDateString()}`

    const EtrogData = await Promise.all(class_events.map(classEvent =>
    {
      // const location = locations.find(l => String(l.Category_ID) === classs.location)
      const location = classEvent.location
      return getEtrog(classEvent, location)
    }))
    console.log({EtrogData})
    // const errors = EtrogData.filter(([d, e]) => e).map(([d, e]) => e.message);
    // if (errors && errors.length) console.error(errors.join(", "));
    // const { meat : meatRaw, grocery: groceryRaw } = EtrogData.reduce((p, c, i) =>
    // {
    //     console.log({c,p})
    //     const { meats: m = [], groceries: g = [] } = c;
    //     return {meat:[...p.meat, ...m ], grocery: [...p.grocery, ...g]}
    // }, {meat: [], grocery: []})
      
    const groceryRaw = EtrogData.reduce((p, c, i) =>
    {
      let rtnSummed = p
      Object.keys(c).forEach(key =>
      {
        const ingredientAlreadyAdded = p[key] || {}
        const currentIngredient = c[key]
        if (ingredientAlreadyAdded) {
          const summedUp = Object.keys(currentIngredient).reduce((preUnits, unit) =>
          {
            const unitExists = preUnits[unit]
            if (unitExists) {
              return {...preUnits, [unit]: (preUnits[unit] + currentIngredient[unit])}
            } 
            return {...preUnits, [unit]: currentIngredient[unit] }
              
          }, ingredientAlreadyAdded)
          // check the brown sugar
          if(key === "BJ28DzB_z") console.log({ingredientAlreadyAdded, currentIngredient, summedUp})
          rtnSummed = {
            ...rtnSummed,
            [key]: summedUp
          }
        } else {
          rtnSummed = {
            ...rtnSummed,
            [key]: currentIngredient
          }
        }
      })
      return rtnSummed
    }, {})

    console.log({ groceryRaw })

    const [classes, classes_order] = fixClasses(class_events, class_types, locations, recipes)
    // const [meat, meat_order] = fixMeat(reduceIngredients(meatRaw));
    const [grocery, grocery_order] = fixGrocery(reduceIngredients(groceryRaw))
    const [locs, locs_order] = fixLocations(locations)
    console.log({ locs, grocery, classes })
    fetch(`${baseNodeUrl}/api/kiyomi`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        idToken: localStorage.getItem('firebase.auth.idToken')
      },
      body: JSON.stringify({
        data:[{
          order: grocery_order,
          data: grocery,
          range: `Grocery!A1:JJ${grocery.length + 1}`
        },
        {
          order: classes_order,
          data: classes,
          range: `Classes!A1:GG${classes.length + 1}`
        },
        {
          order: locs_order,
          data: locs,
          range: `Locations!A1:JJ${locs.length + 1}`
        }],
        templateSpreadsheetId: '1tf1tpv8A-2UNjVy5Yy0bHqGouux2QUWHYJkKrHKUmOU',
        fileName: `${daterange} Forecast`,
        email: ["kelsi@citruspeardinners.com", "mace@citruspeardinners.com", "mckenzie@citruspeardinners.com", email],
        parents: ["1F9mN6WUd3ngxqKx0z2KQnmwaXSJtpBGW"]
      })
    })
      .then(res => {
        return res.json()
      })
      .then(data => {
        const { spreadsheetId } = data
        res({ spreadsheetId })
      })
      .catch(e => {
        rej(e)
        console.log(e)
      })
  })
    
}

const fixMeat = ingredients =>
{
  const newData = formatIngredientsForCSV(ingredients)
  const order = [
    'Recipe',
    'Ingredient',
    'RegularPortion',
    'SmallPortion',
    'RegularAmount',
    'SmallAmount',
    'Description'
  ]
  return [ newData, order ]
}

const fixGrocery = ingredients =>
{
  const newData = formatIngredientsForCSV(ingredients)
  const order = [
    'Category',
    'Allergy',
    'Ingredient',
    'Description',
    'Total',
    'Unit',
    'AmountEachSet',
    'ContainerQty',
    'ContainerSize',
    'ContainerUnits'
  ]
  return [ newData, order ]
}

const fixLocations = locations =>
{
  const newLocations = locations.map(l =>
  {
    const { data, ...rest } = l
    return { ...data, ...rest }
  })
  const order = [
    'location_id',
    'name',
    'active',
    'Store_Name',
    'Address',
    'Lead_RD',
    'Email_Address',
    'Category_ID',
    'Code'
  ]
  return [ newLocations, order ]
}

const fixClasses = (classes, class_types, locations, recipes) =>
{
  if (!classes || !classes.length)
    throw new Error('Classes must be defined.')
  if (!class_types)
    throw new Error('Class Types must be defined.')
  if (!locations || !locations.length)
    throw new Error('Locations must be defined.')
  if (!recipes || !recipes.length)
    throw new Error('Recipes must be defined.')
  const newClasses = classes.map(class_event =>
  {
    // const { value, ...rest } = c
    const newRecipes = class_event.recipes.map((recipe) => {
      return {id: recipe.id, name: recipe.name, amount: ''}
    })
    // const newRecipes = rest.recipes.map(rec =>
    // {
    //   const r = recipes.find(r => r.id === rec.name)
    //   return {id: r.db_id, name: r.name, amount: rec.amount}
    // }
    // )
    // const location = locations.find(l => String(l.Category_ID) === String(rest.location))
    // const class_type = class_types[rest.class_type] ? class_types[rest.class_type] : {}

    return {
      class_event_id: class_event.id,
      recipes: JSON.stringify(newRecipes),
      date: class_event.date,
      sku: class_event.sku,
      regular: class_event.regular,
      small: class_event.small,
      participants: class_event.participants,
      location: class_event.location.name,
      class_type: class_event.class_type,
      name: class_event.name,
      desc: class_event.desc,
      classType: class_event.classType,
      assembled: class_event.assembled,
      changeAmounts: class_event.changeAmounts,
      stock: '',
      notes: class_event.notes
    }
  })
  const order = [
    'class_event_id',
    'recipes',
    'date',
    'sku',
    'regular',
    'small',
    'participants',
    'location',
    'class_type',
    'name',
    'desc',
    'classType',
    'assembled',
    'changeAmounts',
    'stock',
    'notes'
  ]
  return [ newClasses, order ]
}

const fixParticipants = orders =>
{
  const newOrders = orders.map(l =>
  {
    const { value, ...rest } = l
    return { ...value, ...rest, recipes: JSON.stringify(rest.recipes) }
  })
  const order = [
    'number',
    'status',
    'date_created',
    'first_name',
    'last_name',
    'email',
    'phone',
    'sku',
    'item_name',
    'variation_id',
    'quantity',
    'order_line_subtotal',
    'coupon',
    'discount_amt',
    'payment_method',
    'order_refund_amt',
    'date_modified'
  ]
  return [ newOrders, order ]
}

export default foodForecast