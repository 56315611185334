import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import ErrorIcon from '@material-ui/icons/Error'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import { addError, setErrors } from '../actions'

const ErrorStyles = theme => ({
  marginBottom: '10px',
  errorClass: {
    borderRadius: '4px',
    backgroundColor: '#6da72a',
    marginBottom: '10px'
  },
  errorContainer: {
    width: '40%',
    position: 'absolute',
    top: '50px',
    zIndex: 1400
  },
  deleteStyles: {
    cursor: 'pointer',
    color: '#fff',
    height: 24
  }
})

class Error extends Component {
  constructor() {
    super()
    this.state = {}
  }

  componentDidMount() {
    // this.props.addError(ErrorFactory("1st Error"), this.props.errors)
    // setTimeout(() => {
    //   this.props.addError(ErrorFactory("2nd Error"), this.props.errors)
    // }, 20)
  }

  set = (key, e) => {
    const val =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value
      // this.setState({
      //   [key]: val
      // })
    this.props.onChange(val)
  };

  delete = id => {
    this.props.setErrors(this.props.errors.filter(er => er.id !== id))
  };

  render() {
    const {
      classes: { errorClass, errorContainer, deleteStyles },
      errors
    } = this.props
    return (
      <Grid
        container
        spacing={10}
        className={errorContainer}
        style={{
          justifyContent: 'center',
          marginBottom: '10px',
          position: 'fixed'
        }}
      >
        {errors.map(er => (
          <Grid
            key={er.id}
            className={errorClass}
            style={{ justifyContent: 'center', display: 'flex' }}
            item
            sm={12}
            xs={12}
          >
            <ErrorIcon style={{ color: '#8bc34a' }} />
            <Typography
              component="span"
              style={{
                width: 'calc(100% - 45px)',
                marginLeft: '5px',
                marginTop: 3,
                display: 'inline-flex',
                color: '#fff'
              }}
              autoFocus
            >
              {er.msg}
            </Typography>
            <IconButton
              className={deleteStyles}
              onClick={() => this.delete(er.id)}
              aria-label="Clear"
            >
              <Clear />
            </IconButton>
          </Grid>
        ))}
      </Grid>
    )
  }
}
export default withStyles(ErrorStyles)(
  connect(
    state => ({
      errors: state.app.errors
    }),
    {
      addError,
      setErrors
    }
  )(Error)
)
