import React, { useEffect, useState } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { IconButton, TextField } from '@material-ui/core'
import { Delete } from '@material-ui/icons'

export default function RecipeSelect(props) {

  const [selectedRecipes, setSelectedRecipes] = useState([])
  const [filteredRecipes, setFilteredRecipes] = useState([])

  const [select, setSelect] = useState(undefined)
  const [input, setInput] = useState(undefined)
  const [clear, setClear] = useState(0)

  const orderRecipes = (recipes) => {
    return  recipes.sort((a,b) => {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })
  }

  useEffect(() => {

    setSelectedRecipes(orderRecipes(props.classRecipes))

    let selected = props.classRecipes.map((recipe) => {return recipe.id})
    setFilteredRecipes(props.recipes.filter((recipe) => { return !selected.includes(recipe.id)}))
  }, [props.classRecipes])

  useEffect(() => {
    let selected = selectedRecipes.map((recipe) => {return recipe.id})
    setFilteredRecipes(props.recipes.filter((recipe) => { return !selected.includes(recipe.id)}))
    props.onRecipeSelect(selectedRecipes)
  }, [selectedRecipes])

  const validRecipe = (recipe) => {
    let isValid = selectedRecipes.filter((selected) => { return selected === recipe.id })

    return isValid.length === 0
  }

  const handleSelect = (event, recipe) => {
    event.preventDefault()

    if(recipe && validRecipe(recipe)) {
      let recipes = [...selectedRecipes, recipe]
      setSelectedRecipes(orderRecipes(recipes))
    }
    else {
      setSelect(undefined)
      setInput(undefined)
    }

    setClear(clear + 1)
  }

  const handleRemove = (event, recipeId) => {
    event.preventDefault()

    setSelectedRecipes(selectedRecipes.filter((selected) => {return selected.id !== recipeId}))
  }

  return (
    <div>
      <Autocomplete
        id="class-types"
        value={select}
        inputValue={input}
        options={filteredRecipes}
        disabled={selectedRecipes.length >= 12}
        getOptionLabel={(option) => option ? option.name : ''}
        onChange={(event, value) => {handleSelect(event, value)}}
        renderInput={(params) => <TextField {...params} label="Recipes" variant="standard" fullWidth={true} />}
        key={clear}
      />

      {selectedRecipes.map((recipe) => {
        return (
          <div key={`class_recipe_${recipe.id}`}>
            <IconButton onClick={(event) => {handleRemove(event, recipe.id)}} aria-label="delete">
              <Delete />
            </IconButton>
            <span>{ recipe.name }</span>
          </div>
        )
      })}
    </div>
  )
}