const parse = require('csv-parse')

async function readFile(csvFile)
{
  return new Promise((res) =>
  {
    const reader = new FileReader()

    // Closure to capture the file information.
    // eslint-disable-next-line no-unused-vars
    reader.onload = ((_theFile) => {
      return (e) => {
        res(e.target.result)
      }
    })(csvFile)

    reader.readAsText(csvFile)
  })
}


async function parseCSV(csvText)
{
  return new Promise((res, rej) =>
  {
    parse(csvText, {
      // eslint-disable-next-line func-names
    }, function(err, output){
      if (err) return rej(err)
      return res(output)
    })
  })
}


async function massageForGraphQL({ header, rows, locations, roles })
{
  console.log({locations})
  return new Promise((res) =>
  {
        
    const o = [
      header.indexOf("USER_ID"),
      header.indexOf("BDAY"),
      header.indexOf("Full Name"),
      header.indexOf("Email"),
      header.indexOf("Pay Schedule"),
      header.indexOf("Location"),
      header.indexOf("Location_2"),
      header.indexOf("Completed Paperwork"),
      header.indexOf("Notes"),
      header.indexOf("Street Address"),
      header.indexOf("City"),
      header.indexOf("State"),
      header.indexOf("Zip"),
      header.indexOf("PhoneNumber"),
      header.indexOf("Start Date"),
      header.indexOf("Food Handlers Expiration Date"),
    ]
    const variables = rows.map(r =>
    {
      let { location_id } = locations[r[o[5]]] || {}
      const ls = [{ location_id, name: r[o[5]] }];
      ({ location_id } = locations[r[o[6]]] || {})
      if (location_id) ls.push({location_id, name: r[o[6]] })
            
      let hire_date; let food_handler_date
      const today = (new Date()).toISOString()
      try {
        hire_date = r[o[14]] ?  (new Date(Date.parse(r[o[14]]))).toISOString() : today
      } catch (e) {
        console.error(e)
      }
      try {
        food_handler_date = r[o[15]] ?  (new Date(Date.parse(r[o[15]]))).toISOString() : today
      } catch (e) { 
        console.error(e)
      }
      // console.log({ roles });
      let isLead = false
      let rolez = r[o[4]] || []
      if (!rolez) console.error(`pay schedule: ${r[o[4]]} was not found in the payschedule list.`, r)
      rolez = rolez.map(ro =>
      {
        if (ro.title.includes("Lead")) isLead = true
        const dbRole = roles[ro.role]
        if (!dbRole) console.error(`Role: ${ro.role} was not found in the db list.`, ro)
        return (dbRole ? { ...ro, role_id: dbRole.role_id } : ro)
      })
      return {
        user_id: r[o[0]] === -1 ? undefined : r[o[0]],
        birthday: r[o[1]],
        name: r[o[2]],
        email: r[o[3]],
        rolez,
        locations: ls,
        _location: r[o[5]],
        _location2: r[o[6]],
        paperwork_complete: r[o[7]] !== "FALSE",
        notes: r[o[8]],
        address: r[o[9]],
        city: r[o[10]],
        state: r[o[11]],
        zip: r[o[12]],
        phone: `+${  r[o[13]]}`,
        active: true,
        hire_date,
        auth_level: isLead ? 40 : 80,
        food_handler_date,
      }
    })
    res(variables)
  })
}


export default async function importUsers(file, locations, roles)
{
  console.log("get the raw text")
  const rawCSV = await readFile(file)

  console.log("parse CSV to JSON.")
  const [header, ...rows] = await parseCSV(rawCSV)

  console.log("prepare data for graphQL")
  const users = await massageForGraphQL({
    header,
    rows,
    roles: roles.reduce((pre, curr) =>
    {
      // eslint-disable-next-line no-param-reassign
      pre[curr.name] = curr
      return pre
    }, {}),
    locations: locations.reduce((pre, curr) =>
    {
      // eslint-disable-next-line no-param-reassign
      pre[curr.name] = curr
      return pre
    }, {}) })

  return users
}
