import {
  runQuery,
  CLASS_CREATE_GQL,
  RECIPE_CREATE_GQL,
  INGREDIENT_CREATE_GQL,
  RECIPE_UPDATE_GQL
} from './api'

const importRecipes = async recipes => {
  console.log(recipes)
  // if(!Array.isArray(recipes)) return false;
  Object.keys(recipes).map(async rec => {
    const recipe = recipes[rec]
    const variables = {
      id: rec,
      value: recipe
    }

    await runQuery(RECIPE_CREATE_GQL, variables)
  })
}

const importIngredients = async ingredients => {
  console.log(ingredients)
  // if(!Array.isArray(ingredients)) return false;

  Object.keys(ingredients).map(async ingText => {
    const ing = ingredients[ingText]
    const variables = {
      id: ingText,
      value: ing
    }

    await runQuery(INGREDIENT_CREATE_GQL, variables)
  })
}

export const importClasses = async classes => {

  classes.map(async clss => {
    const variables = {
      id: clss.id,
      value: clss
    }
    await runQuery(CLASS_CREATE_GQL, variables)
  })
}

export const updateRecipes = async (recipes, newRecipes) => {

  recipes.map(async r => {
    const fNewRecipe = newRecipes.find(nR => nR.name === r.name)
    const variables = {
      id: r.db_id,
      value: fNewRecipe ? { ...r, sodium: fNewRecipe.sodium } : r
    }
    await runQuery(RECIPE_UPDATE_GQL, variables)
  })
}

export const syncData = async (ingredients, recipes, classes) => {
  importIngredients(ingredients)
  importRecipes(recipes)
  importClasses(classes)
  return true
}
