import React, { Component } from 'react'
import { connect } from 'react-redux'
import Router, { Routes } from './Routes'
import Header from './components/Header'
import {
  initializeFirebase,
  archiveClasses,
  archiveRecipes,
  multiClassSave,
  resetSelectedRecipes
} from './actions'
import 'normalize.css'
import './App.css'

class App extends Component {
  componentDidMount = () => {
    this.props.initializeFirebase()
  };

  componentWillReceiveProps = nextProps => {
  };

  archive = e => {
    const {
      archiveClasses,
      archiveRecipes,
      recipesSelected,
      classesSelected
    } = this.props
    if (classesSelected > 0) archiveClasses()
    if (recipesSelected > 0) archiveRecipes()
    console.log(
      `archived: ${classesSelected} classes, ${recipesSelected} recipes, `
    )
  };

  multiClassSave = e => {
    console.log("multi-class")
    this.props.multiClassSave()
  }

  clear = e => {
    const { resetSelectedRecipes } = this.props
    resetSelectedRecipes()
    console.log('clear')
  };

  render() {
    const { isAdmin, auth_level, recipesSelected, classesSelected } = this.props
    return (
      <Router>
        <div className="App">
          <Header
            isAdmin={isAdmin}
            auth_level={auth_level}
            isSelected={classesSelected + recipesSelected > 0}
            classesSelected={classesSelected.length}
            recipesSelected={recipesSelected}
            archive={this.archive}
            multiClassSave={this.multiClassSave}
            clear={this.clear}
          />
          <Routes isAdmin={isAdmin} auth_level={auth_level} />
        </div>
      </Router>
    )
  }
}

export default connect(
  state => ({
    ingredients: state.app.ingredients,
    isAdmin: state.auth.permisions.isAdmin,
    auth_level: state.auth.permisions.auth_level,
    classesSelected: amountSelected(state.recipes.selectedClasses),
    recipesSelected: amountSelected(state.recipes.selectedRecipes)
  }),
  {
    initializeFirebase,
    archiveClasses,
    archiveRecipes,
    multiClassSave,
    resetSelectedRecipes
  }
)(App)

const amountSelected = (selected= []) => {
  return Object.keys(selected).filter(k => selected[k] === true).length
}
