export const use_woo_not_magento = true

export const units = [
  { value: 'weight', label: 'Weight' },
  { value: 'volume', label: 'Volume' },
  { value: 'single', label: 'Single Unit' }
]

export const categories = [
  { value: 'cheese', label: 'Cheese' },
  { value: 'dairy', label: 'Dairy' },
  { value: 'frozen', label: 'Frozen' },
  { value: 'grocery', label: 'Grocery' },
  { value: 'meat', label: 'Meat' },
  { value: 'produce', label: 'Produce' },
  { value: 'special', label: 'Specialty Produce' },
  { value: 'spice', label: 'Spice' }
]

export const categoriesObject = categories.reduce(
  (obj, cat) => ({ ...obj, [cat.value]: cat }),
  {}
)

export const subUnits = {
  weight: [
    { value: 'ounces', label: 'oz' },
    { value: 'lbs', label: 'lbs' },
    { value: 'mg', label: 'mg' },
    { value: 'grams', label: 'grams' },
    { value: 'kg', label: 'kg' }
  ],
  volume: [
    { value: 'tsp', label: 'tsp' },
    { value: 'tbsp', label: 'Tbsp' },
    { value: 'floz', label: 'fl oz' },
    { value: 'cup', label: 'cups' },
    { value: 'pint', label: 'pints' },
    { value: 'quart', label: 'quarts' },
    { value: 'gallon', label: 'gallons' },
    { value: 'ml', label: 'ml' },
    { value: 'liter', label: 'liters' },
    { value: 'deciliter', label: 'deciliters' }
  ],
  single: [
    { value: 'item', label: 'each' },
    { value: 'jar', label: 'jar' },
    { value: 'can', label: 'can' },
    { value: 'box', label: 'box' },
    { value: 'dzn', label: 'dozen' }
  ]
}

export const recipeCategories = [
  { value: 'beef', label: 'Beef' },
  { value: 'chicken', label: 'Chicken' },
  { value: 'pork', label: 'Pork' },
  { value: 'turkey', label: 'Turkey' },
  { value: 'vegetarian', label: 'Vegetarian' }
]

export const subUnitsObject = Object.keys(subUnits).reduce(
  (obj, unitType) => ({
    ...obj,
    [unitType]: subUnits[unitType].reduce(
      (obj, unit) => ({
        ...obj,
        [unit.value]: unit
      }),
      {}
    )
  }),
  {}
)

export const wooClassTemplate = {
  name: '{location_name} {date_M_D} In-Person Preparation',
  type: 'variable',
  regular_price: '199.00',
  catalog_visibility: 'hidden',
  description: `<p>Please join us on <strong>{date}</strong> at <strong>{time} {time_before}</strong> for a Citrus Pear class in <strong>{store_city}</strong>, held at {store_name} ({store_address}).</p> <p>In less than <strong>two hours</strong> you will help cut, chop, pour and mix your meals.</p>
  <p>In an effort to be socially and professionally responsible during this time, we have developed the following guidelines:</p>
  <ul>
  <li>All participants will be <strong>required to bring and wear a mask</strong>.<span class="Apple-converted-space">  </span>If you do not have a mask, one will be provided for you.</li>
  <li>All participants will <strong> receive gloves</strong> to wear during the class.</li>
  <li>Each participant will be <strong>temperature checked</strong> upon arrival.</li>
  <li><strong>Participants are encouraged to stay home if they, or any of their family members, are experiencing any cold or flu-like symptoms.</strong> Meal accommodations will be made in this instance.</li>
  </ul>
  <p>Registration is on a first come, first served basis and SPACE IS LIMITED!  <strong>There is only room for a limited amount of people per class, each purchase is for one individual to attend.</strong></p><p>**All refund/exchange requests must be made by {refund_date} MT, all purchases made after that date are final sale.**</p> {carne_asada_handling}`,
  short_description: `<p><span style="color: #000000;">In-Person Preparation:<strong><br />
        <p><span style="color: #000000;"><strong>{date}</strong></span></p>
        <p><span style="color: #000000;"><strong>{time} {time_before}</strong></span></p>
        <p><span style="color: #000000;"><strong>{store_name}</strong></span><br />
        <span style="color: #999999;">{store_address}</span></p>
        {recipes}
        <p>Due to limited food supply, we are only offering an order of 10 regular or 20 small meals per customer</p>
        `,
  manage_stock: 'true',
  stock_quantity: -38762,
  in_stock: 'true',
  purchase_note:
    'Important Citrus Pear In-Person Preparation Information:\n\nLast Day for Refunds/Exchanges:\n{refund_date} (MT)\n\nDate of Class:\n{date} at {time} {time_before}\n{store_name}\n{store_address}\n\nInstruction Cards:\nhttps://api.new.citruspeardinners.com/menu/{sku} \n We ask that no participant attend if they or their family are experiencing any cold or flu like symptoms.  All participants will be required to have their temperature checked and wearing gloves and masks during class. \n',
  categories: [
  ],
  tags: [
    {
      id: 117,
      name: 'Private Menu',
      slug: 'private-menu'
    }
  ],
  attributes: [
    {
      "id": 0,
      "name": "Meal Size",
      "position": 0,
      "visible": false,
      "variation": true,
      "options": [
        "Regular Family Size (6-8 Adults)",
        "Small Family Size (3-4 Adults)"
      ]
    },
    {
      "id": 0,
      "name": "Number of Meals",
      "position": 1,
      "visible": false,
      "variation": true,
      "options": [
        "10 Meals",
        "20 Meals"
      ]
    }
  ],
  default_attributes: [],
  images: [
    {
      "id": 722161,
      "src": "https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/07/12133922/In-Person-Preparation-1-scaled.jpg",
      "name": "In Person Preparation"
    },
    {
      "id": 722157,
      "src": "https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/07/12131255/In-Person-Preparation.jpg",
      "name": "In Person Preparation"
    }
  ]
}

export const wooVariationsTemplate = [
  {
    description: '',
    permalink:
      '{ECOMMERCE_URL}/product/{TEMPLATE}/?attribute_meal-size=Regular+Family+Size+%286-8+Adults%29&attribute_number-of-meals=10+Meals',
    price: '189',
    regular_price: '189',
    on_sale: false,
    visible: true,
    purchasable: true,
    virtual: false,
    downloadable: false,
    tax_status: 'taxable',
    tax_class: '',
    manage_stock: 'parent',
    stock_quantity: 15,
    in_stock: true,
    backorders: 'no',
    backorders_allowed: false,
    backordered: false,
    image: {
      id: 0,
      date_created: '2018-10-12T09:28:46',
      date_created_gmt: '2018-10-12T21:28:46',
      date_modified: '2018-10-12T09:28:46',
      date_modified_gmt: '2018-10-12T21:28:46',
      src:
        'https://www.citruspeardinners.com/wp-content/plugins/woocommerce/assets/images/placeholder.png',
      name: 'Placeholder',
      alt: 'Placeholder',
      position: 0
    },
    attributes: [
      {
        id: 0,
        name: 'Meal Size',
        option: 'Regular Family Size (6-8 Adults)'
      },
      {
        id: 0,
        name: 'Number of Meals',
        option: '10 Meals'
      }
    ],
    menu_order: 3
  },
  {
    description: '',
    permalink:
      '{ECOMMERCE_URL}/product/{TEMPLATE}/?attribute_meal-size=Small+Family+Size+%283-4+Adults%29&attribute_number-of-meals=20+Meals',
    price: '199',
    regular_price: '199',
    on_sale: false,
    visible: true,
    purchasable: true,
    virtual: false,
    downloadable: false,
    tax_status: 'taxable',
    tax_class: '',
    manage_stock: 'parent',
    stock_quantity: 15,
    in_stock: true,
    backorders: 'no',
    backorders_allowed: false,
    backordered: false,
    image: {
      id: 0,
      src:
        'https://www.citruspeardinners.com/wp-content/plugins/woocommerce/assets/images/placeholder.png',
      name: 'Placeholder',
      alt: 'Placeholder',
      position: 0
    },
    attributes: [
      {
        id: 0,
        name: 'Meal Size',
        option: 'Small Family Size (3-4 Adults)'
      },
      {
        id: 0,
        name: 'Number of Meals',
        option: '20 Meals'
      }
    ],
    menu_order: 4
  },
  {
    description: '',
    permalink:
      '{ECOMMERCE_URL}/product/{TEMPLATE}/?attribute_meal-size=Small+Family+Size+%283-4+Adults%29&attribute_number-of-meals=40+Meals',
    price: '360',
    regular_price: '360',
    on_sale: false,
    visible: true,
    purchasable: true,
    virtual: false,
    downloadable: false,
    tax_status: 'taxable',
    tax_class: '',
    manage_stock: 'parent',
    stock_quantity: 15,
    in_stock: true,
    backorders: 'no',
    backorders_allowed: false,
    backordered: false,
    image: {
      id: 0,
      src:
        'https://www.citruspeardinners.com/wp-content/plugins/woocommerce/assets/images/placeholder.png',
      name: 'Placeholder',
      alt: 'Placeholder',
      position: 0
    },
    attributes: [
      {
        id: 0,
        name: 'Meal Size',
        option: 'Small Family Size (3-4 Adults)'
      },
      {
        id: 0,
        name: 'Number of Meals',
        option: '40 Meals'
      }
    ],
    menu_order: 2
  },
  {
    description: '',
    permalink:
      '{ECOMMERCE_URL}/product/{TEMPLATE}/?attribute_meal-size=Regular+Family+Size+%286-8+Adults%29&attribute_number-of-meals=20+Meals',
    price: '340',
    regular_price: '340',
    on_sale: false,
    visible: true,
    purchasable: true,
    virtual: false,
    downloadable: false,
    tax_status: 'taxable',
    tax_class: '',
    manage_stock: 'parent',
    stock_quantity: 15,
    in_stock: true,
    backorders: 'no',
    backorders_allowed: false,
    backordered: false,
    image: {
      id: 0,
      src:
        'https://www.citruspeardinners.com/wp-content/plugins/woocommerce/assets/images/placeholder.png',
      name: 'Placeholder',
      alt: 'Placeholder',
      position: 0
    },
    attributes: [
      {
        id: 0,
        name: 'Meal Size',
        option: 'Regular Family Size (6-8 Adults)'
      },
      {
        id: 0,
        name: 'Number of Meals',
        option: '20 Meals'
      }
    ],
    menu_order: 1
  }
]

const wooVariationsSingleSets = [
  {
    description: '',
    permalink:
      '{ECOMMERCE_URL}/product/{TEMPLATE}/?attribute_meal-size=Regular+Family+Size+%286-8+Adults%29&attribute_number-of-meals=10+Meals',
    price: '199',
    regular_price: '199',
    on_sale: false,
    visible: true,
    purchasable: true,
    virtual: false,
    downloadable: false,
    tax_status: 'taxable',
    tax_class: '',
    manage_stock: 'parent',
    stock_quantity: 15,
    in_stock: true,
    backorders: 'no',
    backorders_allowed: false,
    backordered: false,
    image: {
      id: 0,
      date_created: '2018-10-12T09:28:46',
      date_created_gmt: '2018-10-12T21:28:46',
      date_modified: '2018-10-12T09:28:46',
      date_modified_gmt: '2018-10-12T21:28:46',
      src:
        'https://www.citruspeardinners.com/wp-content/plugins/woocommerce/assets/images/placeholder.png',
      name: 'Placeholder',
      alt: 'Placeholder',
      position: 0
    },
    attributes: [
      {
        id: 0,
        name: 'Meal Size',
        option: 'Regular Family Size (6-8 Adults)'
      },
      {
        id: 0,
        name: 'Number of Meals',
        option: '10 Meals'
      }
    ],
    menu_order: 1
  },
  {
    description: '',
    permalink:
      '{ECOMMERCE_URL}/product/{TEMPLATE}/?attribute_meal-size=Small+Family+Size+%283-4+Adults%29&attribute_number-of-meals=20+Meals',
    price: '209',
    regular_price: '209',
    on_sale: false,
    visible: true,
    purchasable: true,
    virtual: false,
    downloadable: false,
    tax_status: 'taxable',
    tax_class: '',
    manage_stock: 'parent',
    stock_quantity: 15,
    in_stock: true,
    backorders: 'no',
    backorders_allowed: false,
    backordered: false,
    image: {
      id: 0,
      src:
        'https://www.citruspeardinners.com/wp-content/plugins/woocommerce/assets/images/placeholder.png',
      name: 'Placeholder',
      alt: 'Placeholder',
      position: 0
    },
    attributes: [
      {
        id: 0,
        name: 'Meal Size',
        option: 'Small Family Size (3-4 Adults)'
      },
      {
        id: 0,
        name: 'Number of Meals',
        option: '20 Meals'
      }
    ],
    menu_order: 2
  }
]

const wooVariationsDepositOld = [
  {
    description: '',
    permalink:
      '{ECOMMERCE_URL}/product/{TEMPLATE}/?attribute_meal-size=Small+Family+Size+%283-4+Adults%29&attribute_number-of-meals=20+Meals',
    price: '199',
    regular_price: '199',
    on_sale: false,
    visible: true,
    purchasable: true,
    virtual: false,
    downloadable: false,
    tax_status: 'taxable',
    tax_class: '',
    manage_stock: 'parent',
    stock_quantity: 15,
    in_stock: true,
    backorders: 'no',
    backorders_allowed: false,
    backordered: false,
    image: {
      id: 0,
      src:
        'https://www.citruspeardinners.com/wp-content/plugins/woocommerce/assets/images/placeholder.png',
      name: 'Placeholder',
      alt: 'Placeholder',
      position: 0
    },
    attributes: [
      {
        id: 0,
        name: 'Meal Size',
        option: 'Small Family Size (3-4 Adults)'
      },
      {
        id: 0,
        name: 'Number of Meals',
        option: '20 Meals'
      }
    ],
    menu_order: 2,
    "meta_data": [
      {
        "key": "_enable_deposit",
        "value": "yes"
      },
      {
        "key": "_force_deposit",
        "value": "yes"
      },
      {
        "key": "_create_balance_orders",
        "value": "no"
      },
      {
        "key": "_deposit_default",
        "value": "yes"
      },
      {
        "key": "_product_note",
        "value": "Today, you will pay a deposit ($143) and the remaining payment ($100) at pick up."
      },
      {
        "key": "_deposit_expiration_date",
        "value": ""
      },
      {
        "key": "_deposit_expiration_product_fallback",
        "value": "default"
      }
    ]
  }
]

const wooVariationsDeposit = [
  {
    description: '',
    permalink:
      '{ECOMMERCE_URL}/product/{TEMPLATE}/?attribute_meal-size=Small+Family+Size+%283-4+Adults%29&attribute_number-of-meals=20+Meals',
    price: '199',
    regular_price: '199',
    on_sale: false,
    visible: true,
    purchasable: true,
    virtual: false,
    downloadable: false,
    tax_status: 'taxable',
    tax_class: '',
    manage_stock: 'parent',
    stock_quantity: 15,
    in_stock: true,
    backorders: 'no',
    backorders_allowed: false,
    backordered: false,
    image: {
      id: 0,
      src:
        'https://www.citruspeardinners.com/wp-content/plugins/woocommerce/assets/images/placeholder.png',
      name: 'Placeholder',
      alt: 'Placeholder',
      position: 0
    },
    attributes: [
      {
        id: 0,
        name: 'Meal Size',
        option: 'Small Family Size (3-4 Adults)'
      },
      {
        id: 0,
        name: 'Number of Meals',
        option: '20 Meals'
      }
    ],
    menu_order: 2,
    "meta_data": [
      {
        "key": "_enable_deposit",
        "value": "yes"
      },
      {
        "key": "_force_deposit",
        "value": "yes"
      },
      {
        "key": "_create_balance_orders",
        "value": "no"
      },
      {
        "key": "_deposit_default",
        "value": "yes"
      },
      {
        "key": "_product_note",
        "value": "Today, you will pay a deposit ($129) and the remaining payment ($100) at pick up."
      },
      {
        "key": "_deposit_expiration_date",
        "value": ""
      },
      {
        "key": "_deposit_expiration_product_fallback",
        "value": "default"
      }
    ]
  }
]

const wooVariations159Deposit = [
  {
    description: '',
    permalink:
      '{ECOMMERCE_URL}/product/{TEMPLATE}/?attribute_meal-size=Small+Family+Size+%283-4+Adults%29&attribute_number-of-meals=20+Meals',
    price: '199',
    regular_price: '199',
    on_sale: false,
    visible: true,
    purchasable: true,
    virtual: false,
    downloadable: false,
    tax_status: 'taxable',
    tax_class: '',
    manage_stock: 'parent',
    stock_quantity: 15,
    in_stock: true,
    backorders: 'no',
    backorders_allowed: false,
    backordered: false,
    image: {
      id: 0,
      src:
        'https://www.citruspeardinners.com/wp-content/plugins/woocommerce/assets/images/placeholder.png',
      name: 'Placeholder',
      alt: 'Placeholder',
      position: 0
    },
    attributes: [
      {
        id: 0,
        name: 'Meal Size',
        option: 'Small Family Size (3-4 Adults)'
      },
      {
        id: 0,
        name: 'Number of Meals',
        option: '20 Meals'
      }
    ],
    menu_order: 2,
    "meta_data": [
      {
        "key": "_enable_deposit",
        "value": "yes"
      },
      {
        "key": "_force_deposit",
        "value": "yes"
      },
      {
        "key": "_create_balance_orders",
        "value": "no"
      },
      {
        "key": "_deposit_default",
        "value": "yes"
      },
      {
        "key": "_product_note",
        "value": "Today, you will pay a deposit ($159) and the remaining payment ($100) at pick up."
      },
      {
        "key": "_deposit_expiration_date",
        "value": ""
      },
      {
        "key": "_deposit_expiration_product_fallback",
        "value": "default"
      }
    ]
  }
]

const wooVariationsSingleSmallSets = [
  {
    description: '',
    permalink:
      '{ECOMMERCE_URL}/product/{TEMPLATE}/?attribute_meal-size=Small+Family+Size+%283-4+Adults%29&attribute_number-of-meals=20+Meals',
    price: '199',
    regular_price: '199',
    on_sale: false,
    visible: true,
    purchasable: true,
    virtual: false,
    downloadable: false,
    tax_status: 'taxable',
    tax_class: '',
    manage_stock: 'parent',
    stock_quantity: 15,
    in_stock: true,
    backorders: 'no',
    backorders_allowed: false,
    backordered: false,
    image: {
      id: 0,
      src:
        'https://www.citruspeardinners.com/wp-content/plugins/woocommerce/assets/images/placeholder.png',
      name: 'Placeholder',
      alt: 'Placeholder',
      position: 0
    },
    attributes: [
      {
        id: 0,
        name: 'Meal Size',
        option: 'Small Family Size (3-4 Adults)'
      },
      {
        id: 0,
        name: 'Number of Meals',
        option: '20 Meals'
      }
    ],
    menu_order: 2
  }
]

const wooVariationsTemplateOld = wooVariationsTemplate

export const classTypes = [
  // Inactive Class Types
  {
    id: 'adsrw234r',
    name: 'Assembled(old)',
    variations: wooVariationsTemplateOld,
    sku_format: 'YY-MMDD-AS-LC',
    pricing_offset: 60,
    template: {
      name: '{location_name} {date_M_D} Pre-Assembled Meals',
      type: 'variable',
      sku: 'yy-mm-AS-LC',
      regular_price: '189.00',
      description: `<p>Leave the prep work to us!  We will prepare the meals for you!</p><p>**All refund/exchange requests must be made by {refund_date} MT, all purchases made after that date are final sale.**</p> {carne_asada_handling}`,
      short_description: `<p><span style="color: #000000;">
      Pick up:<br />
      <strong>{date}</strong></span></p><br /><br />
            <p><span style="color: #000000;">Pick up arrangements will be made closer the the pick up date</span></p><br /><br />
            <p><span style="color: #000000;"><strong>{store_name}</strong></span><br />
            <span style="color: #999999;">{store_address}</span></p>
            {recipes}`,
      purchase_note:
        'Important Citrus Pear Pick up Information:\n\nLast Day for Refunds/Exchanges:\n{refund_date} (MT)\n\nDate of Pick up:\n{date} \n{store_name}\n{store_address}\nPick up arrangements will be made closer to the pick up date\n\nInstruction Cards:\nhttps://api.new.citruspeardinners.com/menu/{sku} \n',
      categories: [
        {
          id: 94,
          name: 'Pre-Assembled Meals',
          slug: 'pre-assembled-meals'
        }
      ],
      tags: [
        {
          id: 115,
          name: 'Pre-Assembled Meals',
          slug: 'pre-assembled-meals'
        }
      ],
      images: [
        {
          id: 517726,
          src:
            'https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/01/05205624/CP-Product-Image-20-MAIN.png',
          name: 'CP Pricing Base Price',
          alt: '',
          position: 0
        }
      ]
    }
  },
  {
    id: 'ciuw7893hdej',
    name: 'Private(old)',
    variations: wooVariationsTemplateOld,
    template: {
      categories: [
        {
          id: 138,
          name: 'Private Class',
          slug: 'private-class'
        }
      ],
      tags: [
        {
          id: 117,
          name: 'Private Class',
          slug: 'private-class'
        }
      ]
    }
  },
  {
    id: '982jheb8f',
    name: 'Monthly(old)',
    variations: wooVariationsTemplateOld,
    template: {
      categories: [],
      tags: [
        {
          id: 116,
          name: 'Monthly Class',
          slug: 'monthly-class'
        }
      ]
    }
  },
  {
    id: 'ndh37dj38jd9',
    name: 'Couples(old)',
    qty_key: 'Couple_Class_Size',
    variations: wooVariationsTemplateOld,
    template: {
      name: '{location_name} {date_M_D} Date Night Class',
      type: 'variable',
      regular_price: '189.00',
      description: `<p>Please join us on <strong>{date}</strong> at <strong>{time} {time_before}</strong> for a special Date Night Citrus Pear class in <strong>{store_city}</strong>, held at {store_name} ({store_address}).</p> <p>In less than <strong>two hours</strong> you and your partner will help cut, chop, pour and mix your meals. All you need to bring is a <strong>cooler</strong> (we recommend one with wheels)</p> <p>Registration is on a first come, first served basis and SPACE IS LIMITED!  <strong>There is only room for a limited amount of people per class, each purchase is for one couple to attend. You will be sharing a prepping station together. </strong></p><p>**All refund/exchange requests must be made by {refund_date} MT, all purchases made after that date are final sale.**</p> {carne_asada_handling}`,
      short_description: `<p><span style="color: #000000;"><strong>{date}</strong></span></p>
            <p><span style="color: #000000;"><strong>{time} {time_before}</strong></span></p>
            <p><span style="color: #000000;"><strong>{store_name}</strong></span><br />
            <span style="color: #999999;">{store_address}</span></p>
            {recipes}`,
      purchase_note:
        'Important Citrus Pear Special Date Night Class Information:\n\nLast Day for Refunds/Exchanges:\n{refund_date} (MT)\n\nDate of Class:\n{date} at {time} {time_before}\n{store_name}\n{store_address}\n\nInstruction Cards:\nhttps://api.new.citruspeardinners.com/menu/{sku} \n',
      categories: [],
      tags: [
        {
          id: 126,
          name: 'Couples Class',
          slug: 'Couples-Class'
        }
      ],
      images: [
        {
          id: 517726,
          src:
            'https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/01/05205624/CP-Product-Image-20-MAIN.png',
          name: 'CP Pricing Base Price',
          alt: '',
          position: 0
        }
      ]
    },
    stock_quantity: {
      bs: 10,
      cc: 10,
      hb: 12,
      hd: 12,
      if: 10,
      kv: 10,
      no: 8,
      nsl: 10,
      pg: 10,
      rb: 12,
      rv: 12,
      rx: 12,
      sf: 8,
      sg: 10,
      sm: 10,
      gb: 10,
      md: 10,
      tl: 10,
      pv: 10
    }
  },
  {
    id: 'x27he9gfrnhi',
    name: 'Vegetarian(old)',
    variations: wooVariationsTemplateOld,
    template: {
      name: '{location_name} {date_M_D} Vegetarian Class',
      type: 'variable',
      description: `<p>Please join us on <strong>{date}</strong> at <strong>{time} {time_before}</strong> for a Vegetarian Citrus Pear class in <strong>{store_city}</strong>, held at {store_name} ({store_address}).</p> <p>In about <strong>two hours</strong> you will help cut, chop, pour and mix your meals. All you need to bring is a <strong>cooler</strong> (we recommend one with wheels)</p> <p>Registration is on a first come, first served basis and SPACE IS LIMITED!  <strong>There is only room for a limited amount of people per class, each purchase is for one individual to attend.</strong></p><p>**All refund/exchange requests must be made by {refund_date} MT, all purchases made after that date are final sale.**</p> {carne_asada_handling}`,
      short_description: `<p><span style="color: #000000;"><strong>{date}</strong></span></p>
            <p><span style="color: #000000;"><strong>{time} {time_before}</strong></span></p>
            <p><span style="color: #000000;"><strong>{store_name}</strong></span><br />
            <span style="color: #999999;">{store_address}</span></p>
            {recipes}`,
      purchase_note:
        'Important Citrus Pear Vegetarian Class Information:\n\nLast Day for Refunds/Exchanges:\n{refund_date} (MT)\n\nDate of Class:\n{date} at {time} {time_before}\n{store_name}\n{store_address}\n\nInstruction Cards:\nhttps://api.new.citruspeardinners.com/menu/{sku} \n',
      categories: [
        {
          id: 140,
          name: 'Vegetarian',
          slug: 'vegetarian'
        }
      ],
      tags: [
        {
          id: 139,
          name: 'Vegetarian',
          slug: 'vegetarian'
        }
      ],
      images: [
        {
          id: 220495,
          src:
            'https://www.citruspeardinners.com/wp-content/uploads/2019/04/CP-Pricing-BASE-PRICE-01.png',
          name: 'CP Pricing Base Price',
          alt: '',
          position: 0
        }
      ]
    }
  },
  {
    id: 'dd63h59ghw7hr8',
    name: 'Pick Up Meal Kits',
    qty_key: 'Pickup_Class_Size',
    variations: wooVariationsSingleSets,
    pricing_offset: 20,
    sold_individually: true,
    template: {
      name: '{location_name} {date_M_D} Pick up Meal Kits',
      type: 'variable',
      regular_price: '199.00',
      description: `      
      <p>Due to the social distancing precautions with COVID-19 we have decided to do things a little differently. Instead of coming to a class, our staff will do some of the meal assembly for you! We will have the partially assembled meals, all needed ingredients and instructions ready for you to pick up.  Some of the ingredients will need to be added at home before freezing the meals and could be a great activity to do as a family.</p>
      <p>Pick up will be on <strong>{date}</strong> at <strong>{time}</strong> at {store_name} ({store_address}). Currently due to health and safety concerns grocery stores are not allowing reusable bags or coolers to be brought into the store. If you would like to store your meals in a cooler on the way home please keep it in your car and you can transfer your food to it upon returning to your car.</p>
      <p>**All refund/exchange requests must be made by {refund_date} MT, all purchases made after that date are final sale.**</p> {carne_asada_handling}`,
      short_description: `<p><span style="color: #000000;">
      Pick Up Meal Kits Information:<br />
      <strong>{date} at {time}</strong></span></p><br /><br />
            <p><span style="color: #000000;"><strong>{store_name}</strong></span><br />
            <span style="color: #999999;">{store_address}</span></p>
            {recipes}<br />
Due to limited food supply, we are only offering an order of 10 regular or 20 small meals per customer`,
      purchase_note:
        'Important Citrus Pear Pick Up Meal Kits Information:\n\nLast Day for Refunds/Exchanges:\n{refund_date} (MT)\n\nDate of Pick up:\n{date} at {time} \n{store_name}\n{store_address}\n\nPlease remember to bring a cooler.\n\nInstruction Cards:\nhttps://pearcitr.us/menu/{sku} \n',
      categories: [
        {
          id: 181,
          name: 'Pick Up Meals',
          slug: 'pick-up-meals'
        }
      ],
      tags: [
        {
          id: 182,
          name: 'Pick Up Meals',
          slug: 'pick-up-meals'
        }
      ],
      default_attributes: [],
      images: [
        {
          id: 722163,
          src:
            'https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/07/12133957/Pick-Up-Meal-Kits-1-scaled.jpg',
          name: 'CP Pricing PICK UP',
        },
        {
          id: 722155,
          src:
            'https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/07/12131245/Pick-Up-Meal-Kits.jpg',
          name: 'CP Pricing PICK UP',
        }
      ]
    }
  },
  {
    id: 'd73y3tr6diw',
    name: 'Pick Up (old)',
    qty_key: 'Pickup_Class_Size',
    variations: wooVariationsSingleSets,
    pricing_offset: 30,
    sold_individually: true,
    template: {
      name: '{location_name} {date_M_D} Pick up Meals',
      type: 'variable',
      regular_price: '199.00',
      description: `      
      <p>Due to the social distancing precautions with COVID-19 we have decided to do things a little differently. Instead of coming to a class, our staff will do some of the meal assembly for you! We will have the partially assembled meals, all needed ingredients and instructions ready for you to pick up.  Some of the ingredients will need to be added at home before freezing the meals and could be a great activity to do as a family.</p>
      <p>Pick up will be on <strong>{date}</strong> at <strong>{time}</strong> at {store_name} ({store_address}). Currently due to health and safety concerns grocery stores are not allowing reusable bags or coolers to be brought into the store. If you would like to store your meals in a cooler on the way home please keep it in your car and you can transfer your food to it upon returning to your car.</p>
      <p>**All refund/exchange requests must be made by {refund_date} MT, all purchases made after that date are final sale.**</p> {carne_asada_handling}`,
      short_description: `<p><span style="color: #000000;">
      Pick Up Information:<br />
      <strong>{date} at {time}</strong></span></p><br /><br />
            <p><span style="color: #000000;"><strong>{store_name}</strong></span><br />
            <span style="color: #999999;">{store_address}</span></p>
            {recipes}<br />
Due to limited food supply, we are only offering an order of 10 regular or 20 small meals per customer`,
      purchase_note:
        'Important Citrus Pear Pick Up Information:\n\nLast Day for Refunds/Exchanges:\n{refund_date} (MT)\n\nDate of Pick up:\n{date} at {time} \n{store_name}\n{store_address}\n\nPlease remember to bring a cooler.\n\nInstruction Cards:\nhttps://pearcitr.us/menu/{sku} \n',
      categories: [
        {
          id: 181,
          name: 'Pick Up Meals',
          slug: 'pick-up-meals'
        }
      ],
      tags: [
        {
          id: 182,
          name: 'Pick Up Meals',
          slug: 'pick-up-meals'
        }
      ],
      default_attributes: [],
      images: [
        {
          id: 704642,
          src:
            'https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/04/19220601/20-05.png',
          name: 'CP Pricing PICK UP',
          alt: '',
          position: 0
        }
      ]
    }
  },
  {
    id: 'she8432jrf6w723jbd0',
    name: 'Pick up + Deposit',
    qty_key: 'Pickup_Class_Size',
    variations: wooVariationsDeposit,
    sku_format: 'YY-MMDD-LC',
    pricing_override: 229,
    sold_individually: true,
    template: {
      "name": "{location_name} {date_M_D} Pick Up Meal Kits",
      "type": "variable",
      "status": "publish",
      "featured": false,
      "catalog_visibility": "hidden",
      "description": "<p>In order to keep making tasty dinners, we've had to do things a little differently! When registering for your class you will pay part of the balance to Citrus Pear and the remaining to the grocery store upon pick up. Thanks for your patience as we jump through hoops to keep things going during this unpredictable time!</p>\n<p>Instead of coming to a class, our staff will do some of the meal assembly for you! We will have the partially assembled meals, all needed ingredients and instructions ready for you to pick up. Some of the ingredients will need to be added at home before freezing the meals and could be a great activity to do as a family.</p>\n<p>Pick up will be on <strong>{date}</strong> at <strong>{time}</strong> at {store_name} ({store_address}). Currently due to health and safety concerns grocery stores are not allowing reusable bags or coolers to be brought into the store. If you would like to store your meals in a cooler on the way home please keep it in your car and you can transfer your food to it upon returning to your car..</p>\n<p>**All refund/exchange requests must be made by {refund_date} MT, all purchases made after that date are final sale.**</p>\n",
      "short_description": "<p><span style=\"color: #000000;\"><br />\nPick Up Information:<br />\n<strong>{date} at {time}</strong></span></p>\n<p><span style=\"color: #000000;\"><strong>{store_name}</strong></span><br />\n<span style=\"color: #999999;\">{store_address}</span></p>\n<p>{recipes}</p>\n<p>Due to limited food supply, we are only offering an order of 20 small meals (equivalent to 10 regular meals) per customer.</p>\n",
      "sku": "sku",
      "price": "229",
      "regular_price": "",
      "sale_price": "",
      "date_on_sale_from": null,
      "date_on_sale_from_gmt": null,
      "date_on_sale_to": null,
      "date_on_sale_to_gmt": null,
      "on_sale": false,
      "purchasable": true,
      "total_sales": 0,
      "virtual": false,
      "downloadable": false,
      "downloads": [],
      "download_limit": -1,
      "download_expiry": -1,
      "external_url": "",
      "button_text": "",
      "tax_status": "taxable",
      "tax_class": "",
      "manage_stock": true,
      "stock_quantity": 16,
      "in_stock": true,
      "backorders": "no",
      "backorders_allowed": false,
      "backordered": false,
      "sold_individually": true,
      "shipping_required": false,
      "shipping_taxable": false,
      "shipping_class": "",
      "shipping_class_id": 0,
      "reviews_allowed": false,
      "purchase_note": "<p>Important Citrus Pear Pick Up Information:</p>\n<p>Last Day for Refunds/Exchanges:<br />\n{refund_date}</p>\n<p>Date of Pick up:<br />\n{date} at {time}<br />\n{store_name}<br />\n{store_address}</p>\n<p>When you arrive to store, please pay the remaining balance ($100) at the service desk. Please remember to bring a cooler.</p>\n<p>Instruction Cards:<br />\nhttps://pearcitr.us/menu/{sku}</p>\n",
      "categories": [
        {
          "id": 202,
          "name": "Pick Up (Deposit)",
          "slug": "deposit-223"
        }
      ],
      "tags": [
        {
          "id": 182,
          "name": "Pick Up",
          "slug": "pick-up"
        }
      ],
      "images": [
        {
          "id": 722163,
          "src": "https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/04/19220548/20-07.png"
        },
        {
          "id": 722156,
          "src": "https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/07/12131250/Pick-Up-Deposit-Meal-Kits.jpg"
        }
      ],
      "attributes": [
        {
          "id": 0,
          "name": "Meal Size",
          "position": 0,
          "visible": false,
          "variation": true,
          "options": [
            "Regular Family Size (6-8 Adults)",
            "Small Family Size (3-4 Adults)"
          ]
        },
        {
          "id": 0,
          "name": "Number of Meals",
          "position": 1,
          "visible": false,
          "variation": true,
          "options": [
            "10 Meals",
            "20 Meals",
            "40 Meals"
          ]
        }
      ],
      "default_attributes": [
      ],
      "grouped_products": [],
      "menu_order": 0,
      "meta_data": [
        {
          "key": "_wc_max_points_earned",
          "value": ""
        },
        {
          "key": "_wc_min_points_earned",
          "value": ""
        },
        {
          "key": "_pys_super_pack_cpt_url",
          "value": ""
        },
        {
          "key": "_pys_super_pack_cpt_condition",
          "value": "only"
        },
        {
          "key": "_pys_super_pack_cpt_cart",
          "value": "hidden"
        },
        {
          "key": "_enable_deposit",
          "value": "yes"
        },
        {
          "key": "_force_deposit",
          "value": "yes"
        },
        {
          "key": "_create_balance_orders",
          "value": "no"
        },
        {
          "key": "_deposit_default",
          "value": "yes"
        },
        {
          "key": "_product_note",
          "value": "Today, you will pay a deposit ($123) and the remaining payment ($100) at pick up."
        },
        {
          "key": "_deposit_expiration_date",
          "value": ""
        },
        {
          "key": "_deposit_expiration_product_fallback",
          "value": "default"
        },
        {
          "key": "_pys_head_footer",
          "value": {
            "disable_global": false,
            "head_any": "",
            "head_desktop": "",
            "head_mobile": "",
            "footer_any": "",
            "footer_desktop": "",
            "footer_mobile": ""
          }
        },
        {
          "key": "_coupon_title",
          "value": []
        }
      ]
    }
  },
  {
    id: 'f893jld03jod93',
    name: 'Pre-Assembled + Deposit',
    qty_key: 'Pickup_Class_Size',
    variations: wooVariations159Deposit,
    sku_format: 'YY-MMDD-LC-AS',
    pricing_override: 259,
    sold_individually: true,
    template: {
      "name": "{location_name} {date_M_D} Pre-Assembled Meals",
      "type": "variable",
      "status": "publish",
      "featured": false,
      "catalog_visibility": "hidden",
      "description": "<p>In order to keep making tasty dinners, we've had to do things a little differently! When registering for your class you will pay part of the balance to Citrus Pear and the remaining to the grocery store upon pick up. Thanks for your patience as we jump through hoops to keep things going during this unpredictable time!</p>\n<p>Instead of coming to a class, our staff will do the meal assembly for you! All meals will be ready to go directly into the freezer!</p>\n<p>Pick up will be on <strong>{date}</strong> at <strong>{time}</strong> at {store_name} ({store_address}). Currently due to health and safety concerns grocery stores are not allowing reusable bags or coolers to be brought into the store. If you would like to store your meals in a cooler on the way home please keep it in your car and you can transfer your food to it upon returning to your car..</p>\n<p>**All refund/exchange requests must be made by {refund_date} MT, all purchases made after that date are final sale.**</p>\n",
      "short_description": "<p><span style=\"color: #000000;\"><br />\nPick Up Information:<br />\n<strong>{date} at {time}</strong></span></p>\n<p><span style=\"color: #000000;\"><strong>{store_name}</strong></span><br />\n<span style=\"color: #999999;\">{store_address}</span></p>\n<p>{recipes}</p>\n<p>Due to limited food supply, we are only offering an order of 20 small meals (equivalent to 10 regular meals) per customer.</p>\n",
      "sku": "sku",
      "price": "259",
      "regular_price": "",
      "sale_price": "",
      "date_on_sale_from": null,
      "date_on_sale_from_gmt": null,
      "date_on_sale_to": null,
      "date_on_sale_to_gmt": null,
      "on_sale": false,
      "purchasable": true,
      "total_sales": 0,
      "virtual": false,
      "downloadable": false,
      "downloads": [],
      "download_limit": -1,
      "download_expiry": -1,
      "external_url": "",
      "button_text": "",
      "tax_status": "taxable",
      "tax_class": "",
      "manage_stock": true,
      "stock_quantity": 16,
      "in_stock": true,
      "backorders": "no",
      "backorders_allowed": false,
      "backordered": false,
      "sold_individually": true,
      "shipping_required": false,
      "shipping_taxable": false,
      "shipping_class": "",
      "shipping_class_id": 0,
      "reviews_allowed": false,
      "purchase_note": "<p>Important Citrus Pear Pick Up Information:</p>\n<p>Last Day for Refunds/Exchanges:<br />\n{refund_date}</p>\n<p>Date of Pick up:<br />\n{date} at {time}<br />\n{store_name}<br />\n{store_address}</p>\n<p>When you arrive to store, please pay the remaining balance ($100) at the service desk. Please remember to bring a cooler.</p>\n<p>Instruction Cards:<br />\nhttps://pearcitr.us/menu/{sku}</p>\n",
      "categories": [
        {
          "id": 205,
          "name": "Pre-Assembled + Deposit",
          "slug": "deposit-pre-assembled"
        }
      ],
      "tags": [
        {
          "id": 115,
          "name": "Pre-Assembled Meals",
          "slug": "pre-assembled-meals"
        }
      ],
      "images": [
        {
          "id": 722162,
          "src": "https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/07/12133939/Pre-Assembled-Meals-scaled.jpg"
        },
        {
          "id": 724562,
          "src": "https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/08/03232102/Pre-Assembled-Deposit-10.png"
        }
      ],
      "attributes": [
        {
          "id": 0,
          "name": "Meal Size",
          "position": 0,
          "visible": false,
          "variation": true,
          "options": [
            "Regular Family Size (6-8 Adults)",
            "Small Family Size (3-4 Adults)"
          ]
        },
        {
          "id": 0,
          "name": "Number of Meals",
          "position": 1,
          "visible": false,
          "variation": true,
          "options": [
            "10 Meals",
            "20 Meals",
            "40 Meals"
          ]
        }
      ],
      "default_attributes": [
      ],
      "grouped_products": [],
      "menu_order": 0,
      "meta_data": [
        {
          "key": "_wc_max_points_earned",
          "value": ""
        },
        {
          "key": "_wc_min_points_earned",
          "value": ""
        },
        {
          "key": "_pys_super_pack_cpt_url",
          "value": ""
        },
        {
          "key": "_pys_super_pack_cpt_condition",
          "value": "only"
        },
        {
          "key": "_pys_super_pack_cpt_cart",
          "value": "hidden"
        },
        {
          "key": "_enable_deposit",
          "value": "yes"
        },
        {
          "key": "_force_deposit",
          "value": "yes"
        },
        {
          "key": "_create_balance_orders",
          "value": "no"
        },
        {
          "key": "_deposit_default",
          "value": "yes"
        },
        {
          "key": "_product_note",
          "value": "Today, you will pay a deposit ($159) and the remaining payment ($100) at pick up."

        },
        {
          "key": "_deposit_expiration_date",
          "value": ""
        },
        {
          "key": "_deposit_expiration_product_fallback",
          "value": "default"
        },
        {
          "key": "_pys_head_footer",
          "value": {
            "disable_global": false,
            "head_any": "",
            "head_desktop": "",
            "head_mobile": "",
            "footer_any": "",
            "footer_desktop": "",
            "footer_mobile": ""
          }
        },
        {
          "key": "_coupon_title",
          "value": []
        }
      ]
    }
  },
  {
    id: 'gs5275ignsp8',
    name: 'Pick up + Deposit (old)',
    qty_key: 'Pickup_Class_Size',
    variations: wooVariationsDepositOld,
    sku_format: 'YY-MMDD-LC',
    pricing_override: 243,
    sold_individually: true,
    template: {
      "name": "{location_name} {date_M_D} Pick Up Meals",
      "type": "variable",
      "status": "publish",
      "featured": false,
      "catalog_visibility": "hidden",
      "description": "<p>In order to keep making tasty dinners, we've had to do things a little differently! When registering for your class you will pay part of the balance to Citrus Pear and the remaining to the grocery store upon pick up. Thanks for your patience as we jump through hoops to keep things going during this unpredictable time!</p>\n<p>Instead of coming to a class, our staff will do some of the meal assembly for you! We will have the partially assembled meals, all needed ingredients and instructions ready for you to pick up. Some of the ingredients will need to be added at home before freezing the meals and could be a great activity to do as a family.</p>\n<p>Pick up will be on <strong>{date}</strong> at <strong>{time}</strong> at {store_name} ({store_address}). Currently due to health and safety concerns grocery stores are not allowing reusable bags or coolers to be brought into the store. If you would like to store your meals in a cooler on the way home please keep it in your car and you can transfer your food to it upon returning to your car..</p>\n<p>**All refund/exchange requests must be made by {refund_date} MT, all purchases made after that date are final sale.**</p>\n",
      "short_description": "<p><span style=\"color: #000000;\"><br />\nPick Up Information:<br />\n<strong>{date} at {time}</strong></span></p>\n<p><span style=\"color: #000000;\"><strong>{store_name}</strong></span><br />\n<span style=\"color: #999999;\">{store_address}</span></p>\n<p>{recipes}</p>\n<p>Due to limited food supply, we are only offering an order of 20 small meals (equivalent to 10 regular meals) per customer.</p>\n",
      "sku": "sku",
      "price": "243",
      "regular_price": "",
      "sale_price": "",
      "date_on_sale_from": null,
      "date_on_sale_from_gmt": null,
      "date_on_sale_to": null,
      "date_on_sale_to_gmt": null,
      "on_sale": false,
      "purchasable": true,
      "total_sales": 0,
      "virtual": false,
      "downloadable": false,
      "downloads": [],
      "download_limit": -1,
      "download_expiry": -1,
      "external_url": "",
      "button_text": "",
      "tax_status": "taxable",
      "tax_class": "",
      "manage_stock": true,
      "stock_quantity": 16,
      "in_stock": true,
      "backorders": "no",
      "backorders_allowed": false,
      "backordered": false,
      "sold_individually": true,
      "shipping_required": false,
      "shipping_taxable": false,
      "shipping_class": "",
      "shipping_class_id": 0,
      "reviews_allowed": false,
      "purchase_note": "<p>Important Citrus Pear Pick Up Information:</p>\n<p>Last Day for Refunds/Exchanges:<br />\n{refund_date}</p>\n<p>Date of Pick up:<br />\n{date} at {time}<br />\n{store_name}<br />\n{store_address}</p>\n<p>When you arrive to store, please pay the remaining balance ($100) at the service desk. Please remember to bring a cooler.</p>\n<p>Instruction Cards:<br />\nhttps://pearcitr.us/menu/{sku}</p>\n",
      "categories": [
        {
          "id": 185,
          "name": "Pick Up Deposit",
          "slug": "deposit"
        }
      ],
      "tags": [
        {
          "id": 182,
          "name": "Pick Up",
          "slug": "pick-up"
        }
      ],
      "images": [
        {
          "id": 704641,
          "date_created": "2020-04-01T10:11:55",
          "date_created_gmt": "2020-04-01T22:11:55",
          "date_modified": "2020-04-01T10:11:55",
          "date_modified_gmt": "2020-04-01T22:11:55",
          "src": "https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/04/19220548/20-07.png",
          "name": "CP Pricing Base Price 20-07",
          "alt": "",
          "position": 0
        }
      ],
      "attributes": [
        {
          "id": 0,
          "name": "Meal Size",
          "position": 0,
          "visible": false,
          "variation": true,
          "options": [
            "Regular Family Size (6-8 Adults)",
            "Small Family Size (3-4 Adults)"
          ]
        },
        {
          "id": 0,
          "name": "Number of Meals",
          "position": 1,
          "visible": false,
          "variation": true,
          "options": [
            "10 Meals",
            "20 Meals",
            "40 Meals"
          ]
        }
      ],
      "default_attributes": [
        {
          "id": 0,
          "name": "Meal Size",
          "option": "Small Family Size (3-4 Adults)"
        },
        {
          "id": 0,
          "name": "Number of Meals",
          "option": "20 Meals"
        }
      ],
      "grouped_products": [],
      "menu_order": 0,
      "meta_data": [
        {
          "key": "_wc_max_points_earned",
          "value": ""
        },
        {
          "key": "_wc_min_points_earned",
          "value": ""
        },
        {
          "key": "_pys_super_pack_cpt_url",
          "value": ""
        },
        {
          "key": "_pys_super_pack_cpt_condition",
          "value": "only"
        },
        {
          "key": "_pys_super_pack_cpt_cart",
          "value": "hidden"
        },
        {
          "key": "_enable_deposit",
          "value": "yes"
        },
        {
          "key": "_force_deposit",
          "value": "yes"
        },
        {
          "key": "_create_balance_orders",
          "value": "no"
        },
        {
          "key": "_deposit_default",
          "value": "yes"
        },
        {
          "key": "_product_note",
          "value": "Today, you will pay a deposit ($143) and the remaining payment ($100) at pick up."
        },
        {
          "key": "_deposit_expiration_date",
          "value": ""
        },
        {
          "key": "_deposit_expiration_product_fallback",
          "value": "default"
        },
        {
          "key": "_pys_head_footer",
          "value": {
            "disable_global": false,
            "head_any": "",
            "head_desktop": "",
            "head_mobile": "",
            "footer_any": "",
            "footer_desktop": "",
            "footer_mobile": ""
          }
        },
        {
          "key": "_coupon_title",
          "value": []
        }
      ]
    }
  },
  {
    id: 's63yetw9k',
    name: 'Assembled (old)',
    variations: wooVariationsSingleSets,
    sku_format: 'YY-MMDD-AS-LC',
    pricing_offset: 50,
    qty_key: 'Pickup_Class_Size',
    template: {
      name: '{location_name} {date_M_D} Pre-Assembled Meals',
      type: 'variable',
      sku: 'yy-mm-AS-LC',
      regular_price: '199.00',
      description: `<p>Leave the prep work to us!  We will prepare the meals for you!</p><p>**All refund/exchange requests must be made by {refund_date} MT, all purchases made after that date are final sale.**</p> {carne_asada_handling}`,
      short_description: `<p><span style="color: #000000;">
      Pre-Assembled Pick Up:<br />
      <strong>{date} at {time}</strong></span></p><br /><br />
            <p><span style="color: #000000;"><strong>{store_name}</strong></span><br />
            <span style="color: #999999;">{store_address}</span></p>
            {recipes}<br />
Due to limited food supply, we are only offering an order of 10 regular or 20 small meals per customer`,
      purchase_note:
        'Important Citrus Pear Pre-Assembled Information:\n\nLast Day for Refunds/Exchanges:\n{refund_date} (MT)\n\nDate of Pick up:\n{date} at {time}\n{store_name}\n{store_address}\n\nPlease remember to bring a cooler.\n\nInstruction Cards:\nhttps://pearcitr.us/menu/{sku} \n',
      default_attributes: [],
      categories: [
        {
          id: 94,
          name: 'Pre-Assembled Meals',
          slug: 'pre-assembled-meals'
        }
      ],
      tags: [
        {
          id: 115,
          name: 'Pre-Assembled Meals',
          slug: 'pre-assembled-meals'
        }
      ],
      images: [
        {
          id: 695123,
          src:
            'https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/03/27184150/CP-Pricing-PRE-ASSEMBLED-1.png',
          name: 'CP Pricing PRE-ASSEMBLED',
          alt: '',
          position: 0
        }
      ]
    }
  },
  {
    id: 'iau234sflj',
    name: "Pre-Pear'd (old)",
    variations: wooVariationsSingleSmallSets,
    qty_key: 'Pickup_Class_Size',
    sku_format: 'YY-MMDD-LC',
    pricing_offset: 61,
    sold_individually: true,
    template: {
      name: '{location_name} {date_M_D} Pick up Meals',
      type: 'variable',
      regular_price: '189.00',
      description: `      
        <p>Due to the social distancing precautions with COVID-19 we have decided to do things a little differently. Instead of coming to a class, our staff will do some of the meal assembly for you! We will have the partially assembled meals, all needed ingredients and instructions ready for you to pick up.  Some of the ingredients will need to be added at home before freezing the meals and could be a great activity to do as a family.</p>
        <p>Pick up will be on <strong>{date}</strong> at <strong>{time}</strong> at {store_name} ({store_address}). Currently due to health and safety concerns grocery stores are not allowing reusable bags or coolers to be brought into the store. If you would like to store your meals in a cooler on the way home please keep it in your car and you can transfer your food to it upon returning to your car..</p>
        <p>**All refund/exchange requests must be made by {refund_date} MT, all purchases made after that date are final sale.**</p> {carne_asada_handling}`,
      short_description: `<p><span style="color: #000000;">
        Pick Up Information:<br />
        <strong>{date} at {time}</strong></span></p><br /><br />
              <p><span style="color: #000000;"><strong>{store_name}</strong></span><br />
              <span style="color: #999999;">{store_address}</span></p>
              {recipes}<br />
              Due to limited food supply, we are only offering an order of 20 small meals (equivalent to 10 regular meals) per customer.`,
      purchase_note:
          'Important Citrus Pear Pick Up Information:\n\nLast Day for Refunds/Exchanges:\n{refund_date} (MT)\n\nDate of Pick up:\n{date} at {time} \n{store_name}\n{store_address}\n\nPlease remember to bring a cooler.\n\nInstruction Cards:\nhttps://pearcitr.us/menu/{sku} \n',
      categories: [
        {
          id: 181,
          name: 'Pick Up Meals',
          slug: 'pick-up-meals'
        }
      ],
      tags: [
        {
          id: 182,
          name: 'Pick Up Meals',
          slug: 'pick-up-meals'
        }
      ],
      default_attributes: [
        {
          id: 0,
          name: 'Meal Size',
          option: 'Small Family Size (3-4 Adults)'
        },
        {
          id: 0,
          name: 'Number of Meals',
          option: '20 Meals'
        }
      ],
      images: [
        {
          id: 695180,
          src:
            'https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/03/27232038/CP-Pricing-PRE-PEARD-2.png',
          name: "CP Pricing PRE PEAR'D",
          alt: '',
          position: 0
        }
      ]
    }
  },
  {
    id: 'e732hrafbddfhaoawe',
    name: "Pre-Pear'd (old)",
    variations: wooVariationsSingleSmallSets,
    qty_key: 'Pickup_Class_Size',
    sku_format: 'YY-MMDD-AS-LC',
    pricing_offset: 51,
    template: {
      name: '{location_name} {date_M_D} Pre-Assembled Meals',
      type: 'variable',
      regular_price: '199.00',
      sold_individually: true,
      description: `<p>Leave the prep work to us!  We will prepare the meals for you!</p><p>**All refund/exchange requests must be made by {refund_date} MT, all purchases made after that date are final sale.**</p> {carne_asada_handling}`,
      short_description: `<p><span style="color: #000000;">
      Pre-Assembled Pick Up:<br />
      <strong>{date} at {time}</strong></span></p><br /><br />
            <p><span style="color: #000000;"><strong>{store_name}</strong></span><br />
            <span style="color: #999999;">{store_address}</span></p>
            {recipes}<br />
            Due to limited food supply, we are only offering an order of 20 small meals (equivalent to 10 regular meals) per customer.`,
      purchase_note: 'Important Citrus Pear Pre-Assembled Information:\n\nLast Day for Refunds/Exchanges:\n{refund_date} (MT)\n\nDate of Pick up:\n{date} at {time}\n{store_name}\n{store_address}\n\nPlease remember to bring a cooler.\n\nInstruction Cards:\nhttps://pearcitr.us/menu/{sku} \n',
      categories: [
        {
          id: 94,
          name: 'Pre-Assembled Meals',
          slug: 'pre-assembled-meals'
        }
      ],
      tags: [
        {
          id: 115,
          name: 'Pre-Assembled Meals',
          slug: 'pre-assembled-meals'
        }
      ],
      default_attributes: [
        {
          id: 0,
          name: 'Meal Size',
          option: 'Small Family Size (3-4 Adults)'
        },
        {
          id: 0,
          name: 'Number of Meals',
          option: '20 Meals'
        }
      ],
      images: [
        {
          id: 699962,
          src:
            'https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/04/08222418/CP-Pricing-PRE-PEARD-06.png',
          name: "CP Pricing PRE PEAR'D",
          alt: '',
          position: 0
        }
      ]
    }
  },
  {
    id: 'hd73yh4uw02tgvzs9',
    name: "Pre-Pear'd",
    variations: wooVariationsSingleSmallSets,
    qty_key: 'Pickup_Class_Size',
    sku_format: 'YY-MMDD-LC-AS',
    pricing_offset: 61,
    template: {
      name: '{location_name} {date_M_D} Pre-Assembled Meals',
      type: 'variable',
      regular_price: '189.00',
      sold_individually: true,
      short_description: `<p><span style="color: #000000;">
      Pre-Assembled Pick Up:<br />
      <strong>{date} at {time}</strong></span></p><br /><br />
            <p><span style="color: #000000;"><strong>{store_name}</strong></span><br />
            <span style="color: #999999;">{store_address}</span></p>
            {recipes}<br />
            Due to limited food supply, we are only offering an order of 20 small meals (equivalent to 10 regular meals) per customer.`,
      description: `<p>Leave the prep work to us!  We will prepare the meals for you!</p><p>**All refund/exchange requests must be made by {refund_date} MT, all purchases made after that date are final sale.**</p> {carne_asada_handling}`,
      purchase_note: 'Important Citrus Pear Pre-Assembled Information:\n\nLast Day for Refunds/Exchanges:\n{refund_date} (MT)\n\nDate of Pick up:\n{date} at {time}\n{store_name}\n{store_address}\n\nPlease remember to bring a cooler.\n\nInstruction Cards:\nhttps://pearcitr.us/menu/{sku} \n',
      categories: [
        {
          id: 94,
          name: 'Pre-Assembled Meals',
          slug: 'pre-assembled-meals'
        }
      ],
      tags: [
        {
          id: 115,
          name: 'Pre-Assembled Meals',
          slug: 'pre-assembled-meals'
        }
      ],
      default_attributes: [
        {
          id: 0,
          name: 'Meal Size',
          option: 'Small Family Size (3-4 Adults)'
        },
        {
          id: 0,
          name: 'Number of Meals',
          option: '20 Meals'
        }
      ],
      images: [
        {
          id: 722162,
          src:
            'https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/07/12133939/Pre-Assembled-Meals-scaled.jpg',
          name: "CP Pricing PRE PEAR'D",
        },
        {
          id: 722151,
          src:
            'https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/07/12131227/Pre-Assembled-Pre-Peard.jpg',
          name: "CP Pricing PRE PEAR'D",
        }
      ]
    }
  },
  {
    id: 'q28rul50fe',
    name: 'Private2(old)',
    variations: wooVariationsTemplate,
    template: {
      categories: [
        {
          id: 138,
          name: 'Private Class',
          slug: 'private-class'
        }
      ],
      tags: [
        {
          id: 117,
          name: 'Private Class',
          slug: 'private-class'
        }
      ]
    }
  },
  {
    id: 'js73ytsnx9',
    name: 'Monthly (old)',
    variations: wooVariationsTemplate,
    template: {
      categories: [],
      tags: [
        {
          id: 116,
          name: 'Monthly Class',
          slug: 'monthly-class'
        }
      ]
    }
  },
  {
    id: 'qdiufr30f7',
    name: 'Vegetarian (old)',
    variations: wooVariationsTemplate,
    template: {
      name: '{location_name} {date_M_D} Vegetarian Class',
      type: 'variable',
      description: `<p>Please join us on <strong>{date}</strong> at <strong>{time} {time_before}</strong> for a Vegetarian Citrus Pear class in <strong>{store_city}</strong>, held at {store_name} ({store_address}).</p> <p>In about <strong>two hours</strong> you will help cut, chop, pour and mix your meals. All you need to bring is a <strong>cooler</strong> (we recommend one with wheels)</p> <p>Registration is on a first come, first served basis and SPACE IS LIMITED!  <strong>There is only room for a limited amount of people per class, each purchase is for one individual to attend.</strong></p><p>**All refund/exchange requests must be made by {refund_date} MT, all purchases made after that date are final sale.**</p> {carne_asada_handling}`,
      short_description: `<p><span style="color: #000000;"><strong>{date}</strong></span></p>
            <p><span style="color: #000000;"><strong>{time} {time_before}</strong></span></p>
            <p><span style="color: #000000;"><strong>{store_name}</strong></span><br />
            <span style="color: #999999;">{store_address}</span></p>
            {recipes}`,
      purchase_note:
        'Important Citrus Pear Vegetarian Class Information:\n\nLast Day for Refunds/Exchanges:\n{refund_date} (MT)\n\nDate of Class:\n{date} at {time} {time_before}\n{store_name}\n{store_address}\n\nInstruction Cards:\nhttps://api.new.citruspeardinners.com/menu/{sku} \n',
      categories: [
        {
          id: 140,
          name: 'Vegetarian',
          slug: 'vegetarian'
        }
      ],
      tags: [
        {
          id: 139,
          name: 'Vegetarian',
          slug: 'vegetarian'
        }
      ],
      images: [
        {
          id: 517725,
          src:
            'https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/01/05205618/CP-Product-Image-20-VEG.png',
          name: 'CP Pricing Base Price',
          alt: '',
          position: 0
        }
      ]
    }
  },
  {
    id: 'oa34q90fd',
    name: 'Assembled Vegetarian (old)',
    variations: wooVariationsSingleSets,
    sku_format: 'YY-MMDD-AS-LC',
    pricing_offset: 50,
    qty_key: 'Pickup_Class_Size',
    template: {
      name: '{location_name} {date_M_D} Vegetarian Pre-Assembled Meals',
      type: 'variable',
      sku: 'yy-mm-AS-LC',
      regular_price: '199.00',
      description: `<p>Leave the prep work to us!  We will prepare the meals for you! Currently due to health and safety concerns grocery stores are not allowing reusable bags or coolers to be brought into the store. If you would like to store your meals in a cooler on the way home please keep it in your car and you can transfer your food to it upon returning to your car.</p><p>**All refund/exchange requests must be made by {refund_date} MT, all purchases made after that date are final sale.**</p> {carne_asada_handling}`,
      short_description: `<p><span style="color: #000000;">
      Pre-Assembled Vegetarian Pick Up:<br />
      <strong>{date} at {time}</strong></span></p><br /><br />
            <p><span style="color: #000000;"><strong>{store_name}</strong></span><br />
            <span style="color: #999999;">{store_address}</span></p>
            {recipes}<br />
Due to limited food supply, we are only offering an order of 10 regular or 20 small meals per customer`,
      purchase_note:
        'Important Citrus Pear Pre-Assembled Information:\n\nLast Day for Refunds/Exchanges:\n{refund_date} (MT)\n\nDate of Pick up:\n{date} at {time}\n{store_name}\n{store_address}\n\nCurrently due to health and safety concerns grocery stores are not allowing reusable bags or coolers to be brought into the store. If you would like to store your meals in a cooler on the way home please keep it in your car and you can transfer your food to it upon returning to your car.\n\nInstruction Cards:\nhttps://pearcitr.us/menu/{sku} \n',
      categories: [
        {
          id: 199,
          name: 'Vegetarian Pre-Assembled',
          slug: `vegetarian-assembled`
        }
      ],
      tags: [
        {
          id: 200,
          name: 'Vegetarian Pre-Assembled',
          slug: 'veg-assembled'
        }
      ],
      images: [
        {
          id: 716210,
          src:
            'https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/06/01193157/CP-Assembled-Vegetarian-20-09.png',
          name: 'CP Assembled-Vegetarian 20-09',
          alt: '',
          position: 0
        }
      ]
    }
  },
  {
    id: 'djq73ythrhe02h3bdvs',
    name: 'Pre-Pear\'d Vegetarian',
    variations: wooVariationsSingleSmallSets,
    sku_format: 'YY-MMDD-LC-AS',
    pricing_offset: 51,
    qty_key: 'Pickup_Class_Size',
    template: {
      name: '{location_name} {date_M_D} Vegetarian Pre-Assembled Meals',
      type: 'variable',
      sku: 'YY-MMDD-LC-AS',
      regular_price: '199.00',
      description: `<p>Leave the prep work to us!  We will prepare the meals for you! Currently due to health and safety concerns grocery stores are not allowing reusable bags or coolers to be brought into the store. If you would like to store your meals in a cooler on the way home please keep it in your car and you can transfer your food to it upon returning to your car.</p><p>**All refund/exchange requests must be made by {refund_date} MT, all purchases made after that date are final sale.**</p> {carne_asada_handling}`,
      short_description: `<p><span style="color: #000000;">
      Pre-Assembled Vegetarian Pick Up:<br />
      <strong>{date} at {time}</strong></span></p><br /><br />
            <p><span style="color: #000000;"><strong>{store_name}</strong></span><br />
            <span style="color: #999999;">{store_address}</span></p>
            {recipes}<br />
            Due to limited food supply, we are only offering an order of 20 small meals (equivalent to 10 regular meals) per customer.`,
      purchase_note:
        'Important Citrus Pear Pre-Assembled Information:\n\nLast Day for Refunds/Exchanges:\n{refund_date} (MT)\n\nDate of Pick up:\n{date} at {time}\n{store_name}\n{store_address}\n\nCurrently due to health and safety concerns grocery stores are not allowing reusable bags or coolers to be brought into the store. If you would like to store your meals in a cooler on the way home please keep it in your car and you can transfer your food to it upon returning to your car.\n\nInstruction Cards:\nhttps://pearcitr.us/menu/{sku} \n',
      categories: [
        {
          id: 199,
          name: 'Vegetarian Pre-Assembled',
          slug: `vegetarian-assembled`
        }
      ],
      tags: [
        {
          id: 139,
          name: 'Vegetarian Menu',
          slug: 'vegetarian-menu'
        },
        {
          id: 115,
          name: 'Pre-Assembled Meals',
          slug: 'pre-assembled-meals'
        },
        {
          id: 200,
          name: 'Vegetarian Pre-Assembled',
          slug: 'veg-assembled'
        }
      ],
      images: [
        {
          id: 722160,
          src:
            'https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/07/12133902/Pre-Assembled-Vegetarian-1-scaled.jpg'
        },
        {
          id: 722352,
          src:
            'https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/07/13190852/Pre-Assembled-Vegetarian-Pre-Peard-09.jpg'
        }
      ]
    }
  },

  // Active Class Types
  {
    id: 'cjks94u9hsdiysf',
    name: 'Pre-Assembled',
    variations: wooVariationsSingleSets,
    sku_format: 'YY-MMDD-LC-AS',
    pricing_offset: 50,
    qty_key: 'Pickup_Class_Size',
    template: {
      name: '{location_name} {date_M_D} Pre-Assembled Meals',
      type: 'variable',
      sku: 'YY-MMDD-LC-AS',
      regular_price: '199.00',
      description: `<p>Leave the prep work to us!  We will prepare the meals for you!</p><p>**All refund/exchange requests must be made by {refund_date} MT, all purchases made after that date are final sale.**</p> {carne_asada_handling}`,
      short_description: `<p><span style="color: #000000;">
      Pre-Assembled Pick Up:<br />
      <strong>{date} at {time}</strong></span></p><br /><br />
            <p><span style="color: #000000;"><strong>{store_name}</strong></span><br />
            <span style="color: #999999;">{store_address}</span></p>
            {recipes}<br />
Due to limited food supply, we are only offering an order of 10 regular or 20 small meals per customer`,
      purchase_note:
        'Important Citrus Pear Pre-Assembled Information:\n\nLast Day for Refunds/Exchanges:\n{refund_date} (MT)\n\nDate of Pick up:\n{date} at {time}\n{store_name}\n{store_address}\n\nPlease remember to bring a cooler.\n\nInstruction Cards:\nhttps://pearcitr.us/menu/{sku} \n',
      default_attributes: [],
      categories: [
        {
          id: 94,
          name: 'Pre-Assembled Meals',
          slug: 'pre-assembled-meals'
        }
      ],
      tags: [
        {
          id: 115,
          name: 'Pre-Assembled Meals',
          slug: 'pre-assembled-meals'
        }
      ],
      images: [
        {
          id: 722162,
          src:
            'https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/07/12133939/Pre-Assembled-Meals-scaled.jpg',
          name: 'CP Pricing PRE-ASSEMBLED'
        },
        {
          id: 722152,
          src:
            'https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/07/12131231/Pre-Assembled.jpg',
          name: 'CP Pricing PRE-ASSEMBLED'
        }
      ]
    }
  },
  {
    id: 'dbq727sdnczb05ha',
    name: 'Pre-Assembled Vegetarian',
    variations: wooVariationsSingleSets,
    sku_format: 'YY-MMDD-LC-AS',
    pricing_offset: 50,
    qty_key: 'Pickup_Class_Size',
    template: {
      name: '{location_name} {date_M_D} Vegetarian Pre-Assembled Meals',
      type: 'variable',
      sku: 'YY-MMDD-LC-AS',
      regular_price: '199.00',
      description: `<p>Leave the prep work to us!  We will prepare the meals for you! Currently due to health and safety concerns grocery stores are not allowing reusable bags or coolers to be brought into the store. If you would like to store your meals in a cooler on the way home please keep it in your car and you can transfer your food to it upon returning to your car.</p><p>**All refund/exchange requests must be made by {refund_date} MT, all purchases made after that date are final sale.**</p> {carne_asada_handling}`,
      short_description: `<p><span style="color: #000000;">
      Pre-Assembled Vegetarian Pick Up:<br />
      <strong>{date} at {time}</strong></span></p><br /><br />
            <p><span style="color: #000000;"><strong>{store_name}</strong></span><br />
            <span style="color: #999999;">{store_address}</span></p>
            {recipes}<br />
Due to limited food supply, we are only offering an order of 10 regular or 20 small meals per customer`,
      purchase_note:
        'Important Citrus Pear Pre-Assembled Information:\n\nLast Day for Refunds/Exchanges:\n{refund_date} (MT)\n\nDate of Pick up:\n{date} at {time}\n{store_name}\n{store_address}\n\nCurrently due to health and safety concerns grocery stores are not allowing reusable bags or coolers to be brought into the store. If you would like to store your meals in a cooler on the way home please keep it in your car and you can transfer your food to it upon returning to your car.\n\nInstruction Cards:\nhttps://pearcitr.us/menu/{sku} \n',
      categories: [
        {
          id: 199,
          name: 'Vegetarian Pre-Assembled',
          slug: `vegetarian-assembled`
        }
      ],
      tags: [
        {
          id: 139,
          name: 'Vegetarian Menu',
          slug: 'vegetarian-menu'
        },
        {
          id: 115,
          name: 'Pre-Assembled Meals',
          slug: 'pre-assembled-meals'
        },
        {
          id: 200,
          name: 'Vegetarian Pre-Assembled',
          slug: 'veg-assembled'
        }
      ],
      images: [
        {
          id: 722160,
          src:
            'https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/07/12133902/Pre-Assembled-Vegetarian-1-scaled.jpg'
        },
        {
          id: 722153,
          src:
            'https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/07/12131236/Pre-Assembled-Vegetarian.jpg'
        }
      ]
    }
  },
  {
    id: 'a59hm9n5h',
    name: 'Couples',
    qty_key: 'Couple_Class_Size',
    variations: wooVariationsSingleSets,
    template: {
      name: '{location_name} {date_M_D} Special Couples Class',
      type: 'variable',
      regular_price: '199.00',
      description: `<p>Please join us on <strong>{date}</strong> at <strong>{time} {time_before}</strong> for a special Date Night Citrus Pear class in <strong>{store_city}</strong>, held at {store_name} ({store_address}).</p> <p>In less than <strong>two hours</strong> you and your partner will help cut, chop, pour and mix your meals. All you need to bring is a <strong>cooler</strong> (we recommend one with wheels)</p> <p>Registration is on a first come, first served basis and SPACE IS LIMITED!  <strong>There is only room for a limited amount of people per class, each purchase is for one couple to attend. You will be sharing a prepping station together. </strong></p><p>**All refund/exchange requests must be made by {refund_date} MT, all purchases made after that date are final sale.**</p> {carne_asada_handling}`,
      short_description: `<p><span style="color: #000000;"><strong>{date}</strong></span></p>
            <p><span style="color: #000000;"><strong>{time} {time_before}</strong></span></p>
            <p><span style="color: #000000;"><strong>{store_name}</strong></span><br />
            <span style="color: #999999;">{store_address}</span></p>
            {recipes}`,
      purchase_note:
        'Important Citrus Pear Special Date Night Class Information:\n\nLast Day for Refunds/Exchanges:\n{refund_date} (MT)\n\nDate of Class:\n{date} at {time} {time_before}\n{store_name}\n{store_address}\n\nInstruction Cards:\nhttps://api.new.citruspeardinners.com/menu/{sku} \n',
      categories: [],
      tags: [
        {
          id: 126,
          name: 'Couples Class',
          slug: 'Couples-Class'
        },
        {
          id: 201,
          name: 'In-Person Preparation',
          slug: 'class'
        }
      ],
      images: [
        {
          id: 722158,
          src:
            'https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/07/12133826/In-Person-Couple-scaled.jpg'
        },
        {
          id: 722150,
          src:
            'https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/07/12131221/In-Person-Preparation-Couple-Class.jpg'
        }
      ]
    }
  },
  {
    id: 'dsfhje7234ireoufdjks9',
    name: 'In-Person Vegetarian',
    variations: wooVariationsSingleSets,
    template: {
      name: '{location_name} {date_M_D} In-Person Vegetarian Preparation',
      type: 'variable',
      description: `<p>Please join us on <strong>{date}</strong> at <strong>{time} {time_before}</strong> for a Citrus Pear vegetarian class in <strong>{store_city}</strong>, held at {store_name} ({store_address}).</p> <p>In less than <strong>two hours</strong> you will help cut, chop, pour and mix your meals.</p>
      <p>In an effort to be socially and professionally responsible during this time, we have developed the following guidelines:</p>
      <ul>
      <li>All participants will be <strong>required to bring and wear a mask</strong>.<span class="Apple-converted-space">  </span>If you do not have a mask, one will be provided for you.</li>
      <li>All participants will <strong> receive gloves</strong> to wear during the class.</li>
      <li>Each participant will be <strong>temperature checked</strong> upon arrival.</li>
      <li><strong>Participants are encouraged to stay home if they, or any of their family members, are experiencing any cold or flu-like symptoms.</strong> Meal accommodations will be made in this instance.</li>
      </ul>
      <p>Registration is on a first come, first served basis and SPACE IS LIMITED!  <strong>There is only room for a limited amount of people per class, each purchase is for one individual to attend.</strong></p><p>**All refund/exchange requests must be made by {refund_date} MT, all purchases made after that date are final sale.**</p> {carne_asada_handling}`,
      short_description: `<p><span style="color: #000000;">In-Person Vegetarian Preparation:<strong><br />
            <p><span style="color: #000000;"><strong>{date}</strong></span></p>
            <p><span style="color: #000000;"><strong>{time} {time_before}</strong></span></p>
            <p><span style="color: #000000;"><strong>{store_name}</strong></span><br />
            <span style="color: #999999;">{store_address}</span></p>
            {recipes}
            <p>Due to limited food supply, we are only offering an order of 10 regular or 20 small meals per customer</p>
            `,
      purchase_note:
        'Important Citrus Pear In-Person Vegetarian Preparation Information:\n\nLast Day for Refunds/Exchanges:\n{refund_date} (MT)\n\nDate of Class:\n{date} at {time} {time_before}\n{store_name}\n{store_address}\n\nInstruction Cards:\nhttps://api.new.citruspeardinners.com/menu/{sku} \n We ask that no participant attend if they or their family are experiencing any cold or flu like symptoms.  All participants will be required to have their temperature checked and wearing gloves and masks during class. \n',
      categories: [
        {
          id: 140,
          name: 'Vegetarian',
          slug: 'vegetarian'
        }
      ],
      tags: [
        {
          id: 139,
          name: 'Vegetarian Menu',
          slug: 'vegetarian-menu'
        },
        {
          id: 201,
          name: 'In-Person Preparation',
          slug: 'class'
        }
      ],
      images: [
        {
          id: 722159,
          src:
            'https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/07/12133847/In-Person-Vegetarian-scaled.jpg'
        },
        {
          id: 722154,
          src:
            'https://sfo2.digitaloceanspaces.com/cjtech/wp-content/uploads/2020/07/12131240/In-Person-Preparation-Vegetarian.jpg'
        }
      ]
    }
  },
  {
    id: '33fhq83yehs',
    name: 'In-Person Preparation',
    variations: wooVariationsSingleSets,
    template: {
      categories: [],
      tags: [
        {
          id: 201,
          name: 'In-Person Preparation',
          slug: 'class'
        }
      ]
    }
  },
]

export const timeZones = [
  'America/Denver',
  'America/Los_Angeles',
  'America/Boise',
  'America/Phoenix',
  'America/Chicago',
  'America/New_York',
  'America/Detroit',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Indiana/Indianapolis',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Vevay',
  'America/Indiana/Tell_City',
  'America/Indiana/Knox',
  'America/Menominee',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/North_Dakota/Beulah',
  'America/Anchorage',
  'America/Juneau',
  'Europe/Andorra',
  'Asia/Dubai',
  'Asia/Kabul',
  'Europe/Tirane',
  'Asia/Yerevan',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  'Antarctica/Mawson',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Cordoba',
  'America/Argentina/Salta',
  'America/Argentina/Jujuy',
  'America/Argentina/Tucuman',
  'America/Argentina/Catamarca',
  'America/Argentina/La_Rioja',
  'America/Argentina/San_Juan',
  'America/Argentina/Mendoza',
  'America/Argentina/San_Luis',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Ushuaia',
  'Pacific/Pago_Pago',
  'Europe/Vienna',
  'Australia/Lord_Howe',
  'Antarctica/Macquarie',
  'Australia/Hobart',
  'Australia/Currie',
  'Australia/Melbourne',
  'Australia/Sydney',
  'Australia/Broken_Hill',
  'Australia/Brisbane',
  'Australia/Lindeman',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Australia/Perth',
  'Australia/Eucla',
  'Asia/Baku',
  'America/Barbados',
  'Asia/Dhaka',
  'Europe/Brussels',
  'Europe/Sofia',
  'Atlantic/Bermuda',
  'Asia/Brunei',
  'America/La_Paz',
  'America/Noronha',
  'America/Belem',
  'America/Fortaleza',
  'America/Recife',
  'America/Araguaina',
  'America/Maceio',
  'America/Bahia',
  'America/Sao_Paulo',
  'America/Campo_Grande',
  'America/Cuiaba',
  'America/Santarem',
  'America/Porto_Velho',
  'America/Boa_Vista',
  'America/Manaus',
  'America/Eirunepe',
  'America/Rio_Branco',
  'America/Nassau',
  'Asia/Thimphu',
  'Europe/Minsk',
  'America/Belize',
  'America/St_Johns',
  'America/Halifax',
  'America/Glace_Bay',
  'America/Moncton',
  'America/Goose_Bay',
  'America/Blanc-Sablon',
  'America/Toronto',
  'America/Nipigon',
  'America/Thunder_Bay',
  'America/Iqaluit',
  'America/Pangnirtung',
  'America/Atikokan',
  'America/Winnipeg',
  'America/Rainy_River',
  'America/Resolute',
  'America/Rankin_Inlet',
  'America/Regina',
  'America/Swift_Current',
  'America/Edmonton',
  'America/Cambridge_Bay',
  'America/Yellowknife',
  'America/Inuvik',
  'America/Creston',
  'America/Dawson_Creek',
  'America/Fort_Nelson',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Dawson',
  'Indian/Cocos',
  'Europe/Zurich',
  'Africa/Abidjan',
  'Pacific/Rarotonga',
  'America/Santiago',
  'America/Punta_Arenas',
  'Pacific/Easter',
  'Asia/Shanghai',
  'Asia/Urumqi',
  'America/Bogota',
  'America/Costa_Rica',
  'America/Havana',
  'Atlantic/Cape_Verde',
  'America/Curacao',
  'Indian/Christmas',
  'Asia/Nicosia',
  'Asia/Famagusta',
  'Europe/Prague',
  'Europe/Berlin',
  'Europe/Copenhagen',
  'America/Santo_Domingo',
  'Africa/Algiers',
  'America/Guayaquil',
  'Pacific/Galapagos',
  'Europe/Tallinn',
  'Africa/Cairo',
  'Africa/El_Aaiun',
  'Europe/Madrid',
  'Africa/Ceuta',
  'Atlantic/Canary',
  'Europe/Helsinki',
  'Pacific/Fiji',
  'Atlantic/Stanley',
  'Pacific/Chuuk',
  'Pacific/Pohnpei',
  'Pacific/Kosrae',
  'Atlantic/Faroe',
  'Europe/Paris',
  'Europe/London',
  'Asia/Tbilisi',
  'America/Cayenne',
  'Africa/Accra',
  'Europe/Gibraltar',
  'America/Godthab',
  'America/Danmarkshavn',
  'America/Scoresbysund',
  'America/Thule',
  'Europe/Athens',
  'Atlantic/South_Georgia',
  'America/Guatemala',
  'Pacific/Guam',
  'Africa/Bissau',
  'America/Guyana',
  'Asia/Hong_Kong',
  'America/Tegucigalpa',
  'America/Port-au-Prince',
  'Europe/Budapest',
  'Asia/Jakarta',
  'Asia/Pontianak',
  'Asia/Makassar',
  'Asia/Jayapura',
  'Europe/Dublin',
  'Asia/Jerusalem',
  'Asia/Kolkata',
  'Indian/Chagos',
  'Asia/Baghdad',
  'Asia/Tehran',
  'Atlantic/Reykjavik',
  'Europe/Rome',
  'America/Jamaica',
  'Asia/Amman',
  'Asia/Tokyo',
  'Africa/Nairobi',
  'Asia/Bishkek',
  'Pacific/Tarawa',
  'Pacific/Enderbury',
  'Pacific/Kiritimati',
  'Asia/Pyongyang',
  'Asia/Seoul',
  'Asia/Almaty',
  'Asia/Qyzylorda',
  'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  'Asia/Aqtobe',
  'Asia/Aqtau',
  'Asia/Atyrau',
  'Asia/Oral',
  'Asia/Beirut',
  'Asia/Colombo',
  'Africa/Monrovia',
  'Europe/Vilnius',
  'Europe/Luxembourg',
  'Europe/Riga',
  'Africa/Tripoli',
  'Africa/Casablanca',
  'Europe/Monaco',
  'Europe/Chisinau',
  'Pacific/Majuro',
  'Pacific/Kwajalein',
  'Asia/Yangon',
  'Asia/Ulaanbaatar',
  'Asia/Hovd',
  'Asia/Choibalsan',
  'Asia/Macau',
  'America/Martinique',
  'Europe/Malta',
  'Indian/Mauritius',
  'Indian/Maldives',
  'America/Mexico_City',
  'America/Cancun',
  'America/Merida',
  'America/Monterrey',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Chihuahua',
  'America/Ojinaga',
  'America/Hermosillo',
  'America/Tijuana',
  'America/Bahia_Banderas',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Africa/Maputo',
  'Africa/Windhoek',
  'Pacific/Noumea',
  'Pacific/Norfolk',
  'Africa/Lagos',
  'America/Managua',
  'Europe/Amsterdam',
  'Europe/Oslo',
  'Asia/Kathmandu',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'America/Panama',
  'America/Lima',
  'Pacific/Tahiti',
  'Pacific/Marquesas',
  'Pacific/Gambier',
  'Pacific/Port_Moresby',
  'Pacific/Bougainville',
  'Asia/Manila',
  'Asia/Karachi',
  'Europe/Warsaw',
  'America/Miquelon',
  'Pacific/Pitcairn',
  'America/Puerto_Rico',
  'Asia/Gaza',
  'Asia/Hebron',
  'Europe/Lisbon',
  'Atlantic/Madeira',
  'Atlantic/Azores',
  'Pacific/Palau',
  'America/Asuncion',
  'Asia/Qatar',
  'Indian/Reunion',
  'Europe/Bucharest',
  'Europe/Belgrade',
  'Europe/Kaliningrad',
  'Europe/Moscow',
  'Europe/Simferopol',
  'Europe/Kirov',
  'Europe/Astrakhan',
  'Europe/Volgograd',
  'Europe/Saratov',
  'Europe/Ulyanovsk',
  'Europe/Samara',
  'Asia/Yekaterinburg',
  'Asia/Omsk',
  'Asia/Novosibirsk',
  'Asia/Barnaul',
  'Asia/Tomsk',
  'Asia/Novokuznetsk',
  'Asia/Krasnoyarsk',
  'Asia/Irkutsk',
  'Asia/Chita',
  'Asia/Yakutsk',
  'Asia/Khandyga',
  'Asia/Vladivostok',
  'Asia/Ust-Nera',
  'Asia/Magadan',
  'Asia/Sakhalin',
  'Asia/Srednekolymsk',
  'Asia/Kamchatka',
  'Asia/Anadyr',
  'Asia/Riyadh',
  'Pacific/Guadalcanal',
  'Indian/Mahe',
  'Africa/Khartoum',
  'Europe/Stockholm',
  'Asia/Singapore',
  'America/Paramaribo',
  'Africa/Juba',
  'Africa/Sao_Tome',
  'America/El_Salvador',
  'Asia/Damascus',
  'America/Grand_Turk',
  'Africa/Ndjamena',
  'Indian/Kerguelen',
  'Asia/Bangkok',
  'Asia/Dushanbe',
  'Pacific/Fakaofo',
  'Asia/Dili',
  'Asia/Ashgabat',
  'Africa/Tunis',
  'Pacific/Tongatapu',
  'Europe/Istanbul',
  'America/Port_of_Spain',
  'Pacific/Funafuti',
  'Asia/Taipei',
  'Europe/Kiev',
  'Europe/Uzhgorod',
  'Europe/Zaporozhye',
  'Pacific/Wake',
  'America/Sitka',
  'America/Metlakatla',
  'America/Yakutat',
  'America/Nome',
  'America/Adak',
  'Pacific/Honolulu',
  'America/Montevideo',
  'Asia/Samarkand',
  'Asia/Tashkent',
  'America/Caracas',
  'Asia/Ho_Chi_Minh',
  'Pacific/Efate',
  'Pacific/Wallis',
  'Pacific/Apia',
  'Africa/Johannesburg'
]
