const initialState = {
  citrusDisplayableClasses: [],
  wooCategories: [],
  wooTags: [],
  classTypes: [],
  selectedClasses: {},
  participants: [],
  prevClass: {},
  wooClass: {},
  citrusClasses: {},
  prevSku: null,
}

const ClassesReducer = (state=initialState, action) => {
  switch (action.type) {
  case 'SET_DISPLAYABLE_CLASSES':
    return {...state, citrusDisplayableClasses: action.citrusDisplayableClasses}
  case 'SET_SELECTED_CLASSES':
    return {...state, selectedClasses: action.selectedClasses}
  case 'SET_WOOCATEGORIES':
    return {...state, wooCategories: action.wooCategories}
  case 'SET_WOOTAGS':
    return {...state, wooTags: action.wooTags}
  case 'SET_CLASSTYPES':
    return {...state, classTypes: action.classTypes}
  case 'SET_PARTICIPANTS':
    return {...state, participants: action.participants}
  case 'SET_PREV_CLASSES':
    return {...state, prevClass: action.prevClass}
  case 'SET_PREV_SKU':
    return {...state, prevSku: action.sku}
  case 'SET_WOO_CLASS':
    return {...state, wooClass: action.payload}
  case 'SET_CLASS':
    return { ...state, citrusClass: action.payload }
  default:
    return state
  }
}
export default ClassesReducer
