import React, {  Fragment , useState } from 'react'
import { Mutation } from 'react-apollo'
import Card from '@material-ui/core/Card'
import Delete from '@material-ui/icons/Delete'
import Add from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'

import TextField from '@material-ui/core/TextField'
import {
  GET_SETTING,
  UPDATE_SETTING,
  CREATE_SETTING,
  DefaultQuery
} from '../graphql'

export const userVariables = {
  type: 'general',
  sub_type: 'settings',
  sub_sub_type: 'users_admin'
}

const UsersAdmin = props => {
  const [selected, setSelected] = useState('Boise')
  return (
    <DefaultQuery
      query={GET_SETTING}
      variables={userVariables}
      render={data => {
        return (
          <>
            <div
              style={{
                display: 'flex',
                alignSelf: 'flex-start'
              }}
            >
              <div
                style={{
                  flex: '0 1 100%',
                  width: '100%',
                  maxHeight: '80vh',
                  overflow: 'auto'
                }}
              >
                <UserCreate
                  render={({ cSetting }) => (
                    <UserEditor
                      data={data}
                      cSetting={cSetting}
                      render={({ AddUser, DeleteUser }) => (
                        <UserCards
                          setSelected={setSelected}
                          AddUser={AddUser}
                          DeleteUser={DeleteUser}
                          selected={selected}
                          style={{ flex: '0 1 50%' }}
                          {...(data.length > 0 ? data[0] : {})}
                        />
                      )}
                    />
                  )}
                />
              </div>
            </div>
          </>
        )
      }}
    />
  )
}
export default UsersAdmin
const UserEditor = props => {
  const { data: [setting = {}] = [{}], cSetting } = props
  const { data: users = [] } = setting

  return (
    <Mutation
      mutation={UPDATE_SETTING}
      update={(cache, { data: { updateSetting } }) => {
        cache.writeQuery({
          query: GET_SETTING,
          variables: userVariables,
          data: { settings: [updateSetting] }
        })
      }}
    >
      {(uSetting, { data: mData }) => {
        const DeleteUser = passedUser => {
          if (window.confirm('Are you sure you want to delete this user?')) {
            uSetting({
              variables: {
                ...setting,
                data: users.filter(l => l !== passedUser).sort()
              }
            })
          }
        }
        const AddUser = passedUser => {
          if (setting.setting_id) {
            uSetting({
              variables: {
                ...setting,
                data: [...users, passedUser].sort()
              }
            })
          } else {
            cSetting({
              variables: {
                ...userVariables,
                data: [passedUser]
              }
            })
          }
        }
        return props.render({ AddUser, DeleteUser })
      }}
    </Mutation>
  )
}

const UserCreate = props => {
  return (
    <Mutation
      mutation={CREATE_SETTING}
      update={(cache, { data: { createSetting } }) => {
        cache.writeQuery({
          query: GET_SETTING,
          variables: userVariables,
          data: { settings: [createSetting] }
        })
      }}
    >
      {(cSetting, { data: mData }) => {
        return props.render({ cSetting })
      }}
    </Mutation>
  )
}

const CardUser = props => (
  <Card
    style={{
      padding: '20px 40px',
      backgroundColor: props.selected ? '#ff9b4b' : '#FFF',
      color: props.selected ? '#FFF' : '#000',
      display: 'flex',
      alignItems: 'center'
    }}
  >
    {props.children}
  </Card>
)

const UserCards = props => {
  const { data = [], setSelected, AddUser, DeleteUser } = props
  const [user, setUser] = useState('')
  return (
    <div>
      <Card
        style={{
          padding: '20px 40px',
          backgroundColor: '#FFF',
          color: '#000'
        }}
      >
        <TextField
          value={user}
          placeholder="Add Admin Email"
          style={{
            width: 'calc(100% - 48px)'
          }}
          onChange={e => {
            setUser(e.target.value)
          }}
        />
        <IconButton
          onClick={e => {
            if (user === '' || data.find(u => u === user))
              return console.log(
                "User can't be blank, or already in the list."
              )
            AddUser(user)
            setUser('')
          }}
        >
          <Add />
        </IconButton>
      </Card>
      {data.map((user, i) => (
        <CardUser
          selected={props.selected === user.User}
          key={`card_user_${i}`}
          setSelected={setSelected}
        >
          <span
            style={{
              width: 'calc(100% - 48px)',
              minWidth: 'calc(100% - 48px)',
              maxWidth: 'calc(100% - 48px)'
            }}
          >
            {user}
          </span>
          <IconButton
            disabled={false}
            onClick={e => {
              DeleteUser(user)
            }}
          >
            <Delete />
          </IconButton>
        </CardUser>
      ))}
    </div>
  )
}
