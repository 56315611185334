import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { ApolloProvider as LegacyApolloProvider } from 'react-apollo'
import { ApolloProvider } from '@apollo/react-common'
import App from './App'
import {unregister} from './registerServiceWorker'
import store from './store'
import theme from './theme'
import { client } from './graphql'
import './index.css'

const createHistory = require("history").createBrowserHistory

export const history = createHistory()

ReactDOM.render(
  <ApolloProvider client={client}>
    <LegacyApolloProvider client={client}>
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <App />
        </MuiThemeProvider>
      </Provider>
    </LegacyApolloProvider>
  </ApolloProvider>,
  document.getElementById('root')
)
unregister()
