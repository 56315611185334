import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ClearIcon from '@material-ui/icons/Clear'
import Person from '@material-ui/icons/Person'
import Settings from '@material-ui/icons/Settings'
import LocalDining from '@material-ui/icons/LocalDining'
import Grain from '@material-ui/icons/Grain'
import School from '@material-ui/icons/School'
import Timer from '@material-ui/icons/Timer'
import { logoutUser } from '../actions'
import { history } from '../Routes'

const headerStyles = theme => ({
  wrapper: {
    alignItems: 'center'
  },
  logo: {
    color: theme.palette.common.white,
    marginRight: 18,
    width: 55
  },
  buttonsWrapper: {
    marginRight: 20,
    marginTop: 2,
    flex: '0 0 auto'
  },
  button: {
    padding: '4px 15px 5px 15px',
    color: theme.palette.common.white,
    background: 'none',
    border: `1px solid rgba(255,255,255,.5)`,
    borderRadius: 19,
    fontFamily: theme.typography.fontFamily,
    marginLeft: 7,
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '1.4em',
    textDecoration: 'none',
    '&:hover': {
      background: 'rgba(255,255,255,.2)'
    },
    '&:active': {
      background: 'rgba(255,255,255,.1)'
    }
  },
  buttonActive: {
    background: 'rgba(255,255,255,1)',
    color: theme.palette.primary[500],
    '&:hover': {
      background: 'rgba(255,255,255,.8)'
    }
  },
  userWrapper: {
    marginLeft: 'auto'
  },
  userButton: {
    color: theme.palette.common.white
  },
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    logo: {
      display: 'none'
    },
    buttonText: {
      display: 'none'
    },
  }
})

export const ArchiveHeader = withStyles(headerStyles)(props => {
  const {
    archive,
    multiClassSave,
    clear,
    classesSelected,
    classes: { buttonsWrapper, wrapper, userButton }
  } = props
  return (
    <AppBar>
      <Toolbar className={wrapper} style={{ background: '#4789d4' }}>
        <div className={buttonsWrapper} style={{ marginRight: 'auto' }}>
          <Button className={userButton} onClick={archive}>
            Archive
          </Button>
          {classesSelected}
        </div>
        <Button className={userButton} onClick={clear}>
          <ClearIcon />
        </Button>
      </Toolbar>
    </AppBar>
  )
})

class Header extends Component {
  navigateTo = location => {
    history.push(location)
  };

  render()
  {
    const style = {fontSize: ".7rem"}
    const {
      isAdmin,
      auth_level,
      isSelected,
      classesSelected,
      multiClassSave,
      classes: { logo, buttonsWrapper, wrapper, buttonText }
    } = this.props
    return (
      <AppBar>
        {isSelected ? (
          <ArchiveHeader {...this.props} multiClassSave={multiClassSave} />
        ) : (
          <Toolbar className={wrapper}>
            <object data={`${process.env.PUBLIC_URL  }/logo.svg`} className={logo} type="image/svg+xml">Citrus Pear</object>
            <div className={buttonsWrapper}>
              {auth_level <= 40 ? (
                <HeaderButton to="/classes"><School style={style} /> <span className={buttonText}>Classes</span></HeaderButton>
              ) : null}
              {auth_level <= 80 ? (
                <HeaderButton to="/timecard"><Timer style={style} /> <span className={buttonText}>Card</span></HeaderButton>
              ) : null}
              {isAdmin ? (
                <HeaderButton to="/recipes"><LocalDining style={style} /> <span className={buttonText}>Recipes</span></HeaderButton>
              ) : null}
              {isAdmin ? (
                <HeaderButton to="/ingredients"><Grain style={style} /> <span className={buttonText}>Ingredients</span></HeaderButton>
              ) : null}
              {isAdmin ? (
                <HeaderButton to="/settings"><Settings style={style} /> <span className={buttonText}>Settings</span></HeaderButton>
              ) : null}
              {isAdmin ? (
                <HeaderButton to="/users"><Person style={style} /> <span className={buttonText}>Users</span></HeaderButton>
              ) : null}
              {/* <HeaderButton to='/users'>Users</HeaderButton> */}
            </div>
            <User />
          </Toolbar>
        )}
      </AppBar>
    )
  }
}
export default withStyles(headerStyles)(Header)

class HeaderButton extends Component {
  render() {
    const {
      children,
      to,
      classes: { button, buttonActive }
    } = this.props
    return (
      <NavLink to={to} className={button} activeClassName={buttonActive} exact>
        {children}
      </NavLink>
    )
  }
}
HeaderButton = withStyles(headerStyles)(HeaderButton)

class User extends Component {
  constructor() {
    super()
    this.state = {
      anchorEl: null,
      menuOpen: false
    }
  }

  openMenu = e => {
    this.setState({
      anchorEl: e.target,
      menuOpen: true
    })
  };

  logoutUser = () => {
    this.props.logoutUser()
    this.setState({
      menuOpen: false
    })
  };

  closeMenu = () => {
    this.setState({
      menuOpen: false
    })
  };

  redirectToLogin = () => {
    history.push('/login')
  };

  render() {
    const {
      classes: { userWrapper, userButton },
      loggedIn,
      user
    } = this.props
    const { anchorEl, menuOpen } = this.state
    return (
      <div className={userWrapper}>
        {loggedIn ? (
          <Button className={userButton} onClick={this.openMenu}>
            <Person />
          </Button>
        ) : (
          <Button className={userButton} onClick={this.redirectToLogin}>
            Login
          </Button>
        )}
        <Menu
          anchorEl={anchorEl}
          open={menuOpen}
          // onRequestClose={this.closeMenu}
        >
          {loggedIn && <MenuItem onClick={this.closeMenu}>{user.email}</MenuItem>}
          <MenuItem onClick={this.logoutUser}>Logout</MenuItem>
        </Menu>
      </div>
    )
  }
}
User = withStyles(headerStyles)(
  connect(
    state => ({
      loggedIn: state.auth.loggedIn,
      user: state.auth.user,
      isAdmin: state.auth.permisions.isAdmin
    }),
    { logoutUser }
  )(User)
)
