import React, { useState } from 'react'
import { gql } from "apollo-boost"
import { useQuery, useMutation } from '@apollo/react-hooks'
import { withStyles } from '@material-ui/core/styles'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  TextField,
  Switch,
  FormControlLabel
} from '@material-ui/core/'

const GET_ROLE_BY_ID = gql`
query gRole($role_id: Int!){
  role(role_id: $role_id) {
    id
    role_id
    name
    active
  }
}
`

const UPDATE_ROLE = gql`
mutation uRole(
  $role_id: String!,
  $name: String!,
  $active: Boolean!
) {
    updateRole(
      role_id: $role_id,
      name: $name,
      active: $active
    ) {
      id
      role_id
      name
      active
    }
  }
`

const CREATE_ROLE = gql`
mutation cRole(
  $name: String!
  $active: Boolean!
) {
    createRole(
      name: $name
      active: $active
    ) {
      id
      role_id
      name
      active
    }
  }
`

const getDefaults = e =>
{
  return {
    name: "",
    active: true
  }
}

const EditorStylesheet = theme => ({
  input: {
    width: '100%'
  },
  rowMargin: {
    marginBottom: 5
  },
  smallButton: {
    minWidth: '55px',
    padding: '8px 10px'
  },
})

function RolesEditor(props)
{
  const [roleCache, setRoleCache] = useState(getDefaults())
  
  const { role_id } = props.match.params
  const {
    open,
    onClose,
    updateCache,
    classes: { input, rowMargin, midTitle, smallButton }
  } = props
  const isNew = (role_id === "new")
  const { data: { role = {} } = {} } = useQuery(GET_ROLE_BY_ID, {
    variables: {
      role_id: isNew ? -1 : role_id
    },
    onCompleted: (data) =>
    {
      console.log(data)
      const { role = {} } = data
      if (role && role.name) {
        setRoleCache(role)
      }
    }
  })

  const {
    name,
    id,
    active
  } = roleCache

  const [updateRole, { data: updateRoleRtn = {} }] = useMutation(UPDATE_ROLE)
  const [createRole, { data: createRoleRtn = {} }] = useMutation(CREATE_ROLE, {
    update: (cache, { data }) =>
    {
      const { createRole } = data
      console.log({ createRole })
      updateCache(cache, createRole)
    }
  }
  )

  const saveRole = e =>
  {
    console.log({roleCache, isNew})
    if (isNew) {
      const { role_id, ...theRest } = roleCache
      createRole({ variables: theRest })
    } else {
      updateRole({ variables: roleCache })
    }
    props.history.push("/roles")
  }
  const set = (prop, e) =>
  {
    setRoleCache({
      ...roleCache,
      [prop]: e.target.value
    })
  }
  
  return (
    <Dialog
      open={open}
      style={{ visibility: open ? 'display' : 'hidden' }}
      maxWidth="md"
      fullWidth
    >
      <>
        <DialogTitle>Editing Role: {name}</DialogTitle>
        <DialogContent>
          <Grid container className={rowMargin} spacing={3}>

            <Grid item sm={6} xs={12}>
              <TextField
                type="text"
                value={name}
                className={input}
                label="Name"
                onChange={e => {
                  set('name', e)
                }}
              />
            </Grid>

            <Grid item sm={3} xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!active}
                    onChange={e =>
                      set('active', {
                        target: {
                          value: !active,
                          checked: !active
                        }
                      })
                    }
                    color="primary"
                  />
                }
                label="Active"
              />
            </Grid>
            
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={saveRole}
          >
            Save Role
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

RolesEditor = withStyles(EditorStylesheet)(RolesEditor)
export default RolesEditor