import moment from 'moment'
import * as convert from 'convert-units'
// import { PreviousMap } from '../node_modules/postcss';
import orderBy from 'lodash/orderBy'
import { subUnitsObject, categoriesObject } from './data'
import store from './store'
import { baseNodeUrl, getLocations as getLocationsApi } from './api'

export const getLocations = e => store.getState().app.locations
export const getIngredients = e => store.getState().ingredients
export const getRecipes = e => store.getState().recipes.recipes
export const getIngredientsFlat = e => getIngredients().reduce((p, c, i) => ({ ...p, [c.id]: { ...c } }), {})

export const groceryExport = Class => {
  const totalSets =
    ((Class && Class.small ? parseInt(Class.small, 10) : 0) / 2) +
    (Class && Class.regular ? parseInt(Class.regular, 10) : 0)
  const classIngredients = reduceIngredients(getIngredientAmountsFromClass(Class, false))
  const classJson = formatIngredientsForCSV(classIngredients, totalSets)
  const locations = getLocations()
  const classLocation = locations.find(l => String(l.Category_ID) === Class.location).Location

  fetch(`${baseNodeUrl}/api/convertJSONtoCSV`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      idToken: localStorage.getItem('firebase.auth.idToken')
    },
    body: JSON.stringify({
      data: classJson,
      fields: [
        'Category',
        'Allergy',
        'Ingredient',
        'Description',
        'Total',
        'Unit',
        'AmountEachSet',
        'ContainerQty',
        'ContainerSize',
        'ContainerUnits'
      ],
      name: classLocation
    })
  }).then(res => {
    res.text().then(csv => {
      const blobUrl = window.URL.createObjectURL(
        new Blob([csv], { type: 'text/csv' })
      )
      const a = document.createElement('a')
      a.download = `${classLocation} Grocery - ${moment(Class.date).format(
        'MMMM Do, Y'
      )}.csv`
      a.href = blobUrl
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    })
  })
}

export const meatExport = (classObj, class_events) => {
  const Class =
    typeof classObj !== 'string'
      ? classObj
      : class_events.find(c => c.id === classObj)
  // console.log(Class, store.getState().classes, classId)
  const locations = getLocations()
  const classJson = getMeatAmountsFromClass(Class)
  const classLocation = locations.find(l => String(l.Category_ID) === Class.location)
    .Location

  fetch(`${baseNodeUrl}/api/convertJSONtoCSV`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      idToken: localStorage.getItem('firebase.auth.idToken')
    },
    body: JSON.stringify({
      data: classJson,
      fields: [
        'Recipe',
        'Ingredient',
        'RegularAmount',
        'RegularPortion',
        'SmallAmount',
        'SmallPortion',
        'Category'
      ],
      name: classLocation
    })
  }).then(res => {
    res.text().then(csv => {
      const blobUrl = window.URL.createObjectURL(
        new Blob([csv], { type: 'text/csv' })
      )
      const a = document.createElement('a')
      a.download = `${classLocation} Meat - ${moment(Class.date).format(
        'MMMM Do, Y'
      )}.csv`
      a.href = blobUrl
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    })
  })
}

export const getIngredientAmountsFromClass = (Class, includeMeat) => {

  if (!Class) {
    console.error(Class, includeMeat)
  }
  if (!Class.recipes) {
    console.error("Class.recipes is not defined.", JSON.stringify(Class))
  }
  const recipes = getRecipes()
  const ingredients = getIngredients()
  let multipliedIngredients = {}

  // console.log({Class, selections: Class.selections_count, recipes})
  // console.log({'get ingredient': Class, 'recipes': recipes, 'ingredients': ingredients})

  let ingredientCounts = new Map()

  Class.selections_count.forEach((selection) => {
    const foundRecipe = recipes.find(r => r.db_id === `${selection.recipe_id}`)
    if (!foundRecipe) return

    let small_count = 0
    let medium_count = 0
    let large_count = 0

    for (const [key, value] of Object.entries(selection.selections)) {
      if(key === 'small') small_count += Object.values(value).reduce((previous, current) => {return previous + current})
      if(key === 'medium') medium_count += Object.values(value).reduce((previous, current) => {return previous + current})
      if(key === 'large') large_count += Object.values(value).reduce((previous, current) => {return previous + current})
    }

    let selectionCount = (small_count / 4) + (medium_count / 2) + (large_count)

    // console.log({selection, selectionCount})

    foundRecipe.ingredients.forEach((ingredient) => {
      const foundIngredient = ingredients.find(i => i.id === ingredient.name)
      if (foundIngredient?.category === 'meat' && !includeMeat) return

      let ingredientId = ingredient.name

      const ingAmountString = ingredient.amount.trim().split(' ')
      let ingAmount

      if (ingAmountString.length > 1) {
        const split = ingAmountString[1].trim().split('/')
        ingAmount =
          split.length > 1
            ? parseFloat(ingAmountString[0]) +
            parseFloat(split[0]) / parseFloat(split[1])
            : parseFloat(ingAmountString[0]) +
            parseFloat(ingAmountString[1])
      }
      else {
        const split = ingAmountString[0].trim().split('/')
        ingAmount =
          split.length > 1
            ? parseFloat(split[0]) / parseFloat(split[1])
            : parseFloat(ingAmountString[0])
      }

      // console.log({selectionCount, ingAmount, ingredient, ingredientId})

      if(!ingredientCounts.get(ingredientId)) {
        ingredientCounts.set(ingredientId, {[ingredient.subUnit]: (selectionCount * ingAmount)})
      }
      else {
        let current = ingredientCounts.get(ingredientId)

        if(!current[ingredient.subUnit]) {
          ingredientCounts.set(ingredientId, {...current, [ingredient.subUnit]: (selectionCount * ingAmount)})
        }
        else {
          ingredientCounts.set(ingredientId, {...current, [ingredient.subUnit]: (current[ingredient.subUnit] + (selectionCount * ingAmount))})
        }
      }
    })
  })

  console.log({ingredientCounts})

  ingredientCounts.forEach((value, key) => {
    multipliedIngredients = {...multipliedIngredients, [key]: value}
  })

  console.log('multipliedIngredients: ', multipliedIngredients)
  return multipliedIngredients
}

export const reduceIngredients = (multipliedIngredients) =>
{

  const ingredientsFlat = getIngredientsFlat()
  // take those amounts and accumulate them all together
  const reducedIngredients = Object.keys(multipliedIngredients).reduce(
    (prev, k, i) => {
      const ingUnitAmounts = multipliedIngredients[k]

      // take the units and amounts of each ingredient and sort them
      return {
        ...prev,
        [k]: Object.keys(ingUnitAmounts)
          .sort((a, b) => {
            if (a < b) return -1
            if (a > b) return 1
            return 0
          })
          .reduce((p, key, i) => {
            // convert all units into the 1st unit, and sum together
            const convertToKey = key

            const conversionKey = convertSomeUnits(convertToKey)

            const prevKeys = Object.keys(p)
            let currObj = { ...ingUnitAmounts }

            if (prevKeys.length > 0 && conversionKey !== 'count') {
              // console.log(p, prevKeys[0], ingUnitAmounts[key], conversionKey, ingUnitAmounts)
              if (prevKeys.length > 1)
                console.log('You reduced to 2 keys instead of just one.')
              let currConverted = 0.0
              if (prevKeys[0] !== 'count') {
                currConverted = convert(ingUnitAmounts[key])
                  .from(conversionKey)
                  .to(prevKeys[0])
              }
              // console.log(
              //   `from: ${
              //     currObj[key]
              //   } ${conversionKey}s, to: ${currConverted} ${prevKeys[0]}`
              // );

              currObj = { [prevKeys[0]]: currConverted + p[prevKeys[0]] }
              // console.log(currObj);
              return currObj
              // const currBest = convert(currObj[currKey]).from(conversionKey).toBest()
              // // console.log(currObj, currKey, currBest)
              // return { [currBest.unit]: currBest.val }
            } 
            // get any other "count" items and add them to the current one, cross your fingers they made them the same
            const prevAmount = prevKeys.length > 0 ? currObj[prevKeys[0]] : 0

            // use the updated key, or the original if it is a "count" unit
            const aggregateKey =
                conversionKey !== 'count' ? conversionKey : key
            return {
              [aggregateKey]: (currObj[key] ? currObj[key] : 0) + prevAmount
            }
            
          }, {})
      }
    },
    {}
  )

  console.log('reducedIngredients', reducedIngredients)

  // convert each ingredient to wanted units
  const convertedUnits = Object.keys(reducedIngredients).reduce((p, k, i) => {
    const ingredientAmount = reducedIngredients[k]
    if (k === 'B1JNHoKKM') console.log('B1JNHoKKM', ingredientAmount)
    if (k === 'BkFhCctKG') console.log('BkFhCctKG', ingredientAmount)

    // convert each unit to wanted units
    const ingredientRtn = Object.keys(ingredientAmount).reduce(
      (pre, key, index) => {
        const ingAmount = ingredientAmount[key]

        // console.log('ingredientsFlat[k]', ingredientsFlat[k]);

        // change units if it was specified:
        if (
          ingredientsFlat[k] &&
          ingredientsFlat[k].purchaseContainerUnits &&
          ingredientsFlat[k].purchaseContainerUnits !== key
        ) {
          let convertToKey = ingredientsFlat[k].purchaseContainerUnits
          let fromKey

          try {
            convertToKey = convert().describe(convertToKey)
            convertToKey = ingredientsFlat[k].purchaseContainerUnits
          } catch (e) {
            convertToKey = convertSomeUnits(
              ingredientsFlat[k].purchaseContainerUnits
            )
          }

          try {
            fromKey = convert().describe(key)
            fromKey = key
          } catch (e) {
            fromKey = convertSomeUnits(key)
          }

          // console.log('fromKey, convertKey', fromKey, convertToKey);

          if (fromKey === 'count' || convertToKey === 'count')
            return { [key]: ingAmount }
          if (fromKey && convertToKey) {
            const convertedAmount = convert(ingAmount)
              .from(fromKey)
              .to(convertToKey)
            return { [convertToKey]: convertedAmount || 0 }
          }
        }

        // if not found send back the current units
        return { ...pre, [key]: ingAmount }
      },
      {}
    )

    if (k === 'B1JNHoKKM') console.log('B1JNHoKKM', ingredientRtn)

    // return the new ingredient
    return { ...p, [k]: ingredientRtn }
  }, {})

  console.log('convertedUnits', convertedUnits)

  // get the full ingredient information, change units and amounts and return
  const rtnIngredients = Object.keys(convertedUnits).reduce((p, k, i) => {
    const ingredientAmount = convertedUnits[k]

    // get the unit key and value
    const ingredientRtn = Object.keys(ingredientAmount).reduce(
      (pre, key, index) => {
        const amount = ingredientAmount[key]

        // update the object and send it back
        return { ...pre, subUnits: key, amount }
      },
      {}
    )

    // return the new ingredient
    return {
      ...p,
      [k]: {
        ...ingredientsFlat[k],
        ...ingredientRtn
      }
    }
  }, {})

  return rtnIngredients
}

const convertSomeUnits = key => {
  let conversionKey = ''
  // console.log("key: ", key)
  switch (key) {
  case 'Tbsp':
  case 'tbsp':
    conversionKey = 'Tbs'
    break
  case 'lbs':
    conversionKey = 'lb'
    break
  case 'floz':
    conversionKey = 'fl-oz'
    break
  case 'gallon':
    conversionKey = 'gal'
    break
  case 'quart':
    conversionKey = 'qt'
    break

    // if it doesn't need to be converted, return it
  case 'each':
  case 'jar':
  case 'can':
  case 'box':
  case 'dozen':
  case 'item':
    return 'count'
  default:
    conversionKey = key
    break
  }
  return conversionKey
}

export const getMeatAmountsFromClass = Class => {
  const recipes = getRecipes()
  const ingredients = getIngredients()
  let newIngredients = []

  // console.log(Class, Class.selections_count, recipes)

  Class.selections_count.forEach((selection) => {
    const foundRecipe = recipes.find(r => r.db_id === `${selection.recipe_id}`)
    if (!foundRecipe) return

    let meat

    let small_count = 0
    let medium_count = 0
    let large_count = 0

    for (const [key, value] of Object.entries(selection.selections)) {
      if(key === 'small') small_count += Object.values(value).reduce((previous, current) => {return previous + current})
      if(key === 'medium') medium_count += Object.values(value).reduce((previous, current) => {return previous + current})
      if(key === 'large') large_count += Object.values(value).reduce((previous, current) => {return previous + current})
    }

    // console.log({small_count, medium_count, large_count})

    const amountTypedOrDefault = medium_count
    const amountDblTypedOrDefault = large_count

    for (let i = 0; i < foundRecipe.ingredients.length; i++) {
      const ingredient = foundRecipe.ingredients[i]

      const foundIngredient = ingredients.find(i => i.id === ingredient.name)

      const ing = { ...foundIngredient }
      const split = ingredient.amount.split('/')
      const ingAmount =
        split.length > 1
          ? parseFloat(split[0], 10) / parseFloat(split[1], 10)
          : parseFloat(ingredient.amount, 10)
      meat = {
        id: '',
        Recipe: foundRecipe.name,
        Ingredient: '(No Meat)',
        RegularPortion: `${amountDblTypedOrDefault} portions`,
        SmallPortion: `${amountTypedOrDefault} portions`,
        Category: '',
        Allergy: ''
      }
      if (ing && ing.category === 'meat') {
        // if (recipe.name === 'SkUYT2FiM')
        //   console.log("getIngredientAmountsFromClass foreach: ", ingAmount, (ing ? ing.name : ing), recipe,  parseFloat(recipe.amount), recipe.amount, recipe.amountDbl);

        const {id} = ing
        const {subUnit} = ingredient

        // if (recipe.name === 'SkUYT2FiM')
        //   console.log(`${JSON.stringify(ing)} ${currentSubUnit} ${subUnit} ${JSON.stringify(currentIngredient)}`)

        meat = {
          ...meat,
          id,
          Ingredient: foundIngredient.name,
          RegularAmount: amountDblTypedOrDefault,
          RegularPortion: `${ingAmount} ${subUnit}`,
          SmallAmount: amountTypedOrDefault,
          SmallPortion: `${ingAmount / 2} ${subUnit}`,
          Description: foundIngredient.description
            ? foundIngredient.description
            : '',
          Category: ing.category
        }

        // if (recipe.name === 'SkUYT2FiM')
        //   console.log(`${ing.name} largeQty:${largeQty} normalQty:${normalQty} newAmount:${(newAmount)} ingAmount:${ingAmount}`)
        break
      }
    }
    newIngredients = [...newIngredients, { ...meat }]
  })

  // console.log(reducedIngredients);
  return orderBy(newIngredients, ['Ingredient'])
}

export const formatIngredientsForCSV = (ingredients, totalMeals) => {
  const rows = []
  Object.keys(ingredients).forEach(ingId => {
    const ingredient = ingredients[ingId]
    const unitId = ingredient.subUnits
    const cat = subUnitsObject[ingredient.units]
    let subUnit
    subUnit =
      cat && Array.isArray(cat) && cat.length > 0
        ? cat.find(t => t.value === ingredient.subUnits)
        : undefined

    subUnit =
      subUnit === undefined && cat && typeof cat === 'object'
        ? cat[ingredient.subUnits]
        : undefined

    if (subUnit === undefined)
    // console.log('All the things: ', subUnit, cat, subUnitsObject, ingredient);

      subUnit = subUnit || { value: ingredient.subUnit, label: ingredient.subUnit }

    // console.log("All the things: ", subUnit, cat, subUnitsObject, ingredient);

    // console.log("Some of the things: ", cat,
    //   Array.isArray(cat),
    //   cat.length > 0 );
    //   console.log(cat.find(t => t.value === ingredient.subUnits))
    if (ingredient && unitId && unitId !== 'undefined') {
      const Unit = getBetterUnit(unitId, subUnit.label)
      const savedContainerQty =
        ingredient.amount /
        (ingredient.purchaseContainerSize
          ? ingredient.purchaseContainerSize
          : 1)
      const ContainerQty = Math.ceil(
        savedContainerQty || ingredient.amount
      )
      // if (!ContainerQty) console.log('ContainerQty', ContainerQty);
      rows.push({
        Ingredient: ingredient.name,
        Unit,
        Total: ingredient.amount,
        Category: categoriesObject[ingredient.category].label,
        Allergy: ingredient.isAllergy ? 'Yes' : '',
        Description: ingredient.description ? ingredient.description : '',
        AmountEachSet: ingredient.amount / totalMeals,
        ContainerQty,
        ContainerSize: ingredient.purchaseContainerSize
          ? ingredient.purchaseContainerSize
          : 1,
        ContainerUnits: ingredient.purchaseContainerUnits
          ? ingredient.purchaseContainerUnits
          : Unit
      })
    }

    // Object.keys(units).forEach(unitId => {

    // })
  })

  console.log(rows.length)
  return orderBy(rows, ['Category', 'Ingredient'])
}

const getBetterUnit = (unitId, unitName) => {
  let betterUnit = {}
  try {
    betterUnit = convert().describe(unitId || unitName)
  } catch (e) {
    // console.log('not found:', unitName);
  }
  const Unit = betterUnit.plural ? betterUnit.plural : unitName
  return Unit
}

// Class Participants CSV
export const startParticipantOutput = (Class, participantList) => {
  const classParticipants = participantList
  let classJson
  classJson = formatParticipantsForCSV(classParticipants, Class.recipes)
  classJson = classJson
    ? [...classJson, { Note: Class.notes }]
    : [{ Note: Class.notes }]

  const classLocation = Class.location.name

  fetch(`${baseNodeUrl}/api/convertJSONtoCSV`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      idToken: localStorage.getItem('firebase.auth.idToken')
    },
    body: JSON.stringify({
      data: classJson,
      fields: [
        'Note',
        'Ticket',
        'Customer',
        'Email',
        'Quantity',
        'Phone',
        'LabelPortionSize',
        'PortionSize',
        'PortionQty',
        'Status',
        'Allergy',
        'Notes',
        'meal_1_name',
        'meal_1_qty',
        'meal_2_name',
        'meal_2_qty',
        'meal_3_name',
        'meal_3_qty',
        'meal_4_name',
        'meal_4_qty',
        'meal_5_name',
        'meal_5_qty',
        'meal_6_name',
        'meal_6_qty',
        'meal_7_name',
        'meal_7_qty',
        'meal_8_name',
        'meal_8_qty',
        'meal_9_name',
        'meal_9_qty',
        'meal_10_name',
        'meal_10_qty',
        'meal_11_name',
        'meal_11_qty',
        'meal_12_name',
        'meal_12_qty'
      ],
      name: classLocation
    })
  }).then(res => {
    res.text().then(csv => {
      const blobUrl = window.URL.createObjectURL(
        new Blob([csv], { type: 'text/csv' })
      )
      const a = document.createElement('a')
      a.download = `${classLocation} Class List - ${moment(Class.date).format(
        'MMMM Do, Y'
      )}.csv`
      a.href = blobUrl
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    })
  })
}

const formatParticipantsForCSV = (registrations, recipes) => {
  const rows = []
  registrations.forEach(registration => {
    let default_select_size = registration.portion_quantity.value / 10

    if (registration.selections.length > 0) {
      let selected = recipes.map((recipe) => {
        let selection = registration.selections.find((selection) => { return selection.recipe_id === recipe.id })
        return { name: recipe.name, quantity: selection ? selection.quantity : 0 }
      })

      rows.push({
        Ticket: registration.order_id,
        Customer: registration.participant.name,
        Email: registration.participant.email,
        Quantity: 1,
        Type: '',
        Phone: registration.participant.phone ? registration.participant.phone : '',
        LabelPortionSize: registration.portion_size.description ? registration.portion_size.description : '',
        PortionSize: registration.portion_size.name ? registration.portion_size.key : '',
        PortionQty: registration.portion_quantity.value ? registration.portion_quantity.value : '',
        Status: registration.status.name ? registration.status.name : '',
        StatusOrder:
          registration.status.key === 'processing' ||
          registration.status.key === 'completed'
            ? 'a'
            : 'z',
        Allergy: registration.user && registration.user.allergies.length > 0 ? registration.user.allergies.map((allergy) => {return allergy.name}).join(',') : '',
        Refferal: '',
        Notes: registration.instructions ?? '',
        meal_1_name: selected[0].name,
        meal_1_qty: selected[0].quantity,
        meal_2_name: selected[1].name,
        meal_2_qty: selected[1].quantity,
        meal_3_name: selected[2].name,
        meal_3_qty: selected[2].quantity,
        meal_4_name: selected[3].name,
        meal_4_qty: selected[3].quantity,
        meal_5_name: selected[4].name,
        meal_5_qty: selected[4].quantity,
        meal_6_name: selected[5].name,
        meal_6_qty: selected[5].quantity,
        meal_7_name: selected[6].name,
        meal_7_qty: selected[6].quantity,
        meal_8_name: selected[7].name,
        meal_8_qty: selected[7].quantity,
        meal_9_name: selected[8].name,
        meal_9_qty: selected[8].quantity,
        meal_10_name: selected[9].name,
        meal_10_qty: selected[9].quantity,
        meal_11_name: selected[10] ? selected[10].name : 'na',
        meal_11_qty: selected[10] ? selected[10].quantity : 0,
        meal_12_name: selected[11] ? selected[11].name : 'na',
        meal_12_qty: selected[11] ? selected[11].quantity : 0,
      })
    } else {
      rows.push({
        Ticket: registration.order_id,
        Customer: registration.participant.name,
        Email: registration.participant.email,
        Quantity: 1,
        Type: '',
        Phone: registration.participant.phone ? registration.participant.phone : '',
        LabelPortionSize: registration.portion_size.description ? registration.portion_size.description : '',
        PortionSize: registration.portion_size.name ? registration.portion_size.key : '',
        PortionQty: registration.portion_quantity.value ? registration.portion_quantity.value : '',
        Status: registration.status.name ? registration.status.name : '',
        StatusOrder:
          registration.status.key === 'processing' ||
          registration.status.key === 'completed'
            ? 'a'
            : 'z',
        Allergy: registration.user && registration.user.allergies.length > 0 ? registration.user.allergies.map((allergy) => {return allergy.name}).join(',') : '',
        Refferal: '',
        Notes: registration.instructions ?? '',
        meal_1_name: recipes[0].name,
        meal_1_qty: default_select_size,
        meal_2_name: recipes[1].name,
        meal_2_qty: default_select_size,
        meal_3_name: recipes[2].name,
        meal_3_qty: default_select_size,
        meal_4_name: recipes[3].name,
        meal_4_qty: default_select_size,
        meal_5_name: recipes[4].name,
        meal_5_qty: default_select_size,
        meal_6_name: recipes[5].name,
        meal_6_qty: default_select_size,
        meal_7_name: recipes[6].name,
        meal_7_qty: default_select_size,
        meal_8_name: recipes[7].name,
        meal_8_qty: default_select_size,
        meal_9_name: recipes[8].name,
        meal_9_qty: default_select_size,
        meal_10_name: recipes[9].name,
        meal_10_qty: default_select_size,
        meal_11_name: 'na',
        meal_11_qty: 0,
        meal_12_name: 'na',
        meal_12_qty: 0,
      })
    }
  })
  return orderBy(
    rows,
    ['Quantity', 'StatusOrder', 'Customer'],
    ['desc', 'asc', 'asc']
  )
}

const formatParticipantsWithoutSelectionsForCSV = (registrations, recipes) => {
  const rows = []
  registrations.forEach(registration => {
    let default_select_size = registration.portion_quantity.value / 10

    rows.push({
      Ticket: registration.order_id,
      Customer: registration.participant.name,
      Email: registration.participant.email,
      Quantity: 1,
      Type: '',
      Phone: registration.participant.phone ? registration.participant.phone : '',
      PortionSize: registration.portion_size.name ? registration.portion_size.key : '',
      PortionQty: registration.portion_quantity.value ? registration.portion_quantity.value : '',
      Status: registration.status.name ? registration.status.name : '',
      StatusOrder:
        registration.status.key === 'processing' ||
        registration.status.key === 'completed'
          ? 'a'
          : 'z',
      Allergy: registration.user && registration.user.allergies.length > 0 ? registration.user.allergies.map((allergy) => {return allergy.name}).join(',') : '',
      Refferal: '',
      Notes: registration.instructions ?? '',
      meal_1_name: recipes[0].name,
      meal_1_qty: default_select_size,
      meal_2_name: recipes[1].name,
      meal_2_qty: default_select_size,
      meal_3_name: recipes[2].name,
      meal_3_qty: default_select_size,
      meal_4_name: recipes[3].name,
      meal_4_qty: default_select_size,
      meal_5_name: recipes[4].name,
      meal_5_qty: default_select_size,
      meal_6_name: recipes[5].name,
      meal_6_qty: default_select_size,
      meal_7_name: recipes[6].name,
      meal_7_qty: default_select_size,
      meal_8_name: recipes[7].name,
      meal_8_qty: default_select_size,
      meal_9_name: recipes[8].name,
      meal_9_qty: default_select_size,
      meal_10_name: recipes[9].name,
      meal_10_qty: default_select_size,
      meal_11_name: 'na',
      meal_11_qty: 0,
      meal_12_name: 'na',
      meal_12_qty: 0,
    })
  })
  return orderBy(
    rows,
    ['Quantity', 'StatusOrder', 'Customer'],
    ['desc', 'asc', 'asc']
  )
}

export const getEtrog = (Class, classParticipants) => {
  return new Promise((res, rej) => {
    Promise.all([getLocationsApi()])
      .then((values) => {
        console.log(values[0].data)

        let small_count = 0
        let medium_count = 0
        let large_count = 0

        for (const [key, value] of Object.entries(Class.registration_breakdown)) {
          if(key === 'small') {for (const [small_key, small_value] of Object.entries(value)) { small_count += small_value}}
          if(key === 'medium') {for (const [medium_key, medium_value] of Object.entries(value)) { medium_count += medium_value}}
          if(key === 'large') {for (const [large_key, large_value] of Object.entries(value)) { large_count += large_value}}
        }

        const totalSets = Class.registration_breakdown.total
        Class.small = medium_count
        Class.regular = large_count

        // format class object as an array
        const { recipes: ripe, newRecipes, ...noRecipes } = Class

        // console.log({'Class': Class, 'classParticipants': classParticipants})

        let locations = values[0].data
        const location = locations.find(l => parseInt(l.id, 10) === Class.location.id)

        const classIngredients = reduceIngredients(getIngredientAmountsFromClass(Class, false))
        const groceries = formatIngredientsForCSV(classIngredients, totalSets)
        const participants = formatParticipantsForCSV(classParticipants, Class.recipes)
        const meats = getMeatAmountsFromClass(Class)

        console.log({ classIngredients, groceries, locations, participants, meats, location })

        const classeArray = Object.keys(noRecipes).map(key => ({
          key,
          // value: JSON.stringify(noRecipes[key])
          value:
            typeof noRecipes[key] === 'object'
              ? JSON.stringify(noRecipes[key])
              : noRecipes[key]
        }))

        // TODO: fix for when locations go live
        const locationArray = Object.keys(location).map(key => ({
          key,
          // value: JSON.stringify(noRecipes[key])
          value:
            typeof location[key] === 'object'
              ? JSON.stringify(location[key])
              : location[key]
        }))

        fetch(`${baseNodeUrl}/api/etrog`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            idToken: localStorage.getItem('firebase.auth.idToken')
          },
          body: JSON.stringify({
            participants: {
              order: [
                'Ticket',
                'Customer',
                'Email',
                'Quantity',
                'Phone',
                'LabelPortionSize',
                'PortionSize',
                'PortionQty',
                'Status',
                'Allergy',
                'Notes',
                'meal_1_name',
                'meal_1_qty',
                'meal_2_name',
                'meal_2_qty',
                'meal_3_name',
                'meal_3_qty',
                'meal_4_name',
                'meal_4_qty',
                'meal_5_name',
                'meal_5_qty',
                'meal_6_name',
                'meal_6_qty',
                'meal_7_name',
                'meal_7_qty',
                'meal_8_name',
                'meal_8_qty',
                'meal_9_name',
                'meal_9_qty',
                'meal_10_name',
                'meal_10_qty',
                'meal_11_name',
                'meal_11_qty',
                'meal_12_name',
                'meal_12_qty'
              ],
              data: participants,
              range: 'Attendees!A1:AI40'
            },
            meats: {
              order: [
                'Recipe',
                'Ingredient',
                'RegularPortion',
                'SmallPortion',
                'RegularAmount',
                'SmallAmount',
                'Description'
              ],
              data: meats,
              range: 'Meat!A2:G40'
            },
            groceries: {
              order: [
                'Category',
                'Ingredient',
                'ContainerQty',
                'Description',
                'ContainerUnits',
                'ContainerSize',
                'Total',
                'Unit',
                'Allergy',
                'AmountEachSet'
              ],
              data: groceries,
              range: 'Grocery!A3:J100'
            },
            classe: {
              order: ['key', 'value'],
              data: classeArray,
              range: 'Class!A1:B100'
            },
            location: {
              order: ['key', 'value'],
              data: locationArray,
              range: 'Location!A1:B100'
            },
            // templateSpreadsheetId: '1Ue8-6dhB4U4ZQtNfwkQyrTGODKNHraFDjAJTcDnA9lU',
            templateSpreadsheetId: '1Uz1yaOOCcW49voL7UOEp9I9suYd6qSA-UOB8kYp_XSs',
            fileName: `${Class.sku} ${location.name} Etrog`,
            email: [location.lead.email, location.regional.email],
            parents: [location.google_drive_folder]
          })
        })
          .then(response => response.json())
          .then(data => {
            console.log(data)

            const { spreadsheetId } = data
            res({ ...Class, spreadsheet_id: spreadsheetId })
          })
          .catch(e => {
            console.error(e)
            rej(e)
          })
      })
      .catch((error) => {console.error(error)})
  })
}
