import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Redirect } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import {
  Table,
  TableBody,
  TableCell as TableCellOriginal,
  TableHead,
  TableRow
} from '@material-ui/core/'
import EditIcon from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import Close from '@material-ui/icons/Close'
import Save from '@material-ui/icons/Save'
import SentimentDissatisfied from '@material-ui/icons/SentimentDissatisfied'
import Grid from '@material-ui/core/Grid'
import orderBy from 'lodash/orderBy'
import debounce from 'lodash.debounce'
import {
  units as unitOptions,
  subUnits as subUnitsOptions,
  categories as categoryOptions
} from '../data'
import { Body, Content } from './Shell'
import { LoginRedirector } from './Auth'
import {
  saveIngredient,
  selectIngredient,
  deselectAllIngredients
} from '../actions'

const IngredientsStyles = theme => ({
  wrapper: {
    padding: 30
  },
  title: {
    marginBottom: 10
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  addButton: {
    width: '100%',
    color: theme.palette.common.white
  },
  input: {
    width: '100%'
  },
  tableRow: {
    position: 'relative',
    height: 57,
    transition: 'background .3s, box-shadow .3s, height .3s',
    '&:hover': {
      background: 'rgba(0,0,0,.05)'
    },
    '&:hover $editIcon': {
      opacity: 1
    }
  },
  tableCell: {
    padding: '4px 20px 4px 10px'
  },
  editIcon: {
    flex: '0 0 auto',
    marginLeft: 'auto',
    opacity: 0
  },
  unitCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  editCell: {
    position: 'relative'
  },
  unitText: {
    flex: '0 0 auto'
  },
  selected: {
    marginBottom: 15,
    boxShadow: theme.shadows[4],
    height: 90,
    '&:hover': {
      background: '#fff'
    }
  },
  table: {
    overflow: 'auto'
  },
  tableBody: {
    overflow: 'auto'
  },
  saveIcon: {
    flex: '0 0 auto',
    marginLeft: 'auto',
    opacity: 0
  },
  cancelButton: {
    position: 'absolute',
    right: -10,
    top: -10,
    width: 25,
    height: 25,
    borderRadius: '100%',
    border: 'none',
    boxShadow: theme.shadows[5],
    background: theme.palette.grey[600],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.grey[500]
    }
  },
  closeIcon: {
    width: 19,
    height: 'auto',
    color: '#fff'
  },
  content: { maxWidth: '100%', minWidth: '100%' }
})

const TableCell = withStyles({
  root: {
    padding: '4px 20px 4px 10px'
  }
})(TableCellOriginal)

export default class Ingredients extends Component {
  render() {
    return (
      <Body>
        <LoginRedirector />
        {!this.props.isAdmin && <Redirect to="/classes" />}
        <IngredientsToolbar />
        <IngredientsTable />
      </Body>
    )
  }
}

class IngredientsTable extends Component {
  render() {
    const {
      ingredients,
      selected,
      classes: { title, table, tableBody, content }
    } = this.props
    return (
      <Content className={content}>
        <Typography type="title" className={title}>
          Ingredients
        </Typography>
        <Table className={table}>
          <TableHead>
            <TableRow>
              <TableCell>Allergen</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Units</TableCell>
              <TableCell>P. Size</TableCell>
              <TableCell>SubUnits</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={tableBody}>
            {ingredients.map(ingredient => (
              <Ingredient
                {...ingredient}
                selected={selected === ingredient.id}
                key={ingredient.id}
              />
            ))}
          </TableBody>
        </Table>
      </Content>
    )
  }
}
IngredientsTable = withStyles(IngredientsStyles)(
  connect(state => ({
    ingredients: orderBy(
      state.ingredients.filter(ing => !ing.archive),
      ['name'],
      ['asc']
    ),
    selected: state.ingredientsPage.selectedIngredient
  }))(IngredientsTable)
)

class Ingredient extends Component {
  constructor() {
    super()
    this.state = {
      name: '',
      units: '',
      description: '',
      category: '',
      isAllergy: '',
      purchaseContainerUnits: '',
      purchaseContainerSize: ''
    }
  }

  componentWillReceiveProps = nextProps => {
    // if(this.props.selected === false && nextProps.selected === true){
    this.setState({
      name: nextProps.name,
      units: nextProps.units,
      category: nextProps.category,
      description: nextProps.description,
      isAllergy: nextProps.isAllergy,
      purchaseContainerUnits: nextProps.purchaseContainerUnits,
      purchaseContainerSize: nextProps.purchaseContainerSize,
      db_id: nextProps.db_id
    })
    // }
  };

  set = (key, e) => {
    const val =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value
    console.log({ [key]: val })
    this.setState(state => ({ ...(val !== undefined ? { [key]: val } : {}) }))
  };

  select = () => {
    this.props.selectIngredient(this.props.id)
  };

  saveIngredient = e => {
    e.stopPropagation()
    this.props.saveIngredient({
      ...this.state,
      id: this.props.id,
      description: this.state.description ? this.state.description : '',
      name: this.state.name ? this.state.name : '',
      units: this.state.units ? this.state.units : null,
      category: this.state.category ? this.state.category : null,
      isAllergy:
        this.state.isAllergy !== undefined ? this.state.isAllergy : false,
      purchaseContainerUnits:
        this.state.purchaseContainerUnits !== undefined
          ? this.state.purchaseContainerUnits
          : '',
      purchaseContainerSize:
        this.state.purchaseContainerSize !== undefined
          ? this.state.purchaseContainerSize
          : 0
    })
    this.props.deselectAllIngredients()
  };

  archiveIngredient = e => {
    e.stopPropagation()
    if (window.confirm('Are you sure you want to delete this ingredient?')) {
      this.props.saveIngredient({
        ...this.state,
        id: this.props.id,
        description: this.state.description ? this.state.description : '',
        name: this.state.name ? this.state.name : '',
        units: this.state.units ? this.state.units : null,
        category: this.state.category ? this.state.category : null,
        isAllergy:
          this.state.isAllergy !== undefined ? this.state.isAllergy : false,
        purchaseContainerUnits:
          this.state.purchaseContainerUnits !== undefined
            ? this.state.purchaseContainerUnits
            : '',
        purchaseContainerSize:
          this.state.purchaseContainerSize !== undefined
            ? this.state.purchaseContainerSize
            : 0,
        archive: true
      })
      this.props.deselectAllIngredients()
    }
  };

  cancel = e => {
    e.stopPropagation()
    this.props.deselectAllIngredients()
  };

  render() {
    const {
      name,
      units,
      description,
      category,
      selected,
      isAllergy,
      purchaseContainerUnits,
      purchaseContainerSize,
      classes: {
        tableRow,
        editIcon,
        unitCell,
        unitText,
        editCell,
        closeIcon,
        selected: selectedClass,
        cancelButton
      }
    } = this.props
    const categoryLabel = categoryOptions.find(cat => cat.value === category)
      .label
    return (
      <TableRow
        className={`${tableRow} ${selected ? selectedClass : ''}`}
        onClick={this.select}
      >
        {selected ? (
          <TableCell className={editCell} colSpan={7}>
            <IngredientFields
              name={this.state.name}
              description={this.state.description}
              units={this.state.units}
              category={this.state.category}
              isAllergy={this.state.isAllergy}
              purchaseContainerUnits={this.state.purchaseContainerUnits}
              purchaseContainerSize={this.state.purchaseContainerSize}
              onChange={this.set}
              onSubmit={debounce(this.saveIngredient, 500, {
                leading: true,
                trailing: false
              })}
              onArchive={debounce(this.archiveIngredient, 500, {
                leading: true,
                trailing: false
              })}
              buttonDisabled={
                this.state.name === '' ||
                this.state.units === '' ||
                this.state.category === ''
              }
            />
            <button className={cancelButton} onClick={this.cancel}>
              <Close className={closeIcon} />
            </button>
          </TableCell>
        ) : (
          [
            <TableCell key={0}>
              {isAllergy ? <SentimentDissatisfied /> : ''}
            </TableCell>,
            <TableCell key={1}>{name}</TableCell>,
            <TableCell key={2}>{description}</TableCell>,
            <TableCell key={3}>{categoryLabel}</TableCell>,
            <TableCell key={4}>{units}</TableCell>,
            <TableCell key={5}>{purchaseContainerSize}</TableCell>,
            <TableCell key={6}>
              <div className={unitCell}>
                <span className={unitText}>{purchaseContainerUnits}</span>
                <IconButton className={editIcon}>
                  <EditIcon />
                </IconButton>
              </div>
            </TableCell>
          ]
        )}
      </TableRow>
    )
  }
}
Ingredient = withStyles(IngredientsStyles)(
  connect(
    null,
    { selectIngredient, deselectAllIngredients, saveIngredient }
  )(Ingredient)
)

class IngredientsToolbar extends Component {
  constructor() {
    super()
    this.state = {
      isAllergy: '',
      units: 'weight',
      name: '',
      description: '',
      category: 'grocery',
      purchaseContainerUnits: '',
      purchaseContainerSize: ''
    }
  }

  set = (key, e) => {
    const val =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value
    this.setState(state => ({
      ...(val !== undefined ? { [key]: val } : {})
    }))
  };

  addIngredient = () => {
    if (
      this.state.units !== '' &&
      this.state.name !== '' &&
      this.state.category
    ) {
      this.props.saveIngredient({
        ...this.state,
        name: this.state.name,
        units: this.state.units,
        category: this.state.category,
        isAllergy: this.state.isAllergy
      })
      this.setState({
        name: ''
      })
      this.ingFields.focusName()
    }
  };

  render() {
    const {
      classes: { title, content }
    } = this.props
    const {
      name,
      description,
      units,
      category,
      isAllergy,
      purchaseContainerUnits,
      purchaseContainerSize
    } = this.state
    return (
      <Content className={content}>
        <Typography type="title" className={title}>
          Add an Ingredient
        </Typography>
        <IngredientFields
          name={name}
          units={units}
          description={description}
          category={category}
          isAllergy={isAllergy}
          purchaseContainerUnits={purchaseContainerUnits}
          purchaseContainerSize={purchaseContainerSize}
          onChange={this.set}
          onSubmit={this.addIngredient}
          buttonDisabled={name === '' || units === '' || category === ''}
          submitLabel="Add"
          innerRef={ingFields => {
            this.ingFields = ingFields
          }}
        />
      </Content>
    )
  }
}
IngredientsToolbar = withStyles(IngredientsStyles)(
  connect(
    null,
    { saveIngredient }
  )(IngredientsToolbar)
)

class IngredientFields extends Component {
  focusName = () => {
    this.name.focus()
  };

  render() {
    const {
      name,
      description,
      units,
      category,
      isAllergy,
      onChange,
      onSubmit,
      onArchive,
      buttonDisabled,
      purchaseContainerUnits,
      purchaseContainerSize,
      classes: { input, buttonWrapper }
    } = this.props
    const subUnitsList = subUnitsOptions[units]
    return (
      <Grid container item xs={12}>
        <Grid item sm={1} xs={12}>
          <Checkbox
            checked={isAllergy}
            inputRef={isAllergy => {
              this.isAllergy = isAllergy
            }}
            className={input}
            onChange={e => {
              onChange('isAllergy', e)
            }}
            label="Allergen"
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          <TextField
            value={name}
            inputRef={namer => {
              this.name = namer
            }}
            className={input}
            onChange={e => {
              onChange('name', e)
            }}
            label="Name"
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <TextField
            value={description}
            inputRef={desc => {
              this.description = desc
            }}
            className={input}
            onChange={e => {
              onChange('description', e)
            }}
            label="Description"
          />
        </Grid>
        <Grid item sm={1} xs={12}>
          <TextField
            className={input}
            select
            value={units}
            onChange={e => {
              onChange('units', e)
            }}
            label="Units"
          >
            {unitOptions.map(unit => (
              <MenuItem value={unit.value} key={unit.value}>
                {unit.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item sm={2} xs={12}>
          <TextField
            className={input}
            select
            value={category}
            onChange={e => {
              onChange('category', e)
            }}
            label="Category"
          >
            {categoryOptions.map(category => (
              <MenuItem value={category.value} key={category.value}>
                {category.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item sm={1} xs={12}>
          <TextField
            type="number"
            value={purchaseContainerSize}
            inputRef={size => {
              this.purchaseContainerSize = size
            }}
            className={input}
            onChange={e => {
              onChange('purchaseContainerSize', e)
            }}
            label="P. Size"
          />
        </Grid>
        <Grid item sm={1} xs={12}>
          <TextField
            className={input}
            select
            value={purchaseContainerUnits}
            onChange={e => {
              onChange('purchaseContainerUnits', e)
            }}
            label="Units"
          >
            {subUnitsList.map(subUnit => (
              <MenuItem value={subUnit.value} key={subUnit.value}>
                {subUnit.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid className={buttonWrapper} item sm={1} xs={12}>
          <IconButton
            variant="contained"
            color="secondary"
            className={this.saveIcon}
            disabled={buttonDisabled}
            onClick={onArchive}
          >
            <Delete />
          </IconButton>
          <IconButton
            variant="contained"
            color="primary"
            className={this.saveIcon}
            disabled={buttonDisabled}
            onClick={onSubmit}
          >
            <Save />
          </IconButton>
        </Grid>
      </Grid>
    )
  }
}
IngredientFields = withStyles(IngredientsStyles)(IngredientFields)
