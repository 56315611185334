import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Loader from 'react-loader-spinner'
import { addLoading, setLoadingArray } from '../actions'

const LoadingStyles = theme => ({
  marginBottom: '10px',
  loadingClass: {
    borderRadius: '4px',
    backgroundColor: 'rgba(255, 255, 255, .7)',
    marginBottom: '10px',
    padding: 10,
    boxShadow: '5px 5px 12px #888888'
  },
  loadingContainer: {
    position: 'fixed',
    top: 85,
    right: 30,
    zIndex: 1400,
    justifyContent: 'center',
    marginBottom: '10px'
  },
  deleteStyles: {
    cursor: 'pointer',
    color: '#6da72a',
    height: 24
  }
})

class Loading extends Component {
  constructor() {
    super()
    this.state = {}
  }

  set = (key, e) => {
    const val =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value
      // this.setState({
      //   [key]: val
      // })
    this.props.onChange(val)
  };

  delete = id => {
    this.props.setLoadingArray(
      this.props.loadingArray.filter(er => er.id !== id)
    )
  };

  render() {
    const {
      classes: { loadingClass, loadingContainer, deleteStyles },
      loadingArray
    } = this.props
    return (
      <Grid item className={loadingContainer} xs={4} sm={3} md={2}>
        {loadingArray.map(er => (
          <Grid
            key={er.id}
            className={loadingClass}
            style={{ justifyContent: 'center', display: 'flex' }}
            item
            xs={12}
          >
            <Loader type="Puff" color="#6da72a" height={30} width={30} />
            <Typography
              component="span"
              style={{
                width: 'calc(100% - 45px)',
                marginLeft: '5px',
                marginTop: 3,
                display: 'inline-flex',
                color: '#6da72a'
              }}
              autoFocus
            >
              {er.msg}
            </Typography>
            <IconButton
              className={deleteStyles}
              onClick={() => this.delete(er.id)}
              aria-label="Clear"
            >
              <Clear />
            </IconButton>
          </Grid>
        ))}
      </Grid>
    )
  }
}
export default withStyles(LoadingStyles)(
  connect(
    state => ({
      loadingArray: state.app.loadingArray
    }),
    {
      addLoading,
      setLoadingArray
    }
  )(Loading)
)
