import React, {  Fragment, useState, useEffect } from 'react'
import { Mutation } from 'react-apollo'
import SaveIcon from '@material-ui/icons/Save'
import IconButton from '@material-ui/core/IconButton'
import { convertToRaw } from 'draft-js'
import {
  Editor,
  createEditorState,
} from 'medium-draft'
import mediumDraftImporter from 'medium-draft/lib/importer'
import mediumDraftExporter from 'medium-draft/lib/exporter'
import {
  GET_SETTING,
  UPDATE_SETTING,
  CREATE_SETTING,
  DefaultQuery
} from '../graphql'
import 'medium-draft/lib/index.css'

export const variables = {
  type: 'general',
  sub_type: 'settings',
  sub_sub_type: 'recipe_outline'
}

export const RecipeOutline = props => {
  return (
    <DefaultQuery
      query={GET_SETTING}
      variables={variables}
      render={data => {
        return (
          <>
            <div
              style={{
                display: 'flex',
                alignSelf: 'flex-start'
              }}
            >
              <div
                style={{
                  flex: '0 1 100%',
                  width: '100%',
                  maxHeight: '40vh',
                }}
              >
                <Create
                  render={({ cSetting }) => (
                    <SettingSaver 
                      data={data}
                      cSetting={cSetting}
                      render={({ Save, data }) => (
                        <RecipeTextEditor
                          Save={Save}
                          html={data ? data.html : "" }
                        />
                      )}
                    />
                  )}
                />
              </div>
            </div>
          </>
        )
      }}
    />
  )
}
export default RecipeOutline

function RecipeTextEditor(props)
{
  const { html= "", Save } = props
  const [editorState, setEditorState] = useState(
    createEditorState()
  )

  useEffect(() =>
  {
    const editorState = createEditorState(convertToRaw(mediumDraftImporter(html)))
    setEditorState(editorState)
  }, [html])

  return (
    <div style={{
      width: "100%",
      height: 400,
      position: "relative"
    }}>
      <Editor
        editorState={editorState}
        onChange={setEditorState}
      />
      <IconButton
        color="primary"
        style={{
          position: "absolute",
          top: 15,
          right: 15,
          zIndex: 999
        }}
        onClick={e => {
          if (window.confirm("Are you sure you want to save changes to the instruction cards heading?")) {
            const renderedHTML = mediumDraftExporter(editorState.getCurrentContent())
            Save({ html: renderedHTML })              
          }              
        }}
      >
        <SaveIcon />
      </IconButton>
    </div>
  )
}

const SettingSaver = props => {
  const { data: [setting = {}] = [{}], cSetting, defaultData } = props
  const { data = defaultData } = setting

  return (
    <Mutation
      mutation={UPDATE_SETTING}
      update={(cache, { data: { updateSetting } }) => {
        cache.writeQuery({
          query: GET_SETTING,
          variables,
          data: { settings: [updateSetting] }
        })
      }}
    >
      {(uSetting, { data: mData }) => {
        const Save = data => {
          if (setting.setting_id) {
            uSetting({
              variables: {
                ...setting,
                data
              }
            })
          } else {
            cSetting({
              variables: {
                ...variables,
                data
              }
            })
          }
        }
        return props.render({ Save, data })
      }}
    </Mutation>
  )
}

const Create = props => {
  return (
    <Mutation
      mutation={CREATE_SETTING}
      update={(cache, { data: { createSetting } }) => {
        cache.writeQuery({
          query: GET_SETTING,
          variables,
          data: { settings: [createSetting] }
        })
      }}
    >
      {(cSetting, { data: mData }) => {
        return props.render({ cSetting })
      }}
    </Mutation>
  )
}

