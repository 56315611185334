import React, { useState } from 'react'
import AddSharp from '@material-ui/icons/AddSharp'
import FilterList from '@material-ui/icons/FilterList'
import {
  Chip,
  IconButton,
  Button,
  Popover,
  TextField,
  Switch,
  Select,
  MenuItem
} from '@material-ui/core'

import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { LocationPicker, LocationCategoryToName } from './Locations'

const Filters = (props) => {
  const { filters, setFilters } = props
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 15)
  )
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date())
  const [location, setLocation] = useState('')
  const [sku, setSku] = useState('')
  const [template, setTemplate] = useState(0)
  const [classSize, setClassSize] = useState(9)
  const [pub, setPublic] = useState(true)
  const [privateMenu, setPrivateMenu] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  let templates = [
    {id: 1, name: 'In-Person'},
    {id: 2, name: 'In-Person Vegetarian'},
    {id: 3, name: 'Couples'},
    {id: 4, name: 'Pre-Assembled'},
    {id: 5, name: 'Pre-Assembled Vegetarian'},
    {id: 25, name: 'Delivery'},
    {id: 27, name: 'Fall Soup'},
  ]

  return (
    <div
      style={{
        width: '100%',
        padding: '5px 9px',
        display: 'flex',
        flexWrap: 'wrap',
        position: 'absolute',
        top: 57,
        left: 30,
      }}
    >
      <Button
        aria-describedby={id}
        color="primary"
        aria-label="filter"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        style={{ position: 'absolute', top: 13, right: open ? 30 + 15 : 30 }}
      >
        <FilterList /> Filter
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{}}
      >
        <Grid
          container
          justify="space-around"
          style={{ padding: 30, minWidth: 300, minHeight: 200, maxWidth: '80vw' }}
        >
          <FilterRow
            title="Class Template"
            add={() => {
              let value = templates[template]
              setFilters([
                ...filters.filter((f) => f.id !== 'templateFilter'),
                {
                  id: 'templateFilter',
                  name: `${value.name}`,
                  where: { template: value.id },
                },
              ])
            }}
          >
            <Select name="templates" id="template-select" label={templates[template].name} value={template} onChange={(event) => {
              setTemplate(event.target.value)
            }}>
              {templates.map((t, index) => {
                return (<MenuItem value={index} key={index}>{t.name}</MenuItem>)
              })}
            </Select>
          </FilterRow>
          <FilterRow
            title="Location"
            add={() => {

              console.log(location)
              setFilters([
                ...filters.filter((f) => f.id !== '39fjnz0ej'),
                {
                  id: '39fjnz0ej',
                  name: <LocationCategoryToName>{location}</LocationCategoryToName>,
                  where: { location },
                },
              ])
            }}
          >
            <LocationPicker
              value={location}
              showAll={false}
              onChange={(e) => setLocation(e.target.value)}
              style={{ minWidth: 120 }}
            />
          </FilterRow>
          <FilterRow
            title="SKU"
            add={() => {
              setFilters([
                ...filters.filter((f) => f.id !== 'cjw94357tha'),
                {
                  id: 'cjw94357tha',
                  name: sku,
                  where: { sku: { ilike: `%${sku}%` } },
                },
              ])
            }}
          >
            <TextField value={sku} label="SKU" onChange={(e) => setSku(e.target.value)} />
          </FilterRow>
          <FilterRow
            title={`${privateMenu ? 'Private' : 'Monthly'} Menu`}
            add={() => {
              setFilters([
                ...filters.filter((f) => f.id !== 'dew3ryi8jbn'),
                {
                  id: 'dew3ryi8jbn',
                  name: `${privateMenu ? 'Private' : 'Monthly'} Menu`,
                  where: { value: { privateMenu } },
                },
              ])
            }}
          >
            <Switch
              checked={privateMenu}
              onChange={() => setPrivateMenu(!privateMenu)}
              color="primary"
            />
          </FilterRow>
          <FilterRow
            title={pub ? 'Public Visible' : 'Public Hidden'}
            add={() => {
              setFilters([
                ...filters.filter((f) => f.id !== 'gx63tr473jdcnfg57'),
                {
                  id: 'gx63tr473jdcnfg57',
                  name: pub ? 'Public Visible' : 'Public Hidden',
                  where: { value: { classIsPublic: pub } },
                },
              ])
            }}
          >
            <Switch checked={pub} onChange={() => setPublic(!pub)} color="primary" />
          </FilterRow>
          <FilterRow
            title="Class Size Less Than"
            add={() => {
              setFilters([
                ...filters.filter((f) => f.id !== 'hgswrte54ghfu'),
                {
                  id: 'hgswrte54ghfu',
                  name: `Class Size <= ${classSize}`,
                  where: { participants: { lte: classSize } },
                },
              ])
            }}
          >
            <TextField
              value={classSize}
              label="Class Size"
              onChange={(e) => setClassSize(e.target.value)}
            />
          </FilterRow>

          <FilterRow
            title="Date"
            add={() => {
              setFilters([
                ...filters.filter((f) => f.id !== 's83u2hd92'),
                {
                  id: 's83u2hd92',
                  name: `${selectedStartDate.toLocaleDateString()} -> ${selectedEndDate.toLocaleDateString()}`,
                  where: {
                    date: {
                      gte: new Date(selectedStartDate.toDateString()).getTime(),
                      lte: selectedEndDate.getTime() + 1000 * 60 * 60 * 12,
                    },
                  },
                },
              ])
            }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                autoOk
                margin="normal"
                id="date-picker-inline-start"
                label="Start"
                value={selectedStartDate}
                onChange={(date) => setSelectedStartDate(date)}
                KeyboardButtonProps={{
                  'aria-label': 'change start date',
                }}
              />
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                autoOk
                margin="normal"
                id="date-picker-inline-end"
                label="End"
                value={selectedEndDate}
                onChange={(date) => setSelectedEndDate(date)}
                KeyboardButtonProps={{
                  'aria-label': 'change end date',
                }}
              />
            </MuiPickersUtilsProvider>
          </FilterRow>
          <FilterRow
            title="Include Archived"
            add={() => {
              setFilters([
                ...filters.filter((f) => f.id !== 'scsh372g478fhs'),
                {
                  id: 'scsh372g478fhs',
                  name: 'Include Archived',
                  where: { 'value.record_status': { or: ['active', null, 'archive'] } },
                },
              ])
            }}
          ></FilterRow>
        </Grid>
      </Popover>
      {filters.map((fi) => (
        <Chip
          key={fi.id}
          label={fi.name}
          onDelete={() => setFilters(filters.filter((f) => f.id !== fi.id))}
          color="secondary"
          style={{ margin: '12px 0' }}
        />
      ))}
    </div>
  )
}
const sharedStyle = { alignItems: 'center', display: 'flex' }
const FilterRow = ({ children, add, title }) => {
  return (
    <Grid container>
      <Grid row sm={1} style={sharedStyle} item={true}>
        <IconButton onClick={add}>
          <AddSharp />
        </IconButton>
      </Grid>
      <Grid row sm={3} lg={2} xl={2} style={sharedStyle} item={true}>
        {title}
      </Grid>
      <Grid row sm={8} lg={9} xl={9} style={sharedStyle} item={true}>
        {children}
      </Grid>
    </Grid>
  )
}
export default Filters
