import firebase from 'firebase'
import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ECOMMERCE_URL,
  headers: {
    accept: 'application/json',
  }
})

const _getToken = async (request) => {
  try {
    let token = await firebase.auth().currentUser.getIdToken(true)
    localStorage.setItem('firebase.auth.idToken', token)

    request.headers.idToken = token

    return request
  }
  catch (error) {
    return Promise.reject(error)
  }
}

axiosInstance.interceptors.request.use(
  (request) => _getToken(request),
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosInstance