import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import ReduxThunk from 'redux-thunk'
import AuthReducer from './reducers/AuthReducer'
import UsersReducer from './reducers/UsersReducer'
import IngredientsReducer from './reducers/IngredientsReducer'
import IngredientsPageReducer from './reducers/IngredientsPageReducer'
import RecipesReducer from './reducers/RecipesReducer'
import ClassesReducer from './reducers/ClassesReducer'

const initialState = {
  selectedIngredient: null,
  errors: [],
  loadingArray: [],
  locations: []
}

const AppReducer = (state=initialState, action) => {
  switch (action.type) {
  case 'SET_ERRORS':
    return {...state, errors: action.errors}
  case 'SET_LOADING_ARRAY':
    return { ...state, loadingArray: action.loadingArray }
  case 'SET_LOCATIONS':
    return {...state, locations: action.payload}
  default:
    return state
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default createStore(
  combineReducers({
    app: AppReducer,
    auth: AuthReducer,
    users: UsersReducer,
    ingredients: IngredientsReducer,
    ingredientsPage: IngredientsPageReducer,
    recipes: RecipesReducer,
    classes: ClassesReducer
  }),
  composeEnhancers(applyMiddleware(ReduxThunk))
)
