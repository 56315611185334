import { useState, useEffect, useRef } from "react"

export const useTimer = () =>
{
  const [isRunning, setIsRunning] = useState(false)
  const [beginTime, setBeginTime] = useState(-1)
  const [endTime, setEndTime] = useState(null)
  const [elapsedTime, setElapsedTime] = useState(`00:00:00`)
  const timerRef = useRef(false)

  // eslint-disable-next-line no-unused-vars
  function intervalFunc({beginTime: bTime,  endTime: eTime, })
  {
    const now = (new Date()).getTime()
    if (beginTime === -1) setBeginTime(now)
    setEndTime(now)
  }

  const frmt = numb =>
  {
    // return num;
    if (numb < 0) return "00"
    const num = numb
    return num > 9 ? `${  String(num)}` : `0${  String(num)}`
  }
  const getTime = seconds =>
  {
    const rounded = Math.round(seconds)
    const mints = Math.floor(rounded / 60)
    const hrs = Math.floor(rounded / (60 * 60))
    const t = `${frmt(hrs)}:${frmt(mints % 60)}:${frmt(rounded % 60)}`
    return t
  }
  useEffect(
    // eslint-disable-next-line func-names
    function () {
      if (isRunning) {
        console.log("Starting the interval")
        timerRef.current = setInterval(
          // eslint-disable-next-line func-names
          function () {
            // console.log(`calling intervalFunc -> ${beginTime} ${endTime}`)
            intervalFunc({ beginTime, endTime })
          },

          500
        )
      } else {
        clearInterval(timerRef.current)
        console.log("stop:")
        // console.log({now, beginTime, secs, isRunning, endTime})
        // if (secs > .5) {
        // }
        return () => {
          // setElapsedTime("00:00:00")
          clearInterval(timerRef.current)
        }
      }
    },
    [ isRunning]
  )

  useEffect(() =>
  {
    if (isRunning) {
      const now = (new Date()).getTime()
      const secs = (endTime - (beginTime === -1 ? now : beginTime)) / 1000
      setElapsedTime(getTime(secs))
      // console.log(`setElapsedTime -> ${isRunning} ${endTime}`)
    } else {
      clearInterval(timerRef.current)
    }
  },[endTime])

  return {
    // elapsedSeconds: elapsedTime.toFixed(1),
    elapsedTime,
    setBeginTime,
    setEndTime,
    isRunning,
    setIsRunning,
    setElapsedTime
  }
}

export const useStopwatch = () => {
  const [laps, setLaps] = useState([])
  const { isRunning, setIsRunning, elapsedTime, setBeginTime, setEndTime, setElapsedTime } = useTimer()

  const handleReset = () =>
  {
    console.log("reset")
    setIsRunning(() =>
    {
      setEndTime(-1)
      setBeginTime(-1)
      setElapsedTime("00:00:00")
      return false
    })
    setLaps([])
  }

  const handleStop = () =>
  {
    console.log("stop")

    setIsRunning(() =>
    {
      return false
    })
    // setEndTime(tm ? tm : now);
  }

  const handleStart = (tm) =>
  {
    console.log("start")
    const now = (new Date()).getTime()
    setBeginTime(prev =>
    {
      console.log(`setBeginTime ${prev} --> ${tm || now}`)
      setIsRunning(pre =>
      {
        console.log(`setIsRunning ${pre} --> ${true}`)
        console.log(`setEndTime ${-1}`)
        setEndTime(-1)
        return true
      })
      return tm || now
    })
  }

  const handleAddLap = () => {
    const prevTotal =
      laps.length > 0 ? laps.reduce((acc, curr) => acc + curr, 0) : 0
    const currentLap = laps.length > 0 ? elapsedTime - prevTotal : elapsedTime
    if(isRunning) setLaps([...laps, currentLap])
  }

  return {
    elapsedTime,
    laps,
    addLap: () => handleAddLap(),
    resetTimer: () => handleReset(),
    startTimer: (tm) => handleStart(tm),
    stopTimer: (tm) => handleStop(tm),
    isRunning
  }
}
