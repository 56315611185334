import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Slide from '@material-ui/core/Slide'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Favorite from '@material-ui/icons/Favorite'
import FavoriteBorder from '@material-ui/icons/FavoriteBorder'
import LocalDining from '@material-ui/icons/LocalDining'
import Delete from '@material-ui/icons/Delete'
import TagFaces from '@material-ui/icons/TagFaces'
import Add from '@material-ui/icons/Add'
import orderBy from 'lodash/orderBy'
import { subUnits, recipeCategories } from '../data'
import { saveRecipe } from '../actions'
// import Autosuggest from './Autosuggest'

const EditorStylesheet = theme => ({
  input: {
    width: '100%'
  },
  rowMargin: {
    marginBottom: 5
  },
  hiddenUpload: {
    width: 1,
    height: 1,
    opacity: '.01',
    position: 'fixed',
    left: -5,
    top: -5
  },
  upload: {
    width: '100%',
    minHeight: 70,
    border: `2px dashed ${theme.palette.primary[400]}`,
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary[600],
    transition: 'background 200ms',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    padding: 10,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      background: theme.palette.primary[100]
    }
  },
  midTitle: {
    marginTop: 45,
    fontSize: 18
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  filter: {
    width: '100%',
    height: 50,
    padding: 13,
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'italic',
    '&:focus': {
      outline: 'none',
      background: theme.palette.secondary[100],
      border: 'none'
    }
  },
  ingredientRow: {
    paddingLeft: '30px !important',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    '&:hover': {
      '& $deleteButton': {
        opacity: 1
      }
    }
  },
  deleteButton: {
    position: 'absolute',
    left: 0,
    top: 35,
    width: 30,
    height: 30,
    opacity: 0,
    transition: 'opacity 300ms',
    '&:hover': {
      color: theme.palette.grey[700]
    },
    '&:focus': {
      opacity: 1
    }
  },
  recipeImage: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    height: 70,
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover $imageDeleteButton': {
      opacity: 1
    }
  },
  imageDeleteButton: {
    width: 40,
    height: 40,
    borderRadius: '100%',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: theme.shadows[3],
    opacity: 0,
    transition: 'opacity 300ms'
  },
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    imageDeleteButton: {
      opacity: 1
    },
    deleteButton: {
      opacity: 1
    }
  }
})

const getIngredientRow = () => ({ name: '', amount: '', subUnit: '' })

const getNewRecipeObject = () => ({
  id: null,
  name: '',
  image: null,
  desc: '',
  cookInst: '',
  servings: '',
  serveSuggestions: '',
  heart: false,
  calConsc: false,
  diabetes: false,
  calories: '',
  tfat: '',
  carbs: '',
  fiber: '',
  protein: '',
  sugar: '',
  satFat: '',
  sodium: '',
  dietNotes: '',
  pressCookInst: '',
  grocList: '',
  addiSeas: '',
  category: '',
  cook_time_sl_sm: '',
  cook_time_sl_rg: '',
  cook_time_pr_sm: '',
  cook_time_pr_rg: '',
  cook_inst_sl_sm: '',
  cook_inst_sl_rg: '',
  cook_inst_pr_sm: '',
  cook_inst_pr_rg: '',
  grocery1: '',
  grocery2: '',
  grocery3: '',
  grocery4: '',
  grocery5: '',
  grocery6: '',
  serve_amount_sm: '',
  serve_amount_rg: '',
  serve_size: '',
  ingredients: [getIngredientRow()]
})

function Transition(props) {
  return <Slide direction="up" {...props} />
}

class RecipeEditor extends Component {
  constructor() {
    super()
    this.state = {
      recipe: { ...getNewRecipeObject() },
      saving: false
    }
  }

  componentWillReceiveProps = nextProps => {
    const editorStarted = this.props.open === false && nextProps.open === true
    if (editorStarted) {
      this.setState({
        recipe: {
          ...getNewRecipeObject(),
          ...(nextProps.incomingRecipe ? nextProps.incomingRecipe : {})
        },
        saving: false
      })
    }
  };

  set = (key, e) => {
    const val =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value
    this.setState(state => ({
      recipe: { ...state.recipe, ...(val !== undefined ? { [key]: val } : {}) }
    }))
  };

  addIngredientRow = () => {
    this.setState(state => ({
      recipe: {
        ...state.recipe,
        ingredients: [...state.recipe.ingredients, getIngredientRow()]
      }
    }))
  };

  deleteIngredientRow = index => {
    this.setState(state => ({
      recipe: {
        ...state.recipe,
        ingredients: [
          ...this.state.recipe.ingredients.slice(0, index),
          ...this.state.recipe.ingredients.slice(index + 1)
        ]
      }
    }))
  };

  setIngredients = value => {
    this.setState(state => ({
      recipe: { ...state.recipe, ingredients: value }
    }))
  };

  setImage = e => {
    const image = e.target.files.length > 0 ? [...e.target.files][0] : null
    this.setState(state => ({
      recipe: { ...state.recipe, image }
    }))
  };

  removeImage = () => {
    this.setState(state => ({
      recipe: { ...state.recipe, image: null }
    }))
  };

  saveRecipe = () => {
    this.setState({
      saving: true
    })

    this.props.saveRecipe({ ...this.state.recipe }, rec => {})

    this.props.onClose()
  };

  render() {
    // console.log("saving: ", this.state.saving, "open: ", this.props.open )
    const {
      open,
      title,
      classes: {
        input,
        rowMargin,
        upload,
        midTitle,
        hiddenUpload,
        recipeImage,
        imageDeleteButton
      }
    } = this.props
    const {
      name,
      desc,
      heart = true,
      calConsc = true,
      diabetes = true,
      serveSuggestions,
      addiSeas,
      calories,
      tfat,
      carbs,
      fiber,
      protein,
      sugar,
      satFat,
      sodium = 0,
      image,
      category,
      cook_time_sl_sm,
      cook_time_sl_rg,
      cook_time_pr_sm,
      cook_time_pr_rg,
      cook_inst_sl_sm,
      cook_inst_sl_rg,
      cook_inst_pr_sm,
      cook_inst_pr_rg,
      grocery1,
      grocery2,
      grocery3,
      grocery4,
      grocery5,
      grocery6,
      serve_amount_sm,
      serve_amount_rg,
      serve_size,
      ingredients,
      dietNotes,
      nutritionNotes
    } = this.state.recipe
    const filename = image && typeof image === 'object' ? image.name : ''
    const imageSrc =
      image && typeof image === 'string' && image !== '' ? image : null
    return (
      <Dialog
        open={open}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent id="alert-dialog-slide-description">
          <FormGroup>
            <Grid container className={rowMargin} spacing={16}>
              <Grid item sm={4} xs={12}>
                <input
                  name="upload"
                  onChange={this.setImage}
                  id="upload"
                  type="file"
                  className={hiddenUpload}
                />
                {imageSrc ? (
                  <div
                    className={recipeImage}
                    style={{
                      backgroundImage: `url(${imageSrc.replace(
                        /recipeImages/,
                        'recipeImages%2Fthumbs'
                      )})`
                    }}
                  >
                    <div className={imageDeleteButton}>
                      <IconButton onClick={this.removeImage}>
                        <Delete />
                      </IconButton>
                    </div>
                  </div>
                ) : (
                  <label htmlFor="upload" className={upload}>
                    {filename === '' ? 'Upload Recipe Image' : filename}
                  </label>
                )}
              </Grid>
              <Grid item sm={8} xs={12}>
                <TextField
                  value={name}
                  onChange={e => {
                    this.set('name', e)
                  }}
                  autoFocus
                  className={input}
                  label="Recipe Name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={input}
                  select
                  value={category}
                  onChange={e => {
                    this.set('category', e)
                  }}
                  label="Category"
                >
                  {recipeCategories.map(cat => (
                    <MenuItem value={cat.value} key={cat.value}>
                      {cat.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  value={desc}
                  onChange={e => {
                    this.set('desc', e)
                  }}
                  className={input}
                  label="Description"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  multiline
                  value={cook_time_sl_sm}
                  onChange={e => {
                    this.set('cook_time_sl_sm', e)
                  }}
                  className={input}
                  label="Cooking Time Slow Cooker Small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  multiline
                  value={cook_time_sl_rg}
                  onChange={e => {
                    this.set('cook_time_sl_rg', e)
                  }}
                  className={input}
                  label="Cooking Time Slow Cooker Regular"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  multiline
                  value={cook_time_pr_sm}
                  onChange={e => {
                    this.set('cook_time_pr_sm', e)
                  }}
                  className={input}
                  label="Cooking Time Pressure Cooker Small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  multiline
                  value={cook_time_pr_rg}
                  onChange={e => {
                    this.set('cook_time_pr_rg', e)
                  }}
                  className={input}
                  label="Cooking Time Pressure Cooker Regular"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  value={cook_inst_sl_sm}
                  onChange={e => {
                    this.set('cook_inst_sl_sm', e)
                  }}
                  className={input}
                  label="Slow Cooker Instructions Small"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  value={cook_inst_sl_rg}
                  onChange={e => {
                    this.set('cook_inst_sl_rg', e)
                  }}
                  className={input}
                  label="Slow Cooker Instructions Regular"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  value={cook_inst_pr_sm}
                  onChange={e => {
                    this.set('cook_inst_pr_sm', e)
                  }}
                  className={input}
                  label="Pressure Cooker Instructions Small"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  value={cook_inst_pr_rg}
                  onChange={e => {
                    this.set('cook_inst_pr_rg', e)
                  }}
                  className={input}
                  label="Pressure Cooker Instructions Regular"
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  value={serve_size}
                  onChange={e => {
                    this.set('serve_size', e)
                  }}
                  className={input}
                  label="Serving Size"
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  value={serve_amount_sm}
                  onChange={e => {
                    this.set('serve_amount_sm', e)
                  }}
                  className={input}
                  label="Servings Small"
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  value={serve_amount_rg}
                  onChange={e => {
                    this.set('serve_amount_rg', e)
                  }}
                  className={input}
                  label="Servings Regular"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  value={serveSuggestions}
                  onChange={e => {
                    this.set('serveSuggestions', e)
                  }}
                  className={input}
                  label="Serving Suggestions"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={grocery1}
                  onChange={e => {
                    this.set('grocery1', e)
                  }}
                  className={input}
                  label="Grocery List 1"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={grocery2}
                  onChange={e => {
                    this.set('grocery2', e)
                  }}
                  className={input}
                  label="Grocery List 2"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={grocery3}
                  onChange={e => {
                    this.set('grocery3', e)
                  }}
                  className={input}
                  label="Grocery List 3"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={grocery4}
                  onChange={e => {
                    this.set('grocery4', e)
                  }}
                  className={input}
                  label="Grocery List 4"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={grocery5}
                  onChange={e => {
                    this.set('grocery5', e)
                  }}
                  className={input}
                  label="Grocery List 5"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={grocery6}
                  onChange={e => {
                    this.set('grocery6', e)
                  }}
                  className={input}
                  label="Grocery List 6"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  value={addiSeas}
                  onChange={e => {
                    this.set('addiSeas', e)
                  }}
                  className={input}
                  label="Additional Seasonings"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography type="title" className={midTitle}>
                  Ingredients
                </Typography>
              </Grid>
              <IngredientsEditor
                value={ingredients}
                onChange={this.setIngredients}
                onDelete={this.deleteIngredientRow}
                onButtonClick={this.addIngredientRow}
              />
              <Grid item xs={12}>
                <Typography type="title" className={midTitle}>
                  Diet Information
                </Typography>
              </Grid>
              <Grid item sm={4} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={heart}
                      onChange={e => {
                        this.set('heart', e)
                      }}
                      value="heart"
                      icon={<FavoriteBorder />}
                      checkedIcon={<Favorite />}
                    />
                  }
                  label="Heart Healthy"
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={calConsc}
                      onChange={e => {
                        this.set('calConsc', e)
                      }}
                      value="calConsc"
                      icon={<LocalDining />}
                      checkedIcon={<LocalDining />}
                    />
                  }
                  label="Calorie Conscious"
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={diabetes}
                      onChange={e => {
                        this.set('diabetes', e)
                      }}
                      value="diabetes"
                      icon={<TagFaces />}
                      checkedIcon={<TagFaces />}
                    />
                  }
                  label="Diabetes Friendly"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  value={dietNotes}
                  onChange={e => {
                    this.set('dietNotes', e)
                  }}
                  className={input}
                  label="Diet Notes"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography type="title" className={midTitle}>
                  Nutrition Facts
                </Typography>
              </Grid>
              <Grid item sm={3} xs={4}>
                <TextField
                  value={calories}
                  onChange={e => {
                    this.set('calories', e)
                  }}
                  className={input}
                  label="Calories"
                />
              </Grid>
              <Grid item sm={3} xs={4}>
                <TextField
                  value={tfat}
                  onChange={e => {
                    this.set('tfat', e)
                  }}
                  className={input}
                  label="Total Fat"
                />
              </Grid>
              <Grid item sm={3} xs={4}>
                <TextField
                  value={carbs}
                  onChange={e => {
                    this.set('carbs', e)
                  }}
                  className={input}
                  label="Carbs"
                />
              </Grid>
              <Grid item sm={3} xs={4}>
                <TextField
                  value={sodium}
                  onChange={e => {
                    this.set('sodium', e)
                  }}
                  className={input}
                  label="Sodium"
                />
              </Grid>
              <Grid item sm={3} xs={4}>
                <TextField
                  value={fiber}
                  onChange={e => {
                    this.set('fiber', e)
                  }}
                  className={input}
                  label="Fiber"
                />
              </Grid>
              <Grid item sm={3} xs={4}>
                <TextField
                  value={protein}
                  onChange={e => {
                    this.set('protein', e)
                  }}
                  className={input}
                  label="Protein"
                />
              </Grid>
              <Grid item sm={3} xs={4}>
                <TextField
                  value={sugar}
                  onChange={e => {
                    this.set('sugar', e)
                  }}
                  className={input}
                  label="Sugars"
                />
              </Grid>
              <Grid item sm={3} xs={4}>
                <TextField
                  value={satFat}
                  onChange={e => {
                    this.set('satFat', e)
                  }}
                  className={input}
                  label="Saturated Fat"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  value={nutritionNotes}
                  onChange={e => {
                    this.set('nutritionNotes', e)
                  }}
                  className={input}
                  label="Nutrition Fact Notes"
                />
              </Grid>
            </Grid>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={!this.state.saving ? this.saveRecipe : undefined}
            onDoubleClick={() => {
              console.log('double')
            }}
          >
            Save Recipe
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
export default withStyles(EditorStylesheet)(
  connect(
    null,
    { saveRecipe }
  )(RecipeEditor)
)

class IngredientsEditor extends Component {
  bubbleIngredient = (value, index) => {
    this.props.onChange([
      ...this.props.value.slice(0, index),
      value,
      ...this.props.value.slice(index + 1)
    ])
  };

  render() {
    const {
      value,
      onButtonClick,
      onDelete,
      classes: { buttonRow }
    } = this.props
    return [
      ...value.map((row, i) => (
        <IngredientRow
          row={row}
          onChange={this.bubbleIngredient}
          onDelete={onDelete}
          index={i}
          key={i}
        />
      )),
      <Grid item xs={12} className={buttonRow} key="button">
        <Button color="primary" onClick={onButtonClick}>
          <Add />
          Add Ingredient
        </Button>
      </Grid>
    ]
  }
}
IngredientsEditor = withStyles(EditorStylesheet)(IngredientsEditor)

class IngredientRow extends Component {
  constructor() {
    super()
    this.state = {
      filter: ''
    }
  }

  // componentDidMount = () => {
  //   console.log(this.ingredientName.node.focus)
  //   this.ingredientName.node.focus()
  // }
  set = (name, value, numeric) => {
    numeric = numeric === 'numeric'
    if (numeric) {
      value = value.replace(/[^0-9.]/g, '')
      if (value.split('.').length > 2) {
        value = this.props.row[name]
      }
    }
    let newValue = { ...this.props.row, [name]: value }
    if (name === 'name') {
      newValue = { ...newValue, subUnit: '' }
    }
    this.props.onChange(newValue, this.props.index)
    if (name === 'name' || name === 'subUnit') {
      this.setState({
        filter: ''
      })
    }
  };

  handleInputClick = e => {
    e.stopPropagation()
  };

  setFilter = e => {
    this.setState({
      filter: e.target.value
    })
  };

  deleteRow = () => {
    this.props.onDelete(this.props.index)
  };

  render() {
    // ingredients.filter(ing => ing.name.toLowerCase().includes(this.state.filter.toLowerCase())).map(ing => (
    const {
      ingredients,
      ingredientsLookup,
      row: { name, amount, subUnit },
      classes: { input, filter, ingredientRow, deleteButton }
    } = this.props
    // console.log("ingredientsLookup", ingredientsLookup);
    const subUnitsOptions =
      name !== '' ? subUnits[ingredientsLookup[name].units] : []
    return (
      <Grid item container xs={12} className={ingredientRow}>
        <IconButton className={deleteButton} onClick={this.deleteRow}>
          <Delete />
        </IconButton>
        <Grid item sm={5} xs={12} key={0}>
          <TextField
            select
            value={name}
            onChange={e => {
              this.set('name', e.target.value)
            }}
            className={input}
            label="Name"
          >
            <FilterInput
              className={filter}
              type="text"
              value={this.state.filter}
              onClick={this.handleInputClick}
              onChange={this.setFilter}
            />
            {ingredients
              .filter(ing =>
                ing.name.toLowerCase().includes(this.state.filter.toLowerCase())
              )
              .map(ing => (
                <MenuItem value={ing.id} key={ing.id}>
                  {ing.name}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item sm={4} xs={12} key={1}>
          <TextField
            value={amount}
            onChange={e => {
              this.set('amount', e.target.value, 'numeric')
            }}
            className={input}
            label="Amount"
          />
        </Grid>
        <Grid item sm={3} xs={12} key={2}>
          {/* <TextField value={subUnit} onChange={e=>{this.set('subUnit',e.target.value,'numeric')}} className={input} label='Unit' /> */}
          <TextField
            select
            disabled={subUnitsOptions.length === 0}
            value={subUnit}
            onChange={e => {
              this.set('subUnit', e.target.value)
            }}
            className={input}
            label="Unit"
          >
            <FilterInput
              className={filter}
              type="text"
              value={this.state.filter}
              onClick={this.handleInputClick}
              onChange={this.setFilter}
            />
            {subUnitsOptions
              .filter(unit =>
                unit.value
                  .toLowerCase()
                  .includes(this.state.filter.toLowerCase())
              )
              .map(unit => (
                <MenuItem value={unit.value} key={unit.value}>
                  {unit.label}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      </Grid>
    )
  }
}
IngredientRow = withStyles(EditorStylesheet)(
  connect(state => ({
    ingredientsLookup: Array.isArray(state.ingredients)
      ? state.ingredients.reduce((p, c, i) => {
        return { ...p, [c.id]: c }
      }, {})
      : {}, // state.ingredients,
    ingredients: orderBy(state.ingredients.filter(ing => !ing.archive), [
      'name'
    ])
  }))(IngredientRow)
)

class FilterInput extends Component {
  componentDidMount = () => {
    this.input.focus()
  };

  render() {
    const { className, value, onClick, onChange } = this.props
    return (
      <input
        ref={input => {
          this.input = input
        }}
        className={className}
        type="text"
        value={value}
        onClick={onClick}
        onMouseDown={onClick}
        onMouseUp={onClick}
        onChange={onChange}
      />
    )
  }
}
