import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'

const shellStyles = theme => ({
  body: {
    padding: '120px 30px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  content: {
    padding: 30,
    width: '100%',
    maxWidth: 800,
    marginBottom: 20
  },
  column: {
    flex: '0 0 25%',
    padding: '0px 5px',
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: -5,
    marginRight: -5
  }
})

export let Body = ({children, classes: { body }, className=''}) => (
  <div className={`${body} ${className}`}>{children}</div>
)
Body = withStyles(shellStyles)(Body)

export let Content = ({children, classes: { content }, className=''}) => (
  <Paper className={`${content} ${className}`}>{children}</Paper>
)
Content = withStyles(shellStyles)(Content)

export let Row = ({children, className, classes: {row}}) => (
  <div className={`${row} ${className}`}>{children}</div>
)
Row = withStyles(shellStyles)(Row)

export let Column = ({children, className, width=25, classes: {column}}) => (
  <div className={`${column} ${className}`} style={{flex: `0 0 ${width}%`}}>{children}</div>
)
Column = withStyles(shellStyles)(Column)
