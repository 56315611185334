import React from 'react'
import { ApolloClient } from 'apollo-boost'
import { ApolloLink, split } from 'apollo-link'
import { Query } from 'react-apollo'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { setContext } from 'apollo-link-context'

import gql from 'graphql-tag'
import store from './store'
import { attachLoginListener } from './actions'
import { baseNodeUrl as baseUrl } from './api'

export const GRAPHQL_API_URL = `${baseUrl}/graphql`
export const GRAPHQL_API_WS = `ws${baseUrl.split(/http/)[1]}`

const getUrl = window.location
const isDev =
  `${getUrl.protocol}//${getUrl.host}/` === 'http://localhost:3000/'

// console.log("GRAPHQL_API_URL: ", GRAPHQL_API_URL);
// console.log("GRAPHQL_WS: ", GRAPHQL_API_WS);
// Create an http link:
const httpLink = new HttpLink({
  uri: GRAPHQL_API_URL,
})

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: GRAPHQL_API_WS,
  options: {
    reconnect: true,
  },
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists

  // return the headers to the context so httpLink can read them

  const token = localStorage.getItem('firebase.auth.idToken')
  return {
    headers: {
      ...headers,
      idToken: token ? `${token}` : '',
    },
  }
})

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  httpLink
)

const getHeaders = () => {
  const idToken = localStorage.getItem('firebase.auth.idToken')
  return {
    'content-type': 'application/json',
    idToken,
  }
}

let lastRefresh = new Date()


function handleTextResponse(response) {
  return response.text().then((text) => {
    if (response.ok) {
      return text
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      status: response ? response.status : 500,
      statusText: response ? response.statusText : 'Error occurred in request.',
      err: text,
    })
  })
}


export const refreshAuth = async () => {
  return attachLoginListener(store.dispatch, () => {})
}

const AuthenticationEnforcer = onError(
  ({ networkError }) => {
    if (networkError) {
      if (networkError.statusCode === 440) {
        // filter for 1 minute
        refreshAuth()
        // console.log("post it!")
      } else if (networkError.statusCode === 401) {
        // filter for 1 minute
        const now = new Date()
        const minutes = 1
        // console.log('before the check.', now, lastRefresh);
        if (now >= lastRefresh) {
          refreshAuth()
          lastRefresh = new Date(now.getTime() + minutes * 60000)
          // console.log('after the check.', now, lastRefresh);
        }
        // console.log("post it!")
      }
    }
  }
)

export const config = (data) => ({
  body: JSON.stringify(data), // must match 'Content-Type' header
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  credentials: isDev ? 'include' : 'same-origin', // include, same-origin, *omit
  headers: getHeaders(),
  method: 'GET', // *GET, POST, PUT, DELETE, etc.
  mode: 'cors', // no-cors, cors, *same-origin
  redirect: 'follow', // *manual, follow, error
  referrer: 'no-referrer', // *client, no-referrer
})


function handleJSONResponse(response) {
  return response !== ''
    ? response.json().then((json) => {
      if (response.ok) {
        return json
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        ...json,
        status: response ? response.status : 500,
        statusText: response
          ? response.statusText
          : 'Error occurred in request.',
      })
    })
    : []
}


function handleResponse(response) {
  const contentType = response.headers.get('content-type')
  if (contentType && contentType.includes('application/json')) {
    return handleJSONResponse(response)
  }
  if (contentType === undefined || contentType.includes('text/html')) {
    return handleTextResponse(response)
  }
  // Other response types as necessary. I haven't found a need for them yet though.
  throw new Error(`Sorry, content-type ${contentType} not supported`)
}

export const fetchIt = (con, url, success, fail) => {
  return fetch(baseUrl + url, con)
    .then(handleResponse)
    .then((data) => success(data))
    .catch((error) => fail(error))
}

export const DefaultQuery = ({query, variables, pollInterval = 300000, render, type = 'settings'}) => (
  <Query
    query={query}
    variables={{ ...variables }}
    fetchPolicy="cache-and-network"
    pollInterval={pollInterval}
  >
    {({ loading, error, data }) => {
      if (loading) return 'Loading...'
      if (error) return `Error! ${error.message}`
      return render(
        data[type] || [{}]
      )
    }}
  </Query>
)

export const client = new ApolloClient({
  // By default, this client will send queries to the
  // `/graphql` endpoint on the same host
  // Pass the configuration option { uri: YOUR_GRAPHQL_API_URL } to the `HttpLink` to connect
  // to a different host
  link: ApolloLink.from([
    authLink,
    AuthenticationEnforcer,
    link,
    // new HttpLink({ uri: `${BASE_URL}/graphql` }),
  ]),
  cache: new InMemoryCache(),
})

/** *****************************************************************
 *
 *
 *          QUERIES!!!!
 *
 *
 ****************************************************************** */

// Classes
export const GET_CLASS = gql`
  query getClass($id: Int!) {
    class_event(class_event_id: $id) {
      id
      class_event_id
      original_id
      value
      date
      sku
      class_type
      location_id
      participants
      small
      regular
      recipes
      capacity
      template_id
    }
  }
`

export const GET_CLASS_ALL = gql`
  query gClass($where: SequelizeJSON!) {
    class_events(where: $where, order: "class_event_id") {
      id
      class_event_id
      original_id
      value
      date
      sku
      class_type
      location
      location_id
      participants
      small
      regular
      recipes
      capacity
      active
      template_id
    }
  }
`

export const CREATE_CLASS = gql`
  mutation createClass(
    $value: SequelizeJSON!,
    $date: Date!,
    $sku: String!,
    $class_type: String!,
    $location_id: String!,
    $participants: String!,
    $small: Int!,
    $regular: Int!,
    $recipes: SequelizeJSON!,
    $capacity: Int!
    ) {
    createClass_event( 
      value: $value,
      date: $date,
      sku: $sku,
      class_type: $class_type,
      location_id: $location_id,
      participants: $participants,
      small: $small,
      regular: $regular,
      recipes: $recipes,
      capacity: $capacity
      ) {
      id
      class_event_id
      value
      date
      sku
      class_type
      location_id
      participants
      small
      regular
      recipes
      capacity
    }
  }
`

export const UPDATE_CLASS = gql`
  mutation updateClass(
    $class_event_id: String!,
    $value: SequelizeJSON!,
    $date: Date!,
    $sku: String!,
    $class_type: String!,
    $location_id: String!,
    $participants: String!,
    $small: Int!,
    $regular: Int!,
    $recipes: SequelizeJSON!,
    $capacity: Int!
    ) {
    updateClass_event(
      class_event_id: $class_event_id,
      value: $value,
      date: $date,
      sku: $sku,
      class_type: $class_type,
      location_id: $location_id,
      participants: $participants,
      small: $small,
      regular: $regular,
      recipes: $recipes,
      capacity: $capacity
      ) {
        id
        class_event_id
        original_id
        value
        date
        sku
        class_type
        location_id
        participants
        small
        regular
        recipes
        capacity
    }
  }
`

export const DELETE_CLASS = gql`
  mutation deleteClass($id: String!) {
    deleteClass_event(where: { class_event_id: $id }) {
      id
    }
  }
`

export const class_update_list = (data, mutateClasses, where) => {
  let newData = data

  mutateClasses.map((ei) => {
    const {
      db_id: class_event_id = null,
      original_id = null,
      id,
      date,
      recipes,
      regular,
      small,
      participants,
      location,
      classType: class_type,
      sku,
      ...value
    } = ei || {}

    const eachItem = {
      class_event_id,
      original_id,
      id,
      date,
      recipes,
      regular,
      small,
      participants: String(participants),
      location,
      class_type,
      sku,
      __typename: 'class_event',
      value,
    }
    const { class_events } = newData
    const index = class_events.findIndex(
      (c) => c.class_event_id === class_event_id
    )
    window.class_events = class_events

    if (ei.record_status === 'archive'){
      newData = {
        class_events: class_events.filter(
          (c) => c.class_event_id !== class_event_id
        ),
      }
    } else {
      let ces = []
      if (index < 0) { ces = [eachItem, ...class_events] } else
      if (index === 0) { ces = [
        eachItem,
        ...class_events.slice(index + 1, class_events.length),
      ] } else
      if (index === class_events.length - 1) { ces = [...class_events.slice(0, index), eachItem] } else {
        ces = [
          ...class_events.slice(0, index),
          eachItem,
          ...class_events.slice(index + 1, class_events.length),
        ]
      }
      newData = {
        class_events: ces
      }
    }
    return eachItem
  })

  client.writeQuery({
    query: GET_CLASS_ALL,
    variables: where,
    data: newData,
  })
}

// Variations
// export const GET_VARIATION = gql``
//
// export const UPDATE_VARIATION = gql``
//
// export const CREATE_VARIATION = gql``
//
// export const DELETE_VARIATION = gql``

// Class Types
export const GET_CLASS_TEMPLATES = gql`
  query getClassTemplates($where: SequelizeJSON) {
    class_templates(where: $where) {
      id
      key
      name
      base_price
      purchase_note
      description
      short_description
      sku_format
      active
      created_at
      updated_at
    }
  }
`

export const UPDATE_CLASS_TYPE = gql`
  mutation uClassType(
    $class_type_id: String!
    $name: String
    $base_price: Int
    $sku_format: String
    $purchase_note: String
    $description: String
    $short_desc: String
    $size_option_id: Int
  ) {
    updateClass_type(
      class_type_id: $class_type_id
      name: $name
      base_price: $base_price 
      sku_format: $sku_format
      purchase_note: $purchase_note
      description: $description
      short_desc: $short_desc
      size_option_id: size_option_id
    ) {
      class_type_id
      name
      base_price
      sku_format
      purchase_note
      description
      short_desc
      size_option_id
      id
    }
  }
`

export const CREATE_CLASS_TYPE = gql`
mutation uClassType(
    $name: String!
    $base_price: Int!
    $sku_format: String!
    $purchase_note: String
    $description: String
    $short_desc: String
    $size_option_id: Int!
  ) {
    updateClass_type(
      name: $name
      base_price: $base_price 
      sku_format: $sku_format
      purchase_note: $purchase_note
      description: $description
      short_desc: $short_desc
      size_option_id: $size_option_id
    ) {
      name
      base_price
      sku_format
      purchase_note
      description
      short_desc
      size_option_id
      id
    }
  }
`

export const DELETE_CLASS_TYPE = gql`
  mutation dClassType($class_type_id: String!) {
    deleteClass_type(where: {class_type_id: $class_type_id}) {
      id
    }
  }
`

// Ingredients
export const GET_INGREDIENT = gql`
  query gIngredient($where: SequelizeJSON) {
    ingredients(where: $where) {
      id
      value
      original_id
      ingredient_id
    }
  }
`

export const CREATE_INGREDIENT = gql`
  mutation createIngredient($id: String!, $value: SequelizeJSON!) {
    createIngredient(original_id: $id, value: $value) {
      id
      value
      original_id
      ingredient_id
    }
  }
`

export const UPDATE_INGREDIENT = gql`
  mutation updateIngredient($id: String!, $value: SequelizeJSON!) {
    updateIngredient(ingredient_id: $id, value: $value) {
      id
      value
      original_id
      ingredient_id
    }
  }
`

export const DELETE_INGREDIENT = gql`
  mutation deleteIngredient($id: String!) {
    deleteIngredient(where: { ingredient_id: $id }) {
      ingredient_id
    }
  }
`

// Recipes
export const GET_RECIPE = gql`
  query gRecipe($where: SequelizeJSON) {
    recipes(where: $where) {
      id
      value
      original_id
      recipe_id
    }
  }
`

export const CREATE_RECIPE = gql`
  mutation createRecipe($id: String!, $value: SequelizeJSON!) {
    createRecipe(original_id: $id, value: $value) {
      id
      value
      original_id
      recipe_id
    }
  }
`

export const UPDATE_RECIPE = gql`
  mutation updateRecipe($id: String!, $value: SequelizeJSON!) {
    updateRecipe(recipe_id: $id, value: $value) {
      id
      value
      original_id
      recipe_id
    }
  }
`

export const DELETE_RECIPE = gql`
  mutation deleteRecipe($id: String!) {
    deleteRecipe(where: { recipe_id: $id }) {
      recipe_id
    }
  }
`

// Settings
export const GET_SETTING = gql`
  query getSetting($type: String, $sub_type: String, $sub_sub_type: String) {
    settings(
      where: { type: $type, sub_type: $sub_type, sub_sub_type: $sub_sub_type }
    ) {
      setting_id
      data
    }
  }
`

export const UPDATE_SETTING = gql`
  mutation uSetting($data: SequelizeJSON!, $setting_id: String!) {
    updateSetting(setting_id: $setting_id, data: $data) {
      setting_id
      data
    }
  }
`

export const CREATE_SETTING = gql`
  mutation cSetting(
    $data: SequelizeJSON!
    $type: String
    $sub_type: String
    $sub_sub_type: String
  ) {
    createSetting(
      type: $type
      sub_type: $sub_type
      sub_sub_type: $sub_sub_type
      data: $data
    ) {
      setting_id
      data
    }
  }
`

export const DELETE_SETTING = gql`
  mutation deleteSetting($id: String!) {
    deleteSetting_event(where: { setting_id: $id }) {
      id
    }
  }
`

// Users
export const LOCATIONS_FOR_USER = gql`
  query getLocationsForUser($uid: String!) {
  users(where: { firebase_user_id: $uid }) {
    locations {
      location_id
    }
  }
}
`

// Locations
export const GET_LOCATIONS = gql`
  query getLocations($where: SequelizeJSON) {
    locations(where: $where) {
      location_id
      name
      data
      active
      allow_custom_class
    }
  }
`


