import axios from 'axios'

import gql from 'graphql-tag'
import { runQuery, baseNodeUrl, exportParticipants, getClasses, getLocations } from '../api'
import { classTypes } from '../data'

const GET_LOCATIONS = gql`
  {
    locations(order: "name"){
      id,
      location_id
      active
      name
      data
    }
  }`


const GET_TIME_PUNCHES = gql`
query gPunchs($where: SequelizeJSON!) {
  time_punchs(
    where: $where
    order: "class_event_id"
  ) {
    time_punch_id
    class_event_id
    approved
    role
    user_email
    id
    time_punch_id
    in_time
    location_id
    scheduled_date
    out
  }
}
`

export const getPayrollData = async (class_events, cb) =>
{
  let classIds = class_events.map((class_event) => {return class_event.id}).join('|')

  console.log(classIds)

  const classEvents = await getClasses({'ids': classIds, 'relations': 'classRecipes|classLocation|diet|fulfillment|registrations|registrations.portionQuantity|registrations.portionSize'})
  const kiyomiData = await getKiyomiData(class_events)
  const participants = await exportParticipants(class_events, cb)
  const locations = await getLocations({})

  return { locations: locations.data, kiyomiData: kiyomiData, class_events: classEvents.data, participants: participants }

  // const participants = [];
  // const { locations } = await runQuery(GET_LOCATIONS)
  // const { time_punchs } = await runQuery(GET_TIME_PUNCHES, {
  //   where: {
  //     class_event_id: { in: class_events.map(c => c.class_event_id) },
  //     in_time: {not: null},
  //     approved: true
  //   }
  // });
  // Promise.all([classEvents, kiyomiData, participants, locations])
  //   .then((values) => {
  //     console.log({'promise all': values, classes: values[0].data})
  //
  //     return { locations: values[3].data, kiyomiData: values[1], class_events: values[0].data, participants: values[2] }
  //   })
  //   .catch((errors) => {
  //     console.error(errors)
  //   })
}

export const payroll = ({ class_events, kiyomiData, locations, participants, recipes }) =>
{
  console.log({class_events, kiyomiData, locations, participants, recipes})
  
  return new Promise((res, rej) =>
  {
    const class_types = classTypes.reduce((p, c) =>
    {
      return {...p, [c.id]: c}
    }, {})

    // const recipes = [];
    const first = class_events[0]
    const last = class_events[class_events.length - 1]
    const daterange = `${(new Date(first.date)).toLocaleDateString()} -> ${(new Date(last.date)).toLocaleDateString()}`

    const [classes, classes_order] = fixClasses(class_events, class_types, locations, recipes)
    const [punches, punches_order] = fixKiyomiData(kiyomiData)
    const [orders, orders_order] = fixParticipants(participants)
    const [locs, locs_order] = fixLocations(locations)
    console.log({ classes, punches, orders, locs})

    let data = JSON.stringify({
      data:[{
        order: punches_order,
        data: punches,
        range: `Time Punches!A1:JJ${punches.length+ 1}`
      },
      {
        order: classes_order,
        data: classes,
        range: `Classes!A1:GG${classes.length+ 1}`
      },
      {
        order: orders_order,
        data: orders,
        range: `Orders!A1:GG${orders.length+ 1}`
      },
      {
        order: locs_order,
        data: locs,
        range: `Locations!A1:JJ${locs.length + 1}`
      }],
      templateSpreadsheetId: '136Hwp-prbtrUXVcE014dOEf9ABNySAEG7GFB8kDoUwg',
      fileName: `${daterange}Kiyomi`,
      email: ["kelsi@citruspeardinners.com", "mace@citruspeardinners.com", "mckenzie@citruspeardinners.com"],
      parents: ["1F9mN6WUd3ngxqKx0z2KQnmwaXSJtpBGW"]
    })

    axios.post(`${baseNodeUrl}/api/kiyomi`,
      data,
      {headers: { 'Content-type': 'application/json', idToken: localStorage.getItem('firebase.auth.idToken') }})
      .then((response) => {
        console.log(response)
        const { spreadsheetId } = response.data
        res({ spreadsheetId })
      })
      .catch((error) => {
        console.log(error)
        rej(error)
      })

    // fetch(`${baseNodeUrl}/api/kiyomi`, {
    //   method: 'POST',
    //   headers: {
    //     'Content-type': 'application/json',
    //     idToken: localStorage.getItem('firebase.auth.idToken')
    //   },
    //   body: JSON.stringify({
    //     data:[{
    //       order: punches_order,
    //       data: punches,
    //       range: `Time Punches!A1:JJ${punches.length+ 1}`
    //     },
    //     {
    //       order: classes_order,
    //       data: classes,
    //       range: `Classes!A1:GG${classes.length+ 1}`
    //     },
    //     {
    //       order: orders_order,
    //       data: orders,
    //       range: `Orders!A1:GG${orders.length+ 1}`
    //     },
    //     {
    //       order: locs_order,
    //       data: locs,
    //       range: `Locations!A1:JJ${locs.length + 1}`
    //     }],
    //     templateSpreadsheetId: '136Hwp-prbtrUXVcE014dOEf9ABNySAEG7GFB8kDoUwg',
    //     fileName: `${daterange}Kiyomi`,
    //     email: ["kelsi@citruspeardinners.com", "mace@citruspeardinners.com", "mckenzie@citruspeardinners.com"],
    //     parents: ["1F9mN6WUd3ngxqKx0z2KQnmwaXSJtpBGW"]
    //   })
    // })
    //   .then(res => {
    //     return res.json()
    //   })
    //   .then(data => {
    //     const { spreadsheetId } = data
    //     res({ spreadsheetId })
    //   })
    //   .catch(e => {
    //     rej(e)
    //     console.log(e)
    //   })
  })
    
}


export const getKiyomiData = (class_events) =>
{
  let classes = class_events.map((class_event) => {return class_event.id}).join('|')

  return axios.get(`${baseNodeUrl}/api/kiyomi/data`, {
    headers: {idToken: localStorage.getItem('firebase.auth.idToken'), },
    params: {classes: classes}
  })
    .then((response) => {
      console.log(response.data)
      return response.data
    })
    .catch((error) => {console.error(error)})

  // return new Promise((res, rej) =>
  // {
  //   fetch(`${baseNodeUrl}/api/kiyomi/data`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-type': 'application/json',
  //       idToken: localStorage.getItem('firebase.auth.idToken')
  //     },
  //     body: JSON.stringify({
  //       classes: class_events.map(class_event => class_event.id)
  //     })
  //   })
  //     .then(res => {
  //       return res.json()
  //     })
  //     .then(data => {
  //       res(data)
  //     })
  //     .catch(e => {
  //       rej(e)
  //       console.log(e)
  //     })
  // })
    
}

const fixPunches = punches =>
{
  const order = [
    'time_punch_id',
    'user_email',
    'class_event_id',
    'location_id',
    'scheduled_date',
    'in_time',
    'out',
    'role'
  ]
  return [ punches, order ]
}

const fixLocations = locations =>
{
  const newLocations = locations.map((location) => {
    {
      // const { data, ...rest } = l
      // return { ...data, ...rest }

      return {
        'location_id': location.id,
        'name': location.name,
        'active': location.active,
        'Store_Name': location.store_name,
        'Address': location.store_address,
        'Lead_RD': location.lead.name,
        'Email_Address': location.lead.email,
        'Category_ID': location.category_id ?? '',
        'Code': location.code,
      }
    }
  })
  const order = [
    'location_id',
    'name',
    'active',
    'Store_Name',
    'Address',
    'Lead_RD',
    'Email_Address',
    'Category_ID',
    'Code'
  ]
  return [ newLocations, order ]
}

const fixClasses = (classes, class_types, locations, recipes) =>
{
  console.log({'fix classes': classes})

  let newClasses = classes.map((citrusClass) => {
    let newRecipes = citrusClass.recipes.map((recipe) => {return {id: recipe.id, name: recipe.name, amount: 0}})
    let large = 0
    let medium = 0
    let small = 0

    if(citrusClass?.registration_breakdown) {
      for (const [key, value] of Object.entries(citrusClass.registration_breakdown)) {
        if(key === 'small') {for (const [small_key, small_value] of Object.entries(value)) { small += small_value}}
        if(key === 'medium') {for (const [medium_key, medium_value] of Object.entries(value)) { medium += medium_value}}
        if(key === 'large') {for (const [large_key, large_value] of Object.entries(value)) { large += large_value}}
      }

      // Object.entries(citrusClass.registration_breakdown).forEach(([pKey, pValue]) => {
      //   if(pKey === 'large') {
      //     Object.entries(pValue).forEach(([qKey, qValue]) => {
      //       if(qKey === 'ten') large += (qValue * 1)
      //       if(qKey === 'twenty') large += (qValue * 2)
      //       if(qKey === 'forty') large += (qValue * 4)
      //     })
      //   }
      //   if(pKey === 'medium') {
      //     Object.entries(pValue).forEach(([qKey, qValue]) => {
      //       if(qKey === 'ten') medium += (qValue * 1)
      //       if(qKey === 'twenty') medium += (qValue * 2)
      //       if(qKey === 'forty') medium += (qValue * 4)
      //     })
      //   }
      //   if(pKey === 'small') {
      //     Object.entries(pValue).forEach(([qKey, qValue]) => {
      //       if(qKey === 'ten') small += (qValue * 0.5)
      //       if(qKey === 'twenty') small += (qValue * 1)
      //       if(qKey === 'forty') small += (qValue * 2)
      //     })
      //   }
      // })
    }

    return {
      class_event_id: citrusClass.id,
      recipes: JSON.stringify(newRecipes),
      date: citrusClass.date,
      sku: citrusClass.sku,
      large: large,
      medium: medium,
      small: small,
      participants: citrusClass.registrations_count,
      location: citrusClass.location.name,
      class_type: `${citrusClass.diet.name} ${citrusClass.fulfillment.name}`,
      name: citrusClass.name,
      desc: '',
      classType: '',
      assembled: citrusClass.fulfillment.key === 'pre-assembled',
      changeAmounts: '',
      stock: '',
      notes: citrusClass.notes,
    }
  })

  console.log(newClasses)

  // const newClasses = classes.map(c =>
  // {
  //   const { value, ...rest } = c
  //   const newRecipes = rest.recipes.filter(rec => rec.name).map(rec =>
  //   {
  //     const r = recipes.find(r => r.id === rec.name)
  //     if (!r) {
  //       console.error(`class: ${c.sku} has an issue with recipes, recipe: ${rec.name}`)
  //       return { id: '', name: '', amount: '' }
  //     }
  //     return { id: r.db_id, name: r.name, amount: rec.amount }
  //   }
  //   )
  //   const location = locations.find(l => String(l.data.Category_ID) === String(rest.location))
  //   const class_type = class_types[rest.class_type] ? class_types[rest.class_type] : {}
  //   return {
  //     ...value,
  //     ...rest,
  //     class_type: class_type.name,
  //     location: location ? location.name : rest.location,
  //     recipes: JSON.stringify(
  //       newRecipes
  //     )
  //   }
  // })

  const order = [
    'class_event_id',
    'recipes',
    'date',
    'sku',
    'large',
    'medium',
    'small',
    'participants',
    'location',
    'class_type',
    'name',
    'desc',
    'classType',
    'assembled',
    'changeAmounts',
    'stock',
    'notes'
  ]
  return [ newClasses, order ]
}

const fixParticipants = orders =>
{

  console.log({'fixParticipants': orders})

  const newOrders = orders.map(l =>
  {
    const { value, ...rest } = l
    return { ...value, ...rest, recipes: JSON.stringify(rest.recipes) }
  })
  const order = [
    'number',
    'status',
    'date_created',
    'first_name',
    'last_name',
    'email',
    'phone',
    'sku',
    'item_name',
    'variation_id',
    'quantity',
    'order_line_subtotal',
    'order_line_taxes',
    'coupon',
    'discount_amt',
    'payment_method',
    'order_refund_amt',
    'date_modified',
    'PortionSize',
    'PortionQty',
  ]
  return [ newOrders, order ]
}

const fixKiyomiData = orders =>
{
  const newOrders = orders.map(o =>
    ({
      ...o,
      ...(o.time_worked === null ?
        { time_worked: 0 }
        :
        { time_worked: o.time_worked })
    }))
  const order = [
    "time_punch_id",
    "class_event_id",
    "sku",
    "name",
    "scheduled_date",
    "in_time",
    "approved",
    "out",
    "time_worked",
    "role",
    "base",
    "min",
    "rate",
    "rate2",
    "small",
    "regular",
    "participants",
    "sets",
    "calc_rate",
    "valid",
    "line_total",
  ]
  return [ newOrders, order ]
}


export default payroll