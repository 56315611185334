import React, { useEffect, useState, Fragment, useRef } from 'react'
import { gql } from "apollo-boost"
import { useQuery, useMutation } from '@apollo/react-hooks'
import PublishSharp from '@material-ui/icons/PublishSharp'
import ArrowDown from '@material-ui/icons/ArrowDownwardSharp'
import AddSharp from '@material-ui/icons/AddSharp'
import GetAppSharp from '@material-ui/icons/GetAppSharp'
import ArrowUpward from '@material-ui/icons/ArrowUpwardSharp'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Typography,
  TextField,
  Fab
} from '@material-ui/core/'
import Autocomplete from '@material-ui/lab/Autocomplete'
import EditIcon from '@material-ui/icons/Edit'
import Add from '@material-ui/icons/Add'
import { Link, Route } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import { withStyles } from '@material-ui/core/styles'
import UsersEditor from './UsersEditor'
import { exportList, baseNodeUrl , runQuery } from '../api'
import { handle } from '../utilities'
import importUsers from '../helpers/importUsers'
import { LoginRedirector } from './Auth'
import { Body, Content } from './Shell'
import FabFabulous from './FabFabulous'
import { GET_LOCATIONS } from './Locations'
import { GET_ROLES } from './Roles'


const ClassesStyles = theme => ({
  fab: {
    position: 'fixed',
    bottom: 30,
    right: 30,
    color: '#fff'
  }
})

const GET_USERS_OLD = gql`
query gUsers{
  users(order: "name") {
    user_id
    name
    phone
    email
    id
    active
  }
}
`


const CREATE_USER = gql`
  mutation cUser(
    $name: String!
    $phone: String!
    $address: String!
    $city: String!
    $state: String!
    $zip: String!
    $email: String!
    $active: Boolean!
    $birthday: Date!
    $hire_date: Date!
    $notes: String!
    $auth_level: Int!
    $food_handler_date: Date!,
    $paperwork_complete: Boolean!
  ) {
    createUser(
      name: $name
      phone: $phone
      address: $address
      city: $city
      state: $state
      zip: $zip
      email: $email
      active: $active
      birthday: $birthday
      hire_date: $hire_date
      auth_level: $auth_level
      notes: $notes
      food_handler_date: $food_handler_date,
      paperwork_complete: $paperwork_complete
    ) {
      user_id
      name
      phone
      email
      id
      active
    }
  }
`


const CREATE_ROLE = gql`
mutation cRole(
  $name: String!,
  $active: Boolean!
) {
    createRole(
      name: $name,
      active: $active
    ) {
      id
      role_id
      name
      active
    }
  }
`

const GET_USERS = gql`
query gUsers{
  users(order: "name") {
    user_id
    name
    phone
    address
    city
    state
    zip
    firebase_user_id
    email
    id
    active
    birthday
    hire_date
    notes
    food_handler_date
    paperwork_complete
    auth_level
    locations{
      id
      user_x_location_id
      user_id
      location_id
    }
  	roles{
      id
      user_x_role_id
      user_id
      role_id
      rate
      base
      min
      rate2
    }
  }
}
`


const CREATE_USER_X_ROLE = gql`
mutation cUserRole(
  $role_id: String!,
  $user_id: String!,
  $rate: String!,
  $base: String!,
  $min: String!,
  $rate2: String!
) {
    createUser_x_role(
      role_id: $role_id,
      user_id: $user_id,
      rate: $rate,
      base: $base,
      min: $min,
      rate2: $rate2
    ) {
      id
      user_x_role_id
      user_id
      role_id
      rate
      base
      min
      rate2
    }
  }
`


const CREATE_USER_X_LOCATION = gql`
mutation cUserLocation(
  $location_id: String!,
  $user_id: String!
) {
    createUser_x_location(
      location_id: $location_id,
      user_id: $user_id
    ) {
      id
      location_id
      user_id
    }
  }
`
const exportUsersGoogle = users =>
{
  const usersOrder = [
    "user_id",
    "name",
    "phone",
    "address",
    "city",
    "state",
    "zip",
    "firebase_user_id",
    "email",
    "id",
    "active",
    'birthday',
    "hire_date",
    "notes",
    "food_handler_date",
    "paperwork_complete",
    "auth_level",
    "locations",
    "roles",
  ]
  return new Promise((res, rej) =>
  {
    fetch(`${baseNodeUrl}/api/kiyomi`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        idToken: localStorage.getItem('firebase.auth.idToken')
      },
      body: JSON.stringify({
        data:[{
          order: usersOrder,
          data: users,
          range: `FROM LUMIA!A1:Z${users.length+ 1}`
        }],
        templateSpreadsheetId: '1uGQny5ddqUcMC-fTNrGgD_z5fidDXsGj5DGqfR827iA',
        fileName: `Users ${(new Date()).toLocaleString()}`,
        email: [],
        parents: ["1XFIQ3aG8eLRY6Ow9ZtyBULOK21xnmS5R"]
      })
    })
      .then(rs => {
        return rs.json()
      })
      .then(data => {
        const { spreadsheetId } = data
        res({ spreadsheetId })
      })
      .catch(e => {
        rej(e)
        console.log(e)
      })
  })
  
}




export function User(props)
{ 
  const {
    user_id,
    name,
    phone,
    email,
    active
  } = props
  const inActive = "#AAA"
  
  // const [updateTimePunch, { data: { out: update_out } = {} }] = useMutation(UPDATE_TIME_PUNCH_LEAD)

  return (
    <TableRow>
      <TableCell style={{ color: active ? '#000' : inActive }} key={0}>
        <Link to={`/users/${user_id}`}>
          <IconButton>
            <EditIcon />
          </IconButton>
        </Link>
      </TableCell>
      <TableCell style={{ color: active ? '#000' : inActive }} key={1}>
        {name}
      </TableCell>
      <TableCell style={{ color: active ? '#000' : inActive }} key={2}>
        {phone}
      </TableCell>
      <TableCell style={{ color: active ? '#000' : inActive }} key={3}>
        {email}
      </TableCell>
    </TableRow>
  )
}


function UsersTable(props)
{
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Action</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Phone</TableCell>
          <TableCell>Email</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.children}
      </TableBody>
    </Table>
  )
}

export function Users(props)
{

  const { data: { users = [] } = {} } = useQuery(GET_USERS)
  const { data: { locations = [] } = {} } = useQuery(GET_LOCATIONS)
  const { data: { roles = [] } = {} } = useQuery(GET_ROLES)

  const closeEditor = () =>
  {
    props.history.push("/users")
  }

  const exportUsers = () =>
  {
    const cleanedUsers = users.map(u => ({
      ...u,
      locations: locations.filter( l => u.locations.map(ul => ul.location_id).includes(l.location_id)).map(lf => lf.name).join(","),
      roles: JSON.stringify(u.roles),
    }))
    exportList({
      name: "Users", data: cleanedUsers, fields: [
        "user_id",
        "name",
        "phone",
        "address",
        "city",
        "state",
        "zip",
        "firebase_user_id",
        "email",
        "id",
        "active",
        'birthday',
        "hire_date",
        "notes",
        "food_handler_date",
        "paperwork_complete",
        "auth_level",
        "locations",
        "roles",
      ] })
  }

  const exportToGoogleSheets = async () =>
  {
    const cleanedUsers = users.map(u => ({
      ...u,
      locations: locations.filter( l => u.locations.map(ul => ul.location_id).includes(l.location_id)).map(lf => lf.name).join(","),
      roles: JSON.stringify(u.roles),
    }))
    const rtn = await exportUsersGoogle(cleanedUsers)
    if (rtn.spreadsheetId) {
      const win = window.open(
        `https://docs.google.com/spreadsheets/d/${rtn.spreadsheetId}/`,
        '_blank'
      )
      win.focus()
    }
  }



  const updateCache = (cache, newUser) =>
  {
    let data
    const test = cache.readQuery({ query: GET_USERS })
    const { users } = test
    console.log({ users, newUser })
    if (newUser.user_id === 'new') delete newUser.user_id
    data = { users: users ? [newUser, ...users] : [newUser] }
    cache.writeQuery({
      query: GET_USERS,
      data
    })
  }

  // file upload handling
  const inputFile = useRef(null)
  const openUpload = () =>
  {
    // `current` points to the mounted file input element
    inputFile.current.click()
  }
  const handleUpload = async event =>
  {
    let percentComplete = 0.0; let num = 0.0; let den = 1.0
    const batchSize = 5


    const users = await importUsers(event.target.files[0], locations, roles)
    const userCount = users.length ? users.length : 1


    console.log("class qty:", userCount)
    for (let i = 0; i < userCount; i += batchSize) {
      const array_of_arrays = await Promise.all(
        users
          .filter((e, j) => (j >= i && j < i + batchSize))
          .map(async u =>
          {
            try {
              const { createUser } = (await runQuery(CREATE_USER, u)) || {}
              console.log({ createUser })
              u.locations.map(async l => await runQuery(CREATE_USER_X_LOCATION, { ...l, user_id: createUser.user_id }))
              const rtnUserXrole = u.rolez.map(async r => await runQuery(CREATE_USER_X_ROLE, { ...r, user_id: createUser.user_id }))
              // const rtnUserXrole = await runQuery(CREATE_USER_X_ROLE, { ...u.role, user_id: createUser.user_id })
              console.log({ rtnUserXrole })
            } catch (e) {
              console.error('Error importing users: ', e)
              return []
            }
          }
          )
      )
      num = (i + batchSize)
      den = userCount
      percentComplete = (num / den) * 100
      console.log(`${num}/${den} = ${percentComplete}`)
      // exportLoading = LoadingFactory(
      //   `Downloading order info: ${percentComplete.toFixed(1)}% complete`
      // );
      // this.props.addLoading(exportLoading, []);
    }



    console.log(users)
  }

  const { classes: { fab } } = props
  // const { data: { users = [] } = {} } = useQuery(GET_USERS);
  return (
    <Body>
      <LoginRedirector />
      <input
        type='file'
        id='file'
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={handleUpload}
      />
      <Content>
        <UsersTable>
          {users.map(u => (
            <User key={u.id} {...u} />
          ))}
        </UsersTable>
        <Route
          path={`${props.match.url  }/:user_id`}
          component={prps => (
            <UsersEditor
              {...prps}
              open={typeof prps.match.params.user_id === 'string'}
              updateCache={updateCache}
              onClose={closeEditor}
            />
          )}
        />
        <FabFabulous
          actions={actions}
          exportUsers={e => exportUsers(users)}
          exportToGoogleSheets={ e => exportToGoogleSheets(users)}
          openUpload={openUpload}
          addUser={e => { props.history.push("/users/new") }}
        />
      </Content>
    </Body>
  )
}

export default withStyles(ClassesStyles)(Users)

export function UserPicker(props)
{
  const [val, setVal] = useState(-1)
  const { onChange, value = "", inputId: key, color, disabled } = props
  const { data: { users = [] } = {} } = useQuery(GET_USERS)

  useEffect(() =>
  {
    users.find((u, i) =>
    {
      const found = u.email === value
      if (found) {
        setVal(i)
      }
      return found
    })
  }, [value, users])


  return (
    <Autocomplete
      id={key}
      options={users}
      getOptionLabel={user => user.name || ""}
      style={{ width: 150, color }}
      value={val === -1 ? val : users[val] }
      onChange={onChange}
      autoHighlight
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          style={{ color }}
          fullWidth />
      )}
    />
  )
}

const actions = [
  { icon: <ArrowDown />, name: 'View', onClick: "exportToGoogleSheets" },
  { icon: <ArrowDown />, name: 'Export', onClick: "exportUsers" },
  { icon: <AddSharp />, name: 'Create', onClick: "addUser" },
  { icon: <ArrowUpward />, name: 'Import', onClick: "openUpload" },
]
