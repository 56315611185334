import { createMuiTheme } from '@material-ui/core/styles'
import lightGreen from '@material-ui/core/colors/lightGreen'

const theme = createMuiTheme({
  background: "linear-gradient(-90deg,  #88c543, #abda1c)",
  palette: {
    // primary: {
    //   main: '#88c543',
    //   contrastText: '#ffffff',
    // },
    // secondary: { 500: "#ff8812" }

    primary: lightGreen,
    secondary: {
      main: "#ff8812",
      contrastText: '#ffffff',
    }
  }
})

export default theme
