import React, { useEffect, useState, Fragment } from 'react'
import { gql } from "apollo-boost"
import { useQuery, useMutation } from '@apollo/react-hooks'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Typography,
  Chip,
  Select,
  InputLabel,
  Input,
  MenuItem,
  Grid,
  Fab
} from '@material-ui/core/'
import EditIcon from '@material-ui/icons/Edit'
import Add from '@material-ui/icons/Add'
import { Link, Route } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import { withStyles } from '@material-ui/core/styles'
import RolesEditor from './RolesEditor'
import { LoginRedirector } from './Auth'
import { Body, Content } from './Shell'

const ClassesStyles = theme => ({
  fab: {
    position: 'fixed',
    bottom: 30,
    right: 30,
    color: '#fff'
  }
})


export const GET_ROLES = gql`
query gRoles{
  roles {
    id
    role_id
    name
    active
  }
}
`


const GET_ROLES_ACTIVE = gql`
query gRoles{
  roles(
    where: {
      active: true
      }
  ) {
    id
    role_id
    name
    active
  }
}
`



export function Role(props)
{ 
  const {
    role_id,
    name,
    active
  } = props
  const inActive = "#AAA"
  
  // const [updateTimePunch, { data: { out: update_out } = {} }] = useMutation(UPDATE_TIME_PUNCH_LEAD)

  return (
    <TableRow>
      <TableCell style={{ color: active ? '#000' : inActive }} key={0}>
        <Link to={`/roles/${role_id}`}>
          <IconButton>
            <EditIcon />
          </IconButton>
        </Link>
      </TableCell>
      <TableCell style={{ color: active ? '#000' : inActive }} key={0}>
        {name}
      </TableCell>
    </TableRow>
  )
}


function RolesTable(props)
{
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Action</TableCell>
          <TableCell>Name</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.children}
      </TableBody>
    </Table>
  )
}

function Roles(props)
{
  const closeEditor = () => {
    props.history.push("/roles")
  }



  const updateCache = (cache, newRole) =>
  {
    const test = cache.readQuery({ query: GET_ROLES })
    const {roles} = test
    console.log({roles})
    cache.writeQuery({
      query: GET_ROLES,
      data: { roles: roles ? [newRole, ...roles] : [newRole] }
    })
  }


  const { classes: { fab } } = props
  const { data: { roles = [] } = {} } = useQuery(GET_ROLES)
  return (
    <Body>
      <LoginRedirector />
      <Content>
        <RolesTable>
          {roles.map(u => (
            <Role {...u} />
          ))}
        </RolesTable>
        <Route
          path={`${props.match.url  }/:role_id`}
          component={prps => (
            <RolesEditor
              {...prps}
              open={typeof prps.match.params.role_id === 'string'}
              updateCache={updateCache}
              onClose={closeEditor}
            />
          )}
        />
        <Fab
          variant="round"
          color="primary"
          aria-label="Add"
          className={fab}
          onClick={e =>
          {
            props.history.push("/roles/new")
          }}
        >
          <AddIcon />
        </Fab>
      </Content>
    </Body>
  )
}

export default withStyles(ClassesStyles)(Roles)

export function RolePicker(props)
{

  const { data: { roles = [] } = {} } = useQuery(GET_ROLES_ACTIVE)
  const {
    value,
    onChange: handleChange,
    title
  } = props
  return (
    <Grid item xs={12}>
      <InputLabel id="demo-mutiple-chip-label" shrink>{title}</InputLabel>
      <Select
        labelId="demo-mutiple-chip-label"
        id="role-picker-multi-select"
        style={{ width: '100%' }}
        autoWidth
        value={value}
        onChange={handleChange}
        input={<Input id="select-multiple-role" />}
      >
        {roles.map(r => (
          <MenuItem key={r.id} value={r.role_id} name={r.name}>
            {r.name}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  )
}

export function RoleIdToName(props)
{
  const { data: { roles = [] } = {} } = useQuery(GET_ROLES)
  const role = roles.find(r => r.role_id === props.children)
  // console.log(props.children, roles, role);
  return (<>
    {role && role.name}
  </>)
}

