import React, { useEffect, useState } from 'react'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'


import {
  Typography,
  IconButton,
  CardContent,
  CardMedia,
  Card,
  Chip,
  Avatar
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { Redirect } from 'react-router'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import SkipNextIcon from '@material-ui/icons/SkipNext'
import { LoginRedirector } from './Auth'
import { Body } from './Shell'
import { useStopwatch } from '../hooks/useStopWatch'
import { getSku } from '../api'
import { LocationPicker, GET_LOCATIONS } from './Locations'
import { setLocations } from '../actions'

const GET_SCHEDULED_TIME_PUNCHES = gql`
  query gPunch($user_email: String!) {
    time_punchs(where: { user_email: $user_email, out: null }) {
      time_punch_id
      user_email
      id
      scheduled_date
      in_time
      class_event_id
      location_id
      out
    }
  }
`

const GET_OPEN_TIME_PUNCHES = gql`
  query gUpcomingPunch($user_email: String!, $start: String!, $end: String!) {
    time_punchs(
      where: {
        user_email: $user_email
        scheduled_date: {or: [
          {is: null},
          { gte: $start, lt: $end }
        ]} 
        role: {or: [
          {is: null},
          { notIn: [1, 4] }
        ]}, 
        class_event_id: {ne: null}     
      }
      order: "scheduled_date"
    ) {
      time_punch_id
      user_email
      id
      scheduled_date
      in_time
      class_event_id
      location_id
      out
    }
  }
`

const CREATE_TIME_PUNCH_TEST = {
  location_id: '1',
  in: '12/01/2019',
  user_email: 'chrisjensen23@gmail.com'
}
const CREATE_TIME_PUNCH = gql`
  mutation cTimePunch(
    $in_time: Date!
    $location_id: String!
    $user_email: String!
    $class_event_id: String!
  ) {
    createTime_punch(
      user_email: $user_email
      in_time: $in_time
      location_id: $location_id
      class_event_id: $class_event_id
    ) {
      time_punch_id
      user_email
      id
      scheduled_date
      in_time
      class_event_id
      location_id
      out
    }
  }
`
const UPDATE_SCHEDULE_TIME_PUNCH = gql`
  mutation updateScheduledTimePunch(
    $time_punch_id: String!
    $in_time: Date!
    $location_id: String!
    $user_email: String!
    $class_event_id: String!
  ) {
    updateTime_punch(
      time_punch_id: $time_punch_id
      user_email: $user_email
      in_time: $in_time
      location_id: $location_id
      class_event_id: $class_event_id
    ) {
      time_punch_id
      id
      scheduled_date
      in_time
      class_event_id
      location_id
      out
    }
  }
`
const UPDATE_TIME_PUNCH = gql`
  mutation uTimePunch($time_punch_id: String!, $out: Date!) {
    updateTime_punch(time_punch_id: $time_punch_id, out: $out) {
      time_punch_id
      id
      scheduled_date
      in_time
      class_event_id
      location_id
      out
    }
  }
`

const GET_CLASS_ID_FROM_SKU = gql`
  query gClassIdFromSku($sku: String!) {
    class_events(where: { sku: $sku }) {
      class_event_id
    }
  }
`

const GET_TIME_PUNCH_FROM_SKU = gql`
  query gTimePunchFromClassId($class_event_id: String!, $user_email: String!) {
    time_punchs(
      where: { class_event_id: $class_event_id, user_email: $user_email }
    ) {
      time_punch_id
      id
      time_punch_id
      scheduled_date
      in_time
      class_event_id
      location_id
      out
    }
  }
`

export default function TimeTracker(props) {
  const { elapsedTime, startTimer, stopTimer, resetTimer } = useStopwatch()
  const [today, setToday] = useState(-1)
  const [noSchedule, setNoSchedule] = useState(false)
  const [location, setLocation] = useState('')
  const [selectedPunch, setSelectedPunch] = useState({})
  useEffect(() => {
    setToday(new Date().getTime())
  }, [])

  // get the current state of the user
  const ls = localStorage.getItem('firebase.auth.idToken')
  const admin_permissions = localStorage.getItem('admin_permissions')
  const json = ls && ls.split('.').length > 1 ? atob(ls.split('.')[1]) : `{}`
  const user = JSON.parse(json)
  const [location_id, setLocationId] = useState(
    localStorage.getItem('time_tracker.location_id') || '-1'
  )
  const [permisions, setPermisions] = useState(
    admin_permissions
      ? JSON.parse(admin_permissions)
      : { isAdmin: false, auth_level: 100 }
  )

  // the rest of the data
  const [class_event_id, setClassEventId] = useState('-1')
  const [sku, setSku] = useState('')
  const [filter, setFilter] = useState('')
  const [user_email, setUserEmail] = useState(user.email)
  const oneDay = 1000 * 60 * 60 * 24
  let lastPayPeriodStart = (new Date()).getDate() - 15
  lastPayPeriodStart = lastPayPeriodStart < 0 ? (new Date()).getDate() : lastPayPeriodStart
  lastPayPeriodStart += 0.6

  const GET_OPEN_TIME_PUNCHES_VAR = {
    user_email,
    start: new Date(today - lastPayPeriodStart * oneDay).toISOString(),
    end: new Date(today + 14 * oneDay).toISOString()
  }
  const { data: punches = {time_punchs: []}, error, loading } = useQuery(GET_OPEN_TIME_PUNCHES, {
    variables: GET_OPEN_TIME_PUNCHES_VAR
  })

  const { data: skuData = {}, refetch: refetchBySku } = useQuery(
    GET_CLASS_ID_FROM_SKU,
    {
      variables: { sku }
    }
  )

  const { data: locationData = {} } = useQuery(GET_LOCATIONS)
  const newEvent =
    skuData && skuData.class_events && skuData.class_events[0]
      ? skuData.class_events[0].class_event_id
      : '-1'

  let { in_time: inTime, out, id, time_punch_id, scheduled_date } = selectedPunch || {}

  useEffect(() =>
  {
    setClassEventId(newEvent)
  }, [newEvent])

  useEffect(() => {
    const loc = (locationData && locationData.locations
      ? locationData.locations
      : []
    ).find(l => String(l.location_id) === location_id)
    localStorage.setItem('time_tracker.location_id', location_id)
    const genSku = getSku(new Date(), loc ? loc.data.Code : '')
    console.log(genSku, loc ? loc.data.Code : '')
    console.log({ loc })
    setSku(genSku)
    if (loc) setLocation(loc.name)
    refetchBySku()
  }, [location_id])

  useEffect(() =>
  {
    const openClass = punches.time_punchs.find(t => t.out === null && t.in_time !== null)
    if (openClass) {
      const { in_time: in_t, out: out_t } = openClass
      console.log({ in_t, out_t })
      const tm = (new Date(in_t)).getTime()
      console.log({openClass})
      startTimer(tm)
      setNoSchedule(true)
      setSelectedPunch(openClass)
    }
    // if (inTime && !out) {
      
    // }
  }
  , [punches])

  const updateQuery = (cache, updateTime_punch) =>
  {
    const test = cache.readQuery({
      query: GET_OPEN_TIME_PUNCHES,
      variables: GET_OPEN_TIME_PUNCHES_VAR
    })
    const { time_punchs } = test
    const currentPunch = time_punchs.pop()
    console.log({ test })
    cache.writeQuery({
      query: GET_OPEN_TIME_PUNCHES,
      variables: GET_OPEN_TIME_PUNCHES_VAR,
      data: {
        time_punchs: time_punchs
          ? [{ ...currentPunch, ...updateTime_punch }, ...time_punchs]
          : [updateTime_punch]
      }
    })
    time_punch_id = updateTime_punch.time_punch_id
    inTime = updateTime_punch.in_time
  }

  // create a timepunch, that isn't scheduled
  const [
    createTimePunch,
    { data: { time_punch_id: create_id } = {} }
  ] = useMutation(CREATE_TIME_PUNCH, {
    update(cache, { data })
    {
      const { createTime_punch } = data
      console.log({ createTime_punch })
      setSelectedPunch(createTime_punch)
      startTimer()

      const test = cache.readQuery({
        query: GET_OPEN_TIME_PUNCHES,
        variables: GET_OPEN_TIME_PUNCHES_VAR
      })
      const { time_punchs } = test
      console.log({ test })
      cache.writeQuery({
        query: GET_OPEN_TIME_PUNCHES,
        variables: GET_OPEN_TIME_PUNCHES_VAR,
        data: {
          time_punchs: time_punchs
            ? [...time_punchs, createTime_punch]
            : [createTime_punch]
        }
      })
      time_punch_id = createTime_punch.time_punch_id
      inTime = createTime_punch.in_time
    }
  })

  const [startScheduleTimePunch, { data: schedule_data = {} }] = useMutation(
    UPDATE_SCHEDULE_TIME_PUNCH,
    {
      update(cache, { data }) {
        startTimer()        
      }
    }
  )

  const [updateTimePunchClockOut, { data: { out: update_out } = {} }] = useMutation(
    UPDATE_TIME_PUNCH,
    {
      update(cache, { data }) {
        const { updateTime_punch } = data
        const { out } = updateTime_punch
        stopTimer((new Date(out)).getTime())
        setNoSchedule(false)
        setSelectedPunch({})
      }
    }
  )

  if (loading) {
    return <div>Loading...</div>
  }

  const togglePunch = (shouldEnd, punch) =>
  {
    if (punch) {
      setNoSchedule(true)
      setLocation(punch.location_id)
      setClassEventId(punch.class_event_id)
      setSelectedPunch(punch)
    };
    if (shouldEnd) {
      updateTimePunchClockOut({
        variables: {
          time_punch_id,
          out: new Date().toString()
        }
      })
    } else {
      console.log({class_event_id})
      if (class_event_id === '-1' && !punch) {
        setNoSchedule(false)
        return window.alert(
          "No Class Data found for location, and today. Can't start timepunch."
        )
      }        
      console.log({ time_punch_id, punch })
      if (!time_punch_id && !punch) {
        createTimePunch({
          variables: {
            user_email,
            location_id,
            class_event_id,
            in_time: new Date().toString(),
          }
        })
      } else {
        startScheduleTimePunch({
          variables: {
            time_punch_id,
            user_email,
            location_id,
            class_event_id,
            // if punch is passed, override everything else
            ...(punch || {}),
            in_time: new Date().toString(),
          }
        })
      }
    }
  }
  const letMePunch = () =>
  {
    resetTimer()
    setNoSchedule(true)
  }
  const shouldEnd = typeof time_punch_id === 'string'
  return (
    <Body>
      {permisions.auth_level > 80 ? <Redirect to="/youdontbelonghere" /> : null}
      {error && <div>Error! {error.message}</div>}
      {noSchedule ? (
        <>
          <Typography
            style={{ alignSelf: 'flex-center' }}
            variant="h4"
            gutterBottom
          >
            {elapsedTime}
          </Typography>
          {!shouldEnd && (
            <LocationPicker
              value={location_id}
              onChange={e => setLocationId(e.target.value)}
              showAll={false}
              className="input"
              location={location}
              filter={filter}
              onChangeFilter={e => setFilter(e.target.value)}
              save_id="location_id"
            />
          )}
          <Button
            color="primary"
            onClick={e => {
              togglePunch(shouldEnd)
            }}
          >
            {shouldEnd ? 'Stop' : 'Start'}
          </Button>
        </>
      ) : (
        <PunchList
          punches={punches}
          locations={locationData.locations || []}
          togglePunch={togglePunch}
          letMePunch={letMePunch}
        />
      )}
    </Body>
  )
}

function PunchList(props)
{
  const { letMePunch, togglePunch } = props
  const {
    // time_punchs: [{ in_time: inTime, out, id, time_punch_id, scheduled_date} = {}] = [{}],
    time_punchs = []
  } = props.punches || {}

  const dateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }
  const [today, setToday] = useState(
    new Date().toLocaleDateString('en-us', dateOptions)
  )
  const todayPunch = time_punchs.find(t =>
  {
    const dt = (new Date(t.scheduled_date).toLocaleDateString('en-us', dateOptions))
    return dt === today && t.in_time === null
  })
  // console.log(time_punchs.map(t => t.scheduled_date))
  return (
    <div>
      {time_punchs.map(p => (
        <Punch
          key={`punch_list_${  p.time_punch_id}`}
          time_punch_id={p.time_punch_id}
          scheduled_date={p.scheduled_date}
          today={today}
          out={p.out}
          in_time={p.in_time}
          dateOptions={dateOptions}
          setTimePunch={() => {}}
          location={props.locations.find(l => l.location_id === p.location_id)}
          togglePunch={togglePunch}
          punch={p}
        />
      ))}
      {time_punchs.length == 0 && <div>Nothing Scheduled</div>}
      <PunchHere >
        {
          todayPunch ?
            <FoundThatSchedule
              togglePunch={togglePunch}
              punch={todayPunch} />
            :
            <CantFindSchedule
              letMePunch={letMePunch}
            />
        }
      </PunchHere>

    </div>
  )
}

const PunchHere = props =>
{
  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100vw',
        height: 100,
        backgroundColor: '#8bc34a',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: "flex-end"
      }}
    >
      {props.children}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    marginBottom: 4,
    position: 'relative'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    flex: '1 0 auto',
    width: '100%'
  },
  cover: {
    width: 151
  },
  playIcon: {
    height: 38,
    width: 38
  },
  button: {
    position: 'absolute',
    top: 32,
    right: 8
  }
}))

export function Punch(props) {
  const [date, setDate] = useState('')
  const [subtitle, setSubtitle] = useState('')
  const [timeWorked, setTimeWorked] = useState('')
  const [warning, setWarning] = useState('')
  const [isToday, setIsToday] = useState(false)
  const [isActive, setIsActive] = useState(true)
  const classes = useStyles()
  const theme = useTheme()
  const {
    scheduled_date,
    today,
    dateOptions: options,
    location = {},
    start,
    togglePunch,
    role,
    punch,
    in_time,
    out
  } = props

  const en = {
    itsToday: 'Scheduled Today',
    sComplete: 'Shift Completed!',
    notScheduled: 'Time recorded, awaiting lead approval.',
    noPunch: 'Punch Missed, please contact lead.'
  }

  useEffect(() => {
    console.log(date, scheduled_date)
    const schedule = new Date(scheduled_date === null ? (new Date()) : scheduled_date)
    
    setIsActive(schedule.getTime() - (new Date()).getTime() > (-1000* 60 * 60 * 24 * 0.6))
    const dt = schedule.toLocaleDateString('en-US', options)
    const today = new Date().toLocaleDateString('en-US', options)
    setDate(dt)
    setIsToday(dt === today)
  }, [props.scheduled_date])


  useEffect(() => {
    setSubtitle(location.name)
  }, [location.name])


  useEffect(() => {
    console.log(date, scheduled_date)
    const dt = new Date(scheduled_date).toLocaleDateString('en-US', options)
    if (out) {
      const now = (new Date()).getTime()
      const beginTime = (new Date(in_time)).getTime()
      const endTime = (new Date(out)).getTime()
      const secs = (endTime - (beginTime == -1 ? now : beginTime)) / 1000
      setTimeWorked(`${getTime(secs)}`)
    }
  }, [out])

  useEffect(() =>
  {
    if (scheduled_date === null) {
      setWarning(en.notScheduled)
    }
    if (out === null && !isActive) {
      setWarning(en.noPunch)
    }
  }, [scheduled_date, out, isActive])

  const frmt = numb =>
  {
    // return num;
    if (numb < 0) return "00"
    const num = numb
    return num > 9 ? `${  String(num)}` : `0${  String(num)}`
  }
  const getTime = seconds =>
  {
    const rounded = Math.round(seconds)
    const mints = Math.floor(rounded / 60)
    const hrs = Math.floor(rounded / (60 * 60))
    const t = `${frmt(hrs)}:${frmt(mints % 60)}:${frmt(rounded % 60)}`
    return t
  }
  const textColor = isToday ? '#8bc34a' : (timeWorked ? '#aaa' : '#000')
  const subTextColor = isToday ? '#8bc34a' : (timeWorked ? '#ccc' : '#aaa')
  return (
    <>{(!isToday || timeWorked) && 
      <Card className={classes.card}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            {(timeWorked || !isActive) ?
              <>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: '#ccc',
                    width: 'calc(75% - 15px)',
                    textAlign: 'left',
                    display: 'inline-block'
                  }}
                >
                  {date}
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: '#ccc',
                    width: 'calc(25% - 15px)',
                    textAlign: 'right',
                    display: 'inline-block'
                  }}
                >
                  {timeWorked}
                </Typography>
              </>
              :
              <>
                <Typography
                  component={timeWorked ? 'p' : 'h5'}
                  variant={timeWorked ? 'p' : 'h5'}
                  style={{ color: textColor }}
                >
                  {date}
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: subTextColor,
                    width: 'calc(50% - 15px)',
                    textAlign: 'left',
                    display: 'inline-block'
                  }}
                >
                  {subtitle}
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: subTextColor,
                    width: 'calc(50% - 15px)',
                    textAlign: 'right',
                    display: 'inline-block'
                  }}
                >
                  {timeWorked}
                </Typography>
              </>
            }

            {warning && (
              <Chip
                style={{width: "100%"}}
                label={warning}
                color="secondary"
                size="small"
                avatar={<Avatar>!</Avatar>}
              />
            )}
          </CardContent>
        </div>
      </Card>
    }</>
    
  )
}

export function CantFindSchedule(props) {
  const classes = useStyles()
  const theme = useTheme()
  const { letMePunch } = props

  return (
    <>
      <Button
        aria-label="start clock"
        style={{
          border: "1px solid #fff",
          color: "#fff",
          fontSize: "1.2rem",
          borderRadius: "2rem",
          padding: ".5rem 1rem",
          width: "calc(100vw - 90px)"
        }}
        onClick={e =>
        {
          letMePunch(true)
        }}
      >
        Punch Clock
      </Button>
      <Typography
        variant="subtitle1"
        style={{ color: '#fff', textAlign: "center" }}
      >
        Not scheduled
      </Typography>
    </>
  )
}


export function FoundThatSchedule(props) {
  const { togglePunch, punch } = props
  return (
    <>
      <Button
        color="primary"
        aria-label="start clock"
        style={{
          border: "1px solid #fff",
          color: "#fff",
          fontSize: "1.2rem",
          borderRadius: "2rem",
          padding: ".5rem 1rem",
          width: "calc(100vw - 90px)"
        }}
        onClick={e => togglePunch(false, punch)}
      >
        Start Shift
      </Button>
      <Typography
        variant="subtitle1"
        style={{ color: '#fff', textAlign: "center" }}
      >
        Scheduled today: {new Date(punch.scheduled_date).toDateString()}
      </Typography>
    </>
  )
}

