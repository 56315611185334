import React, { useEffect, Fragment, useRef , useState } from 'react'
import { Mutation } from 'react-apollo'
import { SchemaForm } from 'react-schema-form'
import { withStyles } from '@material-ui/core/styles'
import { useQuery, useMutation } from '@apollo/react-hooks'
import {
  Card,
  Button,
  MenuItem,
  TextField,
  Grid,
  InputLabel,
  Select,
  Input,
  Chip
} from '@material-ui/core'
import Save from '@material-ui/icons/Save'
import Delete from '@material-ui/icons/Delete'

import orderBy from 'lodash/orderBy'
import gql from 'graphql-tag'
import { GET_SETTING, UPDATE_SETTING, DefaultQuery } from '../graphql'
import { timeZones } from '../data'

const LocationStyles = theme => (
  {
    filter: {
      width: '100%',
      height: 50,
      padding: 13,
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'italic',
      '&:focus': {
        outline: 'none',
        background: theme.palette.secondary[100],
        border: 'none'
      }
    }
  })

const CREATE_LOCATION = gql`
mutation cLocation($name: String!, $data: SequelizeJSON!) {
    createLocation(
      name: $name,
      data: $data,
      active: true
    ) {
      id,
      location_id,
      active,
    	name,
    	data
    }
  }`

export const GET_LOCATIONS = gql`
  {
    locations(order: "name"){
      id,
      location_id
      active
      name
      data
    }
  }`

const UDPATE_LOCATION_TEST = {
  "location_id": 1,
  "name": "test1",
  "active": true,
  "data": {}
}
const UDPATE_LOCATION = gql`
mutation uLocation($location_id: String!, $name: String!, $data: SequelizeJSON!, $active: Boolean!, $price_modifier: String!, $surcharge: String!) {
    updateLocation(
      location_id: $location_id,
      name: $name,
      data: $data,
      active: $active
      price_modifier: $price_modifier,
      surcharge: $surcharge,
    ) {
      id,
      location_id,
    	name,
      active,
      price_modifier,
      surcharge,
    	data
    }
  }`

export const locationVariables = {
  type: 'general',
  sub_type: 'settings',
  sub_sub_type: 'locations'
}

const defaultLocationValues = {
  Location: '',
  Store_Name: '',
  Address: '',
  Lead_RD: '',
  Email_Address: '',
  Category_ID: '',
  Code: '',
  lat: 0,
  long: 0,
  surcharge: 0,
  price_modifier: 1,
  timeZone: 'America/Denver',
  Couple_Class_Size: '',
  Pickup_Class_Size: '',
  Default_Class_Size: '',
  Grocery_L_Note: '',
  Meat_L_Note: ''
}

const formSchema = {
  uiSchema: {
    Location: {
      'ui:autofocus': true,
      'ui:emptyValue': ''
    }
  },
  formSchema: {
    title: 'Location Edit',
    description: 'For Editing a location',
    type: 'object',
    required: [
      'Code',
      'Default_Class_Size',
      'active',
      'name',
      'Store_Name',
      'Address',
      'Lead_RD',
      'Email_Address',
      'Regional_RD',
      'Regional_RD_Email',
      'Code',
      'Couple_Class_Size',
      'Default_Class_Size',
      'Grocery_L_Note',
      'Meat_L_Note'
    ],
    properties: {
      active: {
        type: 'boolean',
        title: 'Active'
      },
      name: {
        type: 'string',
        title: 'Location Name'
      },
      Store_Name: {
        type: 'string',
        title: 'Store Name'
      },
      Address: {
        type: 'string',
        title: 'Address',
        default: ''
      },
      timezone: {
        type: 'string',
        title: 'Time Zone',
        default: 'America/Denver',
        enum: timeZones
      },
      Lead_RD: {
        type: 'string',
        title: 'Lead Name'
      },
      Email_Address: {
        type: 'string',
        title: 'Lead Email'
      },
      Regional_RD: {
        type: 'string',
        title: 'Regional Lead Name'
      },
      Regional_RD_Email: {
        type: 'string',
        title: 'Regional Lead Email'
      },
      Category_ID: {
        type: 'number',
        title: 'WOO Category ID'
      },
      Code: {
        type: 'string',
        title: '2-3 Character Abbreviation'
      },
      Google_Drive_Folder: {
        type: 'string',
        title: 'Google Drive FolderId'
      },
      Couple_Class_Size: {
        type: 'number',
        title: 'Couples Class Size',
        default: 10
      },
      Default_Class_Size: {
        type: 'number',
        title: 'Default Class Size',
        default: 15
      },
      Pickup_Class_Size: {
        type: 'number',
        title: 'Pickup Class Size',
        default: 16
      },
      Grocery_L_Note: {
        type: 'string',
        title: 'Grocery Etrog Note'
      },
      Meat_L_Note: {
        type: 'string',
        title: 'Meat Etrog Note'
      },
      lat: {
        type: 'number',
        title: 'Lattitude',
        default: 16
      },
      long: {
        type: 'number',
        title: 'Longitute',
        default: 16
      },
      price_modifier: {
        type: 'number',
        title: 'Pricing Multiplier',
        default: 16
      },
      surcharge: {
        type: 'number',
        title: 'Pricing Offset',
        default: 16
      },
    }
  }
}

export const Locations = props => {
  const [selected, setSelected] = useState('3')
  return (
    <DefaultQuery
      query={GET_LOCATIONS}
      type="locations"
      render={data => {
        return (
          <>
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  flex: '0 1 30%',
                  width: 'calc(30% - 50px)',
                  maxHeight: '80vh',
                  overflow: 'auto'
                }}
              >
                <Card
                  style={{
                    padding: '20px 40px',
                    backgroundColor: selected === -1 ? '#8bc34a' : '#FFF',
                    color: selected === -1 ? '#FFF' : '#000',
                    cursor: 'pointer'
                  }}
                  onClick={e => setSelected(-1)}
                >
                  ADD LOCATION
                </Card>
                <LocationCards
                  setSelected={setSelected}
                  selected={selected}
                  style={{ flex: '0 1 50%' }}
                  data={data}
                />
              </div>
              <Card
                style={{
                  flex: '0 1 70%',
                  width: 'calc(70% - 50px)',
                  padding: '30px 60px',
                  position: 'relative'
                }}
              >
                <LocationEditor
                  selected={selected}
                  setSelected={setSelected}
                  data={data}
                />
              </Card>
            </div>
          </>
        )
      }}
    />
  )
}
export default Locations

const LocationEditor = props => {
  const {
    data: locations,
    selected,
    setSelected
  } = props
  // console.log({locations})
  const [location, setLocation] = useState({})
  const [showErrors, setShowErrors] = useState(false)
  useEffect(() =>
  {
    if (selected === -1) return setLocation(defaultLocationValues)
    const l = locations.find(l => l.location_id === selected)
    setLocation(l)
  }, [selected, locations])

  // orderBy(
  //   selected === -1
  //     ? [...locations, location]
  //     : locations.map(l =>
  //         l.Location === selected ? location : l
  //       ),
  //   ['Location'],
  //   ['asc']
  // )

  return (
    <DefaultQuery
      query={GET_SETTING}
      variables={{
        type: 'general',
        sub_type: 'settings',
        sub_sub_type: 'locations_schema'
      }}
      render={data => {
        return data && location ? (
          <Mutation
            mutation={CREATE_LOCATION}
          >
            {(cLocation, { data: mData }) => (
              <Mutation
                mutation={UDPATE_LOCATION}
              >
                {(uLocation, { data: mData }) => (
                  <div style={{ overflow: "auto", height: "calc(80vh - 140px)" }}>
                    <SchemaForm
                      schema={
                        data.length > 0 ? data[0].data : formSchema.formSchema
                      }
                      form={['*']}
                      model={{...defaultLocationValues, ...location, ...location.data}}
                      onModelChange={(key, val, type) => {
                        let price_modifier = location.price_modifier ?? 1
                        let surcharge = location.surcharge ?? 0

                        if (key[0] === 'surcharge') {
                          surcharge = val
                        }
                        if (key[0] === 'price_modifier') {
                          price_modifier = val
                        }
                        setLocation(key[0] === "name" || key[0] === "active" ? { ...location, price_modifier: `${price_modifier}`, surcharge: `${surcharge}`, [key[0]]: val } : { ...location, price_modifier: `${price_modifier}`, surcharge: `${surcharge}`, data: { ...location.data, [key[0]]: val } })
                      }}
                      showErrors={showErrors}
                      onError={e => console.log('errors', e)}
                    />
                    {selected !== -1 && (
                      <Button
                        style={{
                          position: 'absolute',
                          left: '60px',
                          bottom: '40px'
                        }}
                        onClick={e =>
                        {
                          if (
                            window.confirm(
                              'Are you sure you want to delete this location?'
                            )
                          ) {
                            uLocation({
                              variables: {
                                ...location,
                                active: false
                              }
                            })
                          }
                        }}
                      >
                        <Delete /> &nbsp; Delete
                      </Button>
                    )}

                    <Button
                      style={{
                        position: 'absolute',
                        right: '60px',
                        bottom: '40px'
                      }}
                      onClick={e =>
                      {
                        if (selected === -1) {
                          cLocation({
                            variables: {
                              ...location
                            }
                          })
                        } else {
                          uLocation({
                            variables: {
                              ...location
                            }
                          })
                        }
                    
                      }}
                    >
                      <Save /> &nbsp; Save
                    </Button>
                  </div>
                )}
              </Mutation>
            )}
          </Mutation>
        ) : (
          <div>No Data</div>
        )
      }}
    />
  )
}

const CardLocation = props => (
  <Card
    style={{
      padding: '20px 40px',
      backgroundColor: props.selected ? '#ff9b4b' : '#FFF',
      color: props.selected ? '#FFF' : props.active ? '#000' : '#AAA',
      cursor: 'pointer'
    }}
    onClick={e =>
      props.setSelected ? props.setSelected(props.location_id) : () => {}
    }
  >
    {props.children}
  </Card>
)

const LocationCards = props => {
  const { data = [], setSelected } = props
  return (
    <div>
      {data.map((location, i) => (
        <CardLocation
          selected={props.selected === location.location_id}
          key={`card_location_${i}`}
          location_id={location.location_id}
          active={location.active}
          setSelected={setSelected}
        >
          {location.name}
        </CardLocation>
      ))}
    </div>
  )
}


export function LocationPickerMulti(props)
{

  const { data: { locations: query_locations = [] } = {} } = useQuery(GET_LOCATIONS)
  const {
    value,
    onChange: handleChange,
    showAll = true,
    title = "Locations"
  } = props
  const locations = query_locations.filter(l => showAll || l.active)
  return (
    <Grid item xs={12}>
      <InputLabel id="location-picker-multi-select-label" shrink>{title}</InputLabel>
      <Select
        labelId="location-picker-multi-select-label"
        id="location-picker-multi-select"
        style={{ width: '100%' }}
        autoWidth
        multiple
        value={value}
        onChange={handleChange}
        input={<Input id="select-multiple-role" />}
        InputLabelProps={{
          shrink: true
        }}
        renderValue={selected => (
          <div>
            {locations.filter(l => selected.includes(l.location_id)).map(location => (
              <Chip key={location.location_id} label={location.name} />
            ))}
          </div>
        )}
      >
        {locations.map(r => (
          <MenuItem key={r.id} value={r.location_id}>
            {r.name}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  )
}


export let LocationPicker = props =>
{
  const {
    disabled,
    value,
    onChange,
    className,
    filter = "",
    style,
    onChangeFilter,
    defaultLocations,
    showAll = true,
    multiple = true,
    save_id = "Category_ID"
  } = props
  return (<DefaultQuery
    query={GET_LOCATIONS}
    type="locations"
    render={data =>
    {
      const maybeDefaultLocations = defaultLocations || (data ? data.map(l => ({ location_id: l.location_id, name: l.name, active: l.active, ...l.data })) : [])
      const locations = maybeDefaultLocations.filter(l => showAll || l.active)
      // console.log({ maybeDefaultLocations })
      return data ? (
        <TextField
          disabled={disabled}
          select
          multiple={multiple}
          value={value}
          style={style}
          onChange={e => onChange(e)}
          className={className}
          label="Location"
        >
          <FilterInput
            className={filter}
            type="text"
            value={filter}
            onChange={onChangeFilter}
          />
          {locations
            .filter(loc =>
              loc && loc.name && loc.name.toLowerCase().includes(
                filter.toLowerCase()
              )
            )
            .map((loc, i) => (
              <MenuItem
                key={loc.location_id}
                value={loc.location_id}
              >
                {loc.name}
              </MenuItem>
            ))}
        </TextField>
      ) : (
        []
      )
    }}
  />)
}
LocationPicker = withStyles(LocationStyles)(LocationPicker)

const FilterInput = props =>
{
  const inputEl = useRef(null)
  const { className, value, onClick, onChange } = props
  useEffect(() =>
  {
    inputEl.current.focus()
  }, [])

  return (
    <input
      ref={inputEl}
      className={className}
      type="text"
      value={value}
      onClick={onClick}
      onMouseDown={onClick}
      onMouseUp={onClick}
      onChange={onChange}
    />
  )
}

export function LocationIdToName(props)
{
  const { data: { locations = [] } = {} } = useQuery(GET_LOCATIONS)
  const location = locations.find(r => r.location_id === props.children)
  // console.log(props.children, roles, role);
  return (<>
    {location && location.name}
  </>)
}

export function LocationCategoryToName(props)
{
  const { data: { locations = [] } = {} } = useQuery(GET_LOCATIONS)
  const location = locations.find(r => String(r.location_id) === String(props.children))
  // console.log(props.children, locations, location);
  return (<>
    {location && location.name}
  </>)
}