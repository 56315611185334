const initialState = {
  selectedIngredient: null
}

const IngredientsPageReducer = (state=initialState, action) => {
  switch (action.type) {
  case 'SELECT_INGREDIENT':
    return {...state, selectedIngredient: action.id}
  case 'DESELECT_ALL_INGREDIENTS':
    return {...state, selectedIngredient: null}
  default:
    return state
  }
}
export default IngredientsPageReducer
