import React, { useEffect, useState, Fragment } from 'react'
import shortid from 'shortid'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { withStyles } from '@material-ui/core/styles'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Switch,
  FormControlLabel,
  InputLabel
} from '@material-ui/core/'
import ReactJson from 'react-json-view'
import { wooClassTemplate, wooVariationsTemplate } from '../data'

export const GET_CLASS_TYPES = gql`
  query gClassType {
    class_types(order: "name") {
      id
      class_type_id
      string_id
      name
      schema_type
      template
      sub
      active
    }
  }
`

const GET_CLASS_TYPE_BY_ID = gql`
  query gClassType($class_type_id: Int!) {
    class_type(class_type_id: $class_type_id) {
      id
      class_type_id
      string_id
      name
      schema_type
      template
      sub
      active
    }
  }
`

const UPDATE_CLASS_TYPE = gql`
  mutation uClassType(
    $class_type_id: String!
    $name: String!
    $string_id: String!
    $template: SequelizeJSON!
    $sub: SequelizeJSON!
    $active: Boolean!
  ) {
    updateClass_type(
      class_type_id: $class_type_id
      name: $name
      string_id: $string_id
      schema_type: "WOO"
      active: $active
      template: $template
      sub: $sub
    ) {
      id
      class_type_id
      string_id
      name
      schema_type
      template
      sub
      active
    }
  }
`

const CREATE_CLASS_TYPE = gql`
  mutation cClassType(
    $name: String!
    $string_id: String!
    $template: SequelizeJSON!
    $sub: SequelizeJSON!
    $active: Boolean!
  ) {
    createClass_type(
      name: $name
      string_id: $string_id
      schema_type: "WOO"
      active: $active
      template: $template
      sub: $sub
    ) {
      id
      class_type_id
      string_id
      name
      schema_type
      template
      sub
      active
    }
  }
`

const getDefaults = e => {
  return {
    name: '',
    string_id: shortid.generate(),
    template: wooClassTemplate,
    schema_type: 'WOO',
    sub: wooVariationsTemplate,
    active: true
  }
}

const EditorStylesheet = theme => ({
  input: {
    width: '100%'
  },
  rowMargin: {
    marginBottom: 5
  },
  smallButton: {
    minWidth: '55px',
    padding: '8px 10px'
  }
})

function ClassTypesEditor(props) {
  const [classTypeCache, setClassTypeCache] = useState(getDefaults())
  const [clipboard, setClipboard] = useState(null)

  const { class_type_id } = props.match.params
  const {
    open,
    onClose,
    updateCache,
    classes: { input, rowMargin, midTitle, smallButton }
  } = props
  const isNew = class_type_id === 'new'
  const { data: { class_type = {} } = {} } = useQuery(GET_CLASS_TYPE_BY_ID, {
    variables: {
      class_type_id
    },
    onCompleted: data => {
      console.log(data)
      const { class_type = {} } = data
      setClassTypeCache(class_type)
    }
  })

  const {
    name,
    id,
    string_id,
    sub,
    template,
    schema_type,
    active
  } = classTypeCache

  const [updateClassType, { data: updateClassTypeRtn = {} }] = useMutation(
    UPDATE_CLASS_TYPE
  )
  const [createClassType, { data: createClassTypeRtn = {} }] = useMutation(
    CREATE_CLASS_TYPE,
    {
      update: (cache, { data }) => {
        const { createClassType } = data
        console.log({ createClassType })
        updateCache(cache, createClassType)
      }
    }
  )

  const saveClassType = e => {
    if (isNew) {
      createClassType({ variables: classTypeCache })
    } else {
      updateClassType({ variables: classTypeCache })
    }

    console.log('save class_type')
    props.history.push("/class-type")
  }
  const set = (prop, e) => {
    setClassTypeCache({
      ...classTypeCache,
      [prop]: e.target.value
    })
  }

  return (
    <Dialog
      open={open}
      style={{ visibility: open ? 'display' : 'hidden' }}
      maxWidth="md"
      fullWidth
    >
      <>
        <DialogTitle>Editing Class Type: {name}</DialogTitle>
        <DialogContent>
          <Grid container className={rowMargin} spacing={3}>
            <Grid item sm={6} xs={12}>
              <TextField
                type="text"
                value={name}
                className={input}
                label="Name"
                onChange={e => {
                  set('name', e)
                }}
              />
            </Grid>

            <Grid item sm={3} xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!active}
                    onChange={e =>
                      set('active', {
                        target: {
                          value: !active,
                          checked: !active
                        }
                      })
                    }
                    color="primary"
                  />
                }
                label="Active"
              />
            </Grid>
            <Grid xs={12} style={{ padding: 16 }}>
              <InputLabel style={{ paddingBottom: 8 }}>
                Product (class)
              </InputLabel>
              <Button onClick={e => set('template', { target: { value: wooClassTemplate } })} >Set Default</Button>
              <ReactJson
                src={template}
                indentWidth={2}
                displayDataTypes={false}
                displayObjectSize={false}
                iconStyle="circle"
                onEdit={edits => set('template', { target: { value: edits.updated_src } })}
                onDelete={edits => set('template', { target: { value: edits.updated_src } } )}
                onAdd={edits =>
                {
                  console.log(edits.namespace)
                  if (Array.isArray(edits.new_value)) {
                    set('template', { target: { value: { ...edits.updated_src, [edits.name]: [...edits.existing_value, clipboard] } } })
                  } else {
                    set('template', { target: { value: { ...edits.updated_src } } })
                  }
                }}
                enableClipboard={cpy => setClipboard(cpy.src) }
              />
            </Grid>
            <Grid xs={12} style={{ padding: 16 }}>
              <InputLabel style={{ paddingBottom: 8 }}>
                Variation (sub)
              </InputLabel>
              <Button onClick={e => set('sub', { target: { value: wooVariationsTemplate } })} >Set Default</Button>
              <ReactJson
                src={sub}
                indentWidth={2}
                displayDataTypes={false}
                displayObjectSize={false}
                iconStyle="circle"
                onEdit={edits => set('sub', { target: { value: edits.updated_src } } )}
                onDelete={edits => set('sub', { target: { value: edits.updated_src } } )}
                onAdd={edits =>
                {
                  if (Array.isArray(edits.new_value)) {
                    set('sub', { target: { value: { ...edits.updated_src, [edits.name]: [...edits.existing_value, clipboard] } } })
                  } else {
                    set('sub', { target: { value: { ...edits.updated_src } } })
                  }
                }}
                enableClipboard={cpy => setClipboard(cpy.src) }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button color="primary" onClick={saveClassType}>
            Save Class Type
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

ClassTypesEditor = withStyles(EditorStylesheet)(ClassTypesEditor)
export default ClassTypesEditor
