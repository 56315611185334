import shortid from 'shortid'
import orderBy from 'lodash/orderBy'
import firebase, {
  baseNodeUrl,
  batchPromises,
  getEcommerceClass,
  getEcommerceParticipants,
  getPdfJson,
  saveEcommerceClass,
  completeWooOrders,
  setOnholdWooOrders,
  deleteWooClassPromise,
  runQuery,
  INGREDIENT_CREATE_GQL,
  INGREDIENT_DELETE_GQL,
  INGREDIENT_UPDATE_GQL,
  INGREDIENT_GET_ALL_GQL,
  CLASS_CREATE_GQL,
  CLASS_DELETE_GQL,
  CLASS_UPDATE_GQL,
  RECIPE_CREATE_GQL,
  RECIPE_DELETE_GQL,
  RECIPE_UPDATE_GQL,
  RECIPE_GET_ALL_GQL,
  LOCATIONS_FOR_USER_GQL
} from './api'

import {client, CREATE_CLASS, UPDATE_CLASS, GET_CLASS_ALL} from './graphql'

import { GET_LOCATIONS } from './components/Locations'
import { getEtrog } from './classOutput'
import { syncData as syncDataReal, importClasses } from './import'
import { classTypes } from './data'

export const setLoadingArray = loadingArray => ({
  type: 'SET_LOADING_ARRAY',
  loadingArray
})

export const LoadingFactory = error => ({
  msg: error,
  id: shortid.generate()
})

export const deleteLoadingById = (id, errors) => dispatch => {
  dispatch(setLoadingArray(errors.filter(e => e.id !== id)))
}

export const addLoading = (load, loadingArray) => (dispatch, getState) => {
  dispatch(
    setLoadingArray([
      ...(loadingArray || getState().app.loadingArray),
      load
    ])
  )
}

export const setErrors = errors => ({
  type: 'SET_ERRORS',
  errors
})

export const ErrorFactory = error => ({
  msg: error,
  id: shortid.generate()
})

export const deleteErrorById = (id, errors) => (dispatch, getState) => {
  dispatch(
    setErrors(
      (errors || getState().app.errors).filter(e => e.id !== id)
    )
  )
}

export const addError = (error, errors) => (dispatch, getState) => {
  console.log(error)
  dispatch(setErrors([...(errors || getState().app.errors), error]))
}

const setCheckingAuthState = checkingAuthState => ({
  type: 'SET_CHECKING_AUTH_STATE',
  checkingAuthState
})

const setLoggedInUser = user => ({
  type: 'SET_LOGGED_IN_USER',
  user
})

export const logoutUser = () => dispatch => {
  firebase
    .auth()
    .signOut()
    .catch(err => {
      console.error(err)
    })
}

export const clearUser = () => ({
  type: 'LOGOUT_USER'
})

export const setUserPermisions = payload => ({
  type: 'SET_USER_PERMISIONS',
  payload
})

// Ingredients Actions
const attachIngredientsListener = async dispatch => {
  const ingredients = await ingredientGet()
  // console.log(ingredients);
  dispatch(setIngredients(ingredients))
}

// const detachIngredientsListener = () => dispatch => {
//   // firebase.database().ref('/ingredients/').off();
// };

export const setIngredients = ingredients => ({
  type: 'SET_INGREDIENTS',
  ingredients
})

export const saveIngredient = ingredient => async (dispatch, getState) => {
  const id = ingredient.id ? ingredient.id : shortid.generate()
  const rtnIngredient = await ingredientSave({ ...ingredient, id })

  // console.log(getState().ingredients, ingredient)
  const ingredients = ingredient.db_id
    ? getState().ingredients.map(i => {
      if (i.id === id) return rtnIngredient
      return i
    })
    : [rtnIngredient, ...getState().ingredients]
  dispatch(setIngredients(ingredients))
}

export const deleteIngredient = ing => async dispatch => {
  // firebase.database().ref(`/ingredients/`).child(id).remove();
  const d = await ingredientDelete(ing)
  return d
}

export const selectIngredient = id => ({
  type: 'SELECT_INGREDIENT',
  id
})

export const deselectAllIngredients = () => ({
  type: 'DESELECT_ALL_INGREDIENTS'
})

export const updateIngredient = ingredient => async dispatch => {
  // console.log(ingredient);
  const d = await ingredientSave(ingredient)
  return d
}

// Recipes Actions
const attachRecipesListener = async (dispatch, getState) => {
  const recipes = await recipeGet()
  dispatch(setRecipes(recipes))
  setTimeout(() => {
    filterDisplayableRecipes()(dispatch, getState)
  }, 20)
}

export const deleteRecipe = rec => async dispatch => {
  const d = await recipeDelete(rec)
  return d
}

// const detachRecipesListener = () => dispatch => {};

const setRecipes = recipes => ({ type: 'SET_RECIPES', recipes })

export const setDisplayableRecipes = displayableRecipes => ({
  type: 'SET_RECIPES_DISPLAYABLE',
  displayableRecipes
})

export const setRecipeDisplayableFilter = displayableFilter => ({
  type: 'SET_RECIPE_FILTER',
  displayableFilter
})

export const setSelectedRecipes = selectedRecipes => ({
  type: 'SET_SELECTED_RECIPES',
  selectedRecipes
})

export const resetSelectedRecipes = () => (dispatch, getState) => {
  dispatch(setSelectedRecipes({}))
}

export const filterDisplayableRecipes = filter => (dispatch, getState) => {
  const state = getState()

  const filterWasPassedIntoFunction =
    filter !== undefined && typeof filter === 'string'

  if (filterWasPassedIntoFunction) {
    dispatch(setRecipeDisplayableFilter(filter))
    localStorage.setItem('recipeDisplayableFilter', JSON.stringify(filter))
  }

  filter = filterWasPassedIntoFunction
    ? filter
    : state.recipes.displayableFilter
  const filterUpper = filter.toUpperCase()
  const tempRecipes = state.recipes.recipes.filter(c => {
    // const name = c.name.toUpperCase();
    const name = c.name ? c.name.toUpperCase() : ''
    const t = name.includes(filterUpper)
    return t
  })

  dispatch(setDisplayableRecipes(tempRecipes))
}

/**
 * @description Archives recipe so it isn't pulled into the view on load
 * @returns {any} the returned recipe from the db
 */
export const archiveRecipes = () => async (dispatch, getState) => {
  const { selectedRecipes, recipes, displayableFilter } = getState().recipes
  const Recipes = Object.keys(selectedRecipes).map(k =>
    recipes.find(c => c.id === k)
  )
  console.log(Recipes)

  // clear selected recipees
  dispatch(setSelectedRecipes({}))

  const recipesPromises = Recipes.map(Recipe => {
    return recipeSave({
      ...Recipe,
      record_status: 'archive'
    })
  })
  return Promise.all(recipesPromises)
    .then((archivedRecipes, err) => {
      if (err)
        setErrors([
          ...getState().app.errors,
          ErrorFactory('Error archiving recipes.')
        ])
      const db_ids = archivedRecipes.map(c => c.db_id)
      // console.log("db_ids: ", db_ids)
      dispatch(
        setRecipes(
          recipes.filter(c => {
            const notFound = db_ids.includes(c.db_id) === false
            // console.log("notFound: ", notFound);
            return notFound
          })
        )
      )
      filterDisplayableRecipes(displayableFilter)(dispatch, getState)
      // console.log("archivedRecipes: ", archivedRecipes);
    })
    .catch(e => {
      setErrors([
        ...getState().app.errors,
        ErrorFactory('Error archiving recipes.')
      ])
      console.log(e)
    })
}

export const saveRecipe = (recipe, cb) => async (dispatch, getState) => {
  const id = recipe.id ? recipe.id : shortid.generate()
  let image = typeof recipe.image === 'string' ? recipe.image : ''

  if (typeof recipe.image === 'object' && recipe.image) {
    console.log('uploading image.')
    const fileExtension = recipe.image.name.split('.').pop()
    const imageRef = firebase
      .storage()
      .ref()
      .child(`recipeImages/${id}.${fileExtension}`)
    const snapshot = await imageRef.put(recipe.image)
    image = snapshot.downloadURL
  }

  const rtnRecipe = await recipeSave({
    ...recipe,
    id,
    image
  })

  let {recipes} = getState().recipes
  const foundRecipe = recipes.find(r => r.db_id === rtnRecipe.db_id)

  recipes = foundRecipe
    ? recipes.map(r => {
      if (r.id === id) return rtnRecipe
      return r
    })
    : [...recipes, rtnRecipe]
  dispatch(setRecipes(recipes))

  return rtnRecipe
}

export const deleteClass = (classe) => {
  return async (dispatch, getState) => {
    return runQuery(CLASS_DELETE_GQL, { id: classe.db_id })
  }
}

// delete once ClassEditorNew is functional
// export const deleteClass = (classe, errCb, cb) => async (
//   dispatch
// ) => {
//   try {
//     const classes = await classDelete(classe)
//
//     console.log('deleted.', classes)
//     cb({ ...classe, record_status: 'archive' })
//   } catch (e) {
//     addError(ErrorFactory('Failed to delete Class.', classe.id), [])(dispatch)
//   }
// }

export const markClassComplete = (classe, errCb, cb) => async (
  dispatch,
  getState
) => {

  const {locations} = getState().app
  const location = locations.find(
    l => String(l.location_id) === classe.location_id
  )
  const ecommerceClass = getState().classes.wooClass

  const pdfJson = await getPdfJson(classe.sku)

  // get orders
  getEcommerceParticipants(classe, orders => {
    console.log('get orders:', orders)

    // complete orders
    completeWooOrders(
      orders,
      (rtnOrders, err) => {
        console.log('saved rtnOrders:', rtnOrders, err)
        // move to draft mode
        saveEcommerceClass(
          ecommerceClass,
          { ...classe, completed: true, locked: true, status: 'complete' },
          location,
          async savedEcommerceClass => {
            console.log('saved Ecommerce Class:', savedEcommerceClass)
            let afterSaveClass = classe
            if (savedEcommerceClass.id) {
              // update class as locked
              afterSaveClass = await classSave({
                ...classe,
                completed: true,
                locked: true,
                pdfJson,
                status: 'complete'
              })
            }
            cb(afterSaveClass)
          },
          errCb
        )
      },
      errCb
    )
  })
}

export const markClassCancelled = (classe, errCb, cb) => async (
  dispatch,
  getState
) => {
  // const { id } = classe;

  const {locations} = getState().app
  const location = locations.find(
    l => String(l.Category_ID) === classe.location
  )
  const ecommerceClass = getState().classes.wooClass

  // move to draft mode
  saveEcommerceClass(
    ecommerceClass,
    { ...classe, enabled: false, completed: false, locked: true, status: 'cancelled' },
    location,
    async savedEcommerceClass => {
      console.log('saved Ecommerce Class:', savedEcommerceClass)
      let afterSaveClass = classe
      if (savedEcommerceClass.id) {
        // update class as locked
        afterSaveClass = await classSave({
          ...classe,
          enabled: false,
          completed: false,
          locked: true,
          status: 'cancelled'
        })
      }
      // get orders
      getEcommerceParticipants(classe, orders => {
        console.log('get orders:', orders)

        // cancel orders
        setOnholdWooOrders(
          orders,
          (rtnOrders, err) => {
            console.log('saved rtnOrders:', rtnOrders, err)
            cb(afterSaveClass)
          },
          errCb
        )
      })
    },
    errCb
  )
}

export const setSelectedClasses = selectedClasses => ({
  type: 'SET_SELECTED_CLASSES',
  selectedClasses
})

export const setCitrusDisplayableClasses = citrusDisplayableClasses => ({
  type: 'SET_DISPLAYABLE_CLASSES',
  citrusDisplayableClasses
})

export const setPreviousClass = curClass => {
  const { sku, product_id, id, ...prevClass } = curClass
  return {
    type: 'SET_PREV_CLASSES',
    prevClass
  }
}

export const setPrevSku = sku => {
  return {
    type: 'SET_PREV_SKU',
    sku
  }
}

export const setWooClass = payload => ({
  type: 'SET_WOO_CLASS',
  payload
})

export const setClass = (payload) => {
  return { type: 'SET_CLASS', payload }
}

/**
 * @description Archives class so it isn't pulled into the view on load
 * @returns {any} the returned class from the db
 */
export const archiveClasses = ({
  selectedClasses,
  citrusClasses,
  onFinish
}) => async (dispatch, getState) => {
  const Classes = Object.keys(selectedClasses).map(class_event_id =>
    citrusClasses.find(c => c.db_id === class_event_id)
  )
  console.log(Classes)

  // clear selected classes
  dispatch(setSelectedClasses({}))

  const classesPromises = Classes.map(Class => {
    const { db_id: class_event_id, ...value } = Class
    return classSave({
      ...value,
      record_status: 'archive',
      db_id: class_event_id
    })
  })
  return Promise.all(classesPromises)
    .then((archivedClasses, err) => {
      if (err)
        setErrors([
          ...getState().app.errors,
          ErrorFactory('Error archiving classes.')
        ])
    })
    .catch(e => {
      setErrors([
        ...getState().app.errors,
        ErrorFactory('Error archiving classes.')
      ])
      console.log(e)
    })
}

export const multiClassSave =  ({
  selectedClasses,
  citrusClasses,
  onFinish
}) => async (dispatch, getState) =>
{      

  const {
    app: { loadingArray }
  } = getState()

  const class_events = Object.keys(selectedClasses).map(class_event_id =>
    citrusClasses.find(c => c.db_id === class_event_id)
  )

  // clear selected classes
  dispatch(setSelectedClasses({}))
  const percentComplete = 0
  let exportLoading = LoadingFactory(
    `Saving Multiple Classes: ${percentComplete}% complete`
  )
  dispatch(addLoading(exportLoading, loadingArray))

  const simpleClassSave = c => {
    return new Promise((res, rej) => {
      saveClass(c, res, rej)(dispatch, getState)
    })
  }

  // save the classes
  await batchPromises(class_events, simpleClassSave, (pComplete) =>
  {
    exportLoading = LoadingFactory(
      `Saving Multiple Classes: ${pComplete.toFixed(1)}% complete`
    )
    dispatch(addLoading(exportLoading, []))
  })

  if (onFinish) onFinish(true)

  dispatch(setLoadingArray(
    loadingArray.filter(l => l.id !== exportLoading.id)
  ))
}

export const saveClass = (citrusClass) => {
  return (dispatch, getState) => {
    // Try to save in the DB
    // Then Update state
    // Catch error, update UI

    console.log(citrusClass)

    const id = citrusClass.id ? citrusClass.id : shortid.generate()
    const { db_id, class_event_id, date, original_id, sku, location, newRecipes, regular, small, participants, classType: class_type, recipes, pdf_json, ...value } = citrusClass
    const params = { value, date, original_id, sku, location: String(location), regular, small, participants: String(participants), class_type, recipes, pdf_json }

    let query

    if (!class_event_id) {
      params.id = id
      params.value.id = id
      query = CLASS_CREATE_GQL
    } else {
      params.id = class_event_id
      query = CLASS_UPDATE_GQL
    }

    console.log(params, id, citrusClass.id)

    return runQuery(query, params)
      .then((result) => {
        return result
      })
      .catch((error) => {
        return error
      })
  }
}

// export const saveClass = (clasee, errCb, cb) => (dispatch, getState) => {
//   dispatch(setPreviousClass(clasee))
//
//   const id = clasee.id ? clasee.id : shortid.generate()
//   // let index;
//   const {locations} = getState().app
//   const location = locations.find(
//     l => String(l.Category_ID) === String(clasee.location)
//   )
//   // debugger;
//   getEcommerceClass(
//     clasee,
//     location,
//     async ecommerceClass => {
//       console.log('saveClass->getEcommerceClass: ', ecommerceClass)
//       let { newRecipes, ...savedClass } = clasee
//       if (ecommerceClass.id) {
//         let {pdfJson} = clasee
//         if (pdfJson && pdfJson.err) {
//           pdfJson = await getPdfJson(clasee.sku)
//         }
//         savedClass = await classSave({
//           ...savedClass,
//           date: clasee.date,
//           ...(ecommerceClass.participants || ecommerceClass.participants === 0
//             ? { participants: ecommerceClass.participants }
//             : {}),
//           ...(ecommerceClass.small || ecommerceClass.small === 0
//             ? { small: ecommerceClass.small }
//             : {}),
//           ...(ecommerceClass.regular || ecommerceClass.regular === 0
//             ? { regular: ecommerceClass.regular }
//             : {}),
//           ...(ecommerceClass.sku ? { sku: ecommerceClass.sku } : {}),
//           ...(ecommerceClass.id ? { product_id: ecommerceClass.id } : {}),
//           ...(pdfJson ? { pdfJson } : {}),
//           id,
//           newRecipes: null,
//           image: typeof clasee.image === 'string' ? clasee.image : ''
//         })
//         console.log('saveClass->savedClass:', savedClass)
//       } else {
//         console.log('saveClass->Not Valid ecommerceClass:', ecommerceClass)
//       }
//       dispatch(setWooClass(ecommerceClass))
//       saveEcommerceClass(
//         ecommerceClass,
//         { ...savedClass, newRecipes },
//         location,
//         async savedEcommerceClass => {
//           console.log('saved Ecommerce Class:', savedEcommerceClass)
//           if (savedEcommerceClass.id) {
//             savedClass = await classSave({
//               ...savedClass,
//               id,
//               newRecipes: null,
//               enabled: savedEcommerceClass.status === 'publish',
//               classIsPublic:
//                 savedEcommerceClass.catalog_visibility === 'visible',
//               ...(savedEcommerceClass.sku
//                 ? { sku: savedEcommerceClass.sku }
//                 : {}),
//               ...(savedEcommerceClass.id
//                 ? { product_id: savedEcommerceClass.id }
//                 : {}),
//               ...(savedEcommerceClass.small || savedEcommerceClass.small === 0
//                 ? { small: savedEcommerceClass.small }
//                 : {}),
//               ...(savedEcommerceClass.regular ||
//               savedEcommerceClass.regular === 0
//                 ? { regular: savedEcommerceClass.regular }
//                 : {}),
//               ...(savedEcommerceClass.participants ||
//               savedEcommerceClass.participants === 0
//                 ? { participants: savedEcommerceClass.participants }
//                 : {}),
//               ...(savedEcommerceClass.name
//                 ? { name: savedEcommerceClass.name }
//                 : {})
//             })
//           }
//           cb(savedClass)
//         },
//         errCb
//       )
//     },
//     errCb
//   )
// }

export const getClass = (class_id) => {
  return (dispatch, setState) => {
    return client.query({query: GET_CLASS_ALL, variables: {where: {class_event_id: class_id}} })
  }
}

// export const getClass = (clasee, cb, errCb) => (dispatch) => {
//   const id = clasee && clasee.id ? clasee.id : shortid.generate()
//   const location = {}
//   let savedClass = clasee
//   getEcommerceClass(
//     clasee,
//     location,
//     async ecommerceClass => {
//       console.log('getClass: ', ecommerceClass)
//       dispatch(setWooClass(ecommerceClass))
//       if (ecommerceClass.id) {
//         savedClass = await classSave(
//           {
//             ...clasee,
//             // enabled: true,
//             // completed: false,
//             // locked: false,
//             date: clasee.date,
//             ...(ecommerceClass.sku ? { sku: ecommerceClass.sku } : {}),
//             ...(ecommerceClass.id ? { product_id: ecommerceClass.id } : {}),
//             enabled: ecommerceClass.status === 'publish',
//             classIsPublic: ecommerceClass.catalog_visibility === 'visible',
//             id,
//             image: typeof clasee.image === 'string' ? clasee.image : '',
//             ...(ecommerceClass.participants || ecommerceClass.participants === 0
//               ? { participants: ecommerceClass.participants }
//               : {}),
//             ...(ecommerceClass.small || ecommerceClass.small === 0
//               ? { small: ecommerceClass.small }
//               : {}),
//             ...(ecommerceClass.regular || ecommerceClass.regular === 0
//               ? { regular: ecommerceClass.regular }
//               : {})
//           },
//           () => {}
//         )
//       }
//       cb(savedClass)
//     },
//     errCb
//   )
// }

export const getParticipants = (classe, cb) => () => {
  getEcommerceParticipants(classe, participants => {
    if (cb) {
      cb(participants)
    }
  })
}

export const setParticipants = participants => ({
  type: 'SET_PARTICIPANTS',
  participants
})

export const setWooCategories = wooCategories => ({
  type: 'SET_WOOCATEGORIES',
  wooCategories
})

export const setWooTags = wooTags => ({
  type: 'SET_WOOTAGS',
  wooTags
})

export const setClassTypes = classTypes => ({
  type: 'SET_CLASSTYPES',
  classTypes
})

export const setLocations = payload => ({
  type: 'SET_LOCATIONS',
  payload: orderBy(payload, ['Location'], ['asc'])
})

export const setDataForClasses = () => dispatch => {
  dispatch(setClassTypes(classTypes))
}

export const classSave = async classe => {

  let rtn = []
  try {
    console.log('classe before save: ', classe)
    const {
      class_event_id,
      date,
      original_id,
      sku,
      location_id,
      newRecipes,
      regular,
      small,
      participants,
      class_type_id,
      recipes,
      pdf_json,
      class_size,
      ...value
    } = classe
    if (classe.class_event_id) {
      let params = {
        class_event_id,
        value,
        date,
        original_id,
        sku,
        location_id: String(location_id),
        regular,
        small,
        participants: String(participants),
        class_type_id,
        recipes,
        pdf_json,
        class_size,
      }
      rtn = await client.mutate({mutation: UPDATE_CLASS, variables: params})

      console.log(rtn)

      const { value: createValue, ...theRest } = rtn.data.updateClass_event
      rtn = {
        ...theRest,
        classType: theRest.class_type,
        ...rtn.data.updateClass_event.value,
        class_event_id: rtn.data.updateClass_event.class_event_id
      }
    }
    else {
      let params = {
        value,
        date,
        original_id,
        sku,
        location_id: String(location_id),
        newRecipes,
        regular,
        small,
        participants: String(participants),
        class_type_id,
        recipes,
        pdf_json,
        class_size,
      }
      rtn = await client.mutate({mutation: CREATE_CLASS, variables: params})

      const { value: createValue, ...theRest } = rtn.createClass_event
      rtn = {
        ...theRest,
        classType: theRest.class_type,
        ...rtn.createClass_event.value,
        class_event_id: rtn.createClass_event.class_event_id
      }
    }
    console.log(`class: `, rtn)
  }
  catch (e) {
    console.error('Error Saving Ingredient: ', e)
  }
  return rtn
}

const ingredientSave = async ingredient => {
  let rtn = []
  try {
    console.log('ingredient before save: ', ingredient)
    if (ingredient.db_id) {
      rtn = await runQuery(INGREDIENT_UPDATE_GQL, {
        id: ingredient.db_id,
        value: ingredient
      })
      rtn = {
        ...rtn.updateIngredient.value,
        db_id: rtn.updateIngredient.ingredient_id
      }
    } else {
      rtn = await runQuery(INGREDIENT_CREATE_GQL, {
        id: ingredient.id,
        value: ingredient
      })
      rtn = {
        ...rtn.createIngredient.value,
        db_id: rtn.createIngredient.ingredient_id
      }
    }
    console.log(`ingredient: `, rtn)
  } catch (e) {
    console.error('Error Saving Ingredient: ', e)
  }
  return rtn
}

const ingredientGet = async () => {
  try {
    const ingredientes = await runQuery(INGREDIENT_GET_ALL_GQL)
    // console.log(ingredientes)
    return ingredientes.ingredients.map(c => ({
      ...c.value,
      db_id: c.ingredient_id
    }))
  } catch (e) {
    console.error('Error loading Ingredients: ', e)
    return []
  }
}

export const locationsGet = async () => {
  try {
    const locationRtn = await runQuery(GET_LOCATIONS, {})
    // console.log(locationRtn)
    return locationRtn.locations
      ? locationRtn.locations.map(l => ({
        ...l.data,
        Location: l.name,
        active: l.active,
        location_id: l.location_id
      }))
      : []
  } catch (e) {
    console.error('Error loading Ingredients: ', e)
    return []
  }
}

const locationsForUserGet = async uid => {
  try {
    const locationRtn = await runQuery(LOCATIONS_FOR_USER_GQL, { uid })
    console.log({ locationRtn, uid })
    return locationRtn.users &&
      locationRtn.users[0] &&
      locationRtn.users[0].locations
      ? locationRtn.users[0].locations.map(l => l.location_id)
      : []
  } catch (e) {
    console.error('Error loading locationsForUserGet: ', e)
    return []
  }
}

const ingredientDelete = async ingrediente => {
  try {
    const ingredientes = await runQuery(INGREDIENT_DELETE_GQL, {
      id: ingrediente.db_id
    })
    return ingredientes
  } catch (e) {
    console.error('Error deleting Ingredients: ', e)
    return []
  }
}

const recipeSave = async recipe => {
  let rtn = []
  try {
    console.log('recipe before save: ', recipe)
    if (recipe.db_id) {
      rtn = await runQuery(RECIPE_UPDATE_GQL, { id: recipe.db_id, value: recipe })
      rtn = { ...rtn.updateRecipe.value, db_id: rtn.updateRecipe.recipe_id }
    } else {
      rtn = await runQuery(RECIPE_CREATE_GQL, { id: recipe.id, value: recipe })
      rtn = { ...rtn.createRecipe.value, db_id: rtn.createRecipe.recipe_id }
    }
    console.log(`recipe: `, rtn)
  } catch (e) {
    console.error('Error Saving Recipe: ', e)
  }
  return rtn
}

const recipeGet = async () => {
  try {
    const recipes = await runQuery(RECIPE_GET_ALL_GQL)
    return orderBy(
      recipes.recipes
        .map(c => ({ ...c.value, db_id: c.recipe_id }))
        .filter(
          c => c.record_status === undefined || c.record_status !== 'archive'
        ),
      ['name'],
      ['asc']
    )
  } catch (e) {
    console.error('Error getting Recipe: ', e)
    return []
  }
}

const recipeDelete = async recipe => {
  try {
    const recipes = await runQuery(RECIPE_DELETE_GQL, { id: recipe.db_id })
    return recipes
  } catch (e) {
    console.error('Error Deleting Recipe: ', e)
    return []
  }
}

export const syncData = (ingredients, recipes, classes) => () => {
  try {
    return syncDataReal(ingredients, recipes, classes)
  } catch (e) {
    console.error('Error Syncing Data from firebase: ', e)
    return []
  }
}

export const importJanuaryClasses = classes => () => {
  try {
    return importClasses(classes)
  } catch (e) {
    console.error('Error Syncing January classes: ', e)
    return []
  }
}

export const startEtrog = (classe, participants) => async () => {
  getEtrog(classe, participants).then((classEvent) => {
    console.log(classEvent)

    if (classEvent.spreadsheet_id) {
      const win = window.open(
        `https://docs.google.com/spreadsheets/d/${classEvent.spreadsheet_id}/`,
        '_blank'
      )

      win.focus()
    }

    return classEvent
  })
    .catch((error) => {
      console.log(error)
    })
}

export const attachNonAuthListeners = () => (dispatch, getState) => {
  attachIngredientsListener(dispatch, getState)
  attachRecipesListener(dispatch, getState)
}

export const detachListeners = () => () => {
  // dispatch(detachIngredientsListener())
  // dispatch(detachRecipesListener())
  // dispatch(detachClassesListener())
}

// Authentication Actions
export const attachLoginListener = (dispatch, cb) => {
  return firebase.auth().onAuthStateChanged(async user => {
    if (user) {
      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then(idTokenResult => {
          localStorage.setItem('firebase.auth.idToken', idTokenResult.token)
          const admin_permissions = localStorage.getItem('admin_permissions')
          const auth_level =
            idTokenResult.claims && idTokenResult.claims.auth_level
              ? idTokenResult.claims.auth_level
              : 100
          localStorage.setItem(
            'admin_permissions',
            JSON.stringify({
              ...(admin_permissions ? JSON.parse(admin_permissions) : {}),
              auth_level,
              isAdmin: auth_level <= 20
            })
          )
          cb(idTokenResult.token, idTokenResult.claims)
        })

      const locations = await locationsGet()
      const locationsForUser = await locationsForUserGet(user.uid)

      localStorage.setItem('user_locations', locationsForUser)

      const locs = locations.filter(l =>
        l.location_id && locationsForUser
          ? locationsForUser.includes(l.location_id)
          : false
      )

      dispatch(
        setUserPermisions({
          location: locs ? [...locs] : undefined
        })
      )
      dispatch(setLocations(locations))

      dispatch(setLoggedInUser(user))
      dispatch(detachListeners())
      dispatch(attachNonAuthListeners())
    } else {
      localStorage.setItem('firebase.auth.idToken', false)
      dispatch(setCheckingAuthState(false))
      dispatch(clearUser())
      dispatch(detachListeners())
    }
  })
}

// Initialization Actions
export const initializeFirebase = () => (dispatch, getState) => {
  attachLoginListener(dispatch, (token, claims) => {
    dispatch(
      setUserPermisions({
        auth_level: claims.auth_level,
        isAdmin: claims.auth_level <= 20
      })
    )
    attachNonAuthListeners()(dispatch, getState)
  })
}
